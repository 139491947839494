import React, {Component} from 'react';

export default class TermsRoute extends Component {
	render() {
		return <div className="PageContainer-Center PageContent">
			<h2>Terms of Use</h2>
			<p>Last updated November 7, 2021.</p>
			<p>This policy of terms and conditions is a legally binding agreement made between you and Neon Potato LLC, DBA Lalachievements, (referred to as Lalachievements, Us, or We hereafter) regarding your use of lalachievements.com and any other services provided (referred to as the "website" hereafter). By accessing this website you agree to be bound by these terms of use. If you do not agree with all of these terms, you must discontinue use of lalachievements.com immediately.</p>
			<p>This website is intended for users who are at least 13 years of age. If you are under the age of 13 you must obtain permission from your parent or legal guardian to use this website, and they must agree to these terms.</p>

			<p>ARBITRATION NOTICE: These terms contain an arbitration clause. You may opt out of this clause within thirty (30) days of agreeing to these terms by contacting us in writing.</p>
			<h3>Use of the website</h3>
			<p>If you register an account with the website you agree to keep your account (including password) secure and will be responsible for any use of your account. You agree that (1) any registration information you submit (including but not limited to contact and billing information) is accurate and complete to the best of your knowledge; (2) you are legally allowed to use this website and agree with these terms; (3) you are at least 13 years of age, and have received permission from your parent or legal guardian if you are a minor; (4) you will not use the website in any way that is illegal, violates any laws or regulations, interferes with normal operation of the website, or is prohibited by these terms.</p>
			<p>You agree not to use the website or any services provided by Lalachievements to:</p>
			<ol>
				<li>Impersonate another user or person, including by accessing another user's account</li>
				<li>Advertise, sell, or attempt to sell any goods or services</li>
				<li>Attempt to deceive or defraud any person</li>
				<li>Harass, abuse, intimidate, or attempt to harm any person or user</li>
				<li>Interfere with normal operations of, or create an exessive burden on, the website</li>
				<li>Attempt to bypass any security measures on the website</li>
				<li>Upload or transmit any viruses, trojan horses, spyware, or any other material that collects information or affects the website</li>
				<li>Disparage or otherwise harm, in our own opinion, the reputation of us or of the website</li>
				<li>Use the website in a manner inconsistent with any laws or regulations of the State of Arizona</li>
			</ol>
			<p>Any such forbidden use of the website may result in termination or suspension of your rights to use the website without warning.</p>
			<h3>Intellectual property rights</h3>
			<p>Unless otherwise indicated, the website is our proprietary property, and the content of the website is provided AS IS.</p>
			<p>Certain website content, including but not limited to images, text, videos, and trademarks, are owned by Square Enix Co., LTD. and used under license. We do not claim any ownership of any kind over these content.</p>
			<h3>User generated content</h3>
			<p>Certain website features, such as comment sections, allow users to submit content to the website. This content may be publicly viewable by other users, and by submitting any such content you warrant and agree that:</p>
			<ol>
				<li>The content does not infringe upon the rights of any third party, including but not limited to copyright rights</li>
				<li>You are the creator of the content, or have the necessary rights or permissions to allow us to use it</li>
				<li>The content is not misleading</li>
				<li>The content does not harass, abuse, intimidate, or attempt to harm any person or user</li>
				<li>The content does not include any offensive comments regarding race, gender, sexual preferences, physical handicaps, or any other topic we decide to be offensive</li>
				<li>The content does not violate the privacy of any person or user</li>
				<li>The content does not show or exploit any persons under the age of 18 in a sexual or excessively violent manner</li>
				<li>The content does not violate the laws of the State of Arizona or these terms of use</li>
			</ol>
			<p>Any such forbidden content submitted to the website may result in termination or suspension of your rights to use the website without warning.</p>
			<p>We do not claim ownership over any user submitted content. You grant us a non-exclusive, royalty-free, worldwide, perpetual license to copy, transmit, display, or otherwise use the content for any purpose related to the website or any other Lalachievements services.</p>
			<p>We claim the right to edit or remove any user submitted content at any time and for any reason.</p>
			<h3>Third party websites and content</h3>
			<p>The website may contain, or you may be sent to via links or advertisements, content from other websites. We are not responsible for any such third party content or services and you agree to hold us harmless from any losses or harm caused by such.</p>
			<h3>Privacy policy</h3>
			<p>By using the website you agree to be bound by our <a href="/privacypolicy">Privacy Policy</a>.</p>
			<h3>Terms and termination</h3>
			<p>These terms shall be in force for as long as you use the website. We reserve the right to deny access to the website to any person, for any reason or for no reason, at any time. If we terminate or suspend your account, you are prohibited from registering or using any account, including on behalf of a third party. If we deem your use of the website or services to be in breach of these terms, we reserve the right to take any appropriate legal action without limitation.</p>
			<h3>Modifications and interruptions</h3>
			<p>We reserve the right to change or remove any contents of the website at any time for any reason, but have no obligation to do so, or to provide any notification of such. We may discontinue any or all of the website without notice at any time, and you agree not to hold us liable for any such change, removal, or discontinuation.</p>
			<p>The website may at times be unavailable, whether because of hardware or software problems, maintenance, or other reasons. We do not warrant any availability and you agree not to hold us liable for any inconvenience, loss, or damage caused by any downtime, problems, or discontinuation of the website.</p>
			<h3>Governing law</h3>
			<p>These terms of use are governed by the laws of the State of Arizona.</p>
			<h3>Dispute resolution</h3>
			<p>In the event there is a dispute or claim related to these terms of use brought by either you or us (the "parties"), the parties agree to first attempt to negotiate the dispute informally for at least thirty (30) days before initiating arbitration.</p>
			<p>If the parties are unable to resolve a dispute through informal negotiations, the dispute will be resolved by binding arbitration. YOU ACKNOWLEDGE THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. You may opt out of this provision by contacting us in writing within thirty (30) days of agreeing to these terms. If you opt out, the remainder of these terms will remain in full effect.</p>
			<p>In no event shall any dispute be commenced more than one (1) year after the cause of action.</p>
			<p>The parties agree that any arbitration shall be limited to be between the parties individually. To the full extent permitted by law, no arbitration or any other legal action shall be joined with any other proceeding, including on a class-action basis, and a dispute may not be brought on behalf of the general public or another person.</p>
			<h3>Disclaimer and limitation of liability</h3>
			<p>This website is provided on an AS IS and AS AVAILABLE basis. You agree that your use of the website will be at your sole risk, and we will assume no liability or responsibility for any use of the website.</p>
			<p>You agree to defend, indemnify, and hold us harmless from and against any loss, damage, or liability made by any third party out of your use of the website or any breach of these terms of use.</p>
			<h3>Contact information</h3>
			<p>If you have any concerns about these terms, contact Lalachievements at:</p>
			<p>
				Neon Potato LLC<br />
				PO Box 1040<br />
				Laveen, Arizona 85339-0770
			</p>
			<p>info@lalachievements.com</p>
		</div>;
	}
}