import React, {Component} from 'react';
import '../gamedata/spritesheet/mount.css';
import '../gamedata/spritesheet/minion.css';
import '../gamedata/spritesheet/achievement.css';

//let mounts = require('../gamedata/json/en/mounts.json');
//let minions = require('../gamedata/json/en/minions.json');
//let achievements = require('../gamedata/json/en/achievements.json');
let dataCenters = require('../gamedata/json/dataCenters.json');
let clans = require('../gamedata/json/en/clans.json');
//let genders = require('../gamedata/json/en/genders.json');

class RarityRoute extends Component {
	state = {
		loading: true,
		loadedType: ''
	};
	componentDidMount() {
		this.fetch();
	}
	componentDidUpdate(prevProps) {
		if (this.props.url[2] !== prevProps.url[2] || this.props.url[1] !== prevProps.url[1]) {
			this.setState({loading: true});
			this.fetch();
		}
	}
	fetch() {
		let world = this.props.url[1];
		fetch('/api/stats/' + world).then((res) => {
			if (res.status < 200 || res.status >= 400) {
				return 500; //return this.setState({loading: false, loadedType: 'error'});
			}
			try {
				return res.json();
			} catch (e) {
				console.error(e);
			}
		}).then(rankings => {
			if (rankings === 500) {
				return this.setState({loading: false, loadedType: 'error'});
			}
			if (rankings.deployTime > window.LALA_DEPLOY_TIME) {
				return window.location.reload();
			}
			//let rankings = res.json();
			this.setState({
				loading: false,
				data: rankings.stats,
				charCount: rankings.count,
				generateTime: rankings.generateTime
			});
		});
	}
	render() {
		let world = this.props.url[1], lang = this.props.lang;
		let worldMenus = [];

		let className = '';
		if (world === 'global') className += ' current';
		worldMenus.push(<a href={`/${lang}/stats/global/`} className={className} key="global">Global</a>);
		for (let dc of dataCenters) {
			let className = 'SideMenu-Group';
			if (world === dc.Name) className += ' current';
			worldMenus.push(<a href={`/${lang}/stats/${dc.Name}/`} className={className} key={dc.Name}>{dc.Name}</a>);
			for (let w of dc.Worlds) {
				let className = '';
				if (world === w.Name) className += ' current';
				worldMenus.push(<a href={`/${lang}/stats/${w.Name}/`} className={className} key={w.Name}>{w.Name}</a>);
			}
		}

		let raceRows = [], pageContent;
		console.log(this.state.data);
		if ((!this.state.loading && this.state.loadedType === 'error')) {
			pageContent = 'Error loading';
		} else if (this.state.loading) {
			pageContent = 'Loading...';
		} else {
			for (let id in clans) {
				let clan = clans[id];
				let data = this.state.data.races[clan.Id];
				if (!data) {
					data = {
						total: 0,
						male: 0,
						female: 0
					};
				}
				raceRows.push(<tr className="RankingRow">
					<td>{Math.round(data.total * 1000 / this.state.data.count)/10}%</td>
					<td>{clan.Race}</td>
					<td>{clan.Tribe}</td>
					<td>{data.male}</td>
					<td>{data.female}</td>
					<td>{data.total}</td>
				</tr>);
			}
			let malePercent = Math.round(this.state.data.male * 1000 / this.state.data.count)/10;
			let femalePercent = Math.round(this.state.data.female * 1000 / this.state.data.count)/10;
			let hasFcPercent = Math.round(this.state.data.hasFc * 1000 / this.state.data.count)/10;
			let legacyPercent = Math.round(this.state.data.legacy * 1000 / this.state.data.count)/10;
			pageContent = <div>
				{ /*<p>
					Generated on {generateTime}.<br />
					Statistics update twice daily at midnight and noon.
				</p>*/ }

				<p>Characters: {this.state.data.count}</p>

				<p>
					Male: {this.state.data.male} ({malePercent}%)<br />
					Female: {this.state.data.female} ({femalePercent}%)
				</p>

				<p>
					Has FC: {this.state.data.hasFc} ({hasFcPercent}%)
				</p>

				<p>
					Legacy: {this.state.data.legacy} ({legacyPercent}%)
				</p>

				<table>
					<thead>
					<tr>
						<th>&nbsp;</th>
						<th>Race</th>
						<th>Tribe</th>
						<th>Male</th>
						<th>Female</th>
						<th>Total</th>
					</tr>
					</thead>
					<tbody>
					{raceRows}
					</tbody>
				</table>
			</div>;
		}
		/*let charRows;
		if ((!this.state.loading && this.state.loadedType === 'error') || !data) {
			charRows = 'Error loading';
			generateTime = ' ... ';
		} else if (this.state.loading || this.state.loadedType !== type) {
			charRows = 'Loading...';
			generateTime = ' ... ';
		} else {
			charRows = [];
			let i = 0;
			for (let rarity of this.state.data) {
				i++;
				let d = data.find(d => d.Id === rarity.id);
				let count = 0;
				if (rarity) count = rarity.count;
				let percent = count*100 / this.state.charCount;
				if (percent < 0.1) {
					percent = Math.round(percent * 1000) / 1000;
				} else if (percent < 1) {
					percent = Math.round(percent*100)/100;
				} else {
					percent = Math.round(percent*10)/10;
				}
				if (type === 'mount' || type === 'minion') {
					charRows.push(<tr class="RankingRow">
						<td class="RankingRow-Rank">{i}</td>
						<td class="RankingRow-Icon"><span className={`icon icon-${type}-${d.Icon}`} title={d.Name}/>
						</td>
						<td class="RankingRow-Text">
							<a href={`/${lang}/char/-/${type}/${d.Id}`} class="RankingRow-Name">{d.Name}</a><br />
							<span class="RankingRow-World">{d.data.howTo}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">{percent} %</span><br />
							<span class="RankingRow-All">({count} characters)</span>
						</td>
					</tr>);
				} else if (type === 'achievement') {
					charRows.push(<tr class="RankingRow">
						<td class="RankingRow-Rank">{i}</td>
						<td class="RankingRow-Icon"><span className={`icon icon-achievement-${d.Icon}`} title={d.Name}/>
						</td>
						<td class="RankingRow-Text">
							<a href={`/${lang}/char/-/${type}/${d.Id}`} class="RankingRow-Name">{d.Name}</a><br />
							<span class="RankingRow-World">{d.AchievementKind} / {d.AchievementCategory}</span><br />
							<span dangerouslySetInnerHTML={{__html:d.Description}} />
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">{percent} %</span><br />
							<span class="RankingRow-All">({count} characters)</span>
						</td>
					</tr>);
				} else if (type === 'title') {
					charRows.push(<tr class="RankingRow">
						<td class="RankingRow-Rank">{i}</td>
						<td class="RankingRow-Icon"><span className={`icon icon-achievement-${d.Icon}`} title={d.Name}/>
						</td>
						<td class="RankingRow-Text">
							<a href={`/${lang}/char/-/${type}/${d.Id}`} class="RankingRow-Name">{d.Title.Feminine}</a><br />
							<span class="RankingRow-World">{d.AchievementKind} / {d.AchievementCategory} / {d.Name}</span><br />
							<span dangerouslySetInnerHTML={{__html:d.Description}} />
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">{percent} %</span><br />
							<span class="RankingRow-All">({count} characters)</span>
						</td>
					</tr>);
				}
				//if (i >= 250) break;
			}
			generateTime = new Date(this.state.generateTime).toLocaleString('en-US', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				timeZoneName: 'short'
			});
		}*/

		return (
			<div class="PageContainer-Fixed">
				<div class="SideMenu">
					{worldMenus}
				</div>
				<div class="PageContent PageContent-WithMenu">
					<div className="PageNav PageNav-MobileMenu">
						<a href={'/'+this.props.url.join('/')} className="PageNav-MenuToggle" onClick={this.props.toggleBurger}>☰ <span className="PageNav-MenuToggleText">{world} (tap to change world)</span></a>
					</div>

					<h2>Statistics: {world}</h2>

					{pageContent}
				</div>
			</div>
		);
	}
}

export default RarityRoute;