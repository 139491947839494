import React, {Component} from 'react';

class HomeRoute extends Component {
	render() {
		let lang = this.props.lang, urlBase = this.props.urlBase;
		return (
			<div>
				<p className="HomeHeader">Keep track of your progress collecting everything that can be collected in FFXIV.</p>
			<div className="PageContainer-Center PageContent">
				<div className="HomeSidebar">
					<h2>Quick Links</h2>
					<a href={`${urlBase}ranking/achievement/global/`}>
						<i className="fontello-rankings" />
						<p>Rankings</p>
						See who has the most stuff
					</a>
					<a href={`${urlBase}rarity/title/global/`}>
						<i className="fontello-rarity" />
						<p>Rarity</p>
						See what the rarest things are
					</a>
					<a href={`${urlBase}charactersearch/`}>
						<i className="fontello-search" />
						<p>Character Search</p>
						Find yourself
					</a>
					<p>&nbsp;</p>
					<a href="https://discord.gg/jUfj3NV">
						<img src="/images/Discord-Logo-White.png" width="48" height="48" alt="Discord Logo" />
						<p>Discord</p>
						For problems, questions, &amp; suggestions&mdash;or just to talk
					</a>
					<a href="https://www.patreon.com/lalachievements">
						<img src="/images/Patreon_Mark_White.png" width="48" height="48" alt="Patreon Logo" />
						<p>Patreon</p>
						Help keep this website running (and help me eat)
					</a>
				</div>
				<div className="HomeText">
					<p>Lalachievements is a collectors guide for FFXIV, helping you track your progress completing your collections and compare yourself to other players on the rankings.</p>
					<p>All characters that are visible on the achievement rankings will (usually) update in the background daily. Other characters will update when someone visits their profile, and eventually on their own in the background as well. The frequency a character updates on its own depends on how high it is on the rankings.</p>
					<h2>Ranking Content Philosophy</h2>
					<p>Alternatively: Why is my achievement score different from the one displayed in-game?</p>
					<p>This website will only count things that a new player can obtain by playing the game, or can expect to obtain in the future. This means any limited-time items (such as from seasonal events) are not counted. However, they are always displayed on your profile.</p>
					<h2>API Access</h2>
					<p>This site has an API, but documentation is not public. Join the discord to discuss what you need.</p>
					<h2>Road Map</h2>
					<p>In no particular order, this is what you can eventually expect from this website:</p>
					<ul>
						<li>More collections: big fish, relics, quests, blue magic, etc</li>
						<li>More statistics and data</li>
						<li>Personalized todo lists and recommendations</li>
						<li>Trophies</li>
						<li>Layout improvements: mobile, checklists, etc</li>
						<li>French version (German and Japanese are also possible further in the future)</li>
						<li>General infrastructure improvements (like a better login system)</li>
						<li>Character comparisons</li>
						<li>Discord integration</li>
						<li>Patreon-only perks&mdash;the website will always be free. This will include things like control over your character portrait and a custom URL for your characters.</li>
						<li>Other things</li>
					</ul>
				</div>
			</div>
			</div>
		);
	}
}

export default HomeRoute;