import React, {Component} from 'react';
import auth0 from'../../auth0';
//import {useQuery} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import '../settings.scss';
import {ResponsiveRadar} from '@nivo/radar';
import theme from '../../styles/nivotheme';
import userStore from '../../stores/user';
let store = require('../../stores/gameData').default;

//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let charStore = require('../../stores/char').default;

function makeState() {
	let achievementCategories = store.get('achievementCategories');
	let titles = store.get('titles');
	let achievements = store.get('achievements').filter(a => a.titleId).map(a => {
		return Object.assign({}, a, {
			title: titles.find(t => t.id === a.titleId)
		});
	}).filter(a => a.title);
	return {
		achievements: achievements,
		achievementCategories: achievementCategories,
		titles: titles
	}
}
export default class CharVerifyRoute extends Component {
	state = Object.assign({
		verificationCode: '',
		loading: true,
		verifying: false,
		verified: false,
		error: false,
		imagePath: '',
		imageProgress: 0,
		imageStatus: ''
	}, makeState());
	constructor(props) {
		super(props);
		this.onFile = this.onFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.ProgressHandler = this.ProgressHandler.bind(this);
		this.SuccessHandler = this.SuccessHandler.bind(this);
		this.ErrorHandler = this.ErrorHandler.bind(this);
		this.AbortHandler = this.AbortHandler.bind(this);
	}
	async deleteFile(e) {
		e.preventDefault();
		await auth0.fetch(`/api/user/upload/charbanner/${this.props.char.id}`, {
			method: 'DELETE'
		}).then(f => f.text()).then(f => {
			if (f === 'OK') {
				this.setState({imageStatus: 'Banner image deleted'});
				this.props.char.hasBanner = false;
				//this.props.reload();
			} else {
				this.setState({imageStatus: f});
			}
		});
	}
	async onFile(e) {
		this.setState({imageStatus: '', imageProgress: 0.01});
		let file = e.target.files[0];
		//setFile(URL.createObjectURL(file));
		let formData = new FormData();
		formData.append('image', file);
		let xhr = await auth0.xhr(`/api/user/upload/charbanner/${this.props.char.id}`);
		xhr.upload.addEventListener('progress', this.ProgressHandler, false);
		xhr.addEventListener('load', this.SuccessHandler, false);
		xhr.addEventListener('error', this.ErrorHandler, false);
		xhr.addEventListener('abort', this.AbortHandler, false);
		//xhr.open("POST", `/api/user/upload/charbanner/${this.props.char.id}`);
		xhr.send(formData);
	}
	ProgressHandler(e) {
		//loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
		let percent = (e.loaded / e.total) * 100;
		//progressRef.current.value = Math.round(percent);
		//statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
		this.setState({imageProgress: Math.round(percent)});
	}
	SuccessHandler(e) {
		if (e.target.responseText === 'OK') {
			this.setState({imageStatus: 'Banner image saved', imageProgress: 0});
			//this.props.reload();
			this.props.char.hasBanner = new Date().getTime();
		} else {
			this.setState({imageStatus: e.target.responseText, imageProgress: 0});
		}
	}
	ErrorHandler() {
		this.setState({imageStatus: 'Failed'});
	}
	AbortHandler() {
		this.setState({imageStatus: 'Canceled'});
	}
	render() {
		let char = this.props.char;

		let isCharMine = userStore.getUserChars().find(c => c.id === char.id);
		if (!isCharMine) {
			return <div>Forbidden</div>;
		}

		let verifyButton, error;
		if (this.state.loading) {
			verifyButton = <button disabled="disabled">Loading...</button>;
		} else if (this.state.verifying) {
			verifyButton = <button disabled="disabled">Verifying...</button>;
		} else if (!this.state.verified) {
			verifyButton = <button onClick={this.verify}>Verify</button>;
		}
		if (this.state.error) {
			error = <p className="InfoBox InfoBox-Error">Could not find the verification code on this character's Lodestone profile. Unable to verify ownership of this character.</p>;
		}
		if (this.state.verified) {
			error = <p className="InfoBox InfoBox-Success">Verified successfully!</p>;
		}
		let titles = this.state.achievements.filter(a => char.achievements.find(ca => ca.id === a.id)).sort((a,b)=> {
			if (a.title.feminine < b.title.feminine) {return -1;}
			if (a.title.feminine > b.title.feminine) {return 1;}
			return 0;
		}).map(a => {
			return <option value={a.id}>{a.title.isPrefix ? a.title.feminine+'...' : '...'+a.title.feminine}</option>;
		});
		return <div>
			<div className="ImageBox ImageBoxTransparent">
				<img src="/images/search.png" alt="" />
				<div>
					<form className="SettingsBox">
						<h2>Privacy</h2>
						<p>{char.private ? <div>
							<p>This character is set to PRIVATE.</p>
						</div> : <div>
							<p>This character is set to public.</p>
						</div>}</p>
					</form>
					<form className="SettingsBox">
						<h2>Profile Banner Image</h2>
						<p>PNG or JPEG up to 10MB is accepted.</p>
						{this.state.imageStatus ? <p>{this.state.imageStatus}</p> : ''}
						{this.state.imageProgress > 0 ? <p><i className="fontello-spin1 animate-spin"/> {this.state.imageProgress} %</p> : ''}
						{char.hasBanner ? <img className="Char-Portrait" src={`/uploads/${char.id}.${char.hasBanner}.png`} alt="" /> : ''}
						<p><input type="file" name="file" onChange={this.onFile} /></p>
						{char.hasBanner ? <p><button onClick={this.deleteFile}>Delete</button></p> : ''}
					</form>
					{ /*<form className="SettingsBox">
						<h2>Featured Title</h2>
						<p>Select a title to feature on your profile page.</p>
						<select><option value={-1}>(none)</option>{titles}</select>
					</form>
					<p>&nbsp;</p>
					<form className="SettingsBox SettingsBox-Premium">
						<h2>Color Theme</h2>
						<p>Select a color for your character page. This primarily affects progress bars and links.</p>
						<div className="SettingsCharIconGroup SettingsCharColorGroup">
							<label className={'SettingsCharIcon SettingsCharColor0' + (true ? ' checked' : '')}><input type="radio" name="color" value="0" checked={true} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor1' + (false ? ' checked' : '')}><input type="radio" name="color" value="1" checked={false} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor2' + (false ? ' checked' : '')}><input type="radio" name="color" value="2" checked={false} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor3' + (false ? ' checked' : '')}><input type="radio" name="color" value="3" checked={false} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor4' + (false ? ' checked' : '')}><input type="radio" name="color" value="4" checked={false} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor5' + (false ? ' checked' : '')}><input type="radio" name="color" value="5" checked={false} /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon SettingsCharColor6' + (false ? ' checked' : '')}><input type="radio" name="color" value="6" checked={false} /><span>&nbsp;</span></label>
						</div>
					</form>*/ }
					<form className="SettingsBox SettingsBox-Premium">
						<h2>Character Icon</h2>
						<p>Select a facial expression to display on your profile and rankings. This does not affect full-size portraits.</p>
						<div className="SettingsCharIconGroup">
							<label className={'SettingsCharIcon' + (true ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={true} /><img src={char.iconUrl.replace('0_96x96', '0_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '1_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '2_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '3_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '4_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '5_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '6_96x96')} alt="" /><span>&nbsp;</span></label>
							<label className={'SettingsCharIcon' + (false ? ' checked' : '')}><input type="radio" name="icon" value="0" checked={false} /><img src={char.iconUrl.replace('0_96x96', '7_96x96')} alt="" /><span>&nbsp;</span></label>
						</div>
					</form>{/*
					<form className="SettingsBox SettingsBox-Premium">
						<h2>Custom URL</h2>
						<label className="SettingsPrefixedInput">
						https://lalachievements.com/{this.props.lang}/char/<input type="textbox" value={char.id} readOnly={false} />
						</label>
						<p>Custom URLs are not case sensitive and must be unique, alphanumeric, and start with a letter.</p>
						<button onClick={(e) => e.preventDefault()} disabled="disabled">Save</button>
					</form>*/ }
				</div>
			</div>
		</div>;
	}
}
