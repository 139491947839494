import React, {Component} from 'react';
import '../../gamedata/spritesheet/barding.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

let store = require('../../stores/gameData').default;
//let bardings = require('../../gamedata/json/en/bardings.json');

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        sourceTypes: store.get('sourceTypes'),
        bardings: store.get('bardings')
    };
}
export default class CharBardingsRoute extends Component {
    state = Object.assign({
        sourceFilter: -1,
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }
    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('barding').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('barding').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }
    setFilter(e) {
        let f = e.target.dataset.source * 1;
        if (this.state.sourceFilter === f) {
            this.setState({sourceFilter: -1});
        } else {
            this.setState({sourceFilter: f});
        }
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('barding', id);
        } else {
            charStore.unmarkItem('barding', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        let currentId = this.props.url[0]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            bardings.filter(barding => {
                if (category.Id === 1) return true;
                return barding.bardingCategory === category;
            }).forEach(barding => {
                let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}bardings/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/
        let filters = this.state.bardings.reduce(function(accumulator, d) {
            if (accumulator.indexOf(d.sourceTypeId) === -1) {
                accumulator.push(d.sourceTypeId);
            }
            return accumulator;
        }, []).sort();
        let filtersElements = filters.map((filterId) => {
            let sourceType = this.state.sourceTypes.find(t => t.id === filterId*1);
            if (!sourceType) return;
            let filterName = sourceType.name;
            let filteredBardings = this.state.bardings.filter(barding => barding.obtainable && barding.sourceTypeId === filterId);
            let hasBardings = filteredBardings.filter(barding => char.id > 0 && charStore.hasItem('barding', barding.id));
            let percent = char.id > 0 && hasBardings.length * 100 / filteredBardings.length;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <button data-source={filterId} key={filterId} className={this.state.sourceFilter === filterId*1?'current':''} onClick={this.setFilter}>
                {filterName}
                {char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
            </button>;
        });

        let hasCount = 0, totalCount = 0;
        let rows = this.state.bardings.filter(barding => {
            return true;
            //if (currentCategory.Id === 1) return true;
            //return barding.bardingCategory === currentCategory;
        }).filter(barding => {
            //let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('barding', barding.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (this.state.sourceFilter > 0 && this.state.sourceFilter !== barding.sourceTypeId) return false;
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort < b.sort) {return -1;}
            if (a.sort > b.sort) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(barding => {
            let url = `${urlBase}bardings/${barding.id}/`;
            //let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('barding', barding.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(barding.id, !has)}>
                    <i className={this.state.char.char.bardingload && this.state.char.char.bardingload.includes(barding.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let icon;
            if (barding.sourceTypeId === 7 || barding.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
            else if (!barding.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            return <tr className={className} key={barding.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${barding.id}h`} title={barding.name}/>{icon}</td>
                <td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${barding.id}b`} title={barding.name}/></td>
                <td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${barding.id}l`} title={barding.name}/></td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{barding.name}</a><br />
                </td>
                <td dangerouslySetInnerHTML={{__html:barding.howTo}} />
            </tr>;
        });
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let barding = this.state.bardings.find(l => l.id === currentId);
            if (barding) {
                /*let order;
                if (barding.Order < 1000) {
                    order = <div># {barding.Order}</div>
                } else {
                    order = <div>Hidden until obtained</div>
                }*/
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/bardings/`}><i
                        className="fontello-left-open"/> Back to barding list</a>
                    <h2>{barding.name}</h2>
                    { /*<div className="DetailsRewardsBox">
                        <div>{barding.bardingCategory.Name}</div>
                        <div>{order}</div>
                    </div>
                    <h3>In-game Source</h3>
                    <p>{barding.Description}</p>
                    <h3>Detailed Source</h3>
                    <p>&nbsp;</p>
                    <h3>Sample</h3>
                    <Audio src={`/assets/barding/${barding.Id}.ogg`} />*/ }
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">
            {/*this.state.loading &&
            <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
            */}

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>Bardings</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(char.bardingPercent)}%</p>
                    <p className="CompletionSummary-Numbers">{char.bardingCount} / {char.bardingPossible}</p>
                    <p className="CompletionSummary-Remaining">({char.bardingPossible-char.bardingCount} remaining)</p>
                    <span className={progressClassName} style={{width:char.bardingPercent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                    <h3>Bardings</h3>
                </div>}

                <div className="FilterBox">
                    <h2>Source</h2>
                    {filtersElements}
                </div>

                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}