import React, {Component} from 'react';
//import {useQuery, useApolloClient, useSubscription} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import '../../gamedata/spritesheet/achievement.css';
let urlify = require('urlify').create();

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let achievementCategories = require('../../gamedata/json/en/achievementCategories.json');
//let unobtainableAchievements = require('../../data/unobtainableAchievements');

let store = require('../../stores/gameData').default;

/*export default function CharAchievementsRoute({url, lang}) {
	let achievementKindsQuery = gql`
subscription {
  achievementKinds {
    id
    name:name_en
    achievementCategories {
      id
      name:name_en
    }
  }
}
`;
	let achievementsQuery = gql`
subscription {
  achievements {
    id
    name:name_en
    obtainable
    icon
    points
    description:description_en
    sortIndex:sort
    notLegacy
    item {
      id
      name:name_en
    }
    title:titleRef {
      id
      name:feminine_en
    }
    achievementCategoryId
  }
}
`;
	let characterQuery = gql`
subscription characters($characterId:Int!) {
  characters_by_pk(id: $characterId) {
    id
    achievements {
      createdAt
      achievementId
    }
  }
}
`;
	let achievementKindsResult = useSubscription(achievementKindsQuery);
	let achievementsResult = useSubscription(achievementsQuery);
	let characterResult = useSubscription(characterQuery, {
		variables: {characterId:url[1]}
	});
	/*const {data, error, loading} = useQuery(QUERY, {
		variables: {characterId:url[1]}
	});* /
	if (achievementKindsResult.loading || achievementsResult.loading || characterResult.loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (achievementKindsResult.error || achievementsResult.error || characterResult.error) {
		return <div>Error.</div>
	}
	let char = characterResult.data.characters_by_pk;
	let achievements = achievementsResult.data.achievements;
	let achievementKinds = achievementKindsResult.data.achievementKinds;
	/*return <CharAchievementsRouteInner lang={lang} char={data.characters_by_pk} achievements={data.achievements} achievementKinds={data.achievementKinds} url={url} />;
}*/

export default class CharAchievementsRouteInner extends Component {
	state = {
		achievements: store.get('achievements'),
		achievementCategories: store.get('achievementCategories'),
		titles: store.get('titles'),
		items: store.get('items'),
		rarity: store.getRarity('achievements'),
		sort: 'In-Game'
	};

	constructor(props) {
		super(props);
		store.on(this.onChange, this);
		this.setSort = this.setSort.bind(this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}

	onChange() {
		this.setState({
			achievements: store.get('achievements'),
			achievementCategories: store.get('achievementCategories'),
			titles: store.get('titles'),
			items: store.get('items'),
			rarity: store.getRarity('achievements')
		});
	}
	setSort(e) {
		this.setState({
			sort: e.target.innerHTML
		});
	}
	render() {
		let char = this.props.char, lang = this.props.lang, urlBase = this.props.urlBase;//, achievements = this.props.achievements, achievementKinds = this.props.achievementKinds;
		//let obtained = [], extra = [], remaining = [], unobtainable = [];*/
		let currentKindSlug = this.props.url[0], currentKind;
		let currentCategorySlug = this.props.url[1], currentCategory;

		let achievements = this.state.achievements;
		let achievementCategories = this.state.achievementCategories;
		let titles = this.state.titles;
		let items = this.state.items;
		let rarity = this.state.rarity;
		//let achievements = store.get('achievements');
		//let achievementCategories = store.get('achievementCategories');
//console.log(achievements);
		/*char.achievementKinds = {};
		char.achievementPoints = 0;
		char.achievementPointsLegacy = 0;
		char.achievementPointsPossible = 0;
		achievements.forEach(a => {
			let has = !!char.achievements.find(aa => aa.achievementId === a.Id);
			if (a.obtainable) {
				if (has) char.achievementPoints += a.points;
				char.achievementPointsPossible += a.points;
			}
			if (has) char.achievementPointsLegacy += a.points;
		});
		char.achievementRecent = char.achievements.length > 1 ? char.achievements.sort(function sortDateThenId(m1, m2) {
				if (m1.date > m2.date) return -1;
				if (m1.date < m2.date) return 1;
				if (m1.Id > m2.Id) return -1;
				if (m1.Id < m2.Id) return 1;
				return 0;
			})[0].Id : 0;
		achievementKinds.forEach(kind1 => {
			let kind = kind1.Name;
			char.achievementKinds[kind] = {
				count: 0,
				countPossible: 0,
				points: 0,
				pointsPossible: 0,
				titles: 0,
				titlesPossible: 0,
				items: 0,
				itemsPossible: 0
			};
			kind1.achievementCategories.forEach(category => {
				char.achievementKinds[kind][category.Name] = {
					count: 0,
					countPossible: 0,
					points: 0,
					pointsPossible: 0,
					titles: 0,
					titlesPossible: 0,
					items: 0,
					itemsPossible: 0
				};
				achievements.filter(a => a.achievementCategoryId === category.Id)
					.forEach(a => {
						char.achievementKinds[kind].countPossible++;
						char.achievementKinds[kind][category.Name].countPossible++;
						char.achievementKinds[kind].pointsPossible += a.points;
						char.achievementKinds[kind][category.Name].pointsPossible += a.points;
						if (a.title) {
							char.achievementKinds[kind].titlesPossible++;
							char.achievementKinds[kind][category.Name].titlesPossible++;
						}
						if (a.item) {
							char.achievementKinds[kind].itemsPossible++;
							char.achievementKinds[kind][category.Name].itemsPossible++;
						}
						if (char.achievements.find(aa => aa.achievementId === a.Id)) {
							char.achievementKinds[kind].count++;
							char.achievementKinds[kind][category.Name].count++;
							char.achievementKinds[kind].points += a.points;
							char.achievementKinds[kind][category.Name].points += a.points;
							if (a.title) {
								char.achievementKinds[kind].titles++;
								char.achievementKinds[kind][category.Name].titles++;
							}
							if (a.item) {
								char.achievementKinds[kind].items++;
								char.achievementKinds[kind][category.Name].items++;
							}
						}
					});
			});
		});*/
		//let kinds = {};
		let kindAll, categoryAll;
		let categories = [];
		let kinds = achievementCategories.map(function(kind) {
			//let kind = achievementCategories[kindName];
			let kindName = kind.Name;
			//let kindName = kind.Name;
			let slug = kind.Slug;
			let current = slug===currentKindSlug;
			let slug1 = kind.Categories[0].Slug;
			let charKind, pointsPercentKind, titleKind, progressClassName;
			if (char.id > 0) {
				charKind = char.achievementKinds[kind.Id];
				progressClassName = 'FilterProgressInner';
				if (charKind.pointsPossible === 0) {
					pointsPercentKind = Math.floor((charKind.pointsUnobtainable * 100) / charKind.pointsPossibleUnobtainable);
					titleKind = charKind.pointsUnobtainable + ' / ' + charKind.pointsPossibleUnobtainable + ' (' + pointsPercentKind + '%)';
					progressClassName += ' FilterProgressInner-Complete';
				} else {
					pointsPercentKind = Math.floor((charKind.points * 100) / charKind.pointsPossible);
					titleKind = charKind.points + ' / ' + charKind.pointsPossible + ' (' + pointsPercentKind + '%)';
					if (pointsPercentKind === 100) {
						progressClassName += ' FilterProgressInner-Complete';
					}
				}
			}
			if (current) {
				currentKind = kind;
				categories = kind.Categories.map(function(category) {
					//let slug2 = urlify(category.Name);
					let slug2 = category.Slug;
					let current1 = slug2===currentCategorySlug;
					if (current1) {
						slug1 = slug2;
						currentCategory = category;
					}
					let charCategory, pointsPercentCategory, titleCategory, progressClassName1;
					if (char.id > 0) {
						charCategory = charKind[category.Id];
						//pointsPercentCategory = Math.floor((charCategory.points * 100) / charCategory.pointsPossible);
						//titleCategory = charCategory.points + ' / ' + charCategory.pointsPossible + ' (' + pointsPercentCategory + '%)';
						progressClassName1 = 'FilterProgressInner';
						if (charCategory.countPossible === 0) {
							pointsPercentCategory = Math.floor((charCategory.pointsUnobtainable * 100) / charCategory.pointsPossibleUnobtainable);
							titleCategory = charCategory.pointsUnobtainable + ' / ' + charCategory.pointsPossibleUnobtainable + ' (' + pointsPercentCategory + '%)';
							progressClassName1 += ' FilterProgressInner-Complete';
						} else if (charCategory.pointsPossible === 0) {
							pointsPercentCategory = Math.floor((charCategory.count * 100) / charCategory.countPossible);
							titleCategory = charCategory.count + ' / ' + charCategory.countPossible + ' (' + pointsPercentCategory + '%)';
							if (pointsPercentCategory === 100) {
								progressClassName1 += ' FilterProgressInner-Complete';
							}
						} else {
							pointsPercentCategory = Math.floor((charCategory.points * 100) / charCategory.pointsPossible);
							titleCategory = charCategory.points + ' / ' + charCategory.pointsPossible + ' (' + pointsPercentCategory + '%)';
							if (pointsPercentCategory === 100) {
								progressClassName1 += ' FilterProgressInner-Complete';
							}
						}
					}
					return <a href={`${urlBase}achievements/${slug}/${slug2}/`} className={current1?'current':''} key={category.Name} title={char.id > 0 ? titleCategory : ''}>
						{category.Name}
						{char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName1} style={{width:pointsPercentCategory+'%'}}>&nbsp;</span></span> : ''}
					</a>;
				});
				categoryAll = <a href={`${urlBase}achievements/${slug}/`} className={currentCategorySlug===''?'current':''} title={char.id > 0 ? titleKind : ''}>
					All
					{char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:pointsPercentKind+'%'}}>&nbsp;</span></span> : ''}
				</a>;
			}
			return <a href={`${urlBase}achievements/${slug}/`} className={current?'current':''} key={kindName} title={char.id > 0 ? titleKind : ''}>
				{kindName}
				{char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:pointsPercentKind+'%'}}>&nbsp;</span></span> : ''}
			</a>;
		});
		let filteredAchievements = achievements.filter(a => {
			if (currentCategory) return a.category.Id === currentCategory.Id;
			else if (currentKind) return a.kind.Id === currentKind.Id;
			else return false;
		}).sort((a,b)=> {
			if (this.state.sort === 'In-Game') {
				if (a.sort < b.sort) return -1;
				if (a.sort > b.sort) return 1;
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			} else if (this.state.sort === 'Rarest' || this.state.sort === 'Most Common') {
				let ra = rarity.find(_r => _r.id === a.id);
				let rb = rarity.find(_r => _r.id === b.id);
				let rap = 100, rbp = 100;
				if (ra) rap = ra.percentFull;
				if (rb) rbp = rb.percentFull;
				if (this.state.sort === 'Rarest') {
					if (rap < rbp) return -1;
					if (rap > rbp) return 1;
				} else {
					if (rap < rbp) return 1;
					if (rap > rbp) return -1;
				}
				if (a.sort < b.sort) return -1;
				if (a.sort > b.sort) return 1;
				if (a.id < b.id) return -1;
				if (a.id > b.id) return 1;
				return 0;
			}
		});
		let charCategory;

		if (currentCategory && char.id > 0) charCategory = char.achievementKinds[currentKind.Id][currentCategory.Id];
		else if (currentKind && char.id > 0) charCategory = char.achievementKinds[currentKind.Id];
		//console.log(filteredAchievements);
		//let titlesObtained = 0, titlesRemaining = 0, itemsObtained = 0, itemsRemaining = 0, pointsObtained = 0, pointsRemaining = 0;
		function formatRow(a) {
			let url = `${urlBase}achievements/${currentKindSlug}/${currentCategorySlug}/${a.id}`;
			let title, item;
			if (a.titleId > 0) {
				let title1 = titles.find(t => t.id === a.titleId);
				title = <div className="MountRow-Title">Rewards title: "{title1 ? title1.feminine : '? ? ?'}"</div>;
				//titlesObtained++;
			}
			if (a.itemId > 0) {
				let item1 = items.find(i => i.id === a.itemId);
				item = <div className="MountRow-Item">Rewards item: {item1 ? item1.name : '? ? ?'}</div>;
				//itemsObtained++;
			}
			let r = rarity.find(_r => _r.id === a.id);
            let icon;
			if (!a.obtainable) {
			//if (unobtainableAchievements.indexOf(a.id) > -1 || currentKind.Slug === 'Legacy' || currentCategory.Slug === 'Seasonal_Events') {
                icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
			}
			//pointsObtained += a.points;
			//let has = char.achievements.indexOf(a.Id) > -1;
			//let className = has ? 'MountRow MountRow-Obtained' : 'MountRow';
			return <tr className='MountRow' key={a.id}>
				<td className="MountRow-Icon"><a href={url} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-achievement icon-achievement-${a.icon}`} />{icon}</a></td>
				<td className="MountRow-Text">
					<a href={url} className="MountRow-Name">{a.name}</a>
					<div dangerouslySetInnerHTML={{__html:a.description}} />
					{item}{title}
				</td>
				{ /*<td className="MountRow-Reward">{item}{title}</td>*/ }
				<td className="MountRow-Value">
					<span className="RankingRow-Number">{a.points}</span><br />
					{r ? <span className="RankingRow-All" title={`Obtained by ${r.count} characters`}>{r.percent}%</span> : ''}
				</td>
			</tr>;
		}

		let achievementRowsObtained = filteredAchievements
			.filter(a => char.id > 0 && char.achievements.findIndex(ca => ca.id === a.id) > -1)
			.map(formatRow);

		let achievementRowsUnobtainable;
		if (currentCategorySlug !== '') {
			achievementRowsUnobtainable = filteredAchievements
				.filter(a => (char.id <= 0 || char.achievements.findIndex(ca => ca.id === a.id) === -1) && !a.obtainable)
				.map(formatRow);
		} else {
			achievementRowsUnobtainable = [];
		}

		let achievementRowsRemaining = filteredAchievements
			.filter(a => (char.id <= 0 || char.achievements.findIndex(ca => ca.id === a.id) === -1) && a.obtainable)
			.map(formatRow);
			/*.map(function(a) {
				let url = `${urlBase}achievements/${currentKindSlug}/${currentCategorySlug}/${a.id}`;
				let title, item;
				if (a.titleId > 0) {
					let title1 = titles.find(t => t.Id === a.titleId);
					title = <div className="MountRow-Title">"{title1 ? title1.Feminine : 'Unknown Title'}"</div>;
					//titlesRemaining++;
				}
				if (a.ItemId > 0) {
					item = <div className="MountRow-Item">Item: {a.Item.Name}</div>;
					//itemsRemaining++;
				}
				let unobtainable;
				if (unobtainableAchievements.indexOf(a.id) > -1 || currentKind === 'Legacy' || currentCategory.Name === 'Seasonal Events') {
					unobtainable = <i className="fontello-attention" />
				}
				//pointsRemaining += a.points;
				//let has = char.achievements.indexOf(a.Id) > -1;
				//let className = has ? 'MountRow MountRow-Obtained' : 'MountRow';
				return <tr className='MountRow MountRow-Obtained' key={a.id}>
					<td className="MountRow-Icon"><a href={url}><span className={`icon icon-achievement icon-achievement-${a.icon}`} /></a></td>
					<td className="MountRow-Text">
						<a href={url} className="MountRow-Name">{a.name}</a>
						<div dangerouslySetInnerHTML={{__html:a.description}} />
					</td>
					<td className="MountRow-Reward">{item}{title}</td>
					<td className="MountRow-Value">{a.points} {unobtainable}</td>
				</tr>;
			});*/
		/*if (this.state.loading) return <div>Loading...</div>;

		for (let d of data) {
			let charData = this.props.char[typePlural].find(dd => dd.Id === d.Id);
			let has = !!charData; //this.props.data.hasOwnProperty(d.Id);
			let obtainable = typeof d.data.obtainable === 'undefined' || !!d.data.obtainable;
			if (has && obtainable) {
				obtained.push(d);
			} else if (has && !obtainable) {
				extra.push(d);
			} else if (obtainable) {
				remaining.push(d);
			} else {
				unobtainable.push(d);
			}
		}
		obtained = obtained.map(d => {
			return <a href={`${urlBase}${type}/${d.Id}/`} key={d.Id}><span className={`icon icon-${type}-${d.icon}`} title={d.Name} /></a>;
		});
		extra = extra.map(d => {
			return <a href={`${urlBase}${type}/${d.Id}/`} key={d.Id}><span className={`icon icon-${type}-${d.icon}`} title={d.Name} /></a>;
		});
		unobtainable = unobtainable.map(d => {
			return <a href={`${urlBase}${type}/${d.Id}/`} key={d.Id}><span className={`icon icon-${type}-${d.icon}`} title={d.Name} /></a>;
		});

		remaining = remaining.map(d => {
			return <tr className="MountRow" key={d.Id}>
				<td className="MountRow-Icon"><a href={`${urlBase}${type}/${d.Id}/`}><span className={`icon icon-${type}-${d.icon}`} title={d.Name} /></a></td>
				<td className="MountRow-Text">
					<a href={`${urlBase}${type}/${d.Id}/`} className="MountRow-Name">{d.Name}</a><br />
					From: <span className="MountDetails-Tag">{d.data.from}</span>
				</td>
				<td>
					{d.data.howTo}
				</td>
				<td>{d.data.patch}</td>
			</tr>;
		});

		return <div>
			<h2>{obtained.length+extra.length} {type}s</h2>
			<p>{obtained.length} of these count towards your rankings.</p>
			{obtained}

			<p>{extra.length} of these do <em>not</em> count towards your rankings as they are no longer possible to collect.</p>
			{extra}

			<h2>{remaining.length} {type}s left to collect</h2>

			<table className="MountTable">
				<tbody>
				{remaining}
				</tbody>
			</table>

			<h2>{unobtainable.length} {type}s are not currently obtainable</h2>
			<p>These mounts are either from past events, limited-time promotions, or are not yet released, and do not count against your rankings.</p>
			{unobtainable}
		</div>;*/
		//let pointsTotal = pointsRemaining+pointsObtained;
		let pointsPercent, titlesPercent, itemsPercent;
		if (char.id > 0) {
			pointsPercent = charCategory.pointsPossible === 0 ? 100 : Math.floor((charCategory.points * 100) / (charCategory.pointsPossible));
			//let titlesTotal = titlesRemaining+titlesObtained;
			titlesPercent = charCategory.titlesPossible === 0 ? 100 : Math.floor((charCategory.titles * 100) / (charCategory.titlesPossible));
			//let itemsTotal = itemsRemaining+itemsObtained;
			itemsPercent = charCategory.itemsPossible === 0 ? 100 : Math.floor((charCategory.items * 100) / (charCategory.itemsPossible));
		}

		return <div>
			<div className="FilterBoxContainer">
				<div className="FilterBox FilterBox-Small">
					<h2>Sort</h2>
					<button className={this.state.sort==='In-Game'?'current':''} onClick={this.setSort}>
						In-Game
					</button>
					<button className={this.state.sort==='Rarest'?'current':''} onClick={this.setSort}>
						Rarest
					</button>
					<button className={this.state.sort==='Most Common'?'current':''} onClick={this.setSort}>
						Most Common
					</button>
				</div>
			</div>
			<div className="FilterBox">
				<h2>Kind</h2>
				{kindAll}{kinds}
			</div>
			<div className="FilterBox">
				<h2>Category</h2>
				{categoryAll}{categories}
			</div>


			{char.id > 0 ? <div className="CompletionSummaryContainer">
				<div className="CompletionSummary">
					<h3>Progress</h3>
					<p className="CompletionSummary-Percent">{pointsPercent}%</p>
					<p className="CompletionSummary-Numbers">{charCategory.points} / {charCategory.pointsPossible} <span className="CompletionSummary-Remaining">({charCategory.pointsPossible - charCategory.points} remaining)</span></p>

					{charCategory.pointsPossibleUnobtainable > 0 ? <p className="CompletionSummary-Numbers"><span className="CompletionSummary-Remaining"><i className="fontello-attention" /> Unobtainable: {charCategory.pointsUnobtainable} / {charCategory.pointsPossibleUnobtainable}</span></p> : ''}
				</div>
				<div className="CompletionSummary">
					<h3>Titles</h3>
					<p className="CompletionSummary-Percent">{titlesPercent}%</p>
					<p className="CompletionSummary-Numbers">{charCategory.titles} / {charCategory.titlesPossible} <span className="CompletionSummary-Remaining">({charCategory.titlesPossible - charCategory.titles} remaining)</span></p>

					{charCategory.titlesPossibleUnobtainable > 0 ? <p className="CompletionSummary-Numbers"><span className="CompletionSummary-Remaining"><i className="fontello-attention" /> Unobtainable: {charCategory.titlesUnobtainable} / {charCategory.titlesPossibleUnobtainable}</span></p> : ''}
				</div>
				<div className="CompletionSummary">
					<h3>Items</h3>
					<p className="CompletionSummary-Percent">{itemsPercent}%</p>
					<p className="CompletionSummary-Numbers">{charCategory.items} / {charCategory.itemsPossible} <span className="CompletionSummary-Remaining">({charCategory.itemsPossible - charCategory.items} remaining)</span></p>
					{charCategory.itemsPossibleUnobtainable > 0 ? <p className="CompletionSummary-Numbers"><span className="CompletionSummary-Remaining"><i className="fontello-attention" /> Unobtainable: {charCategory.itemsUnobtainable} / {charCategory.itemsPossibleUnobtainable}</span></p> : ''}
				</div>
			</div> : ''}

			<div>
				<h2>{achievementRowsRemaining.length} achievements {char.id > 0 ? 'left to obtain' : ''}</h2>
				<table className="MountTable">
					<tbody>
					{achievementRowsRemaining.slice(0,250)}
					</tbody>
				</table>
				{achievementRowsRemaining.length > 250 ? <p>Plus {achievementRowsRemaining.length-250} more.</p> : ''}
			</div>

			{achievementRowsObtained.length > 0 ? <div><p>&nbsp;</p>
				<h2>{achievementRowsObtained.length} achievements obtained</h2>
				<p>Achievements marked with <i className="fontello-attention" /> are not possible for all players to obtain and do not count towards your total score on the rankings.</p>
				<table className="MountTable">
					<tbody>
					{achievementRowsObtained.slice(0,250)}
					</tbody>
				</table>
				{achievementRowsObtained.length > 250 ? <p>Plus {achievementRowsObtained.length-250} more.</p> : ''}
			</div> : ''}

			{achievementRowsUnobtainable.length > 0 ? <div><p>&nbsp;</p>
				<h2>{achievementRowsUnobtainable.length} unobtainable achievements</h2>
				<p>These achievements are not possible for all characters to obtain, and do not count against you in the rankings.</p>
				<table className="MountTable">
					<tbody>
					{achievementRowsUnobtainable}
					</tbody>
				</table>
			</div> : ''}
		</div>;
	}
}
