import React, {PureComponent} from 'react';
/*
Pure component because re-rendering can cause the audio to stop playing
Load the file when the component loads because these are small and usually the reason to load the page is to listen to the audio
 */
export default class Audio extends PureComponent {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.audioRef.current) {
            this.audioRef.current.load();
        }
    }
    render() {
        return <audio controls="controls" controlsList="nodownload" ref={this.audioRef}>
            <source src={this.props.src} type="audio/ogg" />
        </audio>;
    }
}
