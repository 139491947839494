import EventEmitter from 'eventemitter3';
import auth0 from'../auth0';

//let mounts = require('../gamedata/json/en/mounts.json');
//let minions = require('../gamedata/json/en/minions.json');
//let achievements = require('../gamedata/json/en/achievements.json');
//let achievementCategories = require('../gamedata/json/en/achievementCategories.json');
//let unobtainableAchievements = require('../data/unobtainableAchievements');

let store = require('../stores/gameData').default;
store.on(mutableCalculate);

let EE = new EventEmitter();

let data = {
	loading: true,
	error: false,
	bgloading: false,
	char: {}
};

function accumulateSources(storeData) {
	let sources = {};
	storeData.reduce(function(accumulator, d) {
		if (accumulator.indexOf(d.sourceTypeId) === -1) {
			accumulator.push(d.sourceTypeId);
		}
		return accumulator;
	}, []).sort().forEach(sourceTypeId => {
		sources[sourceTypeId] = {
			count: 0,
			countPossible: 0
		};
	});
	return sources;
}
function addCounts(storeData, key, hasKey) {
	data.char[`${key}Count`] = 0;
	data.char[`${key}Possible`] = 0;
	data.char[`${key}Sources`] = accumulateSources(storeData);
	if (!hasKey) hasKey = `${key}Id`;
	let charKey = key;
	if (key === 'minion') charKey = 'minions';
	if (key === 'mount') charKey = 'mounts';
	storeData.forEach(m => {
		let has = !!data.char[charKey].find(mm => mm[hasKey] === m.id && !mm.deleted);
		if (m.obtainable !== false && m.isInLog !== false && (key !== 'leve' || (m.isRemoved !== true && m.isUnlock !== true))) {
			if (has) {
				if (m.sourceTypeId !== 6 && m.sourceTypeId !== 12 && m.sourceTypeId !== 7 && m.sourceTypeId !== 28 && (key !== 'orchestrion' || (m.orchestrionCategoryId !== 7 && m.orchestrionCategoryId !== 8))) data.char[`${key}Count`]++;
				data.char[`${key}Sources`][m.sourceTypeId].count++;
			}
			if (m.sourceTypeId !== 6 && m.sourceTypeId !== 12 && m.sourceTypeId !== 7 && m.sourceTypeId !== 28 && (key !== 'orchestrion' || (m.orchestrionCategoryId !== 7 && m.orchestrionCategoryId !== 8))) data.char[`${key}Possible`]++;
			data.char[`${key}Sources`][m.sourceTypeId].countPossible++;
		}
	});
	data.char[`${key}Recent`] = data.char[charKey].length > 0 ? data.char[charKey].sort((a, b) => {
		if (a.date > b.date) return -1;
		if (a.date < b.date) return 1;
		if (a.id > b.id) return -1;
		if (a.id < b.id) return 1;
		return 0;
	})[0][hasKey] : 0;
	data.char[`${key}Percent`] = data.char[`${key}Count`] / data.char[`${key}Possible`] * 100;
}
function sortDateThenId(m1, m2) {
	if (m1.date > m2.date) return -1;
	if (m1.date < m2.date) return 1;
	if (m1.id > m2.id) return -1;
	if (m1.id < m2.id) return 1;
	return 0;
}
function immutableCalculate() {
	if (!data.char || !data.char.mounts || !data.char.minions || !data.char.achievements) return;
	addCounts(store.get('mounts'), 'mount', 'id');
	addCounts(store.get('minions'), 'minion', 'id');
	addCounts(store.get('ttcards'), 'triadCard');
	addCounts(store.get('ttnpcs'), 'triadNpc');
	addCounts(store.get('fashions'), 'fashion');
	addCounts(store.get('hairs'), 'hair');
	addCounts(store.get('emotes'), 'emote');
	addCounts(store.get('bardings'), 'barding');
	addCounts(store.get('orchestrions'), 'orchestrion');
	addCounts(store.get('fish'), 'fish');
	addCounts(store.get('leves'), 'leve');
	addCounts(store.get('bozjaNotes'), 'bozjaNote');
	data.char.fashionCombinedPercent = 100*(data.char.fashionCount+data.char.hairCount)/(data.char.fashionPossible+data.char.hairPossible);
}
function mutableCalculate() {
	if (!data.char || !data.char.mounts || !data.char.minions || !data.char.achievements) return;
	immutableCalculate();
	/*data.char.mountCount = 0;
	data.char.mountCountAll = 0;
	data.char.mountPossible = 0;
	data.char.mountSources = accumulateSources(store.get('mounts'));
	/*store.get('mounts').reduce(function(accumulator, d) {
		if (accumulator.indexOf(d.sourceTypeId) === -1) {
			accumulator.push(d.sourceTypeId);
		}
		return accumulator;
	}, []).sort().forEach(sourceTypeId => {
		data.char.mountSources[sourceTypeId] = {
			count: 0,
			countPossible: 0
		};
	});* /

	store.get('mounts').forEach(m => {
		let has = !!data.char.mounts.find(mm => mm.id === m.id);
		if (m.obtainable) {
			if (has) {
				data.char.mountCount++;
				data.char.mountSources[m.sourceTypeId].count++;
			}
			data.char.mountPossible++;
			data.char.mountSources[m.sourceTypeId].countPossible++;
		}
		if (has) data.char.mountCountAll++;
	});
	/*data.char.mounts.forEach(mount => {
		data.char.mountCountAll++;
		let m = mounts.find(m => m.Id === mount.id);
		if (m.data.obtainable) data.char.mountCount++;
	});*/
	//data.char.mountRecent = data.char.mounts.length > 1 ? data.char.mounts.sort(sortDateThenId)[0].id : 0;

	/*data.char.minionCount = 0;
	data.char.minionCountAll = 0;
	data.char.minionPossible = 0;
	//data.char.minionSources = {};
	data.char.minionSources = accumulateSources(store.get('minions'));
	/*store.get('minions').reduce(function(accumulator, d) {
		if (accumulator.indexOf(d.sourceTypeId) === -1) {
			accumulator.push(d.sourceTypeId);
		}
		return accumulator;
	}, []).sort().forEach(sourceTypeId => {
		data.char.minionSources[sourceTypeId] = {
			count: 0,
			countPossible: 0
		};
	});* /
	store.get('minions').forEach(m => {
		let has = !!data.char.minions.find(mm => mm.id === m.id);
		if (m.obtainable) {
			if (has) {
				data.char.minionCount++;
				data.char.minionSources[m.sourceTypeId].count++;
			}
			data.char.minionPossible++;
			data.char.minionSources[m.sourceTypeId].countPossible++;
		}
		if (has) data.char.minionCountAll++;
	});*/
	//data.char.minionRecent = data.char.minions.length > 1 ? data.char.minions.sort(sortDateThenId)[0].id : 0;

	data.char.titleCount = 0;
	data.char.titlePossible = 0;
	data.char.achievementKinds = {};
	data.char.achievementPoints = 0;
	data.char.achievementPointsLegacy = 0;
	data.char.achievementPointsPossible = 0;
	store.get('achievements').forEach(a => {
		let has = !!data.char.achievements.find(aa => aa.id === a.id);
		if (a.obtainable) {
			if (has) data.char.achievementPoints += a.points;
			data.char.achievementPointsPossible += a.points;
			if (has && a.titleId > 0) data.char.titleCount++;
			if (a.titleId > 0) data.char.titlePossible++;
		}
		if (has) data.char.achievementPointsLegacy += a.points;
	});
	data.char.achievementRecent = data.char.achievements.length > 1 ? data.char.achievements.sort(sortDateThenId)[0].id : 0;
	/*data.char.achievements.forEach(achievement => {
		let a = achievements.find(a => a.Id === achievement.id);
		if (a.AchievementKind !== 'Legacy' && a.AchievementCategory !== 'Seasonal Events' && !unobtainableAchievements.includes(a.Id)) {
			data.char.achievementPoints += a.Points;
		}
		data.char.achievementPointsLegacy += a.Points;
	});*/
	store.get('achievementCategories').forEach(kind => {
		data.char.achievementKinds[kind.Id] = {
			count: 0,
			countPossible: 0,
			points: 0,
			pointsPossible: 0,
			titles: 0,
			titlesPossible: 0,
			items: 0,
			itemsPossible: 0,
			countUnobtainable: 0,
			countPossibleUnobtainable: 0,
			pointsUnobtainable: 0,
			pointsPossibleUnobtainable: 0,
			titlesUnobtainable: 0,
			titlesPossibleUnobtainable: 0,
			itemsUnobtainable: 0,
			itemsPossibleUnobtainable: 0,
		};
		kind.Categories.forEach(category => {
			data.char.achievementKinds[kind.Id][category.Id] = {
				count: 0,
				countPossible: 0,
				points: 0,
				pointsPossible: 0,
				titles: 0,
				titlesPossible: 0,
				items: 0,
				itemsPossible: 0,
				countUnobtainable: 0,
				countPossibleUnobtainable: 0,
				pointsUnobtainable: 0,
				pointsPossibleUnobtainable: 0,
				titlesUnobtainable: 0,
				titlesPossibleUnobtainable: 0,
				itemsUnobtainable: 0,
				itemsPossibleUnobtainable: 0,
			};
			store.get('achievements').filter(a => a.achievementCategoryId === category.Id)
				.forEach(a => {
					if (a.obtainable || (kind.Id === 13 && data.char.legacy)) {
						data.char.achievementKinds[kind.Id].countPossible++;
						data.char.achievementKinds[kind.Id][category.Id].countPossible++;
						data.char.achievementKinds[kind.Id].pointsPossible += a.points;
						data.char.achievementKinds[kind.Id][category.Id].pointsPossible += a.points;
						if (a.titleId > 0) {
							data.char.achievementKinds[kind.Id].titlesPossible++;
							data.char.achievementKinds[kind.Id][category.Id].titlesPossible++;
						}
						if (a.itemId > 0) {
							data.char.achievementKinds[kind.Id].itemsPossible++;
							data.char.achievementKinds[kind.Id][category.Id].itemsPossible++;
						}
						if (data.char.achievements.find(aa => aa.id === a.id)) {
							data.char.achievementKinds[kind.Id].count++;
							data.char.achievementKinds[kind.Id][category.Id].count++;
							data.char.achievementKinds[kind.Id].points += a.points;
							data.char.achievementKinds[kind.Id][category.Id].points += a.points;
							if (a.titleId > 0) {
								data.char.achievementKinds[kind.Id].titles++;
								data.char.achievementKinds[kind.Id][category.Id].titles++;
							}
							if (a.itemId > 0) {
								data.char.achievementKinds[kind.Id].items++;
								data.char.achievementKinds[kind.Id][category.Id].items++;
							}
						}
					} else {
						data.char.achievementKinds[kind.Id].countPossibleUnobtainable++;
						data.char.achievementKinds[kind.Id][category.Id].countPossibleUnobtainable++;
						data.char.achievementKinds[kind.Id].pointsPossibleUnobtainable += a.points;
						data.char.achievementKinds[kind.Id][category.Id].pointsPossibleUnobtainable += a.points;
						if (a.titleId > 0) {
							data.char.achievementKinds[kind.Id].titlesPossibleUnobtainable++;
							data.char.achievementKinds[kind.Id][category.Id].titlesPossibleUnobtainable++;
						}
						if (a.itemId > 0) {
							data.char.achievementKinds[kind.Id].itemsPossibleUnobtainable++;
							data.char.achievementKinds[kind.Id][category.Id].itemsPossibleUnobtainable++;
						}
						if (data.char.achievements.find(aa => aa.id === a.id)) {
							data.char.achievementKinds[kind.Id].countUnobtainable++;
							data.char.achievementKinds[kind.Id][category.Id].countUnobtainable++;
							data.char.achievementKinds[kind.Id].pointsUnobtainable += a.points;
							data.char.achievementKinds[kind.Id][category.Id].pointsUnobtainable += a.points;
							if (a.titleId > 0) {
								data.char.achievementKinds[kind.Id].titlesUnobtainable++;
								data.char.achievementKinds[kind.Id][category.Id].titlesUnobtainable++;
							}
							if (a.itemId > 0) {
								data.char.achievementKinds[kind.Id].itemsUnobtainable++;
								data.char.achievementKinds[kind.Id][category.Id].itemsUnobtainable++;
							}
						}
					}
				});
		});
	});
}

async function editItems(type, ids, method) {
	let charId = data.char.id;
	let res = await auth0.fetch(`/api/user/char/${charId}/${type}s/${ids.join(',')}`, {method: method});
	let items = await res.json();
	if (charId !== data.char.id) return;
	if (res.status === 200) {
		for(let id of ids) {
			data.char[type] = data.char[type].filter(d => d[type+'Id'] !== id);
		}
		items.filter(i => i && i[type+'Id']).forEach(i => data.char[type].push(i));
		//data[type] = items;
	}
	if (data.char[type + 'load']) {
		for (let id of ids) {
			data.char[type + 'load'] = data.char[type + 'load'].filter(d => d !== id);
		}
	}
	immutableCalculate();
	EE.emit('change');
}

export default {
	on(listener, context) {
		EE.on('change', listener, context);
	},
	off(listener, context) {
		EE.off('change', listener, context);
	},

	getData() {
		return data;
	},

	async loadChar(id) {
		id = id * 1;
		//if (data.char.id === id) return;

		data = {
			loading: true,
			error: false,
			bgloading: false,
			char: {id: id}
		};
		EE.emit('change');

		let res = await fetch(`/api/charcache/${id}/`);
		let newChar = await res.json();
		//if (data.char.id !== newChar.id) return;
		if (newChar.status === 'adding') {
			data = {
				loading: false,
				error: false,
				bgloading: true,
				char: {id: id, ...newChar}
			};
		} else {
			data = {
				loading: false,
				error: false,
				bgloading: (newChar.status === 'updating' || newChar.status === 'adding') && !(/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)),
				char: newChar
			};
			mutableCalculate();
		}
		//newChar.status !== 'adding' && mutableCalculate();
		EE.emit('change');

		if (data.bgloading) {
			this.sync();
			/*let res = await fetch(`/api/charrealtime/${id}/`);
			let newChar = await res.json();
			if (data.char.id !== newChar.id) return;
			data = {
				loading: false,
				error: false,
				bgloading: false,
				char: newChar
			};
			mutableCalculate();
			EE.emit('change');*/
		}
	},
	async sync() {
		if (!data.bgloading) {
			data = {
				loading: false,
				error: false,
				bgloading: true,
				char: data.char
			};
			EE.emit('change');
		}
		let res = await fetch(`/api/charrealtime/${data.char.id}/`);
		if (!res.ok) {
			data = {
				loading: false,
				error: true,
				bgloading: false,
				char: data.char
			};
			EE.emit('change');
			return;
		}
		let newChar = await res.json();
		if (newChar.status === 'adding') {
			//it no exist
			data = {
				loading: false,
				error: false,
				bgloading: false,
				char: {id: data.char.id, status: 'notFound'}
			};
		} else {
			if (data.char.id !== newChar.id) return;
			data = {
				loading: false,
				error: false,
				bgloading: false,
				char: newChar
			};
			mutableCalculate();
		}
		EE.emit('change');
	},

	async loadTimeline() {
		let id = data.char.id;
		if (data.timeline) return;
		let res = await fetch(`/api/chartimeline/${id}/`);
		let timeline = await res.json();
		if (id !== data.char.id) return;
		data = { ...data, timeline: timeline };
		EE.emit('change');
	},

	async loadItems(type) {
		let charId = data.char.id;
		if (data.char[type]) return;
		let res = await fetch(`/api/char/${charId}/${type}s/`);
		let items = await res.json();
		if (charId !== data.char.id) return;
		data = { ...data };
		data[type] = items;
		EE.emit('change');
	},

	async markItems(type, ids) {
		let ids1 = [];
		for(let id of ids) {
			let item = data.char[type].find(d => d[type + 'Id'] === id && d.deleted !== true);
			if (item) continue; else ids1.push(id);
			if (!data.char[type + 'load']) data.char[type + 'load'] = [];
			data.char[type + 'load'].push(id);
		}
		EE.emit('change');
		if (ids1.length > 0) return editItems(type, ids1, 'POST');
	},
	async markItem(type, id) {
		return this.markItems(type, [id]);
	},
	async unmarkItem(type, id) {
		let item = data.char[type].find(d => d[type+'Id'] === id);
		if (!item) return;
		if (item.deleted) return;
		if (!data.char[type+'load']) data.char[type+'load'] = [];
		data.char[type+'load'].push(id);
		EE.emit('change');
		return editItems(type, [id], 'DELETE');
	},
	hasItem(type, id) {
		let key = type;
		if (!data.char[key]) key = type + 's';
		if (!data.char[key]) {
			return false;
		}
		let item = data.char[key].find(d => (d[type+'Id'] === id || d.id === id) && d.deleted !== true);
		return item ? item.date : false;
	}
};
