let expansions = [
	{
		id: 2,
		name: 'A Realm Reborn',
		levelMin: 1,
		levelMax: 50
	},
	{
		id: 3,
		name: 'Heavensward',
		levelMin: 50,
		levelMax: 60
	},
	{
		id: 4,
		name: 'Stormblood',
		levelMin: 60,
		levelMax: 70
	},
	{
		id: 5,
		name: 'Shadowbringers',
		levelMin: 70,
		levelMax: 80
	},
	{
		id: 6,
		name: 'Endwalker',
		levelMin: 80,
		levelMax: 90
	},
	{
		id: 7,
		name: 'Dawntrail',
		levelMin: 90,
		levelMax: 100
	}
];
module.exports = expansions;
