import userStore from './user';
let recentChars = [];

function loadStorage() {
	recentChars = JSON.parse(localStorage.getItem('recentChars'));

	if (!recentChars || recentChars.length < 1) {
		console.log('claring recent');
		recentChars = [];
	}
}

userStore.on(function() {
	recentChars = recentChars.filter(char => {
		if (userStore.getFavoriteChars().find(c => c.id === char.id)) return false;
		if (userStore.getUserChars().find(c => c.id === char.id)) return false;
		return true;
	});
});

window.addEventListener('storage', loadStorage);

loadStorage();

export default {
	logCharVisit(char) {
		if (!char || !char.id || char.id < 1) return;
		if (userStore.getFavoriteChars().find(c => c.id === char.id)) return;
		if (userStore.getUserChars().find(c => c.id === char.id)) return;
		//if (recentChars.length >= 1 && recentChars[0].id === char.id) return;
		if (recentChars.length > 0) {
			recentChars = recentChars.filter(c => c.id !== char.id);
		}
		recentChars.unshift({
			id: char.id,
			name: char.name,
			worldName: char.worldName,
			iconUrl: char.iconUrl
		});
		if (recentChars.length > 5) {
			recentChars.pop();
		}
		localStorage.setItem('recentChars', JSON.stringify(recentChars));
	},
	get() {
		return recentChars;
	}
};
