import React, {Component} from 'react';
import '../../gamedata/spritesheet/moogletomes.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
import clans from '../../gamedata/json/en/clans.json';
import auth0 from '../../auth0';
//let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

//let shops = require('../../gamedata/json/shops');
/*let realItems = {
    mount: require('../../gamedata/json/en/mounts.json'),
    minion: require('../../gamedata/json/en/minions.json'),
    emote: require('../../gamedata/json/en/emotes.json'),
    orchestrion: require('../../gamedata/json/en/orchestrions.json'),
    triadCard: require('../../gamedata/json/en/ttcards.json')
};*/

let filterTypes = ['All', 'Completed', 'Missing'];

let store = require('../../stores/gameData').default;

function makeState() {
    return {
        char: charStore.getData(),
        mount: store.get('mounts'),
        minion: store.get('minions'),
        emote: store.get('emotes'),
        orchestrion: store.get('orchestrions'),
        triadCard: store.get('ttcards'),
        fashion: store.get('fashions'),
        hair: store.get('hairs'),
        barding: store.get('bardings'),
    }
}
export default class CharMoogleTomesRoute extends Component {
    state = Object.assign({
        shops: [],
        filter: 'All'
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.setFilter = this.setFilter.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }
    componentDidMount() {
        import(/* webpackChunkName: "shops" */ '../../gamedata/json/shops').then(shops => {
            this.setState({shops: shops.default});
        });
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('emote').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('emote').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }
    setFilter(e) {
        let f = e.target.dataset.source * 1;
        if (this.state.currencyFilter === f) {
            this.setState({currencyFilter: -1});
        } else {
            this.setState({currencyFilter: f});
        }
    }

    toggleItem(type, id, toggleTo) {
        if (toggleTo) {
            charStore.markItem(type, id);
        } else {
            charStore.unmarkItem(type, id);
        }
    }

    render() {
        //let lang = this.props.lang;
        let char = this.props.char;
        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        //let currentId = this.props.url[3]*1;
        let user = auth0.getUser();

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        //let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            emotes.filter(emote => {
                if (category.Id === 1) return true;
                return emote.emoteCategory === category;
            }).forEach(emote => {
                let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`/${lang}/char/${char.id}/emotes/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/


        let filters = this.state.shops.reduce(function(accumulator, d) {
            if (accumulator.indexOf(d.item) === -1) {
                accumulator.push(d.item);
            }
            return accumulator;
        }, []).sort();
        let filtersElements = filters.map((filter) => {
            let countHas = 0, countPossible = 0;
            this.state.shops.forEach(shopGroup => {
                if (filter.id !== shopGroup.item.id) return;
                shopGroup.shops.forEach(shop => {
                    shop.items.forEach(shopItem => {
                        let has = char.id > 0 && charStore.hasItem(shopItem.type, shopItem.id);
                        countPossible++;
                        if (has) countHas++;
                    });
                });
            });
            if (countPossible < 1) return;

            let percent = char.id > 0 && countHas * 100 / countPossible;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }

            return <button data-source={filter.id} key={filter.id} className={this.state.currencyFilter === filter.id*1?'current':''} onClick={this.setFilter}>
                {filter.name}
                {char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
            </button>;
        });


        let clan = clans.find(clan => clan.Id === char.clanId2);
        let iconClan = 'midm';
        if (clan) {
            if (clan.Tribe === 'Midlander') iconClan = 'mid';
            else if (clan.Tribe === 'Highlander') iconClan = 'high';
            else if (clan.Race === 'Elezen') iconClan = 'ele';
            else if (clan.Race === 'Lalafell') iconClan = 'lala';
            else if (clan.Race === 'Miqo\'te') iconClan = 'miqo';
            else if (clan.Race === 'Roegadyn') iconClan = 'roe';
            else if (clan.Race === 'Au Ra') iconClan = 'aura';
            else if (clan.Race === 'Hrothgar') iconClan = 'roe';
            else if (clan.Race === 'Viera') iconClan = 'mid';
            else iconClan = 'hyur';
            iconClan = iconClan + (clan.Gender === 1 ? 'f' : 'm');
        }

        let hasCount = 0, totalCount = 0;
        let tables = [];
        this.state.shops.forEach(shopGroup => {
            if (this.state.currencyFilter !== shopGroup.item.id) return;
            shopGroup.shops.forEach(shop => {
                let rows = shop.items.map(shopItem => {
                    //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                    let has = char.id > 0 && charStore.hasItem(shopItem.type, shopItem.id);
                    let className = 'MountRow';
                    if (has) {
                        className += ' MountRow-Have';
                    }
                    className += ` MoogleRow-${shopItem.type}`

                    let realItem;
                    if (this.state[shopItem.type]) {
                        realItem = this.state[shopItem.type].find(i => i.id === shopItem.id);
                    }
                    if (!realItem) {
                        realItem = {
                            Id: 0,
                            Name: shopItem.Name,
                            data: {
                                howTo: ''
                            }
                        }
                    }
                    let howTo, realIconClass;
                    if (shopItem.type === 'orchestrion') {
                        howTo = realItem.description
                    } else if (realItem.howTo) {
                        howTo = realItem.howTo;
                    } else {
                        howTo = '';
                    }
                    if (shopItem.type === 'hair') {
                        realIconClass = `icon icon-${shopItem.type} icon-${shopItem.type}-${realItem.id}_${iconClan}`
                    } else if (shopItem.type === 'triadCard') {
                        realIconClass = `icon icon-ttcards icon-ttcards-${realItem.id}`
                    } else if (shopItem.type === 'barding') {
                        realIconClass = `icon icon-barding icon-barding-${realItem.id}h`
                    } else {
                        realIconClass = `icon icon-${shopItem.type} icon-${shopItem.type}-${realItem.id}`;
                    }
                    /*let checkbox;
                    if (has) {
                        checkbox = <i className={'fontello-ok'}/>;
                    }*/
                    let checkbox;
                    if (isCharMine && (shopItem.type !== 'mount' && shopItem.type !== 'minion')) {
                        checkbox = <button onClick={(e) => this.toggleItem(shopItem.type, realItem.id, !has)}>
                            <i className={this.state.char.char[shopItem.type+'load'] && this.state.char.char[shopItem.type+'load'].includes(realItem.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                        </button>;
                    } else {
                        checkbox = <button disabled><i className={'fontello-ok'}/></button>;
                    }

                    return <tr className={className} key={shopItem.type+shopItem.id}>
                        <td className="MountRow-Check">
                            {checkbox}
                        </td>
                        <td className="MountRow-Icon"><span className={realIconClass} title={realItem.name}/></td>
                        <td className="MountRow-Text">
                            <span className="MountRow-Name">{shopItem.itemReceiveName}</span><br />
                            { /*<span className="MountMinionDetails-Tag">Unlocks: </span> {realItem.name}<br />*/ }
                            <span dangerouslySetInnerHTML={{__html:howTo}} />
                        </td>
                        <td className="MountRow-Value">{shopItem.countCost}</td>
                        { /*<td dangerouslySetInnerHTML={{__html:howTo}} />*/ }
                    </tr>;
                });
                let header = '';
                if (shop.name && shop.name.length > 0) {
                    header = `Unknown vendor - ${shop.name}`;
                } else {
                    header = `Unknown vendor`;
                }
                if (user&&user['https://lalachievements.com/api/roles'].indexOf('debug') > -1) {
                    header += ` [${shop.id}]`;
                }
                tables.push(<div key={shop.id}>
                    <h2>{header}</h2>
                    <table className="MountTable">
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                </div>);
            })
        })
        /*let rows = items.filter(item => {
            return item.id > 0;
            //if (currentCategory.Id === 1) return true;
            //return emote.emoteCategory === currentCategory;
        }).filter(item => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem(item.type, item.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (this.state.filter === 'Completed') {
                return has;
            } else if (this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).map(item => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem(item.type, item.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            className += ` MoogleRow-${item.type}`

            let realItem;
            if (this.state[item.type]) {
                realItem = this.state[item.type].find(i => i.id === item.id);
            }
            if (!realItem) {
                realItem = {
                    Id: 0,
                    Name: item.Name,
                    data: {
                        howTo: ''
                    }
                }
            }
            let howTo, realIconClass;
            if (item.type === 'orchestrion') {
                howTo = realItem.description
            } else if (realItem.howTo) {
                howTo = realItem.howTo;
            } else {
                howTo = '';
            }
            if (item.type === 'hair') {
                realIconClass = `icon icon-${item.type} icon-${item.type}-${realItem.id}_${iconClan}`
            } else if (item.type === 'triadCard') {
                realIconClass = `icon icon-ttcards icon-ttcards-${realItem.id}`
            } else {
                realIconClass = `icon icon-${item.type} icon-${item.type}-${realItem.id}`;
            }
            let checkbox;
            if (has) {
                checkbox = <i className={'fontello-ok'}/>;
            }
            return <tr className={className} key={item.type+item.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={realIconClass} title={realItem.name}/></td>
                <td className="MountRow-Icon"><span className={`icon icon-moogletomes icon-moogletomes-${item.item.Id}`} title={item.item.Name}/></td>
                <td className="MountRow-Text">
                    <span className="MountRow-Name">{item.item.Name}</span><br />
                    <span className="MountMinionDetails-Tag">Unlocks: </span> {realItem.name}
                </td>
                <td dangerouslySetInnerHTML={{__html:howTo}} />
            </tr>;
        });*/
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        /*let rowsUnknown = items.filter(item => {
            return item.id <= 0;
        }).map(item => {
            return <tr className="MountRow" key={item.item.Id}>
                <td className="MountRow-Icon"><span className={`icon icon-moogletomes icon-moogletomes-${item.item.Id}`} title={item.item.Name}/></td>
                <td className="MountRow-Text">
                    <span className="MountRow-Name">{item.item.Name}</span>
                </td>
            </tr>;
        });*/

        if (tables.length < 1) {
            tables.push(<div className="InfoBox" key="InfoBox">
                <div>Select a currency to view vendors.</div>
               </div>);
        }


        return <div className="">

            <div>

                { /*<div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div>
                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>Moogle Treasure Trove</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(percent)}%</p>
                    <p className="CompletionSummary-Numbers">{hasCount} / {totalCount}</p>
                    <p className="CompletionSummary-Remaining">({totalCount-hasCount} remaining)</p>
                    <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                        <h3>Moogle Treasure Trove</h3>
                </div>}*/ }
                <div className="InfoBox InfoBox-Error">
                    <div>This page is under construction.</div>
                </div>
                <div>&nbsp;</div>
                <div className="FilterBox">
                    <h2>Currencies</h2>
                    {filtersElements}
                </div>
                <div>&nbsp;</div>
                {tables}
            </div>

        </div>;
    }
}