import React, {Component} from 'react';
import Fuse from 'fuse.js';
import '../gamedata/spritesheet/mount.css';
import '../gamedata/spritesheet/minion.css';
import '../gamedata/spritesheet/achievement.css';
import '../gamedata/spritesheet/barding.css';
import '../gamedata/spritesheet/ttcards.css';
import '../gamedata/spritesheet/emote.css';
import '../gamedata/spritesheet/fashion.css';
import '../gamedata/spritesheet/hair.css';
import LoadingBox from '../components/LoadingBox';
import Pagination from '../components/Pagination';

//let urlify = require('urlify').create();

//let mounts = require('../gamedata/json/en/mounts.json');
//let minions = require('../gamedata/json/en/minions.json');
//let achievements = require('../gamedata/json/en/achievements.json');
let dataCenters = require('../gamedata/json/dataCenters.json');
//let unobtainableAchievements = require('../data/unobtainableAchievements');

let store = require('../stores/gameData').default;

let fuseOptions = {
	title: {
		keys: ['titleLink.masculine', 'titleLink.feminine', 'description'],
		id: 'id',
		minMatchCharLength: 3,
		threshold: 0.2,
		distance: 1000
	},
	achievement: {
		keys: ['name', 'description'],
		id: 'id',
		minMatchCharLength: 3,
		threshold: 0.2,
		distance: 1000
	},
	mount: {
		keys: ['name', 'description', 'howTo'],
		id: 'id',
		minMatchCharLength: 3,
		threshold: 0.2,
		distance: 1000
	},
	minion: {
		keys: ['name', 'description', 'howTo'],
		id: 'id',
		minMatchCharLength: 3,
		threshold: 0.2,
		distance: 1000
	},
	other: {
		keys: ['name'],
		id: 'id',
		minMatchCharLength: 3,
		threshold: 0.2,
		distance: 1000
	}
};


function makeState() {
	return {
		achievements: store.get('achievements'),
		achievementCategories: store.get('achievementCategories'),
		titles: store.get('titles'),
		mounts: store.get('mounts'),
		minions: store.get('minions'),
		orchestrions: store.get('orchestrions'),
		bardings: store.get('bardings'),
		emotes: store.get('emotes'),
		ttcards: store.get('ttcards'),
		fashions: store.get('fashions'),
		hairstyles: store.get('hairs'),
		sourceTypes: store.get('sourceTypes')
	};
}
class RarityRoute extends Component {
	state = Object.assign({
		loading: true,
		loadedType: '',
		data: [],
		searchText: '',
		page: 1
	}, makeState());
	constructor(props) {
		super(props);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.onPagination = this.onPagination.bind(this);
		store.on(this.onChange, this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState(Object.assign({}, makeState(), {fuse: this.makeFuse()}));
	}
	componentDidMount() {
		this.fetch();
	}
	componentDidUpdate(prevProps) {
		if (this.props.url[2] !== prevProps.url[2] || this.props.url[1] !== prevProps.url[1]) {
			this.setState({loading: true, page: 1});
			this.fetch();
		}
	}
	fetch() {
		let world = this.props.url[2], type = this.props.url[1];
		let typeUrl = type;
		if (typeUrl === 'title') typeUrl = 'achievement';
		fetch('/api/rarity/' + type + '/' + world).then((res) => {
			if (res.status < 200 || res.status >= 400) {
				return 500; //return this.setState({loading: false, loadedType: 'error'});
			}
			try {
				return res.json();
			} catch (e) {
				console.error(e);
			}
		}).then(rankings => {
			if (rankings === 500) {
				return this.setState({loading: false, loadedType: 'error'});
			}
			if (rankings.deployTime > window.LALA_DEPLOY_TIME) {
				return window.location.reload();
			}
			/*let fuse;
			if (type === 'title') {
				fuse = new Fuse(this.state.achievements, fuseOptions.title);
			} else if (type === 'achievement') {
				fuse = new Fuse(this.state.achievements, fuseOptions.achievement);
			} else if (type === 'mount') {
				fuse = new Fuse(this.state.mounts, fuseOptions.mount);
			} else if (type === 'minion') {
				fuse = new Fuse(this.state.minions, fuseOptions.minion);
			}*/
			//let rankings = res.json();
			this.setState({
				loading: false,
				loadedType: type,
				data: rankings.rarity,
				charCount: rankings.charCount,
				generateTime: rankings.generateTime,
				fuse: this.makeFuse(),
				searchText: ''
			});
		});
	}
	makeFuse() {
		let type = this.props.url[1];
		let fuse;
		if (type === 'title') {
			fuse = new Fuse(this.state.achievements.map(a => {
				let title = this.state.titles.find(t => t.id === a.titleId);
				return Object.assign({titleLink: title}, a);
			}), fuseOptions.title);
		} else if (type === 'achievement') {
			fuse = new Fuse(this.state.achievements.map(a => {
				let title = this.state.titles.find(t => t.id === a.titleId);
				return Object.assign({titleLink: title}, a);
			}), fuseOptions.achievement);
		} else if (type === 'mount') {
			fuse = new Fuse(this.state.mounts, fuseOptions.mount);
		} else if (type === 'minion') {
			fuse = new Fuse(this.state.minions, fuseOptions.minion);
		} else {
			fuse = new Fuse(this.state[type+'s'], fuseOptions.other);
		}
		return fuse;
	}
	onSearchChange(e) {
		this.setState({searchText: e.target.value});
	}
	onPagination(newPage) {
		this.setState({page: newPage});
	}
	render() {
		//let startTime = new Date().getTime();
		let world = this.props.url[2], lang = this.props.lang, type = this.props.url[1], urlBase = this.props.urlBase;
		let worldMenus = [];

		let className = '';
		if (world === 'global') className += ' current';
		worldMenus.push(<a href={`${urlBase}rarity/${type}/global/`} className={className} key="global">Global</a>);
		for (let dc of dataCenters) {
			if (dc.Name.toLowerCase().includes('beta')) continue;
			let className = 'SideMenu-Group';
			if (world === dc.Name) className += ' current';
			worldMenus.push(<a href={`${urlBase}rarity/${type}/${dc.Name}/`} className={className} key={dc.Name}>{dc.Name}</a>);
			for (let w of dc.Worlds) {
				let className = '';
				if (world === w.Name) className += ' current';
				worldMenus.push(<a href={`${urlBase}rarity/${type}/${w.Name}/`} className={className} key={w.Name}>{w.Name}</a>);
			}
		}

		let data, rarity = [];
		if (this.state.loadedType === 'mount') {
			data = this.state.mounts;
			rarity = store.getRarity('mounts');
		} else if (this.state.loadedType === 'minion') {
			data = this.state.minions;
			rarity = store.getRarity('minions');
		} else if (this.state.loadedType === 'achievement') {
			data = this.state.achievements;
			rarity = store.getRarity('achievements');
		} else if (this.state.loadedType === 'title') {
			data = this.state.achievements;
		} else if (this.state.loadedType === 'barding') {
			data = this.state.bardings;
		} else if (this.state.loadedType === 'orchestrion') {
			data = this.state.orchestrions;
		} else if (this.state.loadedType === 'ttcard') {
			data = this.state.ttcards;
		} else if (this.state.loadedType === 'emote') {
			data = this.state.emotes;
		} else if (this.state.loadedType === 'fashion') {
			data = this.state.fashions;
		} else if (this.state.loadedType === 'hairstyle') {
			data = this.state.hairstyles;
		}

		let charRows = [], blurb, totalCount = 0;
		if (this.state.loadedType !== 'error' && this.state.charCount > 0 && data && data.length > 0) {
			let generateTime = new Date(this.state.generateTime).toLocaleString('en-US', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				timeZoneName: 'short'
			});
			if (this.state.loadedType === 'title' || this.state.loadedType === 'achievement' || this.state.loadedType === 'minion' || this.state.loadedType === 'mount') {
				blurb = <div>
					<p>
						Generated on {generateTime}.<br/>
						Counts update daily at midnight (UTC), and point values update weekly at server reset based
						on <em>percentiles</em>. That is, the highest tier is assigned the 1% of items that are rarest,
						NOT items owned by fewer than 1% of people.
					</p>
					<ul>
						<li>One or fewer characters: 0 points</li>
						<li>Rarest 1% of items: 70 points</li>
						<li>5%: 50 points</li>
						<li>10%: 30 points</li>
						<li>25%: 20 points</li>
						<li>50%: 10 points</li>
						<li>75%: 5 points</li>
						<li>100%: 1 points</li>
					</ul>
					<p>
						Characters included: {this.state.charCount}
					</p>
					<p>
						Items owned by at least one character: {this.state.data.length}
					</p>
				</div>;
			} else {
				blurb = <div>
					<p>
						Generated on {generateTime}.<br/>
						Counts update daily at midnight (UTC).</p>
					<p>Only characters who have checked off at least one item of this type are included.</p>
					<p>This is based on <em>user-submitted</em> data!</p>
					<p>
						Characters included: {this.state.charCount}
					</p>
					<p>
						Items owned by at least one character: {this.state.data.length}
					</p>
				</div>;
			}
			charRows = [];
			let i = 0, displayedCount = 0;
			let makeTheRow = (d, data) => {
				let type = this.state.loadedType;
				let count = 0;
				if (data) count = data.count;
				let r = rarity.find(_r => _r.id === d.id);
				//let rarityClass = rarity
				let percent = count * 100 / this.state.charCount;
				if (percent < 0) {
					percent = 0;
				} else if (percent > 100) {
					percent = 100;
				} else if (percent < 0.1) {
					percent = Math.round(percent * 1000) / 1000;
				} else if (percent < 1) {
					percent = Math.round(percent * 100) / 100;
				} else {
					percent = Math.round(percent * 10) / 10;
				}
				let unobtainable = '';
				if ((type === 'mount' || type === 'minion') && (d.data && !d.data.obtainable)) {
					unobtainable = '<span class="MountMinionDetails-Tag UnobtainableTag">Unobtainable</span>';
				} else if ((type === 'achievement' || type === 'title') && (d.AchievementKind === 'Legacy' || d.AchievementCategory === 'Seasonal Events')) {
					unobtainable = '<span class="MountMinionDetails-Tag UnobtainableTag">Unobtainable</span>';
				}
				if (type === 'mount' || type === 'minion') {
					let sourceType = this.state.sourceTypes.find(t => t.id === d.sourceTypeId) || {};
					charRows.push(`<tr class="RankingRow">
						<td class="RankingRow-Rank">${i}</td>
						<td class="RankingRow-Icon"><span class="gacha gacha${r ? r.points : 0}"><span class="icon icon-${type} icon-${type}-${d.id}" title="${d.name}" /></span>
						</td>
						<td class="RankingRow-Text">
							<a href="${urlBase}rarity/${type}/${world}/${d.id}/" class="RankingRow-Name">${d.name}</a> ${unobtainable}<br />
							<span class="RankingRow-World">${d.howTo}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${r ? r.points : 0}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${percent} %</span><br />
							<span class="RankingRow-All">(${count} chars)</span>
						</td>
					</tr>`);
				} else if (type === 'achievement') {
					//let slug1 = urlify(d.AchievementKind);
					//let slug2 = urlify(d.AchievementCategory);
					let achievementCategory;
					let achievementKind = this.state.achievementCategories.find(kind => {
						achievementCategory = kind.Categories.find(category => category.Id === d.achievementCategoryId);
						return achievementCategory;
					});
					if (achievementCategory && achievementKind) {
						charRows.push(`<tr class="RankingRow">
						<td class="RankingRow-Rank">${i}</td>
						<td class="RankingRow-Icon"><span class="gacha gacha${r ? r.points : 0}"><span class="icon icon-achievement icon-achievement-${d.icon}" title="${d.name}" /></span>
						</td>
						<td class="RankingRow-Text">
							<a href="${urlBase}rarity/achievement/${world}/${d.id}/" class="RankingRow-Name">${d.name}</a><br />
							<span class="RankingRow-World">${achievementKind.Name} / ${achievementCategory.Name}</span><br />
							<span>${d.description}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${r ? r.points : 0}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${percent} %</span><br />
							<span class="RankingRow-All">(${count} chars)</span>
						</td>
					</tr>`);
					}
				} else if (type === 'title') {
					let title = this.state.titles.find(t => t.id === d.titleId);
					let achievementCategory;
					let achievementKind = this.state.achievementCategories.find(kind => {
						achievementCategory = kind.Categories.find(category => category.Id === d.achievementCategoryId);
						return achievementCategory;
					});
					if (achievementCategory && achievementKind && title) {
						charRows.push(`<tr class="RankingRow">
						<td class="RankingRow-Rank">${i}</td>
						<td class="RankingRow-Icon"><span class="icon icon-achievement icon-achievement-${d.icon}" title="${d.name}" />
						</td>
						<td class="RankingRow-Text">
							<a href="${urlBase}rarity/title/${world}/${d.id}/" class="RankingRow-Name">${title.feminine}</a><br />
							<span class="RankingRow-World">${achievementKind.Name} / ${achievementCategory.Name} / ${d.name}</span><br />
							<span>${d.description}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${percent} %</span><br />
							<span class="RankingRow-All">(${count} chars)</span>
						</td>
					</tr>`);
					}
				} else {
					let icon = `icon icon-${type} icon-${type}-${d.id}`, howTo = d.howTo;
					if (type === 'barding') icon = `icon icon-barding icon-barding-${d.id}h`;
					if (type === 'ttcard') {
						icon = `icon icon-ttcards icon-ttcards-${d.id}`;
					} else if (type === 'hairstyle') {
						icon = `icon icon-hair icon-hair-${d.id}_midf`;
					}
					if (type === 'orchestrion') howTo = d.description;
					charRows.push(`<tr class="RankingRow">
						<td class="RankingRow-Rank">${i}</td>
						<td class="RankingRow-Icon"><span class="${icon}" title="${d.name}" />
						</td>
						<td class="RankingRow-Text">
							<a href="${urlBase}rarity/${type}/${world}/${d.id}/" class="RankingRow-Name">${d.name}</a> ${unobtainable}<br />
							<span class="RankingRow-World">${howTo}</span>
						</td>
						<td class="RankingRow-Values">
							<span class="RankingRow-Number">${percent} %</span><br />
							<span class="RankingRow-All">(${count} chars)</span>
						</td>
					</tr>`);
				}
			};
			//makeTheRow = makeTheRow.bind(this);
			let sortedData = data.sort((a, b) => {
				if (a.Id < b.Id) return -1;
				if (a.Id > b.Id) return 1;
				return 0;
			});
			let searchResults = this.state.fuse.search(this.state.searchText);
			//console.log('==============');
			//console.log(data);
			//console.log(searchResults);
			let type = this.state.loadedType;
			for (let d of sortedData) { // process items without rarity data separately
				if ((type === 'mount' || type === 'minion') && !d.obtainable) {
					continue;
				} else if ((type === 'achievement' || type === 'title') && (!d.notLegacy || !d.obtainable)) {
					continue;
				}
				if (type === 'title' && d.titleId < 1) continue;
				//if (unobtainableAchievements.includes(d.id)) continue;
				let rarity = this.state.data.find(rarity => d.id === rarity.id);
				if (!rarity || rarity.count < 1) {
					i++;
					totalCount++;
					if (this.state.searchText.length < 1 || searchResults.indexOf(d.id+'') > -1) {
						if (i > (this.state.page-1)*250 && i <= this.state.page*250) {
							displayedCount++;
							makeTheRow(d, {count: 0, points: 0});
						}
					}
				}
				//if (displayedCount >= 250) break;
			}
			for (let rarity of this.state.data) {
				let d = data.find(d => d.id === rarity.id);
				if (!d) continue;
				if (d.data && !d.obtainable) continue;
				if (type === 'title' && d.titleId < 1) continue;
				//if (unobtainableAchievements.includes(d.id)) continue;
				i++;
				totalCount++;
				if (this.state.searchText.length < 1 || searchResults.indexOf(d.id+'') > -1) {
					if (i > (this.state.page-1)*250 && i <= this.state.page*250) {
						displayedCount++;
						makeTheRow(d, rarity);
					}
				}
				//if (displayedCount >= 250) break;
			}
		}

		//let tableStyle = {visibility:this.state.loading&&this.state.loadingLong?'hidden':'visible'};
		return (
			<div className="PageContainer-Fixed">
				<div className="SideMenu">
					{worldMenus}
				</div>
				<div className="PageContent PageContent-WithMenu">
					<div className="PageNav PageNav-MobileMenu">
						<a href={'/'+this.props.url.join('/')} className="PageNav-MenuToggle" onClick={this.props.toggleBurger}>☰ <span className="PageNav-MenuToggleText">{world} (tap to change world)</span></a>
					</div>
					<div className="FilterBox">
						<div><h2>Type</h2>
						<a href={`${urlBase}rarity/title/${world}/`} className={type==='title'?'current':''}>Titles</a>
						<a href={`${urlBase}rarity/achievement/${world}/`} className={type==='achievement'?'current':''}>Achievements</a>
						<a href={`${urlBase}rarity/mount/${world}/`} className={type==='mount'?'current':''}>Mounts</a>
						<a href={`${urlBase}rarity/minion/${world}/`} className={type==='minion'?'current':''}>Minions</a></div>
						<div><a href={`${urlBase}rarity/ttcard/${world}/`} className={type==='ttcard'?'current':''}>Triple Triad</a>
						<a href={`${urlBase}rarity/orchestrion/${world}/`} className={type==='orchestrion'?'current':''}>Orchestrion Rolls</a>
						<a href={`${urlBase}rarity/barding/${world}/`} className={type==='barding'?'current':''}>Bardings</a>
						<a href={`${urlBase}rarity/emote/${world}/`} className={type==='emote'?'current':''}>Emotes</a>
						<a href={`${urlBase}rarity/fashion/${world}/`} className={type==='fashion'?'current':''}>Fashion</a>
						<a href={`${urlBase}rarity/hairstyle/${world}/`} className={type==='hairstyle'?'current':''}>Hairstyles</a></div>
					</div>

					<LoadingBox visible={this.state.loading} />


					<div className="ImageBox">
						<img src="/images/top.png" alt="" />
						<div>
							<h2>Rarest {this.state.loadedType}s: {world}</h2>
							{blurb}
						</div>
					</div>
					<label>Search: <input type="text" value={this.state.searchText} onChange={this.onSearchChange} /></label>
					<table className="RankingTable">
						<tbody dangerouslySetInnerHTML={{__html: charRows.join('')}} />
						{ /*<tbody>
						{charRows}
						</tbody> */ }
					</table>
					<Pagination onChange={this.onPagination} current={this.state.page} length={totalCount} />
				</div>
			</div>
		);
	}
}

export default RarityRoute;