import React, {Component} from 'react';
import auth0 from '../auth0';
import './settings.scss';
let userStore = require('../stores/user').default;

class SettingsRoute extends Component {
	state = {
		passwordResetSent: false,
		user: userStore.getSettings()
	};
	constructor(props) {
		super(props);
		this.passwordChangeClick = this.passwordChangeClick.bind(this);
		userStore.on(this.onChange, this);
	}
	onChange() {
		this.setState({user: userStore.getSettings()});
	}
	componentDidMount() {
		/*async function inity() {
			let auth0 = await createAuth0Client({
				"domain": "lalachievements.auth0.com",
				"client_id": "zcMBsGxU9rVQbRBh5DjGqWlwghTeg4WI",
				redirect_uri: 'http://localhost:3000/login'
			});
			const isAuthenticated = await auth0.isAuthenticated();
			console.log('is auth ? ', isAuthenticated);
			if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
				//console.log()
				await auth0.handleRedirectCallback();
			} else if (!isAuthenticated) {
				await auth0.loginWithRedirect();
			} else {
				let user = await auth0.getUser();
				console.log(user);
			}
		}
		inity();*/
	}

	componentWillUnmount() {
		userStore.off(this.onChange, this);
	}
	passwordChangeClick(e) {
		e.preventDefault();
		auth0.sendPasswordReset().then(() => {
			this.setState({passwordResetSent: true});
		});
	}

	render() {
		let user = auth0.getUser();
		console.log(user);
		let passwordReset;
		if (user.sub.indexOf('auth0') > -1) {
			if (this.state.passwordResetSent) {
				passwordReset = <div>
					<h3>Change Password</h3>
					<p>Sent! Please check your email.</p>
				</div>;
			} else {
				passwordReset = <div>
					<h3>Change Password</h3>
					<p>
						<button onClick={this.passwordChangeClick}>
							Click here to send a password change request to your email.
						</button>
					</p>
				</div>;
			}
		}
		return (
			<div className="PageContainer-Center">
				<h2>Settings</h2>
				<p>These apply to the entire site. There are additional character-specific settings you may adjust for each of your characters.</p>
				<div className="SettingsContainer">
					<div className="SettingsColumn">
						<div className="SettingsBox">
							<h3>Email</h3>
							<p>{user.email}</p>
							<p>Only you can see this.</p>
							<p>Your email cannot be changed. If you wish to log in using a different email, you can register a new account and re-verify your character.</p>
							{passwordReset}
						</div>
						<div className="SettingsBox">
							<h3>Nickname</h3>
							<p>{this.state.user.nickname}</p>
							<p>Only you can see this.</p>
							{/*<p><input type="text" value={this.state.user.nickname} /></p>*/}
							{/*<p><input type="button" value="Save" /></p>*/}
						</div>
						<div className="SettingsBox">
							<h3>Patreon</h3>
							<p>Connect your Patreon account to get your benefits applied to your verified characters!</p>
							<p>Under construction.</p>
						</div>
					</div>
					<div className="SettingsColumn">
						{ /*<div className="SettingsBox">
							<h3>Theme</h3>
							<p className="OptionGroup">
								<label><input type="radio" name="theme" value="dark" checked="checked" disabled /><span>Dark</span></label>
								<label><input type="radio" name="theme" value="light" disabled /><span>Light</span></label>
							</p>
						</div>*/ }
						{ /*<div className="SettingsBox">
							<h3>Site link preferences</h3>
							<p className="OptionGroup">
								Crafting lists:<br />
								<label><input type="radio" name="craftingLists" value="garland" checked={this.state.user.linkCraftingListPreference==='garland'} /><span>Garland Data</span></label>
								<label><input type="radio" name="craftingLists" value="ffxivcrafting" checked={this.state.user.linkCraftingListPreference==='ffxivcrafting'} /><span>FFXIV Crafting</span></label>
								<label><input type="radio" name="craftingLists" value="teamcraft" checked={this.state.user.linkCraftingListPreference==='teamcraft'} /><span>Teamcraft</span></label>
							</p>
							<p className="OptionGroup">
								Fish:<br />
								<label><input type="radio" name="fish" value="ff14angler" checked={this.state.user.linkFishPreference==='ff14angler'} /><span>Cat became hungry (FF14Angler)</span></label>
								<label><input type="radio" name="fish" value="teamcraft" checked={this.state.user.linkFishPreference==='teamcraft'} /><span>Teamcraft</span></label>
							</p>
							<p className="OptionGroup">
								Database:<br />
								<label><input type="radio" name="database" value="garland" checked={this.state.user.linkDatabasePreference==='garland'} /><span>Garland Data</span></label>
								<label><input type="radio" name="database" value="teamcraft" checked={this.state.user.linkDatabasePreference==='teamcraft'} /><span>Teamcraft</span></label>
								<label><input type="radio" name="database" value="eriones" checked={this.state.user.linkDatabasePreference==='eriones'} /><span>ERIONES</span></label>
							</p>
						</div>*/ }
					</div>
				</div>
			</div>
		);
	}
}

export default SettingsRoute;