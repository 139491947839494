import React, {Component} from 'react';
//import {Helmet} from "react-helmet";
//import {useQuery} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import './profile.scss';
import {ResponsiveRadar} from '@nivo/radar';
import theme from '../../styles/nivotheme';

import RadarChart from 'react-svg-radar-chart';
//import 'react-svg-radar-chart/build/css/index.css'

import orchestrions from "./orchestrions";

//const radar = require('svg-radar-chart');
//const stringify = require('virtual-dom-stringify');


//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');
//let achievements = require('../../gamedata/json/en/achievements.json');

let store = require('../../stores/gameData').default;
//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let charStore = require('../../stores/char').default;

/*export default function CharProfileRoute({url, lang, char, sync}) {

	let QUERY = gql`
query {
  minions_aggregate(where:{obtainable:{_eq:true}}) {
    aggregate {
      count
    }
  }
  mounts_aggregate(where:{obtainable:{_eq:true}}) {
    aggregate {
      count
    }
  }
  achievements_aggregate(where:{obtainable:{_eq:true}}) {
    aggregate {
      sum {
        points
      }
    }
  }
}`;
	const {data, error, loading} = useQuery(QUERY);
	if (loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (error) {
		return <div>Error.</div>
	}

	return <CharProfileRouteInner url={url} lang={lang} data={data} char={char} sync={sync} />;
}*/
function makeState() {
	return {
		achievements: store.get('achievements'),
		mounts: store.get('mounts'),
		minions: store.get('minions'),
		ttcards: store.get('ttcards'),
		bardings: store.get('bardings'),
		emotes: store.get('emotes'),
		fashions: store.get('fashions'),
		orchestrions: store.get('orchestrions'),
		bozjaNotes: store.get('bozjaNotes'),
		sourceTypes: store.get('sourceTypes'),
		achievementsRarity: store.getRarity('achievements'),
		mountsRarity: store.getRarity('mounts'),
		minionsRarity: store.getRarity('minions')
	};
}
export default class CharProfileRouteInner extends Component {
	state = Object.assign({
		syncRequested: false,
	}, makeState());
	constructor(props) {
		super(props);
		this.sync = this.sync.bind(this);
		store.on(this.onChange, this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState(makeState());
	}
	sync(e) {
		e.preventDefault();
		//fetch(`/api/charrealtime/${this.props.char.id}/`);
		this.props.sync();
		//this.setState({syncRequested: true});
		//charStore.sync();
	}
	render() {
		let lang = this.props.lang;
		let char = this.props.char;
		let urlBase = this.props.urlBase;
		let achievementsEle, legacy, addTimeHuman, updateTimeHuman;
		//let dataCenter = 'unknown';
		/*let world = 'unknown';
		dataCenters.forEach(dc => {
			dc.Worlds.forEach(w => {
				if (w.Id === char.worldId) {
					world = w.Name;
					//dataCenter = dc.Name;
				}
			});
		});*/
		addTimeHuman = new Date(char.createdAt).toLocaleDateString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric'
		});
		updateTimeHuman = new Date(char.updatedAt).toLocaleDateString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			timeZoneName: 'short'
		});

		let recentMinion, rarestMinion;
		if (char.minionRecent) {
			let m = this.state.minions.find(_m => _m.id === char.minionRecent);
			let r = this.state.minionsRarity.find(_r => _r.id === char.minionRecent);
			if (m) {
				recentMinion = <div className="CharProfileBox-List">
					<a href={`${urlBase}minions/${m.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-minion icon-minion-${m.id}`} /></a>
					<h3>Recent</h3>
					<p className="CharProfileBox-List-Name">{m.name}</p>
				</div>;
			}
		}
		let _rarestMinion = this.state.minionsRarity.find(_r => _r.points > 0 && char.minions.find(_m => _m.id === _r.id));
		if (_rarestMinion) {
			if (char.minionRecent) {
				let m = this.state.minions.find(_m => _m.id === _rarestMinion.id);
				let r = this.state.minionsRarity.find(_r => _r.id === _rarestMinion.id);
				if (m) {
					rarestMinion = <div className="CharProfileBox-List">
						<a href={`${urlBase}minions/${m.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-minion icon-minion-${m.id}`} /></a>
						<h3>Rarest</h3>
						<p className="CharProfileBox-List-Name">{m.name}</p>
					</div>;
				}
			}
		}

		let recentMount, rarestMount;
		if (char.mountRecent) {
			let m = this.state.mounts.find(_m => _m.id === char.mountRecent);
			let r = this.state.mountsRarity.find(_r => _r.id === char.mountRecent);
			if (m) {
				recentMount = <div className="CharProfileBox-List">
					<a href={`${urlBase}mounts/${m.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-mount icon-mount-${m.id}`}/></a>
					<h3>Recent</h3>
					<p className="CharProfileBox-List-Name">{m.name}</p>
				</div>;
			}
		}
		let _rarestMount = this.state.mountsRarity.find(_r => _r.points > 0 && char.mounts.find(_m => _m.id === _r.id));
		if (_rarestMount) {
			if (char.mountRecent) {
				let m = this.state.mounts.find(_m => _m.id === _rarestMount.id);
				let r = this.state.mountsRarity.find(_r => _r.id === _rarestMount.id);
				if (m) {
					rarestMount = <div className="CharProfileBox-List">
						<a href={`${urlBase}mounts/${m.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-mount icon-mount-${m.id}`} /></a>
						<h3>Rarest</h3>
						<p className="CharProfileBox-List-Name">{m.name}</p>
					</div>;
				}
			}
		}

		let recentAchievement, rarestAchievement;
		if (char.achievementRecent) recentAchievement = this.state.achievements.find(m => m.id === char.achievementRecent);
		//let recentAchievement = char.recentAchievements[0].achievement;
		//let recentMount = char.recentMounts[0].mount;
		//let recentMinion = char.recentMinions[0].minion;

		if (char.achievementsPrivate) {
			achievementsEle =
				<div className="CharProfileBox">
					<h2><i className="fontello-achievement"/> 0</h2>
					<div className="CharProfileBox-Content">
						<p><strong>This character has achievements set to private on Lodestone.</strong></p>
					</div>
				</div>;
		} else if (!recentAchievement) {
			achievementsEle =
				<div className="CharProfileBox">
					<h2><i className="fontello-achievement"/> 0</h2>
					<div className="CharProfileBox-Content">
						<p><strong>Loading</strong></p>
					</div>
				</div>;
		} else {
			if (char.legacy) {
				//legacy = <p>This is a 1.x character! {char.achievementPointsLegacy} achievement points including legacy.</p>;
			}
			let _rarestAchievement = this.state.achievementsRarity.find(_r => _r.points > 0 && char.achievements.find(_m => _m.id === _r.id));
			if (_rarestAchievement) {
				if (char.achievementRecent) {
					let m = this.state.achievements.find(_m => _m.id === _rarestAchievement.id);
					let r = this.state.achievementsRarity.find(_r => _r.id === _rarestAchievement.id);
					if (m) {
						rarestAchievement = <div className="CharProfileBox-List">
							<span className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-achievement icon-achievement-${m.icon}`} /></span>
							<h3>Rarest</h3>
							<p className="CharProfileBox-List-Name">{m.name}</p>
						</div>;
					}
				}
			}
			let r = this.state.achievementsRarity.find(_r => _r.id === recentAchievement.id);
			achievementsEle =
				<div className="CharProfileBox">
					<h2><i className="fontello-achievement" /> {char.achievementPoints.toLocaleString()}</h2>
					<p className="CharProfileBox-Percent">{Math.floor(char.achievementPoints/char.achievementPointsPossible*100)}%</p>
					<div className="CharProfileBox-Content">
						<h3>Achievement Rank</h3>
						<div className="CharProfileBox-Rank">
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/achievement/global/`}>Global</a>: # {char.globalAchievementRank > 0 ? (char.globalAchievementRank*1).toLocaleString() : 'unranked'}
							</p>
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/achievement/${char.worldName}/`}>{char.worldName}</a>: # {char.achievementRank > 0 ? (char.achievementRank*1).toLocaleString() : 'unranked'}
							</p>
						</div>
						<div className="CharProfileBox-List">
							<span className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-achievement icon-achievement-${recentAchievement.icon}`} /></span>
							<h3>Recent</h3>
							<p className="CharProfileBox-List-Name">{recentAchievement.name}</p>
						</div>
						{rarestAchievement}
						{legacy}
					</div>
				{ /*<p>Rarest achievement:<br />
					Global:
					<span class={`icon icon-achievement-${char.rarestAchievementGlobal[0].Icon}`} /> {char.rarestAchievementGlobal[0].Name}<br />
					World:
					<span class={`icon icon-achievement-${char.rarestAchievementWorld[0].Icon}`} /> {char.rarestAchievementWorld[0].Name}
				</p>*/ }
				</div>;
		}

		let sync;
		if (this.props.syncing) {
			sync = <p>Sync queued.</p>;
		} else {
			sync = <p><button onClick={this.sync}><i className="fontello-sync" /> Sync Now</button></p>;
		}
		//sync = <p>Sync is currently under maintenance.</p>;

		let lodeButton;
		let lodeButtonSubdomain = 'na';
		if (this.props.lang === 'ja') lodeButtonSubdomain = 'jp';
		if (this.props.lang === 'fr') lodeButtonSubdomain = 'fr';
		if (this.props.lang === 'de') lodeButtonSubdomain = 'de';
		lodeButton = <p><a href={`https://${lodeButtonSubdomain}.finalfantasyxiv.com/lodestone/character/${char.id}/`} target="_blank">View on Lodestone</a></p>;
		//lodeButton = <p><a href={`https://${lodeButtonSubdomain}.finalfantasyxiv.com/lodestone/character/${char.id}/`} target="_blank">View on Lodestone</a> | <a href={`/api/charcache/${char.id}/`}>API</a></p>;

		let chart1Captions = {
			achievements: `Achievements (${Math.floor(char.achievementPoints * 100 / char.achievementPointsPossible)}%)`,
			titles: `Titles (${Math.floor(char.titleCount * 100 / char.titlePossible)}%)`,
			mounts: `Mounts (${Math.floor(char.mountCount * 100 / char.mountPossible)}%)`,
			minions: `Minions (${Math.floor(char.minionCount * 100 / char.minionPossible)}%)`
		};
		let chart1Data = [
			{
				achievements: Math.floor(char.achievementPoints * 100 / char.achievementPointsPossible) / 100,
				titles: Math.floor(char.titleCount * 100 / char.titlePossible) / 100,
				mounts: Math.floor(char.mountCount * 100 / char.mountPossible) / 100,
				minions: Math.floor(char.minionCount * 100 / char.minionPossible) / 100
			}
		];
		console.log(chart1Captions, chart1Data);
		let chartData = [
			{
				type: 'Achievements',
				progress: Math.floor(char.achievementPoints * 100 / char.achievementPointsPossible)
			},
			{
				type: 'Titles',
				progress: Math.floor(char.titleCount * 100 / char.titlePossible)
			},
			{
				type: 'Mounts',
				progress: Math.floor(char.mountCount * 100 / char.mountPossible)
			},
			{
				type: 'Minions',
				progress: Math.floor(char.minionCount * 100 / char.minionPossible)
			}
		];
		let fashion;
		if (char.fashionPercent > 0) {
			chartData.push({type:'Fashion & Hairstyles',progress:Math.floor(char.fashionCombinedPercent)});
			chart1Data[0].fashion = Math.floor(char.fashionCombinedPercent)/100;
			chart1Captions.fashion = `Fashion & Hairstyles (${Math.floor(char.fashionCombinedPercent)}%)`;
			let recentFashion = this.state.fashions.find(m => m.id === char.fashionRecent);
			fashion = <div className="CharProfileBox">
				<h2><i className="fontello-umbrella" /> {char.fashionCount.toLocaleString()}</h2>
				<p className="CharProfileBox-Percent">{Math.floor(char.fashionPercent)}%</p>
				{recentFashion ? <div className="CharProfileBox-Content">
					<div className="CharProfileBox-List">
						<span className={`icon icon-fashion icon-fashion-${recentFashion.id}`} />
						<h3>Recent Fashion Accessory</h3>
						<p className="CharProfileBox-List-Name">{recentFashion.name}</p>
					</div>
				</div> : ''}
			</div>
		}
		if (char.levePercent > 0) {
			chartData.push({type:'Leves',progress:Math.floor(char.levePercent)});
			chart1Data[0].leves = Math.floor(char.levePercent)/100;
			chart1Captions.leves = `Leves (${Math.floor(char.levePercent)}%)`;
		}
		let triadCard;
		if (char.triadCardPercent > 0) {
			chartData.push({type:'Triple Triad',progress:Math.floor(char.triadCardPercent)});
			chart1Data[0].triad = Math.floor(char.triadCardPercent)/100;
			chart1Captions.triad = `Triple Triad (${Math.floor(char.triadCardPercent)}%)`;
			let recentTriadCard = this.state.ttcards.find(m => m.id === char.triadCardRecent);
			/*let _rarestTriadCard = this.state.ttcardsRarity.find(_r => _r.points > 0 && char.ttcards.find(_m => _m.id === _r.id));
			if (_rarestTriadCard && recentTriadCard) {
					let m = this.state.ttcards.find(_m => _m.id === _rarestTriadCard.id);
					let r = this.state.ttcardsRarity.find(_r => _r.id === _rarestTriadCard.id);
					if (m) {
						rarestMount = <div className="CharProfileBox-List">
							<a href={`${urlBase}ttcards/${m.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span className={`icon icon-mount icon-ttcards-${m.id}`} /></a>
							<h3>Rarest</h3>
							<p className="CharProfileBox-List-Name">{m.name}</p>
						</div>;
					}
			}*/
			triadCard = <div className="CharProfileBox">
				<h2><i className="fontello-triad" /> {char.triadCardCount.toLocaleString()}</h2>
				<p className="CharProfileBox-Percent">{Math.floor(char.triadCardPercent)}%</p>
				{recentTriadCard ? <div className="CharProfileBox-Content">
					<div className="CharProfileBox-List">
						<span className={`icon icon-ttcards icon-ttcards-${recentTriadCard.id}`} />
						<h3>Recent Card</h3>
						<p className="CharProfileBox-List-Name">{recentTriadCard.name}</p>
					</div>
				</div> : ''}
			</div>
		}
		let orchestrion;
		if (char.orchestrionPercent > 0) {
			chartData.push({type:'Orchestrion',progress:Math.floor(char.orchestrionPercent)});
			chart1Data[0].orchestrion = Math.floor(char.orchestrionPercent)/100;
			chart1Captions.orchestrion = `Orchestrion (${Math.floor(char.orchestrionPercent)}%)`;
			let recentOrchestrion = this.state.orchestrions.find(m => m.id === char.orchestrionRecent);
			orchestrion = <div className="CharProfileBox">
				<h2><i className="fontello-music" /> {char.orchestrionCount.toLocaleString()}</h2>
				<p className="CharProfileBox-Percent">{Math.floor(char.orchestrionPercent)}%</p>
				{recentOrchestrion ? <div className="CharProfileBox-Content">
					<div className="CharProfileBox-List">
						<h3>Recent Orchestrion Roll</h3>
						<p className="CharProfileBox-List-Name">{recentOrchestrion.name}</p>
					</div>
				</div> : ''}
			</div>
		}
		let barding;
		if (char.bardingPercent > 0) {
			chartData.push({type:'Bardings',progress:Math.floor(char.bardingPercent)});
			chart1Data[0].bardings = Math.floor(char.bardingPercent)/100;
			chart1Captions.bardings = `Bardings (${Math.floor(char.bardingPercent)}%)`;
			let recentBarding = this.state.bardings.find(m => m.id === char.bardingRecent);
			barding = <div className="CharProfileBox">
				<h2><i className="fontello-bardings" /> {char.bardingCount.toLocaleString()}</h2>
				<p className="CharProfileBox-Percent">{Math.floor(char.bardingPercent)}%</p>
				{recentBarding ? <div className="CharProfileBox-Content">
					<div className="CharProfileBox-List">
						<span className={`icon icon-barding icon-barding-${recentBarding.id}`} />
						<h3>Recent Barding</h3>
						<p className="CharProfileBox-List-Name">{recentBarding.name}</p>
					</div>
				</div> : ''}
			</div>
		}
		let emote;
		if (char.emotePercent > 0) {
			chartData.push({type:'Emotes',progress:Math.floor(char.emotePercent)});
			chart1Data[0].emotes = Math.floor(char.emotePercent)/100;
			chart1Captions.emotes = `Emotes (${Math.floor(char.emotePercent)}%)`;
			let recentEmote = this.state.emotes.find(m => m.id === char.emoteRecent);
			emote = <div className="CharProfileBox">
				<h2><i className="fontello-wink" /> {char.emoteCount.toLocaleString()}</h2>
				<p className="CharProfileBox-Percent">{Math.floor(char.emotePercent)}%</p>
				{recentEmote ? <div className="CharProfileBox-Content">
					<div className="CharProfileBox-List">
						<span className={`icon icon-emote icon-emote-${recentEmote.id}`} />
						<h3>Recent Emote</h3>
						<p className="CharProfileBox-List-Name">{recentEmote.name}</p>
					</div>
				</div> : ''}
			</div>
		}
		if (char.fishPercent > 0) {
			chartData.push({type:'Fish',progress:Math.floor(char.fishPercent)});
			chart1Data[0].fish = Math.floor(char.fishPercent)/100;
			chart1Captions.fish = `Fish (${Math.floor(char.fishPercent)}%)`;
		}
		if (char.bozjaNotePercent > 0) {
			chartData.push({type:'Bozjan Field Notes',progress:Math.floor(char.bozjaNotePercent)});
			chart1Data[0].bozja = Math.floor(char.bozjaNotePercent)/100;
			chart1Captions.bozja = `Bozjan Field Notes (${Math.floor(char.bozjaNotePercent)}%)`;
		}

		/*let chartV = radar(chart1Captions, chart1Data, {
			size: 100,
			scales: 4,
			axisProps: () => ({className: 'axis'}),
			scaleProps: () => ({className: 'scale', fill: 'none'}),
			shapeProps: () => ({className: 'shape'}),
			captionProps: () => ({
				className: 'caption',
				textAnchor: 'middle', fontSize: 3.5,
				fontFamily: 'sans-serif'
			})
		});
		let chartSvg = `<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 140 100" style="max-height:320px;">
			<style>
				.axis {
					stroke-width: .2;
				}
				.scale {
					stroke-width: .2;
					stroke: rgb(85,85,85);
				}
				.shape {
					stroke-width: .5;
					fill-opacity: .6;
					stroke: rgb(0, 102, 170);
					fill: rgb(0, 102, 170);
				}
			</style>
			${stringify(chartV)}
		</svg>`;

		let chart2Data = [
			{
				data: chart1Data[0]
			}
		];*/
		let banner, containerClasses = 'CharProfile';
		if (char.hasBanner) {
			containerClasses += ' CharProfile-HasBanner';
			banner = <div className="CharProfileBg" style={{backgroundImage: `url(/uploads/${char.id}.${char.hasBanner}.png)`}}><div className="CharProfileBgFade"></div></div>;
		}


		return <div>
			{ /*<Helmet>
				<meta name="description" content={`Character profile for ${char.name} of ${char.worldName}`} />
			</Helmet>*/ }
			<div className={containerClasses}>
				{banner}
				{achievementsEle}

				<div className="CharProfileBox">
					<h2><i className="fontello-mounts" /> {char.mountCount.toLocaleString()}</h2>
					<p className="CharProfileBox-Percent">{Math.floor(char.mountCount/char.mountPossible*100)}%</p>
					<div className="CharProfileBox-Content">
						<h3>Mount Rank</h3>
						<div className="CharProfileBox-Rank">
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/mount/global/`}>Global</a>: # {char.globalMountRank > 0 ? (char.globalMountRank*1).toLocaleString() : 'unranked'}
							</p>
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/mount/${char.worldName}/`}>{char.worldName}</a>: # {char.mountRank > 0 ? (char.mountRank*1).toLocaleString() : 'unranked'}
							</p>
						</div>
						{recentMount}
						{rarestMount}
						{ /*<p>Rarest mount:<br />
						Global:
						<span class={`icon icon-mount-${char.rarestMountGlobal[0].Icon}`} /> {char.rarestMountGlobal[0].Name}<br />
						World:
						<span class={`icon icon-mount-${char.rarestMountWorld[0].Icon}`} /> {char.rarestMountWorld[0].Name}
					</p>*/ }
					</div>
				</div>

				<div className="CharProfileBox">
					<h2><i className="fontello-minions" /> {char.minionCount.toLocaleString()}</h2>
					<p className="CharProfileBox-Percent">{Math.floor(char.minionCount/char.minionPossible*100)}%</p>
					<div className="CharProfileBox-Content">
						<h3>Minion Rank</h3>
						<div className="CharProfileBox-Rank">
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/minion/global/`}>Global</a>: # {char.globalMinionRank > 0 ? (char.globalMinionRank*1).toLocaleString() : 'unranked'}
							</p>
							<p>
								<a href={`${lang!=='en' ? `/${lang}`:``}/ranking/minion/${char.worldName}/`}>{char.worldName}</a>: # {char.minionRank > 0 ? (char.minionRank*1).toLocaleString() : 'unranked'}
							</p>
						</div>
						{recentMinion}
						{rarestMinion}
						{ /*<p>Rarest minion:<br />
							Global:
							<span className={`icon icon-minion-${char.rarestMinionGlobal[0].Icon}`} /> {char.rarestMinionGlobal[0].Name}<br />
							World:
							<span className={`icon icon-minion-${char.rarestMinionWorld[0].Icon}`} /> {char.rarestMinionWorld[0].Name}
						</p>*/ }
					</div>
				</div>

				{/*fashion}
				{triadCard}
				{orchestrion}
				{barding}
				{emote*/}

				<div className="CharProfileBox">
					<h2>Lodestone Sync</h2>
					<div className="CharProfileBox-Content">
						<p>Added: {addTimeHuman}</p>
						<p>Updated: {updateTimeHuman}</p>
						{lodeButton}<br />
						{sync}
					</div>
				</div>


				<div className="CharProfileBox">
					<h2>Collection</h2>
					<div className="CharProfileBox-Content">
						<div style={{height:'320px'}}>
							<ResponsiveRadar
								data={chartData}
								keys={['progress']}
								indexBy="type"
								theme={theme}
								colors="#06a"
								fillOpacity={0.7}
								blendMode="normal"
								//borderColor={{from:'color'}}
								//dotColor={{theme:'background'}}
								//borderWidth={1}
								maxValue={100}
								gridLevels={4}
								gridLabelOffset={7}
								gridShape="linear"
								enableDots={true}
								enableDotLabel={false}
								dotLabelYOffset={-5}
								margin={{top:40,right:90,bottom:40,left:90}}
								isInteractive={false}
								animate={false}
								gridLabel={({id,anchor})=> (
									<text dominantBaseline="central" textAnchor={anchor} style={{fontSize: '11px'}}>{id} ({chartData.find(d=>d.type===id).progress}%)</text>
								)}
							/>
						</div>
					</div>
					{ /*<div className="CharProfileBox">
						<h2>Collection</h2>
						<div className="CharProfileBox-Content">
							<div style={{height:'320px'}}>
								<RadarChart captions={chart1Captions} data={chart2Data} size={320} options={{
									dots: true,
									scales: 4
								}} />
							</div>
						</div>
					</div>
					<div className="CharProfileBox">
						<h2>Collection</h2>
						<div className="CharProfileBox-Content">
							<div style={{height:'320px'}} dangerouslySetInnerHTML={{__html:chartSvg}}>
							</div>
						</div>
					</div>*/ }
				</div>



				<div className="CharProfileBox">
					<h2>Portrait</h2>
					<div>
						<img className="Char-Portrait" src={char.imageUrl} alt="" />
					</div>
				</div>


			</div>

			{ /*<img src={`/${this.props.lang}/char/${char.id}.png`} alt="" />*/ }
		</div>;
	}
}
