import React, {Component} from 'react';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let expansions = require('../../data/expansions');
/*let categories = require('../../gamedata/json/en/orchestrionCategories.json').filter(category => {
    return category.Id > 1;
}).map(category => {
    return Object.assign({}, category, {
        Url: urlify(category.Name)
    });
});
let orchestrions = require('../../gamedata/json/en/orchestrions.json').map(orchestrion => {
    return Object.assign({}, orchestrion, {
        OrchestrionCategory: categories.find(c => c.Id === orchestrion.OrchestrionCategory)
    });
});*/

let filterTypes = ['All', 'Completed', 'Missing'];

let store = require('../../stores/gameData').default;
function makeState() {
    return {
        char: charStore.getData(),
        categories: store.get('orchestrionCategories'),
        orchestrions: store.get('orchestrions')
    };
}
export default class CharOrchestrionsRoute extends Component {
    state = Object.assign({
        filter: 'All'
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('orchestrion').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('orchestrion').then(() => this.setState({loading: false}));
        }
    }*/

    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('orchestrion', id);
        } else {
            charStore.unmarkItem('orchestrion', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        let currentCategory = this.state.categories.find(c => c.slug === this.props.url[0]);
        let currentId = this.props.url[1]*1;

        if (this.state.categories.length < 1 || this.state.orchestrions.length < 1) return <div>Loading</div>;

        if (!currentCategory) currentCategory = this.state.categories[0];

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        let filters = this.state.categories.sort((a, b) => {
            if (a.sort > b.sort) return 1;
            if (a.sort < b.sort) return -1;
            return 0;
        }).map(category => {
            let urlified = category.slug;
            let hasCount = 0, totalCount = 0;
            this.state.orchestrions.filter(orchestrion => {
                if (category.id === 1) return true;
                return orchestrion.orchestrionCategoryId === category.id;
            }).forEach(orchestrion => {
                //let has = this.state.char && this.state.char.orchestrion && this.state.char.orchestrion.find(l => l.orchestrionId === orchestrion.Id && l.deleted !== true);
                //if (has) hasCount++;
                if (char.id > 0 && charStore.hasItem('orchestrion', orchestrion.id)) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}orchestrions/${urlified}`} className={currentCategory.slug===urlified?'current':''} key={category.id}>
                {category.name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });

        let hasCount = 0, totalCount = 0;
        let rows = this.state.orchestrions.filter(orchestrion => {
            if (currentCategory.id === 1) return true;
            return orchestrion.orchestrionCategoryId === currentCategory.id;
        }).filter(orchestrion => {
            //let has = this.state.char && this.state.char.orchestrion && this.state.char.orchestrion.find(l => l.orchestrionId === orchestrion.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('orchestrion', orchestrion.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort > b.sort) return 1;
            if (a.sort < b.sort) return -1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
        }).map(orchestrion => {
            let category = this.state.categories.find(c => c.id === orchestrion.orchestrionCategoryId);
            let url = `${urlBase}orchestrions/${category.slug}/${orchestrion.id}/`;
            //let has = this.state.char && this.state.char.orchestrion && this.state.char.orchestrion.find(l => l.orchestrionId === orchestrion.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('orchestrion', orchestrion.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(orchestrion.id, !has)}>
                    <i className={this.state.char.char.orchestrionload && this.state.char.char.orchestrionload.includes(orchestrion.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            return <tr className={className} key={orchestrion.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="RankingRow-Number">{orchestrion.sort < 1000 ? orchestrion.sort : ''}</td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{orchestrion.name}</a><br />
                    <span className="MountMinionDetails-Tag">{category.name}</span>
                </td>
                <td dangerouslySetInnerHTML={{__html:orchestrion.description}} />
            </tr>;
        });
        //let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (char.orchestrionPercent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let orchestrion = this.state.orchestrions.find(l => l.id === currentId);
            let category = this.state.categories.find(c => c.id === orchestrion.orchestrionCategoryId);
            if (orchestrion) {
                let order;
                if (orchestrion.sort < 1000) {
                    order = <div># {orchestrion.sort}</div>
                } else {
                    order = <div>Hidden until obtained</div>
                }
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/orchestrions/${currentCategory.slug}/`}><i
                        className="fontello-left-open"/> Back to orchestrion list</a>
                    <h2>{orchestrion.name}</h2>
                    <div className="DetailsRewardsBox">
                        <div>{category.name}</div>
                        <div>{order}</div>
                    </div>
                    <h3>In-game Source</h3>
                    <p>{orchestrion.description}</p>
                    <h3>Detailed Source</h3>
                    <p>&nbsp;</p>
                    <h3>Sample</h3>
                    <Audio src={`/assets/orchestrion/${orchestrion.id}.ogg`} />
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">
            {/*this.state.loading &&
            <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
            */}

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                <div className="FilterBox">
                    <h2>Type</h2>
                    {filters}
                </div>
                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>Orchestrions / {currentCategory.name}</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(char.orchestrionPercent)}%</p>
                    <p className="CompletionSummary-Numbers">{char.orchestrionCount} / {char.orchestrionPossible}</p>
                    <p className="CompletionSummary-Remaining">({char.orchestrionPossible-char.orchestrionCount} remaining)</p>
                    <span className={progressClassName} style={{width:char.orchestrionPercent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                    <h3>Orchestrions / {currentCategory.name}</h3>
                </div>}
                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}