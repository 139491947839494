import React, {Component} from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

class LoginRoute extends Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}
	componentDidMount() {
		async function inity() {
			let auth0 = await createAuth0Client({
				"domain": "lalachievements.auth0.com",
				"client_id": "zcMBsGxU9rVQbRBh5DjGqWlwghTeg4WI",
				redirect_uri: 'http://localhost:3000/login'
			});
			const isAuthenticated = await auth0.isAuthenticated();
			console.log('is auth ? ', isAuthenticated);
			if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
				//console.log()
				await auth0.handleRedirectCallback();
			} else if (!isAuthenticated) {
				await auth0.loginWithRedirect();
			} else {
				let user = await auth0.getUser();
				console.log(user);
			}
		}
		inity();
	}
	render() {
		return (
			<div>
				<h2>Login</h2>
				<div ref={this.ref} id="widget"></div>
			</div>
		);
	}
}

export default LoginRoute;