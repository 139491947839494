import React, {Component} from 'react';
import '../../gamedata/spritesheet/fashion.css';

let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;
let store = require('../../stores/gameData').default;

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        fashions: store.get('fashions')
    };
}
export default class CharFashionsRoute extends Component {
    state = Object.assign({
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('fashion', id);
        } else {
            charStore.unmarkItem('fashion', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        let currentId = this.props.url[0]*1;

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        let rows = this.state.fashions.filter(fashion => {
            let has = char.id > 0 && charStore.hasItem('fashion', fashion.id);
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort < b.sort) {return -1;}
            if (a.sort > b.sort) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(fashion => {
            let url = `${urlBase}fashions/${fashion.id}/`;
            let has = char.id > 0 && charStore.hasItem('fashion', fashion.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(fashion.id, !has)}>
                    <i className={this.state.char.char.fashionload && this.state.char.char.fashionload.includes(fashion.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let icon;
            if (fashion.sourceTypeId === 7 || fashion.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
            else if (!fashion.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            return <tr className={className} key={fashion.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={`icon icon-fashion icon-fashion-${fashion.id}`} title={fashion.name}/>{icon}</td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{fashion.name}</a><br />
                </td>
                <td dangerouslySetInnerHTML={{__html:fashion.howTo}} />
            </tr>;
        });
        let progressClassName = 'FilterProgressInner';
        if (char.fashionPercent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let fashion = this.state.fashions.find(l => l.id === currentId);
            if (fashion) {
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/fashions/`}><i
                        className="fontello-left-open"/> Back to fashion accessory list</a>
                    <h2>{fashion.name}</h2>
                    <p dangerouslySetInnerHTML={{__html:fashion.howTo}} />
                    <hr />
                    <img src={`/assets/fashion/${fashion.id}.png`} alt="" />
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                {char.id > 0 ? <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}fashions/`}>Fashion Accessories</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.fashionPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.fashionCount} / {char.fashionPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.fashionPossible-char.fashionCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.fashionPercent+'%'}}>&nbsp;</span>
                    </div>
                    <div className="CompletionSummary">
                        <h3>Spectacles</h3>
                        <p className="CompletionSummary-Numbers">coming soon</p>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}hairstyles/`}>Hairstyles</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.hairPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.hairCount} / {char.hairPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.hairPossible-char.hairCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.hairPercent+'%'}}>&nbsp;</span>
                    </div>
                </div> : <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}fashions/`}>Fashion Accessories</a></h3>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}hairstyles/`}>Hairstyles</a></h3>
                    </div>
                </div>}
                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}