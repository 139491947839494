import React, {Component} from 'react';
import '../../gamedata/spritesheet/emote.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;
let store = require('../../stores/gameData').default;

//let emotes = require('../../gamedata/json/en/emotes.json');

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        sourceTypes: store.get('sourceTypes'),
        emoteCategories: store.get('emoteCategories'),
        emotes: store.get('emotes')
    };
}
export default class CharEmotesRoute extends Component {
    state = Object.assign({
        sourceFilter: -1,
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('emote').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('emote').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }
    setFilter(e) {
        let f = e.target.dataset.source * 1;
        if (this.state.sourceFilter === f) {
            this.setState({sourceFilter: -1});
        } else {
            this.setState({sourceFilter: f});
        }
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('emote', id);
        } else {
            charStore.unmarkItem('emote', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        let currentId = this.props.url[0]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            emotes.filter(emote => {
                if (category.Id === 1) return true;
                return emote.emoteCategory === category;
            }).forEach(emote => {
                let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}emotes/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/
        let filters = this.state.emotes.reduce(function(accumulator, d) {
            if (accumulator.indexOf(d.sourceTypeId) === -1) {
                accumulator.push(d.sourceTypeId);
            }
            return accumulator;
        }, []).sort();
        let filtersElements = filters.map((filterId) => {
            let sourceType = this.state.sourceTypes.find(t => t.id === filterId*1);
            if (!sourceType) return;
            let filterName = sourceType.name;
            let filteredEmotes = this.state.emotes.filter(emote => emote.obtainable && emote.sourceTypeId === filterId);
            let hasEmotes = filteredEmotes.filter(emote => char.id > 0 && charStore.hasItem('emote', emote.id));
            let percent = char.id > 0 && hasEmotes.length * 100 / filteredEmotes.length;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <button data-source={filterId} key={filterId} className={this.state.sourceFilter === filterId*1?'current':''} onClick={this.setFilter}>
                {filterName}
                {char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
            </button>;
        });

        let hasCount = 0, totalCount = 0;
        let rows = this.state.emotes.filter(emote => {
            return true;
            //if (currentCategory.Id === 1) return true;
            //return emote.emoteCategory === currentCategory;
        }).filter(emote => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('emote', emote.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (this.state.sourceFilter > 0 && this.state.sourceFilter !== emote.sourceTypeId) return false;
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort < b.sort) {return -1;}
            if (a.sort > b.sort) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(emote => {
            let url = `${urlBase}emotes/${emote.id}/`;
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('emote', emote.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(emote.id, !has)}>
                    <i className={this.state.char.char.emoteload && this.state.char.char.emoteload.includes(emote.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let emoteCategory = this.state.emoteCategories.find(c => c.id === emote.emoteCategoryId);
            let icon;
            if (emote.sourceTypeId === 7 || emote.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
            else if (!emote.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            return <tr className={className} key={emote.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={`icon icon-emote icon-emote-${emote.id}`} title={emote.name}/>{icon}</td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{emote.name}</a><br />
                    <span className="MountMinionDetails-Tag">{emoteCategory ? emoteCategory.name : ''}</span>
                </td>
                <td dangerouslySetInnerHTML={{__html:emote.howTo}} />
            </tr>;
        });
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let emote = this.state.emotes.find(l => l.id === currentId);
            if (emote) {
                /*let order;
                if (emote.Order < 1000) {
                    order = <div># {emote.Order}</div>
                } else {
                    order = <div>Hidden until obtained</div>
                }*/
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/emotes/`}><i
                        className="fontello-left-open"/> Back to emote list</a>
                    <h2>{emote.name}</h2>
                    { /*<div className="DetailsRewardsBox">
                        <div>{emote.emoteCategory.Name}</div>
                        <div>{order}</div>
                    </div>
                    <h3>In-game Source</h3>
                    <p>{emote.Description}</p>
                    <h3>Detailed Source</h3>
                    <p>&nbsp;</p>
                    <h3>Sample</h3>
                    <Audio src={`/assets/emote/${emote.Id}.ogg`} />*/ }
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div>: ''}
                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>emotes</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(char.emotePercent)}%</p>
                    <p className="CompletionSummary-Numbers">{char.emoteCount} / {char.emotePossible}</p>
                    <p className="CompletionSummary-Remaining">({char.emotePossible-char.emoteCount} remaining)</p>
                    <span className={progressClassName} style={{width:char.emotePercent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                    <h3>emotes</h3>
                </div>}

                <div className="FilterBox">
                    <h2>Source</h2>
                    {filtersElements}
                </div>

                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}