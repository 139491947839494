import React, {Component} from 'react';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import '../../gamedata/spritesheet/rewards.css';
import '../../gamedata/spritesheet/leveAssignmentType.css';
import '../../gamedata/spritesheet/levePlate.css';
import DetailMap from "../../components/DetailMap";
let urlify = require('urlify').create();
let store = require('../../stores/gameData').default;
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

//let dataCenters = require('../../gamedata/json/dataCenters.json');
let expansions = require('../../data/expansions');
//let leves = require('../../gamedata/json/en/leves.json');

let sortTypes = ['Journal', 'Level', 'NPC'];
sortTypes = ['Level'];
let filterTypes = ['All', 'Completed', 'Missing'];
let leveTypes = ['Battlecraft', 'Carpenter', 'Blacksmith', 'Armorer', 'Goldsmith', 'Leatherworker', 'Weaver', 'Alchemist', 'Culinarian', 'Miner', 'Botanist', 'Fisher', 'The_Maelstrom', 'Order_of_the_Twin_Adder', 'Immortal_Flames'];
let leveTypes1 = ['Carpenter', 'Blacksmith', 'Armorer', 'Goldsmith', 'Leatherworker', 'Weaver', 'Alchemist', 'Culinarian'];
let leveTypes2 = ['Battlecraft', 'Miner', 'Botanist', 'Fisher', 'The_Maelstrom', 'Order_of_the_Twin_Adder', 'Immortal_Flames'];
let journalSort = {
	'Gridania': 1,
	'Limsa Lominsa': 2,
	'Ul\'dah': 3,
	'Ishgard': 4,
	'Lower La Noscea': 5,
	'Eastern La Noscea': 6,
	'Western La Noscea': 7,
	'Central Shroud': 8,
	'East Shroud': 9,
	'South Shroud': 10,
	'Western Thanalan': 11,
	'Eastern Thanalan': 12,
	'Southern Thanalan': 13,
	'Northern Thanalan': 14,
	'Coerthas Central Highlands': 15,
	'Coerthas Western Highlands': 16,
	'Mor Dhona': 17,
	'Kugane': 18,
	'The Crystarium': 19,
	'The Sea of Clouds': 20,
	'The Dravanian Forelands': 21,
	'The Dravanian Hinterlands': 22,
	'The Churning Mists': 23,
	'The Fringes': 24,
	'The Peaks': 25,
	'The Ruby Sea': 26,
	'Yanxia': 27,
	'The Azim Steppe': 28,
	'Lakeland': 29,
	'Kholusia': 30,
	'Amh Araeng': 31,
	'Il Mheg': 32,
	'The Rak\'tika Greatwood': 33,
	'Maelstrom': 100,
	'Order of the Twin Adder': 101,
	'Immortal Flames': 102
};
/*let npcSortRegion = {
	'La Noscea': 1,
	'The Black Shroud': 2,
	'Thanalan': 3,
	'Coerthas': 4,
	'Mor Dhona': 5,
};*/

export default class CharLevesRoute extends Component {
	state = {
		groupBy: 'Level',
		filter: 'All',
		char: charStore.getData(),
		leves: store.get('leves'),
		leveAssignmentTypes: store.get('leveAssignmentTypes'),
		npcs: store.get('npcs'),
		placeNames: store.get('placeNames')
	};
	constructor(props) {
		super(props);
		this.toggleItem = this.toggleItem.bind(this);
		this.onGroupByClick = this.onGroupByClick.bind(this);
		this.onFilterClick = this.onFilterClick.bind(this);
		charStore.on(this.onChange, this);
		store.on(this.onChange, this);
	}
	onChange() {
		this.setState({
			char: charStore.getData(),
			leves: store.get('leves'),
			leveAssignmentTypes: store.get('leveAssignmentTypes'),
			npcs: store.get('npcs'),
			placeNames: store.get('placeNames')
		});
	}
	showLoginMessage() {
		alert('Please log in and verify ownership of this character to check off items.');
	}
	componentWillUnmount() {
		charStore.off(this.onChange, this);
		userStore.off(this.onChange, this);
		store.off(this.onChange, this);
	}
	/*componentDidMount() {
		this.setState({loading: true});
		charStore.loadItems('leve').then(() => this.setState({loading: false}));
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.char.id !== this.props.char.id) {
			this.setState({loading: true});
			charStore.loadItems('leve').then(() => this.setState({loading: false}));
		}
	}*/
	onGroupByClick(e) {
		this.setState({groupBy: e.target.innerText});
	}
	onFilterClick(e) {
		this.setState({filter: e.target.innerText});
	}

	toggleItem(id, toggleTo) {
		if (toggleTo) {
			charStore.markItem('leve', id);
		} else {
			charStore.unmarkItem('leve', id);
		}
	}

	render() {
		let lang = this.props.lang;
		let char = this.props.char;
		let url = this.props.url;
		let urlBase = this.props.urlBase;
		//let currentExpansion = this.props.url[4]*1;
		//let currentSort = this.state.groupBy; //this.props.url[3];
		let currentTypeSlug = this.props.url[0];
		let currentLeveId = this.props.url[1]*1;
		//if (leveTypes.indexOf(currentType) === -1) currentType = '';
//console.log(currentSort, currentType, currentLeveId);
		//if (sortTypes.indexOf(currentSort) === -1) currentSort = 'Level';
		//if (leveTypes.indexOf(currentType) === -1) currentType = 'Battlecraft';
		let isCharMine = userStore.getUserChars().find(c => c.id === char.id);
		let currentType = this.state.leveAssignmentTypes.find(type => type.slug === currentTypeSlug);

		let content;
		if (!currentType) {
			let summaryColumns = [leveTypes1, leveTypes2].map((types, i) => {
				let summaryRows = types.map(type1 => {
					let type = this.state.leveAssignmentTypes.find(type => type.slug === type1);
					let hasCount = 0, totalCount = 0;
					this.state.leves.filter(leve => {
						return leve.leveAssignmentTypeId === type.id;
					}).forEach(leve => {
						//let has = this.state.char && this.state.char.leve && this.state.char.leve.find(l => l.leveId === leve.Id && l.deleted !== true);
						if (!leve.isRemoved && !leve.isUnlock) {
							let has = char.id > 0 && charStore.hasItem('leve', leve.id);
							if (has) hasCount++;
							totalCount++;
						}
					});
					let percent = Math.floor(hasCount * 100 / totalCount);
					let progressClassName = 'FilterProgressInner';
					if (percent === 100) {
						progressClassName += ' FilterProgressInner-Complete';
					}


					if (char.id > 0) {
						return <div className="CompletionSummary CompletionSummary-WithIcon" key={type.id}>
							<span className={progressClassName} style={{width: percent + '%'}}>&nbsp;</span>
							<a href={`${urlBase}leves/${type.slug}`}><i
								className={`icon icon-leveAssignmentType icon-leveAssignmentType-${type.slug}`}/></a>
							<h3><a href={`${urlBase}leves/${type.slug}`}>{type.name}</a></h3>
							<p className="CompletionSummary-Percent">{percent}%</p>
							<p className="CompletionSummary-Numbers">{hasCount} / {totalCount} <span
								className="CompletionSummary-Remaining">({totalCount - hasCount} remaining)</span></p>
						</div>;
					} else {
						return <div className="CompletionSummary CompletionSummary-WithIcon" key={type.id}>
							<a href={`${urlBase}leves/${type.slug}`}><i
								className={`icon icon-leveAssignmentType icon-leveAssignmentType-${type.slug}`}/></a>
							<h3><a href={`${urlBase}leves/${type.slug}`}>{type.name}</a></h3>
							<p className="CompletionSummary-Numbers">{totalCount}</p>
						</div>;
					}
				});
			return <div key={i}>{summaryRows}</div>;
			});

			content = <div className="BigSummaryGrid">
				{ /*<table className="MountTable">
					<tbody>*/ }
						{summaryColumns}
				{ /*</tbody>
				</table>*/ }
			</div>;

		} else {

			let sorts = sortTypes.map(type => {
				//let urlified = urlify(currentType);
				/*return <a href={`${urlBase}leves/${type}/${currentType}`} className={currentSort===type?'current':''} key={type}>
                    {type}
                </a>;*/
				return <button onClick={this.onGroupByClick} className={this.state.groupBy===type?'current':''} key={type}>
					{type}
				</button>;
			});
			let viewFilters = filterTypes.map(type => {
				return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
					{type}
				</button>;
			});
			let filters = leveTypes.map(type1 => {
				let type = this.state.leveAssignmentTypes.find(type => type.slug === type1);
				let hasCount = 0, totalCount = 0;
				this.state.leves.filter(leve => {
					return leve.leveAssignmentTypeId === type.id;
				}).forEach(leve => {
					//let has = this.state.char && this.state.char.leve && this.state.char.leve.find(l => l.leveId === leve.Id && l.deleted !== true);
					if (!leve.isRemoved && !leve.isUnlock) {
						let has = char.id > 0 && charStore.hasItem('leve', leve.id);
						if (has) hasCount++;
						totalCount++;
					}
				});
				let percent = hasCount * 100 / totalCount;
//console.log(urlified, currentType);
				return <a href={`${urlBase}leves/${type.slug}`} className={currentType.id===type.id?'current':''} key={type.id}>
					<i className={`icon icon-leveAssignmentType icon-leveAssignmentType-${type.slug}`} />
					<span className="FilterProgressContainer"><span className="FilterProgressInner" style={{width:percent+'%'}}>&nbsp;</span></span>
				</a>;
			});

			let filteredLeves = this.state.leves.filter(leve => {
				return leve.leveAssignmentTypeId === currentType.id;
			}).filter(leve => {
				//let has = this.state.char && this.state.char.leve && this.state.char.leve.find(l => l.leveId === leve.Id && l.deleted !== true);
				let has = char.id > 0 && charStore.hasItem('leve', leve.id);
				if (char.id > 0 && this.state.filter === 'Completed') {
					if (leve.isRemoved && leve.isUnlock) return false;
					return has;
				} else if (char.id > 0 && this.state.filter === 'Missing') {
					if (leve.isRemoved && leve.isUnlock) return false;
					return !has;
				} else {
					return true;
				}
			}).sort((a, b) => {
				if (this.state.groupBy === 'Level') {
					if (a.classJobLevel > b.classJobLevel) return 1;
					if (a.classJobLevel < b.classJobLevel) return -1;
					if (a.id > b.id) return 1;
					if (a.id < b.id) return -1;
					return 0;
				} else if (this.state.groupBy === 'Journal') {
					if (a.journalSort > b.journalSort) return 1;
					if (a.journalSort < b.journalSort) return -1;
					//if (journalSort[a.journalName] > journalSort[b.journalName]) return 1;
					//if (journalSort[a.journalName] < journalSort[b.journalName]) return -1;
					if (a.classJobLevel > b.classJobLevel) return 1;
					if (a.classJobLevel < b.classJobLevel) return -1;
					if (a.id > b.id) return 1;
					if (a.id < b.id) return -1;
					return 0;
				} else if (this.state.groupBy === 'NPC') {
					//if (npcSortRegion[a.Origin.PlaceNameRegion] > npcSortRegion[b.Origin.PlaceNameRegion]) return 1;
					//if (npcSortRegion[a.Origin.PlaceNameRegion] < npcSortRegion[b.Origin.PlaceNameRegion]) return -1;
					if (a.classJobLevel > b.classJobLevel) return 1;
					if (a.classJobLevel < b.classJobLevel) return -1;
					if (a.id > b.id) return 1;
					if (a.id < b.id) return -1;
					return 0;
				}
				if (a.classJobLevel > b.classJobLevel) return 1;
				if (a.classJobLevel < b.classJobLevel) return -1;
				if (a.id > b.id) return 1;
				if (a.id < b.id) return -1;
				return 0;
			});


			let groupRows;
			if (this.state.groupBy === 'Level') {
				groupRows = expansions;
			} else if (this.state.groupBy === 'Journal') {
				groupRows = filteredLeves.reduce((accumulator, currentValue) => {
					if (!accumulator.find(a=>a.name===currentValue.journalName)) accumulator.push({
						name: currentValue.journalName,
						sort: currentValue.journalSort
					});
					return accumulator;
				}, []).sort((a, b) => {
					if (a.journalSort > b.journalSort) return 1;
					if (a.journalSort < b.journalSort) return -1;
					return 0;
				});
			} else if (this.state.groupBy === 'NPC') {
				groupRows = filteredLeves.reduce((accumulator, currentValue) => {
					//let val = currentValue.originNpcId;
					let npc = this.state.npcs.find(npc => npc.id === currentValue.originNpcId);
					//if (accumulator.indexOf(npc.name) === -1) accumulator.push(npc.name);
					if (npc && !accumulator.find(a=>a.id===currentValue.originNpcId)) accumulator.push({
						name: npc.name,
						id: npc.id
					});
					return accumulator;
				}, []).sort((a, b) => {
					if (a.name > b.name) return 1;
					if (a.name < b.name) return -1;
					return 0;
				});
			}
//console.log('groupRows', groupRows);
			let expansionRows = [];
			groupRows.forEach(expansion => {
				let xivcraftingItems = [];
				let garlandItems = [];
				let teamcraftItems = [];
				let hasCount = 0, totalCount = 0, allowancesRequired = 0;
				let filteredLeves2 = filteredLeves.filter(leve => {
					if (this.state.groupBy === 'Level') {
						return leve.classJobLevel >= expansion.levelMin && leve.classJobLevel < expansion.levelMax;
					} else if (this.state.groupBy === 'Journal') {
						return leve.journalName === expansion.name;
					} else if (this.state.groupBy === 'NPC') {
						return leve.originNpcId === expansion.id;
					}
					return true;
				}).map(leve => {
					let leveAssignmentType = this.state.leveAssignmentTypes.find(type => type.id === leve.leveAssignmentTypeId);
					let url = `${urlBase}leves/${leveAssignmentType.slug}/${leve.id}/`;
					//let has = this.state.char && this.state.char.leve && this.state.char.leve.find(l => l.leveId === leve.Id && l.deleted !== true);
					let has = char.id > 0 && charStore.hasItem('leve', leve.id);
					totalCount++;
					let className = 'MountRow';
					if (has) {
						hasCount++;
						className += ' MountRow-Have';
					} else {
						if (leve.name.indexOf('(L)') > -1) allowancesRequired += 10;
						else allowancesRequired++;
					}
					let originNpc = this.state.npcs.find(npc => npc.id === leve.originNpcId);
					let destinationNpc = this.state.npcs.find(npc => npc.id === leve.destinationNpcId);
					let originNpcPlaceName = originNpc && this.state.placeNames.find(pn => pn.id === originNpc.placeNameId);
					let destinationNpcPlaceName = destinationNpc && this.state.placeNames.find(pn => pn.id === destinationNpc.placeNameId);
//console.log(originNpcPlaceName, destinationNpcPlaceName);
					let npc;
					if (originNpcPlaceName && destinationNpcPlaceName && originNpcPlaceName.name !== destinationNpcPlaceName.name) {
						npc = <span>{originNpcPlaceName.name} -> {destinationNpcPlaceName.name}</span>;
					} else if (originNpcPlaceName) {
						npc = <span>{originNpcPlaceName.name}</span>;
					}
					let triple;
					if (leve.itemRepeats > 0) {
						triple = <strong>x {(leve.itemRepeats + 1)}</strong>;
					}
					let craftTurnin;
					if (leve.itemId > 0) {
						craftTurnin = leve.itemName;
						if (leve.itemCount > 1) {
							craftTurnin = craftTurnin + ' x' + leve.itemCount;
						}
						let craftCount = (leve.itemCount * (leve.itemRepeats + 1));
						if (!has) {
							// https://ffxivcrafting.com/list/saved/1820,1:3763,1
							xivcraftingItems.push(leve.itemId + ',' + craftCount);
							garlandItems.push('item/' + leve.itemId + '+' + craftCount);
							teamcraftItems.push(leve.itemId + ',null,' + craftCount);
						}
					}
					let unobtainable;
					if (leve.isRemoved || leve.isUnlock) {
						unobtainable = <span className="MountMinionDetails-Tag UnobtainableTag">Unobtainable</span>;
					}
					let checkbox;
					if (isCharMine) {
						checkbox = <button onClick={(e) => this.toggleItem(leve.id, !has)}>
							<i className={this.state.char.char.leveload && this.state.char.char.leveload.includes(leve.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
						</button>;
					} else {
						checkbox = <i className={'fontello-ok'} onClick={this.showLoginMessage}/>;
					}
					return <tr className={className} key={leve.id}>
						<td className="MountRow-Check">
							{checkbox}
						</td>
						<td className="MountRow-Text"><a href={url} className="MountRow-Name" dangerouslySetInnerHTML={{__html:leve.name}}/><br/><span dangerouslySetInnerHTML={{__html:craftTurnin}}/>{unobtainable}
						</td>
						<td className="MountRow-Text">Lv. {leve.classJobLevel}<br/>{npc}</td>
						<td className="MountRow-Text notMobile">
							{leve.expReward > 0 && <div className="Pill Pill-Reward"><i
								className="icon icon-rewards icon-rewards-065001"/> {leve.expReward.toLocaleString()}
							</div>}
							{leve.gilReward > 0 && <div className="Pill Pill-Reward"><i
								className="icon icon-rewards icon-rewards-065002"/> {leve.gilReward.toLocaleString()}
							</div>}
						</td>
						<td className="MountRow-Reward notMobile">{triple}</td>
					</tr>;
				});
				let title;
				if (this.state.groupBy === 'Level') {
					title = expansion.name;
				} else if (this.state.groupBy === 'Journal') {
					title = expansion.name;
				} else if (this.state.groupBy === 'NPC') {
					title = expansion.name;
				}
				if (filteredLeves2.length === 0) return;
				let craftLinks;
				if (garlandItems.length > 0) {
					craftLinks = <div>Crafting list of remaining items:&nbsp;
						<a href={'https://ffxivcrafting.com/list/saved/' + xivcraftingItems.join(':')} target="_blank">FFXIV
							Crafting</a>&nbsp;|&nbsp;
						<a href={'http://www.garlandtools.org/db/#group/Group{' + garlandItems.join('|') + '}'}
						   target="_blank">Garland Data</a>&nbsp;|&nbsp;
						<a href={'https://ffxivteamcraft.com/import/' + btoa(teamcraftItems.join(';'))}
						   target="_blank">Teamcraft</a>
					</div>
				}
				expansionRows.push(<tr key={title}>
					<td colSpan={5}>
						<h2>{title} ({hasCount} / {totalCount})</h2>
						{allowancesRequired > 0 ? <div>{totalCount-hasCount} remaining, and {allowancesRequired} allowances ({(allowancesRequired/6).toFixed(1)} days) required.</div> : ''}
						{craftLinks}
					</td>
				</tr>);
				filteredLeves2.forEach(row => {
					expansionRows.push(row)
				});
				/*return <div>
                    <h2>{title}</h2>
                    <table className="MountTable">
                        <tbody>
                        {filteredLeves2}
                        </tbody>
                    </table>
                </div>;*/
			});
			content = <div>

				<div className="FilterBoxContainer">
					<div className="FilterBox">
						<h2>Group By</h2>
						{sorts}
					</div>
					{char.id > 0 ? <div className="FilterBox">
						<h2>Filter</h2>
						{viewFilters}
					</div> : <div></div>}
				</div>
				<div className="FilterBox FilterBox-IconFilters">
					<h2>Type</h2>
					{filters}
				</div>
				<table className="MountTable">
					<tbody>
					{expansionRows}
					</tbody>
				</table>
			</div>;
		}


		let details = <div className="DetailsColumn">
			<p>Select an item to view details here.</p>
			<p>This checklist tracks your progress completing every unique levequest in the game to earn the associated achievements. This is not intended as a leveling guide.</p>
			<p>If this is your first time using this checklist, you should set it to "Journal" mode and open your in-game journal to easily check off leves you have already completed. Any leve listed in your journal, you have already completed. Any leves not listed, you have not yet completed.</p>
			<p>ARR battlecraft and fisher leves (below level 50) are available from the NPC randomly. If you do not see the leves you need, sometimes they are also in the associated starter city. Otherwise, you will need to complete other leves to get a new list of leves.</p>
			<p>Large-scale leves (L) require 10 allowances. All other leves require 1. You regenerate allowances at the rate of 3 per 12 hours.</p>
		</div>;
		if (currentLeveId > 0) {
			let leve = this.state.leves.find(leve => leve.id === currentLeveId);
			if (leve) {
				let craftTurnin;
				if (leve.itemId > 0) {
					craftTurnin = <div>
						<h3>Items</h3>
						<p>{leve.itemName + ' x' + leve.itemCount}</p>
						<p>Turn-ins allowed: {leve.itemRepeats+1}</p>
					</div>;
					//let craftCount = (leve.ItemCount * (leve.CraftLeve.Repeats+1));
					//if (leve.ItemCount > 1) {
					//	craftTurnin = craftTurnin + ' x' + leve.ItemCount;
					//}
				}
				let unobtainable;
				if (leve.isRemoved || leve.isUnlock) {
					unobtainable = <div>
						<h3 className="UnobtainableTag">Unobtainable</h3>
						<p>
							{leve.isRemoved ? 'This leve has been removed from the game and is no longer possible to complete.' : ''}
							{leve.isUnlock ? 'This is one of three leves used to unlock the leve system. Only the one for your starter city may be completed.' : ''}
						</p>
					</div>;
				}
				let originNpc = this.state.npcs.find(npc => npc.id === leve.originNpcId);
				let destinationNpc = this.state.npcs.find(npc => npc.id === leve.destinationNpcId);
				let originPlaceName = originNpc && this.state.placeNames.find(pn => pn.id === originNpc.placeNameId);
				let originPlaceNameRegion = originNpc && this.state.placeNames.find(pn => pn.id === originNpc.placeNameRegionId);
				let destinationPlaceName = destinationNpc && this.state.placeNames.find(pn => pn.id === destinationNpc.placeNameId);
				let destinationPlaceNameRegion = destinationNpc && this.state.placeNames.find(pn => pn.id === destinationNpc.placeNameRegionId);
				let iconNumber = ('000000' + leve.issuerIcon).slice(-6);
				let iconFolder = iconNumber.slice(0, 3) + '000';
				details = <div className="DetailsColumn DetailsColumn-MobileVisible">
					<a className="DetailsColumnClose" href={`/${this.props.lang}/char/${char.id}/leves/${currentTypeSlug}/`}><i className="fontello-left-open" /> Back to leve list</a>
					<img src={`/gamedata/ui_webp/${iconFolder}/${iconNumber}.webp`} style={{width:'100%'}} />
					<h2 dangerouslySetInnerHTML={{__html:leve.name}}/>
					<div className="DetailsRewardsBox">
						<div>Lv. {leve.classJobLevel}</div>
						<div className="Pill Pill-Reward"><i className="icon icon-rewards icon-rewards-065001" /> {leve.expReward.toLocaleString()}</div>
						<div className="Pill Pill-Reward"><i className="icon icon-rewards icon-rewards-065002" /> {leve.gilReward.toLocaleString()}</div>
						<div className="Pill Pill-Reward"><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-${currentTypeSlug}`} /> {currentType.name}</div>
					</div>
					<p dangerouslySetInnerHTML={{__html: leve.description}} />
					<p>{leve.leveClient}</p>
					{unobtainable}
					{craftTurnin}
					<h3>{originNpc === destinationNpc ? 'Origin / Destination' : 'Origin'}</h3>
					<DetailMap npc={originNpc} placeName={originPlaceName} />
					{originNpc === destinationNpc ? '' : <div>
					<h3>Destination</h3>
					<DetailMap npc={destinationNpc} placeName={destinationPlaceName} />
					</div>}
				</div>;
			}
		}

		return <div className="PageContent-WithDetails">
			{/*this.state.loading &&
				<div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
			*/}
			{ /*<div className="FilterBox">
				<h2>Expansion</h2>
				<a href={`${urlBase}leves/${currentType}/2`} className={currentExpansion===2?'current':''}>2.x ARR</a>
				<a href={`${urlBase}leves/${currentType}/3`} className={currentExpansion===3?'current':''}>3.x HW</a>
				<a href={`${urlBase}leves/${currentType}/4`} className={currentExpansion===4?'current':''}>4.x SB</a>
				<a href={`${urlBase}leves/${currentType}/5`} className={currentExpansion===5?'current':''}>5.x ShB</a>
			</div>*/ }

			{content}

			{details}
		</div>;
	}
}
