import React, {Component} from 'react';
import '../../gamedata/spritesheet/fish.css';

let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let fishes = require('../../gamedata/json/en/fish.json');
//let spearfishes = require('../../gamedata/json/en/spearfish.json');
//let raids = require('../../data/raids');
//let expansions = require('../../data/expansions');
let store = require('../../stores/gameData').default;


function makeState() {
	return {
		char: charStore.getData(),
		fish: store.get('fish')
	};
}
export default class CharFishRoute extends Component {
	state = Object.assign({
		filter: 'All'
	}, makeState());
	constructor(props) {
		super(props);
		this.toggleItem = this.toggleItem.bind(this);
		this.onFilterClick = this.onFilterClick.bind(this);
		this.selectAll = this.selectAll.bind(this);
		charStore.on(this.onChange, this);
		store.on(this.onChange, this);
	}

	onChange() {
		this.setState(makeState());
	}
	componentWillUnmount() {
		charStore.off(this.onChange, this);
		userStore.off(this.onChange, this);
		store.off(this.onChange, this);
	}

	onFilterClick(e) {
		this.setState({filter: e.target.innerText});
	}

	toggleItem(id, toggleTo) {
		if (toggleTo) {
			charStore.markItem('fish', id);
		} else {
			charStore.unmarkItem('fish', id);
		}
	}
	selectAll(page, fishes) {
		let filteredFish = fishes.filter(fish => {
			return fish.isInLog === true;
			//return charStore.hasItem('triadCard', card.Id);
		}).sort((a, b) => {
			if (a.sort < b.sort) {return -1;}
			if (a.sort > b.sort) {return 1;}
			if (a.id < b.id) {return -1;}
			if (a.id > b.id) {return 1;}
			return 0;
		});
		//console.log(page, page*25, (page+1)*25);
		let ids = [];
		for (let i = page*100; i < (page+1)*100; i++) {
			if (filteredFish[i]) ids.push(filteredFish[i].id);
			//console.log(i, filteredFish[i]);
		}
		charStore.markItems('fish', ids);
	}
	render() {
		let char = this.props.char, lang = this.props.lang, fishload = this.state.char.char.fishload, urlBase = this.props.urlBase;
		//let achievements, legacy, addTimeHuman, updateTimeHuman;
		/*let dataCenter = 'unknown';
		let world = 'unknown';
		dataCenters.forEach(dc => {
			dc.Worlds.forEach(w => {
				if (w.Id === char.worldId) {
					world = w.Name;
					dataCenter = dc.Name;
				}
			});
		});*/



		let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

		let hasCount = 0, totalCount = 0;
		let carbunclePlushyArray = [];

		let makePages = (fishes) => {
			let fishElements = fishes.filter(fish => {
				return fish.isInLog;
				//return charStore.hasItem('triadCard', card.Id);
			}).sort((a, b) => {
				if (a.sort < b.sort) {return -1;}
				if (a.sort > b.sort) {return 1;}
				if (a.id < b.id) {return -1;}
				if (a.id > b.id) {return 1;}
				return 0;
			}).map(fish => {
				let has = char.id > 0 && charStore.hasItem('fish', fish.id);
				if (has) hasCount++;
				totalCount++;
				let className = 'FishIcon ' + (has ? 'IconList-Has' : '');
				//className += fish.IsHidden ? ' FishIcon-Red' : ' FishIcon-Blue';
				let check;
				if (fishload && fishload.includes(fish.id)) {
					check = <i className="IconDecoration fontello-spin1 animate-spin" />
				} else if (has) {
					check = <i className="IconDecoration fontello-ok" />
				} else {
					check = <i className="IconDecoration fontello-help" />
				}
				if (has) carbunclePlushyArray.push(fish.itemId);
				if (isCharMine) {
					return <a href={`${urlBase}fish/${fish.id}/`} key={fish.id} className={className}
							  onClick={(e) => {
								  e.preventDefault();
								  this.toggleItem(fish.id, !has)
							  }} title={fish.name}>
						<span className={`icon icon-fish icon-fish-${fish.id}`}/>
						{check}
					</a>;
				} else {
					return <span key={fish.id} className={className} title={fish.name}>
					<span className={`icon icon-fish icon-fish-${fish.id}`}/>{check}
				</span>;
				}
				/*return <a href={`${urlBase}ttcards/${card.Id}/`} key={card.Id} className="GroupedIcon"><span
                    className={`icon icon-cards icon-cards-${card.Id}`} title={card.Name}/></a>;*/
			});
			let fishPages = [];
			while(fishElements.length) {
				fishPages.push(fishElements.splice(0, 100));
			}
			fishPages = fishPages.map((fish, i) => {
				let selectAll;
				if (isCharMine) selectAll = <button className="LinkButton" onClick={(e) => {
					this.selectAll(i, fishes);
				}}>Select All</button>;
				return <div key={i} className="IconList OneHundredList"><h3>{selectAll}{i+1}</h3>{fish}</div>;
			});
			return fishPages;
		};

		if (this.state.fish.length < 1) {
			return <div>Loading</div>;
		}
		let fishPages = makePages(this.state.fish.filter(fish => !fish.spearfish));
		let spearfishPages = makePages(this.state.fish.filter(fish => fish.spearfish));



		let percent = hasCount * 100 / totalCount;
		let progressClassName = 'FilterProgressInner';
		if (percent === 100) {
			progressClassName += ' FilterProgressInner-Complete';
		}

		let carbunclePlushieString = `[${carbunclePlushyArray.join(',')}]`;

		/*let fishItems = fishes.filter(fish => {
			return fish.IsInLog === true;
		}).map(fish => {
			return <a href={`${urlBase}fish/${fish.Id}/`} key={fish.Id} className="GroupedIcon">
				<span className={`icon icon-fish icon-fish-${fish.Id}`} />
			</a>;
		});*/

		/*let expansionRows = expansions.map(function(expansion) {
			let raidRows = raids.filter(function(raid) {
				return raid.expansion === expansion.id;
			}).map(function(raid) {
				let achievement = achievements.find(a => a.Id === raid.achievementId);
				let released = new Date(raid.released);
				let releasedHuman = released.toLocaleDateString('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric'
				});
				let charAchievement = char.raids.find(a => a.achievementId === raid.achievementId);
				let isCleared, clearedDate, clearedDateHuman;
				if (charAchievement) {
					isCleared = true;
					clearedDate = new Date(charAchievement.createdAt);
					clearedDateHuman = clearedDate.toLocaleDateString('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric'
					});
				}
				//let isCleared = char.achievements.indexOf(raid.achievementId) > -1;
				return <tr className="MountRow MountRow-Obtained" key={raid.achievementId}>
					<td className="MountRow-Icon"><span className={`icon icon-achievement icon-achievement-${achievement.Icon}`} /></td>
					<td className="MountRow-Text">
						<div className="MountRow-Name">{raid.name}</div>
						Added: {releasedHuman}<br />
						Cleared: {isCleared ? clearedDateHuman : 'No'}
					</td>
					</tr>
			});
			return <div key={expansion.id}>
				<p>&nbsp;</p>
				<h2>{expansion.name}</h2>
				<table className="MountTable">
					<tbody>
						{raidRows}
					</tbody>
				</table>
			</div>;
		});*/

		return <div>
			{ /*<div className="FilterBoxContainer">
				<div className="FilterBox">
					<h2>Type</h2>
					<a href={`${urlBase}fish/guide/`} className="current">Guide</a>
					<a href={`${urlBase}fish/log/`}>Log</a>
				</div>
				<div className="FilterBox">
					<h2>Filter</h2>
					&nbsp;
				</div>
			</div>*/ }
			{char.id > 0 ? <div className="CompletionSummary">
				<h3>Fish</h3>
				<p className="CompletionSummary-Percent">{Math.floor(percent)}%</p>
				<p className="CompletionSummary-Numbers">{hasCount} / {totalCount}</p>
				<p className="CompletionSummary-Remaining">({totalCount-hasCount} remaining)</p>
				<span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
			</div> : <div className="CompletionSummary">
				<h3>Fish</h3>
			</div>}
			{isCharMine ? <p>This site does not yet have a complete fish tracker. Instead, you may check off the fish you have on this page, and it will give you a code to import into the Carbuncle Plushy big fish tracker.</p> : '' }
			{ /*<div className="InfoBox InfoBox-Error">
				<img src={require('../../images/error.png')} width="190" height="125" />
				<p>
					<strong>Under construction!</strong><br />
					Using this checklist is not currently recommended.
				</p>
			</div>*/ }
			<h2>Fish</h2>
			{fishPages}
			<h2>Spearfish</h2>
			{spearfishPages}
			{isCharMine ? <div>
				<h2><a href="https://ff14fish.carbuncleplushy.com/" target="_blank">Carbuncle Plushy</a> Tracker Code</h2>
				<p>Click the "Import Fish Checklist" button and paste in this code.</p>
				<input type="text" value={carbunclePlushieString} readOnly />
			</div> : '' }
		</div>;
	}
}
