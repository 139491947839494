import React, {Component} from 'react';
//let mounts = require('../../gamedata/json/en/mounts.json');
let store = require('../../stores/gameData').default;
export default class MountPage extends Component {
    state = {
        mounts: store.get('mounts'),
        sourceTypes: store.get('sourceTypes'),
        rarity: store.getRarity('mounts')
    };
    constructor(props) {
        super(props);
        store.on(this.onChange, this);
    }
    componentWillUnmount() {
        store.off(this.onChange, this);
    }
    onChange() {
        this.setState({
            mounts: store.get('mounts'),
            sourceTypes: store.get('sourceTypes'),
            rarity: store.getRarity('mounts')
        });
    }
    render() {
        if (this.state.mounts.length < 1) {
            return <div>Loading</div>;
        }
        let mount = this.state.mounts.find(m => m.id === this.props.id);
        let char = this.props.char;
        if (!mount) {
            return <div>No mount selected.</div>;
        }
        let charMount = char.id > 0 && char.mounts.find(cm => cm.id === mount.id);
        let has;
        if (charMount) {
            let hasDate = new Date(charMount.date*1000).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
            has = <p><i className="fontello-ok MountHasCheck" /> This mount was confirmed on Lodestone on {hasDate}.</p>;
        } else if (char.id > 0) {
            has = <p>You do not have this mount.</p>;
        }
        let r = this.state.rarity.find(_r => _r.id === mount.id);
        let percent = (r ? r.count : 0) * 100 / store.gameData['mounts'].rarityCharCount;
        if (percent < 0.1) {
            percent = Math.round(percent * 1000) / 1000;
        } else if (percent < 1) {
            percent = Math.round(percent * 100) / 100;
        } else {
            percent = Math.round(percent * 10) / 10;
        }
        let percentText;
        if (r) {
            percentText = `${percent}% of characters own this`;
        }
        let rarityText = '';
        if (r && r.points >= 50) {
            rarityText = 'Legendary!';
        } else if (r && r.points >= 30) {
            rarityText = 'Super Rare';
        } else if (r && r.points >= 20) {
            rarityText = 'Rare';
        } else if (r && r.points >= 10) {
            rarityText = 'Common';
        } else if (r) {
            rarityText = 'Very Common';
        }
        /*
        text.gsub('<SoftHyphen/>', "\u00AD")
  .gsub(/<Switch.*?><Case\(1\)>(.*?)<\/Case>.*?<\/Switch>/, '\1')
  .gsub(/<If.*?>(.*?)<Else\/>.*?<\/If>/, '\1')
  .gsub(/<\/?Emphasis>/, '*')
  .gsub(/<UIForeground>.*?<\/UIGlow>(.*?)<UIGlow>.*?<\/UIForeground>/, '**\1**')
  .gsub('<Indent/>', ' ')
  .gsub(/\<.*?\>/, '')
  .gsub("\r", "\n")
  .gsub("\n\n", ' ')
         */
        return <div>
            <div className={`gacha gacha${r ? r.points : 0}`}>
                <span className={`icon icon-mount icon-mount-${mount.id}`} />
                <div className="details">
                    <h2>{mount.name}</h2>
                    <p className="gachaText">{rarityText}</p>
                    <p className="percentText">{percentText}</p>
                </div>
            </div>
            {has}
            <p dangerouslySetInnerHTML={{__html:mount.howTo}} />
            <hr />
            <p dangerouslySetInnerHTML={{__html:mount.description}} />
            <p dangerouslySetInnerHTML={{__html:mount.descriptionEnhanced}} />
            <div className="MountImageTooltip">
                <img src={`/assets/mountnotebook/${mount.id}.png`} alt="" />
                <div>
                <p dangerouslySetInnerHTML={{__html:mount.tooltip}} />
                </div>
            </div>
        </div>;
    }
}