import React, {PureComponent, Component} from 'react';
import auth0 from'../auth0';

import '../gamedata/spritesheet/fashion.css';
import '../gamedata/spritesheet/mount.css';
import '../gamedata/spritesheet/minion.css';
import '../gamedata/spritesheet/ttcards.css';
import '../gamedata/spritesheet/emote.css';
import '../gamedata/spritesheet/barding.css';
import '../gamedata/spritesheet/hair.css';
import '../gamedata/spritesheet/bozja.css';

let store = require('../stores/gameData').default;
function makeState() {
	return {
		data: store.gameData
	};
}

class AdminRow extends PureComponent {
	state = {
		saving: false
	};
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
	}
	onSubmit(e) {
		e.preventDefault();
		this.setState({saving:true});
		let form = e.target;
		let formData = new FormData(form);
		auth0.fetch(`/api/user/admin/${this.props.type}/${this.props.id}`, {method: 'PUT', body: formData})
			.then(res => res.json())
			.then(result => {
				this.setState({saving:false});
				if (result && result.status === 'ok') {
					store.syncAll();
				}
		}).catch(err => {
			this.setState({saving:false});
			console.error(err);
		});

		//formData.entries().forEach(entry => {console.log(entry)});
		//let queryString = new URLSearchParams(formData).toString();
		//console.log(queryString);
	}

	render() {

		return <form method="GET" onSubmit={this.onSubmit}>
			<table className="" key={this.props.id}>
				<tbody>
				<tr className="">
					<td className="MountRow-Icon"><span className={this.props.icon} title={this.props.name}/></td>
					<td className="MountRow-Name">{this.props.name}</td>
					<td rowSpan="2">
						<textarea defaultValue={this.props.howToEn} name="howTo_en" />
						<textarea defaultValue={this.props.howToFr} name="howTo_fr" />
						<textarea defaultValue={this.props.howToDe} name="howTo_de" />
						<textarea defaultValue={this.props.howToJa} name="howTo_ja" />
					</td>
					<td rowSpan="2">
						<input type="submit" value="Save" disabled={this.state.saving} />
					</td>
				</tr>
				<tr className="MountRow">
					<td><input type="checkbox" defaultChecked={this.props.obtainable} name="obtainable" /></td>
					<td><select defaultValue={this.props.sourceTypeId} name="sourceTypeId">{this.props.sources}</select></td>
				</tr>
				</tbody>
			</table>
		</form>;
	}
}
class AdminRoute extends Component {
	state = Object.assign({
		filter: 'bardings'
	}, makeState());

	constructor(props) {
		super(props);
		this.onFilterClick = this.onFilterClick.bind(this);
		store.syncAll();
		store.on(this.onChange, this);
	}

	onChange() {
		this.setState(makeState());
	}

	onFilterClick(e) {
		this.setState({filter: e.target.innerText});
	}


	render() {
		let lang = this.props.lang;
		let type = this.state.filter;
		let user = auth0.getUser();

		if (!user) {
			return <div>Login required</div>;
		} else if (user['https://lalachievements.com/api/roles'].indexOf('admin') === -1) {
			return <div>No</div>;
		}

		let viewFilters = ['fashions', 'hairs', 'mounts', 'minions', 'ttcards', 'orchestrions', 'bardings', 'emotes', 'bozjaNotes'].map(type => {
			return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
				{type}
			</button>;
		});

		let data = [];
		if (this.state.data[this.state.filter] && this.state.data[this.state.filter].en) {
			data = this.state.data[this.state.filter].en;
		}

		let sources;
		if (this.state.data.sourceTypes.en) {
			sources = this.state.data.sourceTypes.en.map(source => {
				return <option value={source.id} key={source.id}>{source.name}</option>;
			});
		}

		let rows = data.sort((a, b) => {
			if (a.sort > b.sort) return 1;
			if (a.sort < b.sort) return -1;
			if (a.id > b.id) return 1;
			if (a.id < b.id) return -1;
			return 0;
		}).map(row => {
			let icon;

			if (type === 'fashions') icon = `icon icon-fashion icon-fashion-${row.id}`;
			else if (type === 'hairs') icon = `icon icon-hair icon-hair-${row.id}_lalaf`;
			else if (type === 'mounts') icon = `icon icon-mount icon-mount-${row.id}`;
			else if (type === 'minions') icon = `icon icon-minion icon-minion-${row.id}`;
			else if (type === 'bardings') icon = `icon icon-barding icon-barding-${row.id}h`;
			else if (type === 'emotes') icon = `icon icon-emote icon-emote-${row.id}`;
			else if (type === 'ttcards') icon = `icon icon-ttcards icon-ttcards-${row.id}`;
			else if (type === 'bozjaNotes') icon = `icon icon-bozja icon-bozja-${row.number}`;
			else if (type === 'orchestrions') icon = ``;


			if (this.state.data[this.state.filter].en && this.state.data[this.state.filter].fr && this.state.data[this.state.filter].de && this.state.data[this.state.filter].ja) {
				let howToEn = this.state.data[this.state.filter].en ? this.state.data[this.state.filter].en.find(r => r.id === row.id).howTo : '';
				let howToFr = this.state.data[this.state.filter].fr ? this.state.data[this.state.filter].fr.find(r => r.id === row.id).howTo : '';
				let howToDe = this.state.data[this.state.filter].de ? this.state.data[this.state.filter].de.find(r => r.id === row.id).howTo : '';
				let howToJa = this.state.data[this.state.filter].ja ? this.state.data[this.state.filter].ja.find(r => r.id === row.id).howTo : '';
				return <AdminRow key={type+row.id} type={type} id={row.id} name={row.name} obtainable={row.obtainable} sourceTypeId={row.sourceTypeId} sources={sources} icon={icon} howToEn={howToEn} howToFr={howToFr} howToDe={howToDe} howToJa={howToJa} />
			} else {
				return <div>Loading</div>;
			}


			/*return <form onSubmit={this.onSubmit}>
				<table className="" key={row.id}>
				<tbody>
				<tr className="">
					<td className="MountRow-Icon"><span className={icon} title={row.name}/></td>
					<td className="MountRow-Name">{row.name}</td>
					<td rowSpan="2">
						<textarea defaultValue={howToEn} />
						<textarea defaultValue={howToFr} />
						<textarea defaultValue={howToDe} />
						<textarea defaultValue={howToJa} />
					</td>
					<td rowSpan="2">
						<input type="submit" value="Save" />
					</td>
				</tr>
				<tr className="MountRow">
					<td><input type="checkbox" defaultChecked={row.obtainable} /></td>
					<td><select defaultValue={row.sourceTypeId}>{sources}</select></td>
				</tr>
				</tbody>
			</table>
			</form>;*/
		});

		return (
			<div className="Admin">
				<div className="FilterBoxContainer">
					<div>&nbsp;</div>
					<div className="FilterBox">
						{viewFilters}
					</div>
				</div>

					{rows}


			</div>
		);
	}
}

export default AdminRoute;