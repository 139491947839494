
import React from 'react';
import ReactDOM from 'react-dom';
//import ApolloClient from 'apollo-boost';
//import {ApolloProvider} from '@apollo/react-hooks';
//import { ApolloClient } from 'apollo-client';
//import { InMemoryCache } from 'apollo-cache-inmemory';
//import { HttpLink } from 'apollo-link-http';
//import { WebSocketLink } from 'apollo-link-ws';
//import { getMainDefinition } from 'apollo-utilities';
//import { onError } from 'apollo-link-error';
//import { ApolloLink, split } from 'apollo-link';
//import gql from 'graphql-tag';

import 'sanitize.css';
import './fontello/css/fontello.css';
import './fontello/css/animation.css';
import './index.scss';
//import App from './App';

//ReactDOM.render(<App />, document.getElementById('root'));

import Kingfish from 'kingfish';
import App from './App';
//import auth from './auth0';
//require('./ws');
import auth0 from './auth0';

/*if (typeof window.ga === 'function') {
	window.ga('create', 'UA-139967520-1', 'auto');
}*/

const rootElement = document.getElementById('root');

let store = require('./stores/gameData').default;

/*
// Create an http link:
const httpLink = new HttpLink({
	uri: 'https://graphql.lalachievements.com/v1/graphql'
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
	uri: 'wss://graphql.lalachievements.com/v1/graphql',
	options: {
		reconnect: true
	}
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink,
);

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					),
				);
			if (networkError) console.log(`[Network error]:`, networkError.message);
		}),
		link
	]),
	cache: new InMemoryCache({
		addTypename: false
	})
});
*/

//const client = new ApolloClient({
//	uri: 'https://graphql.lalachievements.com/v1/graphql',
	//connectToDevTools: true
//});
/*client
	.query({
		query: gql`
query{
  clans{
    id
    race_en
    tribe_en
    gender_en
  }
}
    `
	})
	.then(result => console.log(result));*/

/*const onRedirectCallback = appState => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};*/

let langRoutes = {
	/*'/': class {
		mount() {
			//window.location = '/en/';
			window.history.pushState({}, document.title, '/en/');
			dispatchEvent(new PopStateEvent('popstate', { state: {}}));
		}
	},*/
	'/login': class {
		mount() {
			auth0.init().then(() => {
				//window.location = '/en/';
				window.history.pushState({}, document.title, '/en/');
				dispatchEvent(new PopStateEvent('popstate', { state: {}}));
			});
		}
	},
	'/?/*': class {
		name = 'App';
		mount() {
			const url = this.params[1].split('/');
			if (url[0].length < 1) url.shift();
			let lang = this.params[0];
			if (['en', 'ja', 'de', 'fr'].indexOf(lang) < 0) {
				url.unshift(lang);
				lang = 'en';
			}
			store.setLang(lang);
			//this.context = React.createContext(new Store());
			/*auth.init()
				.then(() => {return auth.initialized && window.location.search ? auth.auth0.handleRedirectCallback() : undefined})
				.then(() => {return auth.initialized ? auth.auth0.getUser() : undefined})
				.then((user) => {
				console.log(user);*/
			//console.log(url);
			auth0.init().then(() => {
				if (this.element.hasChildNodes()) {
					ReactDOM.hydrate(<App lang={lang} url={url}/>, this.element);
					//ReactDOM.hydrate(<ApolloProvider client={client}><App lang={lang} url={url}/></ApolloProvider>, this.element);
				} else {
					ReactDOM.render(<App lang={lang} url={url}/>, this.element);
					//ReactDOM.render(<ApolloProvider client={client}><App lang={lang} url={url}/></ApolloProvider>, this.element);
				}
				/*if (typeof window.ga === 'function') {
					window.ga('set', 'page', '/' + this.params[0] + '/' + this.params[1]);
					window.ga('send', 'pageview');
				}*/
				//window.dataLayer.push({event: 'virtualPageView'});
			}).catch(function(err) {
				console.error(err);
				window.reportError(err.message + ' [mount]');
			});
			//});

		}
		setParams(newParams) {
			this.params = newParams;
			const url = this.params[1].split('/');
			if (url[0].length < 1) url.shift();
			let lang = this.params[0];
			if (['en', 'ja', 'de', 'fr'].indexOf(lang) < 0) {
				url.unshift(lang);
				lang = 'en';
			}
			store.setLang(lang);

			ReactDOM.render(<App lang={lang} url={url} />, this.element);
			//ReactDOM.render(<ApolloProvider client={client}><App lang={lang} url={url} /></ApolloProvider>, this.element);

			// window.scrollTo(0, 0);

			/*if (typeof window.ga === 'function') {
				window.ga('set', 'page', '/' + this.params[0] + '/' + this.params[1]);
				window.ga('send', 'pageview');
			}*/
			//gtab('event', 'screen_view');
		}
	}
};
let router = new Kingfish(langRoutes, rootElement);

router.start();

//require('./ga');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
