import React, {Component} from 'react';
import Leaflet from 'leaflet';
import { MapContainer, ImageOverlay, TileLayer, Marker, Popup, Tooltip, LayersControl, LayerGroup } from 'react-leaflet';
import '../../gamedata/spritesheet/bozja.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;
let store = require('../../stores/gameData').default;

//let emotes = require('../../gamedata/json/en/emotes.json');

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        notes: store.get('bozjaNotes'),
        fates: store.get('bozjaFates')
    };
}
export default class CharBozjaNotesRoute extends Component {
    state = Object.assign({
        selectedMap: 665,
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('emote').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('emote').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }
    onMapClick(id) {
        this.setState({selectedMap: id});
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('bozjaNote', id);
        } else {
            charStore.unmarkItem('bozjaNote', id);
        }
    }
    markerClick(id) {
        let url = `${this.props.urlBase}bozjanotes/`;
        if (id) url += `${id}/`;
        window.history.pushState({}, document.title, url);
        dispatchEvent(new PopStateEvent('popstate', { state: {}}));
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        let currentId = this.props.url[0]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        let mapFilters = [606, 665].map(mapId => {
            return <button onClick={() => this.onMapClick(mapId)} className={this.state.selectedMap===mapId?'current':''} key={mapId}>
                {mapId === 606 ? 'Bozjan Southern Front' : ''}
                {mapId === 665 ? 'Zadnor' : ''}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            emotes.filter(emote => {
                if (category.Id === 1) return true;
                return emote.emoteCategory === category;
            }).forEach(emote => {
                let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}emotes/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/

        let mapIconsMissing = [];
        let mapIconsHas = [];
        let mapIconsNoDrop = [];
        this.state.fates.filter(fate => fate.mapId === this.state.selectedMap).map(fate => {
            let hasAll = true, clickId;
            let notesArr = this.state.notes.filter(note => note.bozjaFates && note.bozjaFates.indexOf(fate.id) > -1);
            let notes = notesArr.map(note => {
                let has = char.id > 0 && charStore.hasItem('bozjaNote', note.id);
                if (!has) hasAll = false;
                if (!has) clickId = note.number;
                return <p key={note.id}>{has ? <i className="fontello-ok" /> : ''} {note.name}</p>;
            });
            if (!clickId && notesArr.length > 0) clickId = notesArr[0].number;

            let spawns = [];
            let hasAllChain = true;
            let findSpawnedByMap = (fateId) => {
                let fate = this.state.fates.find(f => f.spawnedById === fateId);
                if (!fate) return;
                spawns.push(fate.name);
                let note = this.state.notes.find(note => note.bozjaFates && note.bozjaFates.indexOf(fate.id) > -1);
                if (note) {
                    let has = char.id > 0 && charStore.hasItem('bozjaNote', note.id);
                    if (!has) hasAllChain = false;
                }
                findSpawnedByMap(fate.id);
            };
            findSpawnedByMap(fate.id);

            let spawnedBy = [];
            let findSpawnedBy = (fateId) => {
                let fate = this.state.fates.find(f => f.id === fateId);
                if (!fate) return;
                spawnedBy.push(fate.name);
                if (fate.spawnedBy) {
                    spawnedBy.push(fate.spawnedBy);
                } else if (fate.spawnedById) {
                    findSpawnedBy(fate.spawnedById);
                }
            };
            findSpawnedBy(fate.id);
            spawnedBy.shift();

            let fateName = fate.name;
            if (fate.fateType === 1) fateName = fate.name;
            else if (fate.fateType === 2) fateName = 'CE - ' + fate.name;
            else if (fate.fateType === 3) fateName = 'Duel - ' + fate.name;
            let mapName;
            if (fate.mapId === 606) mapName = 'Bozjan Southern Front';
            else if (fate.mapId === 665) mapName = 'Zadnor';
            let iconNumber = ('000000' + fate.icon).slice(-6);
            let iconFolder = iconNumber.slice(0, 3) + '000';
            let className = 'mapIconClickable';
            if (hasAll) className = className + ' mapIconHas';
            let icon = new Leaflet.Icon({
                iconUrl: `/gamedata/ui_webp/${iconFolder}/${iconNumber}.webp`,
                iconSize: [40, 40],
                iconAnchor: [20, 40],
                tooltipAnchor: [20, -20],
                className: className,
            });

            let z = 0;
            if (notes.length > 0 && hasAll) {
                z = 1;
            } else if (notes.length > 0) {
                z = 100 + fate.fateType;
            } else {
                z = 0;
            }

            let marker = <Marker position={[840-fate.y*2, fate.x*2]} icon={icon} eventHandlers={{click: () => {this.markerClick(clickId)}}} key={fate.id} zIndexOffset={z}>
                <Tooltip>
                    <p><strong>{fateName}</strong></p>
                    {notes}
                    {spawns.length > 0 ? <p>Spawns: <span dangerouslySetInnerHTML={{__html:spawns.join(' &gt; ')}} /></p> : ''}
                    {spawnedBy.length > 0 ? <p>Spawned by: <span dangerouslySetInnerHTML={{__html:spawnedBy.reverse().join(' &gt; ')}} /></p> : ''}
                    <p>{mapName} {fate.x/10}, {fate.y/10}</p>
                </Tooltip>
            </Marker>;
            if (notes.length > 0 && !hasAll) {
                mapIconsMissing.push(marker);
            } else if (!hasAllChain) {
                mapIconsMissing.push(marker);
            } else if (notes.length > 0) {
                mapIconsHas.push(marker);
            } else {
                mapIconsNoDrop.push(marker);
            }
            /*if ((notes.length > 0 && hasAll) && hasAllChain) {
                mapIconsHas.push(marker);
            } else if (notes.length > 0) {
                mapIconsMissing.push(marker);
            } else {
                mapIconsNoDrop.push(marker);
            }*/
        });

        let hasCount = 0, totalCount = 0;
        let rows = this.state.notes.filter(bozjaNote => {
            return true;
            //if (currentCategory.Id === 1) return true;
            //return emote.emoteCategory === currentCategory;
        }).filter(bozjaNote => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('bozjaNote', bozjaNote.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.number < b.number) {return -1;}
            if (a.number > b.number) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(bozjaNote => {
            let url = `${urlBase}bozjanotes/${bozjaNote.number}/`;
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('bozjaNote', bozjaNote.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(bozjaNote.id, !has)}>
                    <i className={this.state.char.char.bozjaNoteload && this.state.char.char.bozjaNoteload.includes(bozjaNote.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let icon;
            if (!bozjaNote.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            let stars = (new Array(bozjaNote.stars*1)).fill('<i class="fontello-star" />').join('');

            let howTo = [];
            if (bozjaNote.howTo.length > 1) howTo.push(bozjaNote.howTo);

            if (bozjaNote.bozjaFates && bozjaNote.bozjaFates.length > 0) {
                bozjaNote.bozjaFates.map(fateId => {
                    let fate = this.state.fates.find(f => f.id === fateId);
                    if (!fate) return;
                    let fateName = fate.name;
                    if (fate.fateType === 1) fateName = fate.name;
                    else if (fate.fateType === 2) fateName = 'CE - ' + fate.name;
                    else if (fate.fateType === 3) fateName = 'Duel - ' + fate.name;
                    howTo.push(fateName);

                });
            }
                    /*if (bozjaNote.bozjaFates && bozjaNote.bozjaFates.length > 0) {
                        bozjaNote.bozjaFates.map(fateId => {
                            let fate = this.state.fates.find(f => f.id === fateId);
                            if (!fate) return;
                            let fateName = fate.name;
                            if (fate.fateType === 1) fateName = fate.name;
                            else if (fate.fateType === 2) fateName = 'CE - ' + fate.name;
                            else if (fate.fateType === 3) fateName = 'Duel - ' + fate.name;
                            let mapName;
                            if (fate.mapId === 606) mapName = 'Bozjan Southern Front';
                            else if (fate.mapId === 665) mapName = 'Zadnor';
                            let iconNumber = ('000000' + fate.icon).slice(-6);
                            let iconFolder = iconNumber.slice(0, 3) + '000';
                            let icon = new Leaflet.Icon({
                                iconUrl: `/gamedata/ui_webp/${iconFolder}/${iconNumber}.webp`,
                                iconSize: [40, 40],
                                iconAnchor: [20, 40],
                                tooltipAnchor: [20, -20],
                            });
                            mapIcons.push(<Marker position={[840-fate.y*2, fate.x*2]} icon={icon} eventHandlers={{click: this.asdf}} key={fateId} opacity={has ? 0.3 : 1} riseOnHover={true}>
                                <Tooltip>
                                    <strong>{fateName}</strong><br />
                                    {bozjaNote.name}<br />
                                    {mapName} {fate.x/10}, {fate.y/10}
                                </Tooltip>
                            </Marker>);
                        })
                    }*/


            return <tr className={className} key={bozjaNote.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Value MountRow-Icon">
                    {bozjaNote.number}
                </td>
                <td className="MountRow-Icon"><span className={`icon icon-bozja icon-bozja-${bozjaNote.number}`} title={bozjaNote.name}/>{icon}</td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{bozjaNote.name}</a><br />
                    <p dangerouslySetInnerHTML={{__html:howTo.join('<br />')}} />
                </td>
                <td><span className="MountMinionDetails-Tag" dangerouslySetInnerHTML={{__html:stars}} /></td>
            </tr>;
        });
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let bozjaNote = this.state.notes.find(l => l.number === currentId);
            if (bozjaNote) {
                /*let order;
                if (emote.Order < 1000) {
                    order = <div># {emote.Order}</div>
                } else {
                    order = <div>Hidden until obtained</div>
                }*/
                let stars = (new Array(bozjaNote.stars*1)).fill('<i class="fontello-star" />').join('');
                let fates = [];
                if (bozjaNote.bozjaFates && bozjaNote.bozjaFates.length > 0) {
                    fates = bozjaNote.bozjaFates.map(fateId => {
                        let fate = this.state.fates.find(f => f.id === fateId);
                        if (!fate) return;

                        let fateName = fate.name;
                        if (fate.fateType === 1 || fate.fateType === 4) fateName = fate.name;
                        else if (fate.fateType === 2) fateName = 'CE - ' + fate.name;
                        else if (fate.fateType === 3) fateName = 'Duel - ' + fate.name;

                        let spawnedBy = [];
                        let findSpawnedBy = (fateId) => {
                            let fate = this.state.fates.find(f => f.id === fateId);
                            if (!fate) return;
                            spawnedBy.push(fate.name);
                            if (fate.spawnedBy) {
                                spawnedBy.push(fate.spawnedBy);
                            } else if (fate.spawnedById) {
                                findSpawnedBy(fate.spawnedById);
                            }
                        };
                        findSpawnedBy(fate.id);
                        spawnedBy.shift();


                        let mapName;
                        if (fate.mapId === 606) mapName = 'Bozjan Southern Front';
                        else if (fate.mapId === 665) mapName = 'Zadnor';
                        let iconNumber = ('000000' + fate.icon).slice(-6);
                        let iconFolder = iconNumber.slice(0, 3) + '000';
                        let icon = new Leaflet.Icon({
                            iconUrl: `/gamedata/ui_webp/${iconFolder}/${iconNumber}.webp`,
                            iconSize: [40, 40],
                            iconAnchor: [20, 40],
                            tooltipAnchor: [20, -20],
                        });

                        let spawnedByEle;
                        if (spawnedBy.length) {
                            spawnedByEle = <p><strong>Spawned by:</strong> <span dangerouslySetInnerHTML={{__html:spawnedBy.reverse().join(' &gt; ')}} /></p>
                        }

                        return <div key={fateId}>
                            <p>
                                <strong>{fateName}</strong>
                            </p>
                            {spawnedByEle}
                            <MapContainer bounds={new Leaflet.LatLngBounds([10, 10], [420, 420])} center={[420-fate.y, fate.x]} zoom={1} minZoom={0} maxZoom={3} scrollWheelZoom={false} crs={Leaflet.CRS.Simple} className="smallmap">
                                <ImageOverlay
                                    url={`/gamedata/map_webp/${fate.mapId}.webp`}
                                    bounds={new Leaflet.LatLngBounds([10, 10], [420, 420])}
                                />
                                <Marker position={[420-fate.y, fate.x]} icon={icon}>
                                    <Tooltip permanent>
                                        <strong>{fateName}</strong><br />
                                        {mapName}<br />
                                        {fate.x/10}, {fate.y/10}
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>;
                    })
                }
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/bozjanotes/`}><i
                        className="fontello-left-open"/> Back to note list</a>
                    <h2>#{bozjaNote.number} - {bozjaNote.name}</h2>
                    <span className="MountMinionDetails-Tag" dangerouslySetInnerHTML={{__html:stars}} />
                    <h3>Obtained from</h3>
                    <p dangerouslySetInnerHTML={{__html:bozjaNote.howTo}} />
                    {fates}
                    { /*<div className="DetailsRewardsBox">
                        <div>{emote.emoteCategory.Name}</div>
                        <div>{order}</div>
                    </div>
                    <h3>In-game Source</h3>
                    <p>{emote.Description}</p>
                    <h3>Detailed Source</h3>
                    <p>&nbsp;</p>
                    <h3>Sample</h3>
                    <Audio src={`/assets/emote/${emote.Id}.ogg`} />*/ }
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">

            <div>

                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>Field Notes</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(char.bozjaNotePercent)}%</p>
                    <p className="CompletionSummary-Numbers">{char.bozjaNoteCount} / {char.bozjaNotePossible}</p>
                    <p className="CompletionSummary-Remaining">({char.bozjaNotePossible-char.bozjaNoteCount} remaining)</p>
                    <span className={progressClassName} style={{width:char.bozjaNotePercent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                    <h3>Field Notes</h3>
                </div>}
                <div>
                    <div className="FilterBox">
                        <h2>Map</h2>
                        {mapFilters}
                    </div>
                    <MapContainer key={this.state.selectedMap} bounds={new Leaflet.LatLngBounds([20, 20], [840, 840])} center={[430, 430]} zoom={0} minZoom={0} maxZoom={3} scrollWheelZoom={false} crs={Leaflet.CRS.Simple} className="bigmap">
                        <ImageOverlay
                            url={`/gamedata/map_webp/${this.state.selectedMap}.webp`}
                            bounds={new Leaflet.LatLngBounds([20, 20], [840, 840])}
                        />
                        <LayersControl position="topright" collapsed={false}>
                            <LayersControl.Overlay name="No Drops"><LayerGroup>{mapIconsNoDrop}</LayerGroup></LayersControl.Overlay>
                            <LayersControl.Overlay name="Completed"><LayerGroup>{mapIconsHas}</LayerGroup></LayersControl.Overlay>
                            <LayersControl.Overlay checked name="Missing"><LayerGroup>{mapIconsMissing}</LayerGroup></LayersControl.Overlay>
                        </LayersControl>
                    </MapContainer>
                </div>
                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}