import React from "react";
import DetailMap from "./DetailMap";

//let cards = require('../gamedata/json/en/ttcards.json');
//let npcs = require('../gamedata/json/en/ttnpcs.json');

function TriadCardSourcesSmall(props) {
    let card = props.card;
    let ttnpcs = props.ttnpcs;
    let sources = [];
    if (card.howTo && card.sourceTypeId !== 9) {
        sources.push(<div dangerouslySetInnerHTML={{__html:card.howTo}} key="howto" />);
    }
    props.ttnpcs.filter(npc => npc.rewardTtcardIds.indexOf(card.id) > -1).forEach(ttnpc => {
        let npc = props.npcs.find(npc => npc.id === ttnpc.npcId);
        if (!npc || !npc.id) return;
        let placeName = props.placeNames.find(pn => pn.id === npc.placeNameId);
        let url1 = `${props.urlBase}ttnpcs/${ttnpc.id}/`;
        if (props.map) {
            sources.push(<div key={npc.id}>NPC: <a href={url1}>{npc.name}</a><DetailMap npc={npc} placeName={placeName} href={url1} /></div>);
        } else {
            sources.push(<div key={npc.id}>NPC: <a href={url1}>{npc.name}</a> - {placeName ? placeName.name : '???'} ({npc.displayX}, {npc.displayY})</div>);
        }
        //let map = props.map && <DetailMap npc={npc} placeName={placeName} href={url1} />;
        //sources.push(<div key={npc.id}>NPC: <a href={url1}>{npc.name}</a> - {placeName ? placeName.name : '???'} ({npc.displayX}, {npc.displayY}){map}</div>);
        //sources.push(`NPC: <a href="${url1}">${npc.name}</a> - ${placeName ? placeName.name : '???'} (${npc.displayX}, ${npc.displayY})`);
    });
    /*card.npcs.forEach(npcId => {
        let npc = npcs.find(npc => npc.Id === npcId);
        if (!npc || !npc.Id) return;
        let url1 = `/${props.lang}/char/${props.char.id}/ttnpcs/${npc.Id}/`;
        sources.push(`NPC: <a href="${url1}">${npc.Name}</a> - ${npc.Location.PlaceName} (${npc.Location.X}, ${npc.Location.Y})`);
    });*/
    //let sources1 = sources.join('<br />');
    //return <div dangerouslySetInnerHTML={{__html:sources1}} />;
    return <div>{sources}</div>
}
export default TriadCardSourcesSmall;