import React, {Component} from 'react';
import '../../gamedata/spritesheet/hair.css';
let clans = require('../../gamedata/json/en/clans.json');

let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;
let store = require('../../stores/gameData').default;

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        hairs: store.get('hairs')
    };
}
export default class CharHairsRoute extends Component {
    state = Object.assign({
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('hair', id);
        } else {
            charStore.unmarkItem('hair', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        let currentId = this.props.url[0]*1;

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        let clan = clans.find(clan => clan.Id === char.clanId2);
        let iconClan = 'midm';
        if (clan) {
            if (clan.Tribe === 'Midlander') iconClan = 'mid';
            else if (clan.Tribe === 'Highlander') iconClan = 'high';
            else if (clan.Race === 'Elezen') iconClan = 'ele';
            else if (clan.Race === 'Lalafell') iconClan = 'lala';
            else if (clan.Race === 'Miqo\'te') iconClan = 'miqo';
            else if (clan.Race === 'Roegadyn') iconClan = 'roe';
            else if (clan.Race === 'Au Ra') iconClan = 'aura';
            else if (clan.Race === 'Hrothgar') iconClan = 'roe';
            else if (clan.Race === 'Viera') iconClan = 'mid';
            else iconClan = 'hyur';
            iconClan = iconClan + (clan.Gender === 1 ? 'f' : 'm');
        }

        let rows = this.state.hairs.filter(hair => {
            let has = char.id > 0 && charStore.hasItem('hair', hair.id);
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort < b.sort) {return -1;}
            if (a.sort > b.sort) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(hair => {
            let url = `${urlBase}hairstyles/${hair.id}/`;
            let has = char.id > 0 && charStore.hasItem('hair', hair.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(hair.id, !has)}>
                    <i className={this.state.char.char.hairload && this.state.char.char.hairload.includes(hair.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let icon;
            if (hair.sourceTypeId === 7 || hair.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
            else if (!hair.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            return <tr className={className} key={hair.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={`icon icon-hair icon-hair-${hair.id}_${iconClan}`} title={hair.name}/>{icon}</td>
                <td className="MountRow-Text">
                    <p><a href={url} className="MountRow-Name">{hair.name}</a></p>
                    <p dangerouslySetInnerHTML={{__html:hair.howTo}} />
                </td>
            </tr>;
        });
        let progressClassName = 'FilterProgressInner';
        if (char.hairPercent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        if (currentId > 0) {
            let hair = this.state.hairs.find(l => l.id === currentId);
            if (hair) {
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/hairstyles/`}><i
                        className="fontello-left-open"/> Back to hair accessory list</a>
                    <h2>{hair.name}</h2>
                    <p dangerouslySetInnerHTML={{__html:hair.howTo}} />
                    <hr />
                    <span className={`icon icon-hair icon-hair-${hair.id}_midm`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_midf`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_highm`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_highf`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_elem`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_elef`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_lalam`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_lalaf`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_miqom`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_miqof`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_roem`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_roef`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_auram`} title={hair.name}/>
                    <span className={`icon icon-hair icon-hair-${hair.id}_auraf`} title={hair.name}/>
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                {char.id > 0 ? <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}fashions/`}>Fashion Accessories</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.fashionPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.fashionCount} / {char.fashionPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.fashionPossible-char.fashionCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.fashionPercent+'%'}}>&nbsp;</span>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}hairstyles/`}>Hairstyles</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.hairPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.hairCount} / {char.hairPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.hairPossible-char.hairCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.hairPercent+'%'}}>&nbsp;</span>
                    </div>
                </div> : <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}fashions/`}>Fashion Accessories</a></h3>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}hairstyles/`}>Hairstyles</a></h3>
                    </div>
                </div>}
                <p>You can easily find which hairstyles you have unlocked in the retainer customization screen. Make sure you check both genders!</p>
                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}