import React, {Component} from 'react';
//let minions = require('../../gamedata/json/en/minions.json');
let store = require('../../stores/gameData').default;
export default class MountPage extends Component {
    state = {
        minions: store.get('minions'),
        sourceTypes: store.get('sourceTypes'),
        rarity: store.getRarity('minions')
    };
    constructor(props) {
        super(props);
        store.on(this.onChange, this);
    }
    componentWillUnmount() {
        store.off(this.onChange, this);
    }
    onChange() {
        this.setState({
            minions: store.get('minions'),
            sourceTypes: store.get('sourceTypes'),
            rarity: store.getRarity('minions')
        });
    }
    render() {
        if (this.state.minions.length < 1) {
            return <div>Loading</div>;
        }
        let minion = this.state.minions.find(m => m.id === this.props.id);
        let char = this.props.char;
        if (!minion) {
            return <div>No minion selected.</div>;
        }
        let charMinion = char.id > 0 && char.minions.find(cm => cm.id === minion.id);
        let has;
        if (charMinion) {
            let hasDate = new Date(charMinion.date*1000).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
            has = <p><i className="fontello-ok MountHasCheck" /> This minion was confirmed on Lodestone on {hasDate}.</p>;
        } else if (char.id > 0) {
            has = <p>You do not have this minion.</p>;
        }
        let r = this.state.rarity.find(_r => _r.id === minion.id);
        let percent = (r ? r.count : 0) * 100 / store.gameData['minions'].rarityCharCount;
        if (percent < 0.1) {
            percent = Math.round(percent * 1000) / 1000;
        } else if (percent < 1) {
            percent = Math.round(percent * 100) / 100;
        } else {
            percent = Math.round(percent * 10) / 10;
        }
        let percentText;
        if (r) {
            percentText = `${percent}% of characters own this`;
        }
        let rarityText = '';
        if (r && r.points >= 50) {
            rarityText = 'Legendary!';
        } else if (r && r.points >= 30) {
            rarityText = 'Super Rare';
        } else if (r && r.points >= 20) {
            rarityText = 'Rare';
        } else if (r && r.points >= 10) {
            rarityText = 'Common';
        } else if (r) {
            rarityText = 'Very Common';
        }

        return <div>
            <div className={`gacha gacha${r ? r.points : 0}`}>
                <span className={`icon icon-minion icon-minion-${minion.id}`} />
                <div className="details">
                    <h2>{minion.name}</h2>
                    <p className="gachaText">{rarityText}</p>
                    <p className="percentText">{percentText}</p>
                </div>
            </div>

            {has}
            <p dangerouslySetInnerHTML={{__html:minion.howTo}} />
            <hr />
            <p dangerouslySetInnerHTML={{__html:minion.description}} />
            <p dangerouslySetInnerHTML={{__html:minion.descriptionEnhanced}} />
            <div className="MountImageTooltip">
                <img src={`/assets/minionnotebook/${minion.id}.png`} alt="" />
                <div>
                <p dangerouslySetInnerHTML={{__html:minion.tooltip}} />
                </div>
            </div>
        </div>;
    }
}