//import {h, Component} from 'preact';
import React, {Component, Suspense} from 'react';
//import auth from './auth0';
//import { withAuth } from '@okta/okta-react';
import auth0 from './auth0';
import userStore from './stores/user';
import recentChars from './stores/recentChars';
import logo from './images/logotext.png';
import loadinggif from './images/tatarus.gif';
let dataCenters = require('./gamedata/json/dataCenters');

let store = require('./stores/gameData').default;

class App extends Component {
	state = {
		burgerVisible: false,
		//mainBurgerVisible: false,
		menuVisible: false,
		user: false,
		favoriteChars: [],
		userChars: [],
		isLoading: true
	};
	constructor(props) {
		super(props);
		this.toggleBurger = this.toggleBurger.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.loginClick = this.loginClick.bind(this);
		this.logoutClick = this.logoutClick.bind(this);
		//this.onScroll = this.onScroll.bind(this);
		//this.scrollTop = document.body.scrollTop;
		userStore.on(this.onChange, this);
		store.on(this.onChange, this);
	}
	onChange() {
		let isLoading = false, isLoadingChar = false;
		Object.keys(store.gameData).forEach(type => {
			if (store.gameData[type].loading) isLoading = true;
		});
		Object.keys(store.chars).forEach(charId => {
			if (store.chars[charId].loading) isLoadingChar = true;
		});
		this.setState({
			isLoading: isLoading,
			isLoadingChar: isLoadingChar,
			favoriteChars: userStore.getFavoriteChars(),
			userChars: userStore.getUserChars()
		});
	}
	loginClick(e) {
		e.preventDefault();
		this.setState({
			menuVisible: false
		});
		auth0.login();
		//auth.auth0.loginWithRedirect();
	}
	logoutClick(e) {
		e.preventDefault();
		this.setState({
			menuVisible: false
		});
		auth0.logout();
		//auth.auth0.loginWithRedirect();
	}
	toggleBurger(e) {
		e.preventDefault();
		this.setState({
			burgerVisible: !this.state.burgerVisible
		});
	}
	toggleMenu(e) {
		e.preventDefault();
		this.setState({
			menuVisible: !this.state.menuVisible
		});
	}
	componentDidMount() {
		//window.addEventListener('scroll', this.onScroll);
	}
	componentWillUnmount() {
		//window.removeEventListener('scroll', this.onScroll);
		userStore.off(this.onChange, this);
	}
	componentDidUpdate(prevProps) {
		if (this.props.url.join('/') !== prevProps.url.join('/') || this.props.lang !== prevProps.lang) {
			this.setState({
				burgerVisible: false,
				menuVisible: false
			});
		}
	}
	onScroll() {
		/*if ((window.scrollY < 200 && !this.state.menuVisible) ||
			(window.scrollY < this.scrollTop && !this.state.menuVisible)) {
			this.setState({
				menuVisible: true
			});
		} else if (window.scrollY > this.scrollTop && this.state.menuVisible && window.scrollY > 200) {
			this.setState({
				menuVisible: false
			});
		}
		this.scrollTop = window.scrollY;*/
	}
	render() {
		let lang = this.props.lang;

		let urlBase = '/';
		if (lang !== 'en') urlBase += lang + '/';
		
		//console.log(this.props.url);
		let Route, showMenu = false;
		switch (this.props.url[0]) {
			case 'char':
				Route = require('./routes/char').default;
				showMenu = true;
				break;
			case 'ranking':
				Route = require('./routes/ranking').default;
				showMenu = true;
				break;
			case 'rarity':
				Route = require('./routes/rarity').default;
				showMenu = true;
				break;
			case 'stats':
				Route = require('./routes/stats').default;
				showMenu = true;
				break;
			case 'islandworkshop':
				Route = require('./routes/tools/islandworkshop').default;
				showMenu = true;
				break;
			case 'islands':
				Route = require('./routes/tools/islands').default;
				showMenu = true;
				break;
			case 'privacypolicy':
				Route = require('./routes/privacyPolicy').default;
				break;
			case 'terms':
				Route = require('./routes/terms').default;
				break;
			case 'charactersearch':
				Route = require('./routes/charsearch').default;
				break;
			case 'login':
				Route = require('./routes/login').default;
				break;
			case 'settings':
				Route = require('./routes/settings').default;
				break;
			case 'api':
				Route = require('./routes/api').default;
				break;
			case 'admin':
				Route = require('./routes/admin').default;
				break;
			case '':
				//Route = require('./routes/home').default;
				Route = require('./routes/char').default;
				showMenu = true;
				break;
			default:
				Route = require('./routes/char').default;
				showMenu = true;
				//Route = require('./routes/404').default;
				break;
		}
		let className = 'App';
		//if (this.state.mainBurgerVisible) className = className + ' App-MainBurgerExpanded';
		if (this.state.burgerVisible) className = className + ' App-BurgerExpanded';
		if (this.state.menuVisible) className = className + ' App-MenuVisible';
		let mobileMenu;
		if (showMenu) {
			/*mobileMenu = <div className="PageNav PageNav-MobileMenu">
				<a href="#" className="PageNav-MenuToggle" onClick={this.toggleBurger}>☰ <span className="PageNav-MenuToggleText">Menu</span></a>
			</div>;*/
		}
		let user = auth0.getUser(), loginButton, userToast;
		if (user) {
			loginButton = <div className="PageNavInner">
				<div className="PageNavLink"><i className="fontello-user"/> <span
					className="PageNav-Text">{user.nickname}</span></div>
				<div className="PageNav-Dropdown">
					<a href={`${urlBase}settings`} className="PageNavLink"><i className="fontello-cog"/> Settings</a>
					<a onClick={this.logoutClick} href="#" className="PageNavLink"><i className="fontello-logout"/>
						Logout</a>
				</div>
			</div>;
		} else {
			loginButton = <a className="PageNavLink" onClick={this.loginClick}><i className="fontello-login" /> <span className="PageNav-Text">Login</span></a>;
		}
		if (user && !user.email_verified) {
			//userToast = <div className="userToast">Please click the verification link in your email to login.</div>;
		}

		let favoriteCharsEle, userCharsEle;

		if (userStore.getUser()) {
			favoriteCharsEle = this.state.favoriteChars.map((char) => {
				return <a className="SideMenu-Char" href={`${urlBase}char/${char.id}/`} key={char.id}>
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<span className="SideMenu-CharName">{char.name}</span>
					<span className="SideMenu-CharWorld">{char.worldName}</span>
				</a>;
			});
			userCharsEle = this.state.userChars.map((char) => {
				return <a className="SideMenu-Char" href={`${urlBase}char/${char.id}/`} key={char.id}>
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<span className="SideMenu-CharName">{char.name}</span>
					<span className="SideMenu-CharWorld">{char.worldName}</span>
				</a>;
			});
		} else {
			favoriteCharsEle = <p>Log in to favorite characters.</p>;
			userCharsEle = <p>Log in to verify your characters.</p>;
		}

		let recentCharsEle = recentChars.get().filter(char => char && char.id > 0 && char.name && char.name.length > 0).map((char) => {
			return <a className="SideMenu-Char" href={`${urlBase}char/${char.id}/`} key={char.id}>
				<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
				<span className="SideMenu-CharName">{char.name}</span>
				<span className="SideMenu-CharWorld">{char.worldName}</span>
			</a>;
		});

		let loading;
		if (this.state.isLoading || this.state.isLoadingChar) {
			loading = <div className="DataLoading">
				<img src={loadinggif} alt="" width="75" height="87" />
				<h2>Loading...</h2>
				{this.state.isLoading ? <p><i className="fontello-spin1 animate-spin"/> Game Data</p> : ''}
				{this.state.isLoadingChar ? <p><i className="fontello-spin1 animate-spin"/> Characters</p> : ''}
			</div>;
		}

		return (
			<div className={className} ref={el => this.AppElement = el}>
				<div className="PageHeader2">
					<a href={`${urlBase}`} className="PageHeaderName">
						<img src={logo} alt="Lalachievements - collect everything" width="171" height="39" />
						{ /*<h1>Lalachievements</h1>
						<p className="PageSubheader">collect everything</p>*/ }
					</a>
					<nav className="PageNav">
						<a className="PageNavLink" href={`${urlBase}ranking/achievement/global/`}><i className="fontello-rankings" /> <span className="PageNav-Text">Rankings</span></a>
						<a className="PageNavLink" href={`${urlBase}rarity/title/global/`}><i className="fontello-rarity" /> <span className="PageNav-Text">Rarity</span></a>
						{ /*<a className="PageNavLink" href={`${urlBase}stats/global/`}><i className="fontello-statistics" /> <span className="PageNav-Text">Statistics</span></a>*/ }
						{ /*<a href={`${urlBase}db/`}>DB</a>*/ }
						<a className="PageNavLink" href={`${urlBase}islands/`}><i className="fontello-wrench" /> <span className="PageNav-Text">Tools</span></a>
					</nav>
					<nav className="PageNav PageNav-Secondary">
						<a href="#" className="PageNavLink-Mobile PageNav-MenuToggle" onClick={this.toggleMenu}>{
							this.state.menuVisible ? <span>X <span className="PageNav-MenuToggleText">Close</span></span>
								: <span>☰ <span className="PageNav-MenuToggleText">Menu</span></span>
						}</a>
						{user&&user['https://lalachievements.com/api/roles'].indexOf('admin') > -1 ? <a className="PageNavLink" href={`${urlBase}admin/`}><span className="PageNav-Text">Admin</span></a> : ''}
						<div className="PageNavInner">
							<div className="PageNavLink"><span className="PageNav-Text">{lang.toUpperCase()}</span></div>
							<div className="PageNav-Dropdown">
								<a href={`/${this.props.url.join('/')}`} className="PageNavLink"> English</a>
								<a href={`/fr/${this.props.url.join('/')}`} className="PageNavLink"> French</a>
								<a href={`/ja/${this.props.url.join('/')}`} className="PageNavLink"> Japanese</a>
								<a href={`/de/${this.props.url.join('/')}`} className="PageNavLink"> German</a>
							</div>
						</div>
						<div className="PageNavInner">
							<a className="PageNavLink" href={`${urlBase}charactersearch/`}><i className="fontello-search" /> <span className="PageNav-Text">Characters</span></a>
							<div className="PageNav-Dropdown CharactersDropdown">
								<div className="CharactersDropdown-CharsContainer">
									<div className="CharactersDropdown-Chars">
										<h3>My Chars</h3>
										{userCharsEle}
									</div>
									<div className="CharactersDropdown-Chars">
										<h3>Favorite Chars</h3>
										{favoriteCharsEle}
									</div>
									<div className="CharactersDropdown-Chars">
										<h3>Recent Chars</h3>
										{recentCharsEle}
									</div>
								</div>
								<div className="CharactersDropdown-Search">
									<a className="PageNavLink" href={`${urlBase}charactersearch/`}><i className="fontello-search" /> <span className="PageNav-Text">Search</span></a>
								</div>
							</div>
						</div>
						{loginButton}
					</nav>
				</div>
				<div className="PageHeaderMobile">
					<nav className="MobileNav">
						<a className="PageNavLink" href={`${urlBase}ranking/achievement/global/`}><i className="fontello-rankings" /> <span className="PageNav-Text">Rankings</span></a>
						<a className="PageNavLink" href={`${urlBase}rarity/title/global/`}><i className="fontello-rarity" /> <span className="PageNav-Text">Rarity</span></a>
						<a className="PageNavLink" href={`${urlBase}islands/`}><i className="fontello-wrench" /> <span className="PageNav-Text">Tools</span></a>
					</nav>
					<div className="PageHeaderMobile-Content">
						<div className="CharactersDropdown-Chars">
							<h3>My Chars</h3>
							{userCharsEle}
						</div>
						<div className="CharactersDropdown-Chars">
							<h3>Favorite Chars</h3>
							{favoriteCharsEle}
						</div>
						<div className="CharactersDropdown-Chars">
							<h3>Recent Chars</h3>
							{recentCharsEle}
						</div>
						<div className="CharactersDropdown-Search">
							<a className="PageNavLink" href={`${urlBase}charactersearch/`}><i className="fontello-search" /> <span className="PageNav-Text">Search for Character</span></a>
						</div>
						<hr />
						{loginButton}
						<hr />
						<div className="PageHeaderMobile-Lang">
							<a href={`/${this.props.url.join('/')}`} className={lang==='en'?'PageNavLink active':'PageNavLink'}> English</a>
							<a href={`/fr/${this.props.url.join('/')}`} className={lang==='fr'?'PageNavLink active':'PageNavLink'}> French</a>
							<a href={`/ja/${this.props.url.join('/')}`} className={lang==='ja'?'PageNavLink active':'PageNavLink'}> Japanese</a>
							<a href={`/de/${this.props.url.join('/')}`} className={lang==='de'?'PageNavLink active':'PageNavLink'}> German</a>
						</div>
					</div>
				</div>
				<div className="BurgerBackground" onClick={this.toggleBurger}>
					<a href={'/'+this.props.url.join('/')} className="PageNav-MenuToggle">X <span className="PageNav-MenuToggleText">Close Menu</span></a>
				</div>
				{loading}
				<div className="PageContainer">
					{mobileMenu}
					{userToast}
					<Route url={this.props.url} lang={lang} urlBase={urlBase} toggleBurger={this.toggleBurger} />
					<div className="PageClear"></div>
				</div>
				<div className="PageFooter">
					<p>Copyright &copy; 2018 - 2024 Neon Potato LLC DBA Lalachievements | <a href="/privacypolicy">Privacy Policy</a> | <a href="/terms">Terms of use</a></p>
					<p><a href="https://discord.gg/jUfj3NV"><img src="/images/Discord-Logo-White.png" width="24" height="24" alt="Discord Logo" /> Discord (for problems or questions)</a> | <a href="https://www.patreon.com/lalachievements"><img src="/images/Patreon_Mark_White.png" width="24" height="24" alt="Patreon Logo" /> Patreon (for monetary support)</a> | <a href="https://www.paypal.me/pikalala">PayPal (for one-time donations)</a></p>
					<p>FINAL FANTASY XIV © 2010 - 2024 SQUARE ENIX CO., LTD.<br/>
						FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd.<br/>
						All materials used under license.</p>
				</div>
			</div>
		);
	}
}

export default App;
