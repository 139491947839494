import React, {Component} from 'react';
import LoadingBox from '../components/LoadingBox';
import '../gamedata/spritesheet/leveAssignmentType.css';
import {ResponsiveLine} from "@nivo/line";
import theme from "../styles/nivotheme";
//import Slideout from 'slideout';
import './settings.scss';

let dataCenters = require('../gamedata/json/dataCenters.json');
let jobs = require('../gamedata/json/en/jobs.json');
//let raids = require('../data/raids');
let store = require('../stores/gameData').default;

function getWorldNameById(worldId) {

	let dataCenter = 'unknown';
	let world = 'unknown';
	dataCenters.forEach(dc => {
		dc.Worlds.forEach(w => {
			if (w.Id === worldId) {
				world = w.Name;
				dataCenter = dc.Name;
			}
		});
	});
	return world;
}
function getJobNameById(jobId) {
	let name = 'unknown';
	let job = jobs.find(j => j.Id === jobId);
	if (job) {
		name = job.Name[0].toUpperCase() + job.Name.substr(1, job.Name.length).toLowerCase();
	}
	return name;
}

class RankingRoute extends Component {
	state = {
		loading: true,
		count: 0,
		chars: [],
		raids: store.get('raids')
	};
	constructor(props) {
		super(props);
		store.on(this.onChange, this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState({
			raids: store.get('raids')
		});
	}
	componentDidMount() {
		this.fetch();
	}
	componentDidUpdate(prevProps) {
		if (this.props.url[2] !== prevProps.url[2] || this.props.url[1] !== prevProps.url[1] || this.props.url[3] !== prevProps.url[3] || this.props.url[4] !== prevProps.url[4] || this.props.url[5] !== prevProps.url[5]) {
			this.setState({loading: true});
			this.fetch();
		}
	}
	fetch() {
		let world = this.props.url[2], type = this.props.url[1], mode = this.props.url[3], mode2 = this.props.url[4];
		let url = '/api/ranking/' + this.props.url.slice(1, this.props.url.length).join('/');
		fetch(url).then((res) => {
		    if (res.status !== 200) {
		        console.error(res);
		        throw new Error('500 response');
            }
            return res.json();
		}).then(rankings => {
			if (rankings.deployTime > window.LALA_DEPLOY_TIME) {
				return window.location.reload();
			}
			//let rankings = res.json();
			this.setState(Object.assign({

				worldScores: [],
				jobScores: [],
			}, rankings, {
				loading: false,
				count: rankings.count*1,
				loadedType: this.props.url[1]
			}));
		}).catch(err => {
		    console.error(err);
		});
	}
	render() {
		let world = this.props.url[2], lang = this.props.lang, type = this.props.url[1], mode = this.props.url[3], generateTime, urlBase = this.props.urlBase;
		let raidId, raid;
		let worldMenus = [];
		if (mode === 'all') {

		} else if (mode === 'xivdb') {

		} else if (mode === 'rarity') {

		} else {
			mode = 'standard';
		}
		if (type === 'raid') {
			raidId = this.props.url[3]*1;
			mode = raidId;
			raid = this.state.raids.find(r => r.id === raidId);
		}

		let sortedRaids = this.state.raids.sort((a, b) => {
			if (a.released > b.released) return 1;
			if (a.released < b.released) return -1;
			if (a.id > b.id) return 1;
			if (a.id < b.id) return -1;
			return 0;
		});

		let className = '';
		if (world === 'global') className += ' current';
		worldMenus.push(<a href={`${urlBase}ranking/${type}/global/${this.props.url.slice(3, this.props.url.length).join('/')}`} className={className} key="global">Global</a>);
		for (let dc of dataCenters) {
			if (dc.Name.toLowerCase().includes('beta')) continue;
			let className = 'SideMenu-Group';
			if (world === dc.Name) className += ' current';
			worldMenus.push(<a href={`${urlBase}ranking/${type}/${dc.Name}/${this.props.url.slice(3, this.props.url.length).join('/')}`} className={className} key={dc.Name}>{dc.Name}</a>);
			for (let w of dc.Worlds) {
				let className = '';
				if (world === w.Name) className += ' current';
				worldMenus.push(<a href={`${urlBase}ranking/${type}/${w.Name}/${this.props.url.slice(3, this.props.url.length).join('/')}`} className={className} key={w.Name}>{w.Name}</a>);
			}
		}

		let charRows = [];
		let i = 0;
		//console.log(this.state.chars);
		if (this.state.loadedType === type) {
			for (let char of this.state.chars) {
				i++;
				let dataCenter = 'unknown';
				let world = 'unknown';
				dataCenters.forEach(dc => {
					dc.Worlds.forEach(w => {
						if (w.Id === char.worldId) {
							world = w.Name;
							dataCenter = dc.Name;
						}
					});
				});
				let rank = i;
				let rankChange, extraIcon, numbers;
				//date = '';
				if (type === 'raid') {
					numbers = <div>
						<div className="RankingRow-Number">{new Date(char.date).toLocaleDateString('en-US', {
							month: 'short',
							day: 'numeric'
						})}</div>
						<div className="RankingRow-All">{new Date(char.date).toLocaleDateString('en-US', {
							year: 'numeric'
						})}</div>
						<div className="RankingRow-All">{new Date(char.date).toLocaleTimeString('en-US', {
							hour: 'numeric',
							minute: 'numeric'
						})}</div>
					</div>;
				} else if (type === 'ishgard') {
					rank = char.rank;
					if (char.rank < char.yesterdayRank || char.yesterdayRank<1) {
						rankChange = <span><i className="fontello-up-open" /> {char.yesterdayRank > 0 ? char.yesterdayRank : '∞'}</span>;
					} else if (char.rank > char.yesterdayRank) {
						rankChange = <span><i className="fontello-down-open" /> {char.yesterdayRank > 0 ? char.yesterdayRank : '∞'}</span>;
					} else {
						rankChange = <span>&nbsp;</span>;
					}
					let job = jobs.find(j => j.Id === char.jobId);
					if (job) {
                        let jobClass = job.Name[0].toUpperCase() + job.Name.toLowerCase().substr(1, job.length);
                        extraIcon = <i className={`icon icon-leveAssignmentType icon-leveAssignmentType-${jobClass}`}/>;
                    }
					numbers = <div>
						<div className="RankingRow-Number">{char.score.toLocaleString()}</div>
						{char.change > 0 ? <div className="RankingRow-NumberChange">+ {char.change.toLocaleString()}</div> : ''}
					</div>;
				} else {
					let count, date1, totalEle;
					if (mode === 'all') {
						count = char.countAll;
						date1 = char.dateAll;
					} else if (type === 'achievement' && mode === 'xivdb') {
						count = char.countXivdb;
						date1 = char.dateXivdb;
						totalEle = <div className="RankingRow-All">({char.countAll} total)</div>;
					} else if (mode === 'rarity') {
						count = char.countLala;
						date1 = char.dateLala;
					} else {
						count = char.count;
						date1 = char.date;
						totalEle = <div className="RankingRow-All">({char.countAll} total)</div>;
					}
					let date = new Date(date1).toLocaleDateString('en-US', {
						month: 'short',
						day: 'numeric',
						year: 'numeric'
					});
					numbers = <div>
						<div className="RankingRow-Number">{count}</div>
						{totalEle}
						<div className="RankingRow-All">{date}</div>
					</div>;
				}

				charRows.push(<tr className="RankingRow" key={i}>
					<td className="RankingRow-Rank">{rank}</td>
					{rankChange ? <td className="RankingRow-RankChange">{rankChange}</td> : ''}
					<td className="RankingRow-Icon"><a href={`${urlBase}char/${char.id}/`}><img src={char.iconUrl} alt=""/></a></td>
                    {extraIcon ? <td className="RankingRow-ExtraIcon">{extraIcon}</td> : ''}
					<td className="RankingRow-Text">
						<a href={`${urlBase}char/${char.id}/`} className="RankingRow-Name">{char.name ? char.name : '[New Character]'}</a>
						<div className="RankingRow-World">{dataCenter} / {world}</div>
						<div className="mobileOnly">
							{numbers}
						</div>
					</td>
					<td className="RankingRow-Values notMobile">
						{numbers}
					</td>
				</tr>);
				if (type === 'ishgard' && i === 12) {
					charRows.push(<tr className="RankingRow" key="divider">
						<td colSpan="4">&nbsp;</td>
					</tr>);
				}
			}
		}
		generateTime = new Date(this.state.generateTime).toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			timeZoneName: 'short'
		});


		let scoringMode;
		if (type === 'raid') {
			if (this.state.raids.length > 1) {
				let raidFilters = sortedRaids.filter(r => r.expansion === raid.expansion).map(function (r) {
					return <a href={`${urlBase}ranking/${type}/${world}/${r.id}`}
					          className={raid.id === r.id ? 'current' : ''}>{r.name}</a>;
				});

				scoringMode = <div>
					<div className="FilterBox">
						<h2>Expansion</h2>
						<a href={`${urlBase}ranking/${type}/${world}/1040`}
						   className={raid.expansion === 2 ? 'current' : ''}>2.x ARR</a>
						<a href={`${urlBase}ranking/${type}/${world}/1642`}
						   className={raid.expansion === 3 ? 'current' : ''}>3.x HW</a>
						<a href={`${urlBase}ranking/${type}/${world}/2121`}
						   className={raid.expansion === 4 ? 'current' : ''}>4.x SB</a>
						<a href={`${urlBase}ranking/${type}/${world}/2412`}
						   className={raid.expansion === 5 ? 'current' : ''}>5.x ShB</a>
						<a href={`${urlBase}ranking/${type}/${world}/3038`}
						   className={raid.expansion === 6 ? 'current' : ''}>6.x EW</a>
						<a href={`${urlBase}ranking/${type}/${world}/3575`}
						   className={raid.expansion === 7 ? 'current' : ''}>7.x DT</a>
					</div>
					<div className="FilterBox">
						<h2>Raid</h2>
						{raidFilters}
					</div>
				</div>;
			}
		} else if (type === 'ishgard') {
			let season = this.props.url[3]*1;
			let jobId = this.props.url[4];
			let day = this.props.url[5]*1;
			if (day < 1 || day > 10 || !day) day = '';
			scoringMode = <div>
				<div className="FilterBox">
					<h2>Job</h2>
                    <a href={`${urlBase}ranking/${type}/${world}/${season}/Crafters/${day}`} className={jobId === 'Crafters' ? 'current' : ''}>Crafters</a>
                    <a href={`${urlBase}ranking/${type}/${world}/${season}/CRP/${day}`} className={jobId === 'CRP' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Carpenter`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/BSM/${day}`} className={jobId === 'BSM' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Blacksmith`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/ARM/${day}`} className={jobId === 'ARM' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Armorer`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/GSM/${day}`} className={jobId === 'GSM' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Goldsmith`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/LTW/${day}`} className={jobId === 'LTW' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Leatherworker`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/WVR/${day}`} className={jobId === 'WVR' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Weaver`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/ALC/${day}`} className={jobId === 'ALC' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Alchemist`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/CUL/${day}`} className={jobId === 'CUL' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Culinarian`} /></a>
                    <a href={`${urlBase}ranking/${type}/${world}/${season}/Gatherers/${day}`} className={jobId === 'Gatherers' ? 'current' : ''}>Gatherers</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/MIN/${day}`} className={jobId === 'MIN' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Miner`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/BTN/${day}`} className={jobId === 'BTN' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Botanist`} /></a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/FSH/${day}`} className={jobId === 'FSH' ? 'current' : ''}><i className={`icon icon-leveAssignmentType icon-leveAssignmentType-Fisher`} /></a>
				</div>
				<div className="FilterBoxContainer">
				<div className="FilterBox">
					<h2>Season</h2>
					<a href={`${urlBase}ranking/${type}/${world}/2/${jobId}/${day}`} className={season === 2 ? 'current' : ''}>2</a>
					<a href={`${urlBase}ranking/${type}/${world}/3/${jobId}/${day}`} className={season === 3 ? 'current' : ''}>3</a>
					<a href={`${urlBase}ranking/${type}/${world}/4/${jobId}/${day}`} className={season === 4 ? 'current' : ''}>4</a>
				</div>
				<div className="FilterBox">
					<h2>Day</h2>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/1`} className={day === 1 ? 'current' : ''}>1</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/2`} className={day === 2 ? 'current' : ''}>2</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/3`} className={day === 3 ? 'current' : ''}>3</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/4`} className={day === 4 ? 'current' : ''}>4</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/5`} className={day === 5 ? 'current' : ''}>5</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/6`} className={day === 6 ? 'current' : ''}>6</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/7`} className={day === 7 ? 'current' : ''}>7</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/8`} className={day === 8 ? 'current' : ''}>8</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/9`} className={day === 9 ? 'current' : ''}>9</a>
					<a href={`${urlBase}ranking/${type}/${world}/${season}/${jobId}/`} className={day === '' ? 'current' : ''}>10</a>
				</div>
				</div>
			</div>;
		} else if (type === 'achievement') {
			scoringMode = <div className="FilterBox">
				<h2>Scoring Mode</h2>
				<a href={`${urlBase}ranking/${type}/${world}/`} className={mode==='standard'?'current':''}>Standard</a>
				<a href={`${urlBase}ranking/${type}/${world}/all/`} className={mode==='all'?'current':''}>All</a>
				<a href={`${urlBase}ranking/${type}/${world}/xivdb/`} className={mode==='xivdb'?'current':''}>XIVDB</a>
				<a href={`${urlBase}ranking/${type}/${world}/rarity/`} className={mode==='rarity'?'current':''}>Rarity</a>
			</div>;
		} else {
			scoringMode = <div className="FilterBox">
				<h2>Scoring Mode</h2>
				<a href={`${urlBase}ranking/${type}/${world}/`} className={mode==='standard'?'current':''}>Standard</a>
				<a href={`${urlBase}ranking/${type}/${world}/all/`} className={mode==='all'?'current':''}>All</a>
				<a href={`${urlBase}ranking/${type}/${world}/rarity/`} className={mode==='rarity'?'current':''}>Rarity</a>
			</div>;
		}


		let header = `${world} ${type} rankings`, graphs;
		if (type === 'raid') {
			if (raid) {
				header += ` (${raid.name})`;
			}
		} else if (type === 'ishgard') {
			let season = this.props.url[3]*1;
			let jobName = this.props.url[4];
			header += ` - season ${season} (${jobName})`;

			function makeGraph(title, data) {
				return <div className="CharProfileBox">
					<h2>{title}</h2>
					<div className="CharProfileBox-Content" style={{height: '320px'}}>
						<ResponsiveLine
							data={data}
							colors={['#a60', '#3aaa28', '#06a']}
							theme={theme}
							/*defs={[{
								id: 'squares',
								type: 'patternSquares',
								"background": "#c93131",
								"color": "#24cc46"
							}]}
							fill={[{
								match: '*', id: 'squares'
							}]}*/
							margin={{top: 30, right: 20, bottom: 80, left: 80}}
							yScale={{
								type: 'linear',
								stacked: false,
							}}
							axisLeft={{
								legendOffset: 12,
							}}
							axisBottom={{
								legendOffset: -12,
								tickRotation: -60
							}}
							enablePoints={true}
							enableArea={false}
							areaOpacity={0.5}
							useMesh={true}
							isInteractive={true}
							legends={[{
								anchor: 'top-right',
								direction: 'row',
								itemWidth: 100,
								itemHeight: 20,
								translateY: -30,
								itemTextColor: '#eee'
							}]}
						/>
					</div>
				</div>;
			}
			function makeWorldGraph(title, data, yPrefix) {
				return makeGraph(title, [{
					id: 'Lowest',
					data: data.map(d => {return {x: getWorldNameById(d.worldId), y: d[yPrefix+'min']}})
				}, {
					id: 'Highest',
					data: data.map(d => {return {x: getWorldNameById(d.worldId), y: d[yPrefix+'max']}})
				}, {
					id: 'Average',
					data: data.map(d => {return {x: getWorldNameById(d.worldId), y: d[yPrefix+'avg']}})
				}]);
			}
			function makeJobGraph(title, data, yPrefix) {
				return makeGraph(title, [{
					id: 'Lowest',
					data: data.map(d => {return {x: getJobNameById(d.jobId), y: d[yPrefix+'min']}})
				}, {
					id: 'Highest',
					data: data.map(d => {return {x: getJobNameById(d.jobId), y: d[yPrefix+'max']}})
				}, {
					id: 'Average',
					data: data.map(d => {return {x: getJobNameById(d.jobId), y: d[yPrefix+'avg']}})
				}]);
			}
			let worldGraphs;
			if (this.state.worldScores && this.state.worldScores.length > 0) {
				worldGraphs = <div className={world==='global' ? '' : 'CharProfileTwo'}>
					{makeWorldGraph(`Rank 1 by World (${jobName})`, this.state.worldScores, 'rank1')}
					{makeWorldGraph(`Rank 12 by World (${jobName})`, this.state.worldScores, 'rank12')}
				</div>;
				/*worldGraphs = <div className={world==='global' ? '' : 'CharProfileTwof'}>
					{makeGraph(`Rank 1/12 by World (${jobName})`, [{
						id: 'Rank 1 - Lowest',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank1min}})
					}, {
						id: 'Rank 1 - Highest',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank1max}})
					}, {
						id: 'Rank 1 - Average',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank1avg}})
					}, {
						id: 'Rank 12 - Lowest',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank12min}})
					}, {
						id: 'Rank 12 - Highest',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank12max}})
					}, {
						id: 'Rank 12 - Average',
						data: this.state.worldScores.map(d => {return {x: getWorldNameById(d.worldId), y: d.rank12avg}})
					}])}
				</div>;*/
			}
			let jobGraphs;
			if (this.state.jobScores && this.state.jobScores.length > 0) {
				jobGraphs = <div className={'CharProfileTwo'}>
					{makeJobGraph(`Rank 1 by Job (${world})`, this.state.jobScores, 'rank1')}
					{makeJobGraph(`Rank 12 by Job (${world})`, this.state.jobScores, 'rank12')}
				</div>;
			}
			graphs = <div>{worldGraphs}{jobGraphs}</div>;

		} else {
			header += ` (${mode})`;
		}

		return (
			<div className="PageContainer-Fixed">
				<div className="SideMenu">
					{worldMenus}
				</div>
				<div className="PageContent PageContent-WithMenu">
					<div className="PageNav PageNav-MobileMenu">
						<a href={'/'+this.props.url.join('/')} className="PageNav-MenuToggle" onClick={this.props.toggleBurger}>☰ <span className="PageNav-MenuToggleText">{world} (tap to change world)</span></a>
					</div>
					<div className="FilterBox">
						<h2>Type</h2>
						<a href={`${urlBase}ranking/achievement/${world}/`} className={type==='achievement'?'current':''}>Achievements</a>
						<a href={`${urlBase}ranking/mount/${world}/`} className={type==='mount'?'current':''}>Mounts</a>
						<a href={`${urlBase}ranking/minion/${world}/`} className={type==='minion'?'current':''}>Minions</a>
						<a href={`${urlBase}ranking/raid/${world}/3575`} className={type==='raid'?'current':''}>Raids</a>
						<a href={`${urlBase}ranking/ishgard/${world}/4/Crafters`} className={type==='ishgard'?'current':''}>Ishgard</a>
					</div>
					{scoringMode}
					{ /*<div className="InfoBox InfoBox-Error">
						<div>
							Dawntrail data will be added when early access ends on July 2.<br />
							Achievements, mounts, minions, and rankings will not update during early access.
						</div>
					</div>*/ }

					{ /*<div className="ImageBox">
						<img src="/images/top.png" alt="" />
						<div>

							<LoadingBox visible={this.state.loading} />

							<div className="SettingsContainer">
								<div className="SettingsColumn">
									<p>
										Generated on {generateTime}.<br />
										Rankings update daily at approximately midnight UTC.
									</p>
								</div>
								<div className="SettingsColumn">
									<p><button className="LinkButton">What do these options mean?</button></p>
								</div>
							</div>
							<div className="SettingsContainer">
								<div className="SettingsColumn">
									<p className="SettingsBox OptionGroup">
										Verified Rankings:<br />
										<label><input type="checkbox" name="items" value="combined" checked={false} /><span>Combined</span></label>
										<label><input type="checkbox" name="items" value="achievements" checked={true} /><span>Achievements</span></label>
										<label><input type="checkbox" name="items" value="mounts" checked={false} /><span>Mounts</span></label>
										<label><input type="checkbox" name="items" value="minions" checked={false} /><span>Minions</span></label>
										<label><input type="checkbox" name="items" value="raid" checked={false} /><span>Raid Clear Date</span></label>
										<label><input type="checkbox" name="items" value="ishgard" checked={false} /><span>Ishgard Reconstruction</span></label>
										<label><input type="checkbox" name="items" value="relics" checked={false} /><span>Relics</span></label>
										<label><input type="checkbox" name="items" value="trophies" checked={false} /><span>Lalachievements Trophies</span></label>
										User-Submitted Rankings:<br />
										<label><input type="checkbox" name="items" value="tt" checked={false} /><span>Triple Triad</span></label>
										<label><input type="checkbox" name="items" value="fish" checked={false} /><span>Fish</span></label>
										<label><input type="checkbox" name="items" value="orchestrion" checked={false} /><span>Orchestrion Rolls</span></label>
										<label><input type="checkbox" name="items" value="fashion" checked={false} /><span>Fashion</span></label>
										<label><input type="checkbox" name="items" value="quests" checked={false} /><span>Quest Completion</span></label>
									</p>
								</div>
								<div className="SettingsColumn">
									<p className="SettingsBox OptionGroup">
										Scoring Mode:<br />
										<label><input type="radio" name="mode" value="standard" checked={true} /><span>Standard</span></label>
										<label><input type="radio" name="mode" value="rarity" checked={false} /><span>Rarity</span></label>
									</p>
									<p className="SettingsBox OptionGroup">
										Optional Items:<br />
										<label><input type="checkbox" name="optional" value="legacy" checked={false} /><span>Seasonal Events and Legacy</span></label>
										<label><input type="checkbox" name="optional" value="mogstation" checked={false} /><span>Merchandise, Online Store, and Pre-Orders</span></label>
									</p>
								</div>
							</div>

							<p>
								If multiple characters have the same score, the date of the most recently obtained item breaks the tie.<br />
								Characters matching your selection: {this.state.count.toLocaleString()}
							</p>
						</div>
					</div>*/ }
					<div className="ImageBox">
						<img src="/images/top.png" alt="" />
						<div>

						<LoadingBox visible={this.state.loading} />

						<p>Generated on {generateTime}.</p>
						<p>
							Rankings update daily at midnight (UTC).
							To ensure your character is up to date, visit your profile before rankings update.
						</p>
						<ul>
							<li><strong>Standard:</strong> All items that are not time-limited and can still be obtained.</li>
							<li><strong>All:</strong> All items.</li>
							<li><strong>XIVDB:</strong> All non-legacy items (only available for achievements), this is how XIVDB rankings worked.</li>
							<li><strong>Rarity:</strong> All items in Standard, but point values assigned weekly (at server reset) based on their <a href={`${urlBase}rarity/${type}/global/`}>rarity</a>.</li>
							<li><strong>Raid:</strong> Rankings are determined by the date <em>on Lodestone</em> of the achievement for clearing the raid.</li>
						</ul>
						<p>If multiple characters have the same score, the one who reached that score first earns the highest rank.</p>

						<p>Characters: {this.state.count.toLocaleString()}</p>
						</div>
					</div>

					{/*<div className="InfoBox InfoBox-Error"><p>Rankings are currently incorrect as a result of patch 6.1 updates, please be patient while this is fixed.</p></div>*/}

					<h2>{header}</h2>
					{graphs}

					<table className="RankingTable">
						<tbody>
						{charRows}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default RankingRoute;