import React, {Component} from "react";

let granaryItems = [27, 28, 29, 30, 31];

const Big = require('big.js');
Big.RM = 0;

let themes = require('../../gamedata/json/islandThemes.json');
let items = require('../../gamedata/json/islandPouch.json');
let itemCategories = require('../../gamedata/json/islandPouchCategory.json');
let crafts = require('../../gamedata/json/islandCraftworks.json').map(craft => {
	craft.granaryMats = 0;
	craft.produceMats = 0;
	craft.leavingsMats = 0;
	craft.ingredients.forEach(ingredient => {	let item = items.find(i => i.id === ingredient.ingredient);
		let category = itemCategories.find(c => c.id === item.category);
		if (granaryItems.indexOf(item.id) > -1) craft.granaryMats++;
		if (category.name === 'Produce') craft.produceMats++;
		if (category.name === 'Leavings') craft.leavingsMats++;
	});
	craft.limitedMats = craft.granaryMats + craft.produceMats + craft.leavingsMats;
	return craft;
});

//let craftsPopularity = {};
let craftsPopularity = {};//{"1":120,"2":100,"3":100,"4":140,"5":120,"6":80,"7":140,"8":100,"9":100,"10":100,"11":80,"12":140,"13":140,"14":120,"15":80,"16":120,"17":120,"18":140,"19":120,"20":140,"21":140,"22":100,"23":140,"24":140,"25":120,"26":120,"27":100,"28":80,"29":140,"30":140,"31":100,"32":100,"33":100,"34":120,"35":100,"36":80,"37":120,"38":80,"39":100,"40":140,"41":100,"42":120,"43":120,"44":120,"45":80,"46":100,"47":80,"48":120,"49":120,"50":100};
let craftsSupply = {};
let craftsDS = {};
let craftsIncluded = {};
crafts.forEach(craft => {
	craftsPopularity[craft.id] = 100;
	craftsSupply[craft.id] = 100;
	craftsDS[craft.id] = 2;
	craftsIncluded[craft.id] = true;
});
let storageCraftsPopularity = JSON.parse(localStorage.getItem('lala-islandWorkshop-craftsPopularity'));
let storageCraftsSupply = JSON.parse(localStorage.getItem('lala-islandWorkshop-craftsSupply'));
let storageCraftsDS = JSON.parse(localStorage.getItem('lala-islandWorkshop-craftsDS'));
let storageCraftsIncluded = JSON.parse(localStorage.getItem('lala-islandWorkshop-craftsIncluded'));

if (storageCraftsPopularity && storageCraftsSupply) {
	crafts.forEach(craft => {
		if (storageCraftsPopularity && storageCraftsPopularity[craft.id]*1 > 10) craftsPopularity[craft.id] = storageCraftsPopularity[craft.id]*1;
		if (storageCraftsSupply && storageCraftsSupply[craft.id]*1 > 10) craftsSupply[craft.id] = storageCraftsSupply[craft.id]*1;
		if (storageCraftsDS && typeof storageCraftsDS[craft.id] !== 'undefined') craftsDS[craft.id] = storageCraftsDS[craft.id]*1;
		if (storageCraftsIncluded && typeof storageCraftsIncluded[craft.id] !== 'undefined') craftsIncluded[craft.id] = !!storageCraftsIncluded[craft.id];
	});
}

let popularityTexts = {
	140: '<span class="icon-popularity-140" title="Very High"></span>',
	120: '<span class="icon-popularity-120" title="High"></span>',
	100: '<span class="icon-popularity-100" title="Average"></span>',
	80: '<span class="icon-popularity-80" title="Low"></span>'
};
let supplyTexts = {
	60: '<span class="icon-supply-60" title="Overflowing"></span>',
	80: '<span class="icon-supply-80" title="Surplus"></span>',
	100: '<span class="icon-supply-100" title="Sufficient"></span>',
	130: '<span class="icon-supply-130" title="Insufficient"></span>',
	160: '<span class="icon-supply-160" title="Nonexistent"></span>',
	130100: '<span class="icon-supply-130100" title="Sufficient or Insufficient"></span>'
}
let dsTexts = {
	0: '<span class="icon-demandshift-0" title="Skyrocketing"></span>',
	1: '<span class="icon-demandshift-1" title="Increasing"></span>',
	2: '<span class="icon-demandshift-2" title="None"></span>',
	3: '<span class="icon-demandshift-3" title="Decreasing"></span>',
	4: '<span class="icon-demandshift-4" title="Plummeting"></span>'
}


let craftChains = [];
function getCraftChains(chain) {
	for (let i = 0; i < crafts.length; i++) {
		let craft = crafts[i];
		if (craft.unlockRank > 8) continue;
		if (chain.length === 0) {
			if (craft.limitedMats > 0) continue;
		} else {
			let prevIndex = chain[chain.length-1] ;
			if (prevIndex === i) continue;
			let prevCraft = crafts[prevIndex];
			if (prevCraft.themes[0] !== craft.themes[0] && prevCraft.themes[0] !== craft.themes[1] && prevCraft.themes[1] !== craft.themes[0] && prevCraft.themes[1] !== craft.themes[1]) continue;
		}
		let newChain = [...chain].concat([i]);
		craftChains.push(newChain);
		//console.log([...chain].concat([crafts[i].id]));
		if (newChain.length < 6) getCraftChains(newChain);
	}
}
//getCraftChains([]);
craftChains = craftChains.filter(chain => {
	let totalTime = 0;
	chain.forEach(i => totalTime += crafts[i].time);
	return totalTime <= 24;
})

class IslandsRoute extends Component {
	state = {
		popularity: 100,
		supply: 100,
		workshopRank: 3,
		islandRank: 10,
		groove: 0,
		selectedCraft: crafts[0],
		craftsPopularity: craftsPopularity,
		craftsSupply: craftsSupply,
		craftsDS: craftsDS,
		craftsIncluded: craftsIncluded,
		tableSort: 'id',
		isEditingPopularity: false,
		isEditingSupply: false,
		isEditingDS: false,
		schedule: [[], [], []]
	};
	constructor(props) {
		super(props);
		this.onCraftSelect = this.onCraftSelect.bind(this);
		this.onPopularity = this.onPopularity.bind(this);
		this.onSupply = this.onSupply.bind(this);
		this.onWorkshopRank = this.onWorkshopRank.bind(this);
		this.onIslandRank = this.onIslandRank.bind(this);
		this.onGroove = this.onGroove.bind(this);
		this.onCraftPopularity = this.onCraftPopularity.bind(this);
		this.onCraftSupply = this.onCraftSupply.bind(this);
		this.onCraftDS = this.onCraftDS.bind(this);
		this.onCraftIncluded = this.onCraftIncluded.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onTableSort = this.onTableSort.bind(this);
	}
	onCraftSelect(e) {
		let craft = crafts[e.target.value*1];
		if (craft) {
			this.setState({selectedCraft: craft});
		}
	}
	onPopularity(e) {
		this.setState({popularity: e.target.value*1});
	}
	onSupply(e) {
		this.setState({supply: e.target.value*1});
	}
	onWorkshopRank(e) {
		this.setState({workshopRank: e.target.value*1});
	}
	onIslandRank(e) {
		this.setState({islandRank: e.target.value*1});
	}
	onGroove(e) {
		this.setState({groove: e.target.value*1});
	}
	onCraftPopularity(e) {
		let newState = {};
		newState[e.target.dataset.craftid*1] = e.target.value*1;
		this.setState({craftsPopularity: Object.assign({}, this.state.craftsPopularity, newState)});
	}
	onCraftSupply(e) {
		let newState = {};
		newState[e.target.dataset.craftid*1] = e.target.value*1;
		this.setState({craftsSupply: Object.assign({}, this.state.craftsSupply, newState)});
	}
	onCraftDS(e) {
		let newState = {};
		newState[e.target.dataset.craftid*1] = e.target.value*1;
		this.setState({craftsDS: Object.assign({}, this.state.craftsDS, newState)});
	}
	onCraftIncluded(e) {
		let newState = {};
		newState[e.target.dataset.craftid*1] = !this.state.craftsIncluded[e.target.dataset.craftid*1];
		this.setState({craftsIncluded: Object.assign({}, this.state.craftsIncluded, newState)});
	}
	onEdit(e) {
		if (e.target.dataset.column === 'popularity') this.setState({isEditingPopularity: !this.state.isEditingPopularity});
		if (e.target.dataset.column === 'supply') this.setState({isEditingSupply: !this.state.isEditingSupply});
		if (e.target.dataset.column === 'ds') this.setState({isEditingDS: !this.state.isEditingDS});
	}
	onTableSort(e) {
		this.setState({tableSort: e.target.value});
	}
	render() {
		localStorage.setItem('lala-islandWorkshop-craftsPopularity', JSON.stringify(this.state.craftsPopularity));
		localStorage.setItem('lala-islandWorkshop-craftsSupply', JSON.stringify(this.state.craftsSupply));
		localStorage.setItem('lala-islandWorkshop-craftsDS', JSON.stringify(this.state.craftsDS));
		localStorage.setItem('lala-islandWorkshop-craftsIncluded', JSON.stringify(this.state.craftsIncluded));

		//let lang = this.props.lang;
		let selectOptions = crafts.map((craft, i) => {
			return <option value={i} key={craft.id}>{craft.name}</option>
		});
		let selectedCraft;
		if (this.state && this.state.selectedCraft) {
			let finalValue = Math.floor(Math.floor(100*this.state.selectedCraft.value * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100)) * (this.state.popularity/100) * (this.state.supply/100) / 100);
			/*let limitedMats = this.state.selectedCraft.ingredients.filter(ingredient => {
				let item = items.find(i => i.id === ingredient.ingredient);
				let category = itemCategories.find(c => c.id === item.category);
				return granaryItems.indexOf(item.id) > -1 || category.name[0] !== 'M'
			}).length;*/
			selectedCraft = <div>
				<p>
					Item: {this.state.selectedCraft.name}<br />
					Themes: {this.state.selectedCraft.themes.join(', ')}<br />
					Ingredients: {
						this.state.selectedCraft.ingredients.map((ingredient, i) => {
							return `${ingredient.amount} ${items.find(i => i.id === ingredient.ingredient).name}`
						}).join(', ')
					}<br />
					Limited Ingredients: {this.state.selectedCraft.limitedMats}<br />
					Unlock Rank: {this.state.selectedCraft.unlockRank}<br />
					Time: {this.state.selectedCraft.time}hrs<br />
					Base Value: {this.state.selectedCraft.value}
				</p>
				<p><strong>
					{/*floor(floor(base value * workshop level% * groove%) * popularity% * supply%)<br />*/}
					{/*floor(floor({this.state.selectedCraft.value} * {(this.state.workshopRank-1)/10+1} * {1+this.state.groove/100}) * {this.state.popularity/100} * {this.state.supply/100})<br />
					floor(floor({100 * this.state.selectedCraft.value * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100)}) * {this.state.popularity/100} * {this.state.supply/100})<br />
					floor({Math.floor(this.state.selectedCraft.value * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100))} * {this.state.popularity/100} * {this.state.supply/100})<br />*/}
					Final Value: {finalValue}
				</strong></p>
				<p>Cowries per Hour: {(finalValue/this.state.selectedCraft.time).toFixed(2)}</p>
				{this.state.selectedCraft.limitedMats ? <p>Cowries per Limited Ingredient: {(finalValue/this.state.selectedCraft.limitedMats).toFixed(2)}</p> : ''}
			</div>;
		}
		let workshopMod = new Big(this.state.workshopRank).minus('1').div('10').plus('1');
		let grooveMod = new Big(this.state.groove).div('100').plus('1');
		let craftData = crafts.filter(craft => craft.unlockRank <= this.state.islandRank).map(craft => {
			//if (craft.name === 'Isleworks Brush') {
				let popularityMod = new Big(this.state.craftsPopularity[craft.id]).div('100');
				let supplyMod = new Big(this.state.craftsSupply[craft.id]).div('100');
				let workshopValue = new Big(craft.value).times(workshopMod).times(grooveMod).round(0);
				let finalValue = workshopValue.times(popularityMod).times(supplyMod).round(0).toFixed(0);
				//console.log('b', workshopMod.toFixed(2), grooveMod.toFixed(2), popularityMod.toFixed(2), supplyMod.toFixed(2));
				//console.log('bb', workshopValue.toFixed(2), finalValue.toFixed(2));
				//console.log(((this.state.workshopRank-1)/10+1), (1+this.state.groove/100));
				//console.log(Math.floor((100*craft.value) * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100) / 100));

				//console.log(Math.floor(Math.floor((100*craft.value) * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100) / 100) * (this.state.craftsPopularity[craft.id]/100) * (this.state.craftsSupply[craft.id]/100)));

			//}
			//let finalValue = Math.floor(Math.floor((100*craft.value) * ((this.state.workshopRank-1)/10+1) * (1+this.state.groove/100) / 100) * (this.state.craftsPopularity[craft.id]/100) * (this.state.craftsSupply[craft.id]/100));
			/*let limitedMats = craft.ingredients.filter(ingredient => {
				let item = items.find(i => i.id === ingredient.ingredient);
				let category = itemCategories.find(c => c.id === item.category);
				return granaryItems.indexOf(item.id) > -1 || category.name[0] !== 'M'
			}).length;*/
			let tomorrowSupply = '?';
			if (this.state.craftsSupply[craft.id] === 60) { // overflowing
				tomorrowSupply = [60];
			} else if (this.state.craftsSupply[craft.id] === 80) { // surplus
				tomorrowSupply = [80];
			} else if (this.state.craftsSupply[craft.id] === 100) { // sufficient
				if (this.state.craftsDS[craft.id] === 0) tomorrowSupply = [130];
				if (this.state.craftsDS[craft.id] === 1) tomorrowSupply = [100,130];
				if (this.state.craftsDS[craft.id] === 2) tomorrowSupply = [100,130];
				if (this.state.craftsDS[craft.id] === 3) tomorrowSupply = [100];
				if (this.state.craftsDS[craft.id] === 4) tomorrowSupply = [100];
			} else if (this.state.craftsSupply[craft.id] === 130) { // insufficient
				if (this.state.craftsDS[craft.id] === 0) tomorrowSupply = [160];
				if (this.state.craftsDS[craft.id] === 1) tomorrowSupply = [100,130];
				if (this.state.craftsDS[craft.id] === 2) tomorrowSupply = [100];
				if (this.state.craftsDS[craft.id] === 3) tomorrowSupply = [100];
				if (this.state.craftsDS[craft.id] === 4) tomorrowSupply = [100];
			} else if (this.state.craftsSupply[craft.id] === 160) { // nonexistent
				tomorrowSupply = [100];
			}

			let tomorrowValues = tomorrowSupply.map(ts => {
				let popularityMod = new Big(this.state.craftsPopularity[craft.id]).div('100');
				let supplyMod = new Big(ts).div('100');
				let workshopValue = new Big(craft.value).times(workshopMod).times(grooveMod).round(0);
				let finalValue = workshopValue.times(popularityMod).times(supplyMod).round(0).toFixed(0);
				return finalValue;
			});
			/*let tomorrowAvgValue = tomorrowValues.reduce((previous, current) => {
				return previous.add(current);
			}, new Big('0')).div(tomorrowValues.length).toFixed(0);*/

			return Object.assign({}, craft, {
				finalValue: finalValue,
				//limitedMats: limitedMats,
				valuePerTime: finalValue/craft.time,
				valuePerLimitedMat: finalValue/craft.limitedMats,
				tomorrowSupply: tomorrowSupply,
				tomorrowValues: tomorrowValues,
				tomorrowAvgValue: tomorrowValues[0],
				tomorrowValuePerTime: tomorrowValues[0]/craft.time,
				tomorrowValuePerLimitedMat: tomorrowValues[0]/craft.limitedMats
			});
		}).sort((a, b) => {
			if (this.state.tableSort === 'id') {
				if (a.id > b.id) return 1;
				if (a.id < b.id) return -1;
			}
			if (a[this.state.tableSort]*1 < b[this.state.tableSort]*1) return 1;
			if (a[this.state.tableSort]*1 > b[this.state.tableSort]*1) return -1;
			if (a.id > b.id) return 1;
			if (a.id < b.id) return -1;
			return 0;
		});

		/*let craftChains = crafts.map(craft => {
			return {
				time: craft.time,
				crafts: [craft]
			};
		});
		function addCraftsToChain() {
			crafts.forEach(craft => {
				craftChains.forEach(chain => {
					if (chain.time + craft.time <= 24) {
						chain.time += craft.time;
						chain.crafts.push(craft);
					}
				});
			})
		}
		addCraftsToChain();
		console.log(craftChains);*/
		//function solve(crafts)

		let craftRows = craftData.map(craft => {
			let ingredientSuffix = [];
			let ingredients = craft.ingredients.map((ingredient, i) => {
				let item = items.find(i => i.id === ingredient.ingredient);
				let category = itemCategories.find(c => c.id === item.category);
				if (granaryItems.indexOf(item.id) > -1) category = {name:'Granary'};
				if (category.name[0] !== 'M' && ingredientSuffix.indexOf(`[${category.name[0]}]`) === -1) ingredientSuffix.push(`[${category.name[0]}]`);
				return <div key={i}>{ingredient.amount} {category.name[0] !== 'M' && `[${category.name[0]}] `}{item.name}</div>
			});
			return <tr className="MountRow" key={craft.id}>
				{ /*<td><input type="checkbox" key={`included${craft.id}`} checked={this.state.craftsIncluded[craft.id]} data-craftid={craft.id} onChange={this.onCraftIncluded} /></td>*/ }
				<td><strong>{craft.name} {ingredientSuffix}</strong><br />{craft.themes.join(', ')}<br />{craft.time} hrs, Rank: {craft.unlockRank}, Base Value: {craft.value}</td>
				<td className="MountRow-Center">
					{this.state.isEditingPopularity ? <select value={this.state.craftsPopularity[craft.id]} data-craftid={craft.id} onChange={this.onCraftPopularity}>
						<option value="140">Very High</option>
						<option value="120">High</option>
						<option value="100">Average</option>
						<option value="80">Low</option>
					</select> : <span dangerouslySetInnerHTML={{__html:popularityTexts[this.state.craftsPopularity[craft.id]]}} />}
				</td>
				<td className="MountRow-Center">
					{this.state.isEditingSupply ? <select value={this.state.craftsSupply[craft.id]} data-craftid={craft.id} onChange={this.onCraftSupply}>
						<option value="60">4 Overflowing</option>
						<option value="80">3 Surplus</option>
						<option value="100">2 Sufficient</option>
						<option value="130">1 Insufficient</option>
						<option value="160">0 Nonexistent</option>
					</select> : <span dangerouslySetInnerHTML={{__html:supplyTexts[this.state.craftsSupply[craft.id]]}} />}
				</td>
				<td className="MountRow-Center">
					{this.state.isEditingDS ? <select value={this.state.craftsDS[craft.id]} data-craftid={craft.id} onChange={this.onCraftDS}>
						<option value="0">2 Skyrocketing</option>
						<option value="1">1 Increasing</option>
						<option value="2">0 None</option>
						<option value="3">-1 Decreasing</option>
						<option value="4">-2 Plummeting</option>
					</select> : <span dangerouslySetInnerHTML={{__html:dsTexts[this.state.craftsDS[craft.id]]}} />}
				</td>

				{ /*<td>{ingredients}</td>*/ }
				<td>Value: {craft.finalValue}<br />
					{(craft.valuePerTime).toFixed(1)}/hr<br />
					{craft.limitedMats ? (craft.finalValue/craft.limitedMats).toFixed(1)+'/mat' : ''}</td>
				<td className="MountRow-Center" dangerouslySetInnerHTML={{__html:craft.tomorrowSupply.length > 1 ? supplyTexts[130100] : supplyTexts[craft.tomorrowSupply[0]]}} />
				{/* craft.tomorrowValues.length > 1 ? <td dangerouslySetInnerHTML={{__html:craft.tomorrowValues.join(' or ')}} /> : <td>{craft.tomorrowAvgValue}</td> */}
				<td>{craft.tomorrowValues.length > 1 ? `Value: ${craft.tomorrowValues[0]}-${craft.tomorrowValues[1]}` : `Value: ${craft.tomorrowValues[0]}`}<br />
					{(craft.tomorrowValuePerTime).toFixed(1)}/hr<br />
					{craft.limitedMats ? (craft.tomorrowAvgValue/craft.limitedMats).toFixed(1)+'/mat' : ''}</td>
			</tr>;
		});
		return (
			<div className="PageContainer-Fixed">
				<div className="SideMenu">
					<p>&nbsp;</p>
					<h3>Island Sanctuary</h3>
					<a href={`/islandworkshop/`} className={`current`} key="islandworkshop">Workshop</a>
					<a href={`/islands/`} className={``} key="islands">Animals</a>
				</div>
				<div className="PageContent PageContent-WithMenu">
					<div className="InfoBox"><div>Calculations should be accurate now; UI is still a work in progress.</div></div>
					<form>
						{ /*<select onChange={this.onCraftSelect}>
							{selectOptions}
						</select>*/ }
						<div style={{display:'flex',justifyContent:'space-between'}}>
							{ /*<div>
								<label>
									Popularity
									<select value={this.state.popularity} onChange={this.onPopularity}>
										<option value="140">Very High</option>
										<option value="120">High</option>
										<option value="100">Average</option>
										<option value="80">Low</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Supply
									<select value={this.state.supply} onChange={this.onSupply}>
										<option value="60">Overflowing</option>
										<option value="80">Surplus</option>
										<option value="100">Sufficient</option>
										<option value="130">Insufficient</option>
										<option value="160">Nonexistent</option>
									</select>
								</label>
							</div>*/ }
							<div>
								<label>
									Workshop Rank
									<select value={this.state.workshopRank} onChange={this.onWorkshopRank}>
										<option value="1">I</option>
										<option value="2">II</option>
										<option value="3">III</option>
									</select>
								</label>
							</div>
							<div>
								<label>
									Island Rank
									<input type="number" value={this.state.islandRank === 0 ? '' : this.state.islandRank} min="1" max="10" onChange={this.onIslandRank} />
								</label>
							</div>
							<div>
								<label>
									Groove
									<input type="number" value={this.state.groove === 0 ? '' : this.state.groove} min="0" max="35" onChange={this.onGroove} />
								</label>
							</div>
							<div>
								<label>Sort by
									<select value={this.state.tableSort} onChange={this.onTableSort}>
										<option value="id">In-Game UI</option>
										<option value="value">Base Value (today)</option>
										<option value="finalValue">Final Value (today)</option>
										<option value="valuePerTime">Value/Time (today)</option>
										<option value="valuePerLimitedMat">Value/LimitedMat (today)</option>
										<option value="tomorrowAvgValue">Final Value (tomorrow)</option>
										<option value="tomorrowValuePerTime">Value/Time (tomorrow)</option>
										<option value="tomorrowValuePerLimitedMat">Value/LimitedMat (tomorrow)</option>
									</select>
								</label>
							</div>
						</div>
						<p><strong>Workshop rank:</strong> Is measured at daily reset. Finalizing a workshop upgrade will not take effect until the next day.</p>
						<p><strong>Groove:</strong> Is measured at the time the craft finishes, and increments by 1 each time at the start of each efficient craft.</p>
						<p><strong>Popularity:</strong> Updates once per week, at weekly reset, and is the same for everybody. You can see next week's popularity in the last column of the Supply &amp; Demand window in-game.</p>
						<p><strong>Supply:</strong> Updates daily for all items, and also for an individual item when you finish a craft. Is measured at the time the craft finishes. Is the same for everyone initially, but affected by your own crafts.</p>
						<p><strong>Demand Shift:</strong> Shows change in supply from yesterday to today. Can be used to predict future supply, but is impacted by your own crafts as well.</p>
						<p><strong>[L]</strong> Leavings &mdash; <strong>[P]</strong> Produce &mdash; <strong>[G]</strong> Granary</p>
						<p>Note: Tomorrow's supply and value predictions are <strong>estimates</strong>. They are usually accurate, but can differ, especially if you have crafted any of that item already during the current season.</p>
						{/*selectedCraft*/}
					</form>
					<p>&nbsp;</p>
					<table className="MountTable">
						<thead><tr>
							{ /*<th>Use</th>*/ }
							<th>Item</th>
							<th>Popularity<br /><label><input type="checkbox" checked={this.state.isEditingPopularity} onChange={this.onEdit} data-column="popularity" /> Edit</label></th>
							<th>Supply<br /><label><input type="checkbox" checked={this.state.isEditingSupply} onChange={this.onEdit} data-column="supply" /> Edit</label></th>
							<th>Demand Shift<br /><label><input type="checkbox" checked={this.state.isEditingDS} onChange={this.onEdit} data-column="ds" /> Edit</label></th>
							{ /*<th>Ingredients</th>*/ }
							<th>Today<br />Value</th>
							<th>Tomorrow<br />Supply</th>
							<th>Tomorrow<br />Value</th>
						</tr></thead>
						<tbody>
							{craftRows}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default IslandsRoute;