import EventEmitter from 'eventemitter3';
import auth0 from'../auth0';
let dataCenters = require('../gamedata/json/dataCenters');

let ee = new EventEmitter();
let favoriteChars = [];
let userChars = [];
let userSettings = {};

function addWorldNameToChar(char) {
    //console.log(char);
    dataCenters.find(dc => {
        return dc.Worlds.find(world => {
            if (world.Id === char.worldId) {
                char.worldName = world.Name;
                char.dcName = dc.Name;
                return true;
            }
        });
    });
    return char;
}
function init() {
    let user = auth0.getUser();
    if (user) {
    //if (user && user.email_verified) {
        auth0.fetch('/api/user/favoritechars', {
            method: 'GET'
        }).then(f => f.json()).then(f => {
            favoriteChars = f.map(addWorldNameToChar);
            ee.emit('change');
            //console.log('favoriteChars', favoriteChars);
        });
        auth0.fetch('/api/user/chars', {
            method: 'GET'
        }).then(f => f.json()).then(f => {
            userChars = f.map(addWorldNameToChar);
            ee.emit('change');
            //console.log('userChars', userChars);
        });
        auth0.fetch('/api/user/settings', {
            method: 'GET'
        }).then(f => f.json()).then(f => {
            userSettings = f;
            ee.emit('change');
            //console.log('userSettings', userSettings);
        });
    } else {
        favoriteChars = [];
        userChars = [];
    }
}

auth0.on(init);

init();

export default {
    on(listener, context) {
        ee.on('change', listener, context);
    },
    off(listener, context) {
        ee.off('change', listener, context);
    },
    getFavoriteChars() {
        return favoriteChars;
    },
    getUserChars() {
        return userChars;
    },
    getSettings() {
        return userSettings;
    },
    async toggleFavoriteChar(characterId) {
        let isFavorite = false;
        if (favoriteChars.find(c => c.characterId === characterId)) {
            isFavorite = true;
        }
        let res;
        if (isFavorite) {
            res = await auth0.fetch('/api/user/favoritechars/' + characterId, {method: 'DELETE'});
        } else {
            res = await auth0.fetch('/api/user/favoritechars/' + characterId, {method: 'POST'});
        }
        let favoriteChars1 = await res.json();
        favoriteChars = favoriteChars1.map(addWorldNameToChar);
        ee.emit('change');
    },
    async getCharVerificationCode(characterId) {
        let res = await auth0.fetch('/api/user/getverificationcode/' + characterId, {method: 'GET'});
        let code = await res.json();
        return code;
    },
    async verifyCode(characterId) {
        let res = await auth0.fetch('/api/user/verifycode/' + characterId, {method: 'GET'});
        let verified = await res.json();
        init();
        return verified.verified;
    },
    async setSetting(key, value) {

    },
    getUser: auth0.getUser
}
