function componentFetch(stateName, fetchUrl) {
	return fetch(fetchUrl).then((res) => {
		if (res.status === 500) {
			throw new Error('500');
		} else if (res.status === 404) {
			throw new Error('404');
		}
		return res.json();
	}).then(data => {
		//let rankings = res.json();
		let stateObj = {
			loading: false,
			bgloading: false,
			status: data.status
		};
		if (data.deployTime > window.LALA_DEPLOY_TIME) {
			return window.location.reload();
		}
		stateObj[stateName] = data;
		this.setState(stateObj);
	}).catch(err => {
		if (err.message === '500') {
			this.setState({
				loading: false,
				bgloading: false,
				status: 'serverError'
			});
		} else if (err.message === '404') {
			this.setState({
				loading: false,
				bgloading: false,
				status: 'notFound'
			});
		} else {
			this.setState({
				loading: false,
				bgloading: false,
				status: 'unknownError'
			});
			console.error(err);
		}
	});
}

module.exports = componentFetch;
