import React, {Component} from 'react';
let componentFetch = require('../helpers/componentFetch');
let dataCenters = require('../gamedata/json/dataCenters.json');

class HomeRoute extends Component {
	state = {
		lalaResults: {results: []},
		lodeResults: {results: []},
		searchText: '',
		what: 'lalachievements',
		typingTimeout: 0,
		loading: false,
		didLoadLala: false
	};
	constructor(props) {
		super(props);
		this.fetch = componentFetch.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.searchLodestone = this.searchLodestone.bind(this);
	}
	searchLodestone(e) {
		e.preventDefault();
		this.setState({loading: true, lodeResults: {results: []}});
		this.fetch('lodeResults', '/api/lodestonesearch/' + this.state.searchText + '/');
	}
	onSearchChange(e) {
		if (this.state.typingTimeout) {
			clearTimeout(this.state.typingTimeout);
		}
		this.setState({
			searchText: e.target.value,
			loading: true,
			lalaResults: {results: []},
			lodeResults: {results: []},
			typingTimeout: setTimeout(() => {
				if (this.state.searchText.length > 3) {
					this.fetch('lalaResults', '/api/charsearch/' + this.state.searchText + '/').then(() => {
						this.setState({didLoadLala: true});
					});
				} else {
					this.setState({loading: false});
				}
			}, 1000)
		});
	}
	render() {
		let lang = this.props.lang, urlBase = this.props.urlBase;
		let lalaCharRows = [], lodeCharRows = [], loading;
		if (this.state.loading) {
			loading = <div className="InfoBox InfoBox-Error"><div>Loading...</div></div>;
		} else if (this.state.status === 'serverError') {
			if (this.state.what === 'lalachievements') {
				loading = <div className="InfoBox InfoBox-Error"><div>An error occurred during character search.</div></div>;
			} else {
				loading = <div className="InfoBox InfoBox-Error"><div>An error occurred during character search. Lodestone is probably down for maintenance.</div></div>;
			}
		} else {
			loading = <div className="InfoBox"><div>&nbsp;</div></div>;
		}
		for (let char of this.state.lalaResults.results) {
			let dataCenter = 'unknown';
			let world = 'unknown';
			let iconUrl, name, id;
			iconUrl = char.iconUrl;
			name = char.name;
			id = char.id;
			dataCenters.forEach(dc => {
				dc.Worlds.forEach(w => {
					if (w.Id === char.worldId) {
						world = w.Name;
						dataCenter = dc.Name;
					}
				});
			});
			lalaCharRows.push(<tr className="RankingRow">
				<td className="RankingRow-Icon"><a href={`${urlBase}char/${id}/`}><img src={iconUrl} alt=""/></a></td>
				<td className="RankingRow-Text">
					<a href={`${urlBase}char/${id}/`} className="RankingRow-Name">{name}</a>
					<div className="RankingRow-World">{dataCenter} / {world}</div>
				</td>
			</tr>);
		}
		for (let char of this.state.lodeResults.results) {
			let dataCenter = 'unknown';
			let world = 'unknown';
			let iconUrl, name, id;
			iconUrl = char.iconUrl;
			name = char.name;
			id = char.id;
			world = char.worldName.split('(')[0].trim();
			dataCenters.forEach(dc => {
				dc.Worlds.forEach(w => {
					if (w.Name === world) {
						world = w.Name;
						dataCenter = dc.Name;
					}
				});
			});
			lodeCharRows.push(<tr className="RankingRow">
				<td className="RankingRow-Icon"><a href={`${urlBase}char/${id}/`}><img src={iconUrl} alt=""/></a></td>
				<td className="RankingRow-Text">
					<a href={`${urlBase}char/${id}/`} className="RankingRow-Name">{name}</a>
					<div className="RankingRow-World">{dataCenter} / {world}</div>
				</td>
			</tr>);
		}
		return (
			<div className="PageContainer-Center PageContent">
				<div className="ImageBox">
					<img src="/images/search.png" alt="" />
					<div>
						<h2>Lalachievements Character Search</h2>
						<label>Character Name: <input type="text" value={this.state.searchText} onChange={this.onSearchChange} /></label>
						<table className="RankingTable">
							<tbody>
							{lalaCharRows}
							</tbody>
						</table>
						{loading}
						{this.state.didLoadLala ? <div><h2>Lodestone Character Search</h2>
						<p>If the above search does not find the character, it may be new to Lalachievements, and you can try searching Lodestone instead:</p>
						<button onClick={this.searchLodestone} disabled={this.state.loading}>Search Lodestone</button>
						<table className="RankingTable">
							<tbody>
							{lodeCharRows}
							</tbody>
						</table></div> : <div></div>}
					</div>
				</div>
			</div>
		);
	}
}

export default HomeRoute;