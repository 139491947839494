import React, {PureComponent} from 'react';

export default class Pagination extends PureComponent {
    constructor(props) {
        super(props);
        this.onFirst = this.onFirst.bind(this);
        this.onLast = this.onLast.bind(this);
        this.onClick = this.onClick.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
    }
    onFirst() {
        this.props.onChange(1);
    }
    onLast() {
        this.props.onChange(Math.ceil(this.props.length/250))
    }
    onClick(e) {
        this.props.onChange(e.target.innerHTML*1);
    }
    render() {
        let buttons = [];
        for (let i = 1; i <= Math.ceil(this.props.length/250); i++) {
            if (this.props.current === i) {
                buttons.push(<button key={i} className="current">{i}</button>);
            } else {
                buttons.push(<button key={i} onClick={this.onClick}>{i}</button>);
            }
        }
        return <div className="PaginationContainer">
            {this.props.current !== 1 ? <button onClick={this.onFirst}><i className="fontello-left-open" /></button> : <button disabled><i className="fontello-left-open" /></button>}
            <div className="PaginationContainer-Pages">{buttons}</div>
            {this.props.current !== Math.ceil(this.props.length/250) ? <button onClick={this.onLast}><i className="fontello-right-open" /></button> : <button disabled><i className="fontello-right-open" /></button>}
        </div>;
    }
}
