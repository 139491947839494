import React, {Component} from 'react';
//import {useQuery, useApolloClient, useSubscription} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import '../../gamedata/spritesheet/achievement.css';
import Fuse from "fuse.js";
let urlify = require('urlify').create();

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let achievementCategories = require('../../gamedata/json/en/achievementCategories.json');
//let unobtainableAchievements = require('../../data/unobtainableAchievements');

let store = require('../../stores/gameData').default;

let fuseOptions = {
    title: {
        keys: ['Title.Masculine', 'Title.Feminine', 'AchievementKind', 'AchievementCategory'],
        id: 'Id',
        minMatchCharLength: 3,
        threshold: 0.2
    }
};
let fuse = new Fuse([], fuseOptions.title);

function makeState() {
    let achievementCategories = store.get('achievementCategories');
    let titles = store.get('titles');
    let achievements = store.get('achievements').filter(a => a.titleId).map(a => {
        return Object.assign({}, a, {
            title: titles.find(t => t.id === a.titleId)
        });
    }).filter(a => a.title);
    return {
        achievements: achievements,
        achievementCategories: achievementCategories,
        titles: titles
    }
}

export default class CharTitlesRoute extends Component {
    state = Object.assign({
        searchText: ''
    }, makeState());
    constructor(props) {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this);
        store.on(this.onChange, this);
    }
    componentWillUnmount() {
        store.off(this.onChange, this);
    }
    onSearchChange(e) {
        this.setState({searchText: e.target.value});
    }
    onChange() {
        let newState = makeState();
        this.setState(newState);
        fuse.setCollection(newState.achievements);
    }
    render() {
        let char = this.props.char, lang = this.props.lang, urlBase = this.props.urlBase;//, achievements = this.props.achievements, achievementKinds = this.props.achievementKinds;
        //let obtained = [], extra = [], remaining = [], unobtainable = [];*/
        let currentKindSlug = this.props.url[0];

        let achievements = this.state.achievements;
        let achievementCategories = this.state.achievementCategories;
        //let titles = store.get('titles');
        let currentKind = achievementCategories.find(k => k.Slug === currentKindSlug);
        if (!currentKind) currentKind = achievementCategories[0];

        //let currentCategorySlug = this.props.url[4], currentCategory;
        let kinds = achievementCategories.map(function(kind) {
            //let kind = achievementCategories[kindName];
            //let kindName = kind.Name;
            //let slug = kind.Slug;
            let current = kind === currentKind;
            //let charKind = char.achievementKinds[kindName];
            let hasCount = char.id > 0 ? char.achievementKinds[kind.Id].titles : 0; //0;
            let totalCount = char.id > 0 ? char.achievementKinds[kind.Id].titlesPossible : 0; //0;
            /*achievements.filter(a => {
                if (a.Title.Id === 0) return false;
                return a.AchievementKind === kindName;
            }).forEach(a => {
                let has = char.id !== '-' && char.achievements.findIndex(ca => ca.id === a.Id) > -1;
                let obtainable = !unobtainableAchievements.includes(a.Id) && a.AchievementCategory !== 'Seasonal Events' && a.AchievementKind !== 'Legacy';
                if (has && obtainable) hasCount++;
                if (obtainable) totalCount++;
            });*/
            let pointsPercentKind = Math.floor((hasCount * 100) / totalCount);
            let titleKind = hasCount + ' / ' + totalCount + ' (' + pointsPercentKind + '%)';
            if (current) {
                //currentKind = kind;
            }
            //console.log(currentKindSlug, slug, slug === currentKindSlug, current, currentKind);
            return <a href={`${urlBase}titles/${kind.Slug}/`} className={current?'current':''} key={kind.Name} title={titleKind}>
                {kind.Name}
                {char.id > 0 ? <span className="FilterProgressContainer"><span className="FilterProgressInner" style={{width:pointsPercentKind+'%'}}>&nbsp;</span></span> : ''}
            </a>;
        });
        //return <p>{currentKind}</p>
        let currentCategories = currentKind ? currentKind.Categories.map(c => c.Id) : [];

        //let hasCount = 0;
        let titleRowsHas = [];
        let titleRowsRemaining = [];
        let titleRowsUnobtainable = [];
        let searchResults = fuse.search(this.state.searchText);
        achievements.filter(a => {
            if (this.state.searchText.length > 1 && searchResults.indexOf(a.id+'') === -1) return false;
            if (a.title.id === 0) return false;
            if (!currentKind) return true;
            return currentCategories.indexOf(a.achievementCategoryId) > -1;
        }).sort((a,b)=> {
            if (a.title.feminine < b.title.feminine) {return -1;}
            if (a.title.feminine > b.title.feminine) {return 1;}
            return 0;
        }).forEach(a => {
            let url = `${urlBase}titles/${currentKind.Slug}/${a.id}`;
            let has = char.id > 0 && char.achievements.find(ca => ca.id === a.id);
            //let obtainable = !unobtainableAchievements.includes(a.id) && a.AchievementCategory !== 'Seasonal Events' && a.AchievementKind !== 'Legacy';
            let obtainable = a.obtainable;
            let className = 'TimelineRow';
            let hasDate;
            if (has) {
                className += ' TimelineRow-Have';
                //hasCount++;
                hasDate = new Date(has.date*1000).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
            //let kind = achievementCategories.find(k => k.Id === a.AchievementKind);
            //let category = kind ? kind.Categories.find(c => c.Id === a.AchievementCategory) : {};
            let kind, category;
            kind = achievementCategories.find(k => {
                category = k.Categories.find(c => c.Id === a.achievementCategoryId);
                return category;
            });
            let row = <tr className={className} key={a.id}>
                <td className="TimelineRow-Check"><i className="fontello-ok" /></td>
                <td className="TimelineRow-Icon"><a href={url}><span className={`icon icon-achievement icon-achievement-${a.icon}`} /></a></td>
                <td className="TimelineRow-Text">
                    <a href={url} className="">{a.title.feminine}</a>
                    { /*<div>{a.AchievementKind} / {a.AchievementCategory} / {a.Name}</div>
                    <div dangerouslySetInnerHTML={{__html:a.Description}} />*/ }
                </td>
                <td className="TimelineRow-Text">{kind.Name} / {category.Name}</td>
                <td className="TimelineRow-Value">{hasDate}</td>
            </tr>;
            if (obtainable && has) {
                titleRowsHas.push(row);
            } else if (obtainable) {
                titleRowsRemaining.push(row);
            } else {
                titleRowsUnobtainable.push(row);
            }
        });

        return <div>

            {char.id > 0 ? <div className="CompletionSummaryContainer">
                <div className="CompletionSummary">
                    <h3>Titles</h3>
                    <p className="CompletionSummary-Percent">{Math.floor(char.titleCount*100/char.titlePossible)}%</p>
                    <p className="CompletionSummary-Numbers">{char.titleCount} / {char.titlePossible}</p>
                    <p className="CompletionSummary-Remaining">({char.titlePossible - char.titleCount} remaining)</p>
                </div>
            </div> : <div className="CompletionSummaryContainer">
                <div className="CompletionSummary">
                    <h3>Titles</h3>
                </div>
            </div>}

            <div className="FilterBox">
                <h2>Kind</h2>
                {kinds}
            </div>
            <label>Search: <input type="text" value={this.state.searchText} onChange={this.onSearchChange} /></label>
            <div><h2>{titleRowsRemaining.length} titles {char.id > 0 ? 'left to obtain' : ''}</h2>
            <table className="TimelineTable">
                <tbody>{titleRowsRemaining}</tbody>
            </table></div>
            {titleRowsHas.length > 0 ? <div><h2>{titleRowsHas.length} titles obtained</h2>
            <table className="TimelineTable">
                <tbody>{titleRowsHas}</tbody>
            </table></div> : ''}
            {titleRowsUnobtainable.length > 0 ? <div><h2>{titleRowsUnobtainable.length} titles can no longer be obtained</h2>
            <table className="TimelineTable">
                <tbody>{titleRowsUnobtainable}</tbody>
            </table></div> : ''}
        </div>;

    }
}
