import React from "react";
import Leaflet from "leaflet";
import {ImageOverlay, MapContainer, Marker, Tooltip} from "react-leaflet";


function DetailMap(props) {
    let npc = props.npc;
    if (!npc || !npc.id || !npc.x || !npc.z || !npc.mapId) return <div />;

    let sizeFactor = 1;
    if (npc.sizeFactor > 0) sizeFactor = npc.sizeFactor / 100;
    let mapx = (npc.x-1)*(sizeFactor)/2;
    let mapy = -(npc.z-1)*(sizeFactor)/2;
    let key = `${npc.id}${npc.mapId}${npc.x}${npc.z}${sizeFactor}`;

    let name;
    if (props.href) {
        name = <a href={props.href}><strong>{npc.name}</strong></a>;
    } else {
        name = <strong>{npc.name}</strong>;
    }

    return <MapContainer key={key} bounds={new Leaflet.LatLngBounds([-500, -500], [500, 50])} center={[mapy, mapx]} zoom={0} minZoom={-1} maxZoom={2} scrollWheelZoom={false} crs={Leaflet.CRS.Simple} className="smallmap">
        <ImageOverlay
            url={`/gamedata/map_webp/${npc.mapId}.webp`}
            bounds={new Leaflet.LatLngBounds([-500, -500], [500, 500])}
        />
        <Marker position={[mapy, mapx]}>
            <Tooltip permanent>
                {name}<br />
                {props.placeName && props.placeName.name}<br />
                {npc.displayX}, {npc.displayY}
            </Tooltip>
        </Marker>
    </MapContainer>;
}
export default DetailMap;