import React, {Component} from "react";

var eightEorzianHours = 8 * 175 * 1000;

var BELL_COUNT = 64; //432;

var weatherFinder = {

	getWeather(timeMillis, zone) {
		return this.weatherChances[zone](this.calculateForecastTarget(timeMillis));
	},

	calculateForecastTarget: function(timeMillis) {
		// Thanks to Rogueadyn's SaintCoinach library for this calculation.
		// lDate is the current local time.

		var unixSeconds = parseInt(timeMillis / 1000);
		// Get Eorzea hour for weather start
		var bell = unixSeconds / 175;

		// Do the magic 'cause for calculations 16:00 is 0, 00:00 is 8 and 08:00 is 16
		var increment = (bell + 8 - (bell % 8)) % 24;

		// Take Eorzea days since unix epoch
		var totalDays = unixSeconds / 4200;
		totalDays = (totalDays << 32) >>> 0; // Convert to uint

		// 0x64 = 100
		var calcBase = totalDays * 100 + increment;

		// 0xB = 11
		var step1 = ((calcBase << 11) ^ calcBase) >>> 0;
		var step2 = ((step1 >>> 8) ^ step1) >>> 0;

		// 0x64 = 100
		return step2 % 100;
	},

	getEorzeaHour: function(timeMillis) {
		var unixSeconds = parseInt(timeMillis / 1000);
		// Get Eorzea hour
		var bell = (unixSeconds / 175) % 24;
		return Math.floor(bell);
	},

	getWeatherTimeFloor: function(date) {
		var unixSeconds = parseInt(date.getTime() / 1000);
		// Get Eorzea hour for weather start
		var bell = (unixSeconds / 175) % 24;
		var startBell = bell - (bell % 8);
		var startUnixSeconds = unixSeconds - (175 * (bell - startBell));
		return new Date(startUnixSeconds * 1000);
	},

	weatherChances: {
		"Limsa Lominsa": function(chance) { if (chance < 20) { return "Clouds"; } else if (chance < 50) { return "Clear Skies"; } else if (chance < 80) { return "Fair Skies"; } else if (chance < 90) { return "Fog"; } else { return "Rain"; } },
		"Middle La Noscea": function(chance) { if (chance < 20) { return "Clouds"; } else if (chance < 50) { return "Clear Skies"; } else if (chance < 70) { return "Fair Skies"; } else if (chance < 80) { return "Wind"; } else if (chance < 90) { return "Fog"; } else { return "Rain"; } },
		"Lower La Noscea": function(chance) { if (chance < 20) { return "Clouds"; } else if (chance < 50) { return "Clear Skies"; } else if (chance < 70) { return "Fair Skies"; } else if (chance < 80) { return "Wind"; } else if (chance < 90) { return "Fog"; } else { return "Rain"; } },
		"Eastern La Noscea": function(chance) { if (chance < 5) { return "Fog"; } else if (chance < 50) { return "Clear Skies"; } else if (chance < 80) { return "Fair Skies"; } else if (chance < 90) { return "Clouds"; } else if (chance < 95) { return "Rain"; } else { return "Showers"; } },
		"Western La Noscea": function(chance) { if (chance < 10) { return "Fog"; } else if (chance < 40) { return "Clear Skies"; } else if (chance < 60) { return "Fair Skies"; } else if (chance < 80) { return "Clouds"; } else if (chance < 90) { return "Wind"; } else { return "Gales"; } },
		"Upper La Noscea": function(chance) { if (chance < 30) { return "Clear Skies"; } else if (chance < 50) { return "Fair Skies"; } else if (chance < 70) { return "Clouds"; } else if (chance < 80) { return "Fog"; } else if (chance < 90) { return "Thunder"; } else { return "Thunderstorms"; } },
		"Outer La Noscea": function(chance) { if (chance < 30) { return "Clear Skies"; } else if (chance < 50) { return "Fair Skies"; } else if (chance < 70) { return "Clouds"; } else if (chance < 85) { return "Fog"; } else { return "Rain"; } },
		"Mist": function(chance) { if (chance < 20) { return "Clouds"; } else if (chance < 50) { return "Clear Skies"; } else if (chance < 70) { return "Fair Skies"; } else if (chance < 80) { return "Fair Skies"; } else if (chance < 90) { return "Fog"; } else { return "Rain"; } },
		"Gridania": function(chance) { if (chance < 5) { return "Rain"; } else if (chance < 20) { return "Rain"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 55) { return "Fair Skies"; } else if (chance < 85) { return "Clear Skies"; } else { return "Fair Skies"; } },
		"Central Shroud": function(chance) { if (chance < 5) { return "Thunder"; } else if (chance < 20) { return "Rain"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 55) { return "Fair Skies"; } else if (chance < 85) { return "Clear Skies"; } else { return "Fair Skies"; } },
		"East Shroud": function(chance) { if (chance < 5) { return "Thunder"; } else if (chance < 20) { return "Rain"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 55) { return "Fair Skies"; } else if (chance < 85) { return "Clear Skies"; } else { return "Fair Skies"; } },
		"South Shroud": function(chance) { if (chance < 5) { return "Fog"; } else if (chance < 10) { return "Thunderstorms"; } else if (chance < 25) { return "Thunder"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 70) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"North Shroud": function(chance) { if (chance < 5) { return "Fog"; } else if (chance < 10) { return "Showers"; } else if (chance < 25) { return "Rain"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 70) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"The Lavender Beds": function(chance) { if (chance < 5) { return "Clouds"; } else if (chance < 20) { return "Rain"; } else if (chance < 30) { return "Fog"; } else if (chance < 40) { return "Clouds"; } else if (chance < 55) { return "Fair Skies"; } else if (chance < 85) { return "Clear Skies"; } else { return "Fair Skies"; } },
		"Ul'dah": function(chance) { if (chance < 40) { return "Clear Skies"; } else if (chance < 60) { return "Fair Skies"; } else if (chance < 85) { return "Clouds"; } else if (chance < 95) { return "Fog"; } else { return "Rain"; } },
		"Western Thanalan": function(chance) { if (chance < 40) { return "Clear Skies"; } else if (chance < 60) { return "Fair Skies"; } else if (chance < 85) { return "Clouds"; } else if (chance < 95) { return "Fog"; } else { return "Rain"; } },
		"Central Thanalan": function(chance) { if (chance < 15) { return "Dust Storms"; } else if (chance < 55) { return "Clear Skies"; } else if (chance < 75) { return "Fair Skies"; } else if (chance < 85) { return "Clouds"; } else if (chance < 95) { return "Fog"; } else { return "Rain"; } },
		"Eastern Thanalan": function(chance) { if (chance < 40) { return "Clear Skies"; } else if (chance < 60) { return "Fair Skies"; } else if (chance < 70) { return "Clouds"; } else if (chance < 80) { return "Fog"; } else if (chance < 85) { return "Rain"; } else { return "Showers"; } },
		"Southern Thanalan": function(chance) { if (chance < 20) { return "Heat Waves"; } else if (chance < 60) { return "Clear Skies"; } else if (chance < 80) { return "Fair Skies"; } else if (chance < 90) { return "Clouds"; } else { return "Fog"; } },
		"Northern Thanalan": function(chance) { if (chance < 5) { return "Clear Skies"; } else if (chance < 20) { return "Fair Skies"; } else if (chance < 50) { return "Clouds"; } else { return "Fog"; } },
		"The Goblet": function(chance) { if (chance < 40) { return "Clear Skies"; } else if (chance < 60) { return "Fair Skies"; } else if (chance < 85) { return "Clouds"; } else if (chance < 95) { return "Fog"; } else { return "Rain"; } },
		"Mor Dhona": function(chance) {if (chance < 15) {return "Clouds";}  else if (chance < 30) {return "Fog";}  else if (chance < 60) {return "Gloom";}  else if (chance < 75) {return "Clear Skies";}  else {return "Fair Skies";}},
		"Ishgard": function(chance) {if (chance < 60) {return "Snow";}  else if (chance < 70) {return "Fair Skies";}  else if (chance < 75) {return "Clear Skies";}  else if (chance < 90) {return "Clouds";}  else {return "Fog";}},
		"Coerthas Central Highlands": function(chance) {if (chance < 20) {return "Blizzards";}  else if (chance < 60) {return "Snow";}  else if (chance < 70) {return "Fair Skies";}  else if (chance < 75) {return "Clear Skies";}  else if (chance < 90) {return "Clouds";}  else {return "Fog";}},
		"Coerthas Western Highlands": function(chance) {if (chance < 20) {return "Blizzards";}  else if (chance < 60) {return "Snow";}  else if (chance < 70) {return "Fair Skies";}  else if (chance < 75) {return "Clear Skies";}  else if (chance < 90) {return "Clouds";}  else {return "Fog";}},
		"The Sea of Clouds": function(chance) {if (chance < 30) {return "Clear Skies";}  else if (chance < 60) {return "Fair Skies";}  else if (chance < 70) {return "Clouds";}  else if (chance < 80) {return "Fog";}  else if (chance < 90) {return "Wind";}  else {return "Umbral Wind";}},
		"Azys Lla": function(chance) {if (chance < 35) {return "Fair Skies";}  else if (chance < 70) {return "Clouds";}  else {return "Thunder";}},
		"The Dravanian Forelands": function(chance) {if (chance < 10) {return "Clouds";}  else if (chance < 20) {return "Fog";}  else if (chance < 30) {return "Thunder";}  else if (chance < 40) {return "Dust Storms";}  else if (chance < 70) {return "Clear Skies";}  else {return "Fair Skies";}},
		"The Dravanian Hinterlands": function(chance) {if (chance < 10) {return "Clouds";}  else if (chance < 20) {return "Fog";}  else if (chance < 30) {return "Rain";}  else if (chance < 40) {return "Showers";}  else if (chance < 70) {return "Clear Skies";}  else {return "Fair Skies";}},
		"The Churning Mists": function(chance) {if (chance < 10) {return "Clouds";}  else if (chance < 20) {return "Gales";}  else if (chance < 40) {return "Umbral Static";}  else if (chance < 70) {return "Clear Skies";}  else {return "Fair Skies";}},
		"Idyllshire": function(chance) {if (chance < 10) {return "Clouds";}  else if (chance < 20) {return "Fog";}  else if (chance < 30) {return "Rain";}  else if (chance < 40) {return "Showers";}  else if (chance < 70) {return "Clear Skies";}  else {return "Fair Skies";}},
		// Data format changed from aggregate to marginal breakpoints
		"Rhalgr's Reach": function(chance) { if ((chance -= 15) < 0) { return "Clear Skies"; } else if ((chance -= 45) < 0) { return "Fair Skies"; } else if ((chance -= 20) < 0) { return "Clouds"; } else if ((chance -= 10) < 0) { return "Fog"; } else { return "Thunder"; } },
		"The Fringes": function(chance) { if ((chance -= 15) < 0) { return "Clear Skies"; } else if ((chance -= 45) < 0) { return "Fair Skies"; } else if ((chance -= 20) < 0) { return "Clouds"; } else if ((chance -= 10) < 0) { return "Fog"; } else { return "Thunder"; } },
		"The Peaks": function(chance) { if ((chance -= 10) < 0) { return "Clear Skies"; } else if ((chance -= 50) < 0) { return "Fair Skies"; } else if ((chance -= 15) < 0) { return "Clouds"; } else if ((chance -= 10) < 0) { return "Fog"; } else if ((chance -= 10) < 0) { return "Wind"; } else { return "Dust Storms"; } },
		"The Lochs": function(chance) { if ((chance -= 20) < 0) { return "Clear Skies"; } else if ((chance -= 40) < 0) { return "Fair Skies"; } else if ((chance -= 20) < 0) { return "Clouds"; } else if ((chance -= 10) < 0) { return "Fog"; } else { return "Thunderstorms"; } },
		"Kugane": function(chance) { if ((chance -= 10) < 0) { return "Rain"; } else if ((chance -= 10) < 0) { return "Fog"; } else if ((chance -= 20) < 0) { return "Clouds"; } else if ((chance -= 40) < 0) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"The Ruby Sea": function(chance) { if ((chance -= 10) < 0) { return "Thunder"; } else if ((chance -= 10) < 0) { return "Wind"; } else if ((chance -= 15) < 0) { return "Clouds"; } else if ((chance -= 40) < 0) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"Yanxia": function(chance) { if ((chance -= 5) < 0) { return "Showers"; } else if ((chance -= 10) < 0) { return "Rain"; } else if ((chance -= 10) < 0) { return "Fog"; } else if ((chance -= 15) < 0) { return "Clouds"; } else if ((chance -= 40) < 0) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"The Azim Steppe": function(chance) { if ((chance -= 5) < 0) { return "Gales"; } else if ((chance -= 5) < 0) { return "Wind"; } else if ((chance -= 7) < 0) { return "Rain"; } else if ((chance -= 8) < 0) { return "Fog"; } else if ((chance -= 10) < 0) { return "Clouds"; } else if ((chance -= 40) < 0) { return "Fair Skies"; } else { return "Clear Skies"; } },
		"Eureka": function(chance) { if ((chance -= 30) < 0) { return "Fair Skies"; } else if ((chance -= 30) < 0) { return "Gales"; } else if ((chance -= 30) < 0) { return "Showers"; } else { return "Snow"; } },
		"Diadem": function(chance) { if ((chance -= 30) < 0) { return "Fair Skies"; } else if ((chance -= 30) < 0) { return "Fog"; } else if ((chance -= 30) < 0) { return "Wind"; } else { return "Umbral Wind"; } },
		"Island Sanctuary": function(chance) { if ((chance -= 25) < 0) { return "Clear Skies"; } else if ((chance -= 45) < 0) { return "Fair Skies"; } else if ((chance -= 10) < 0) { return "Clouds"; } else if ((chance -= 10) < 0) { return "Rain"; } else if ((chance -= 5) < 0) { return "Fog"; } else { return "Showers"; } }
	},

	weatherLists: {
		"Limsa Lominsa": ["Clouds","Clear Skies","Fair Skies","Fog","Rain"],
		"Middle La Noscea": ["Clouds","Clear Skies","Fair Skies","Wind","Fog","Rain"],
		"Lower La Noscea": ["Clouds","Clear Skies","Fair Skies","Wind","Fog","Rain"],
		"Eastern La Noscea": ["Fog","Clear Skies","Fair Skies","Clouds","Rain","Showers"],
		"Western La Noscea": ["Fog","Clear Skies","Fair Skies","Clouds","Wind","Gales"],
		"Upper La Noscea": ["Clear Skies","Fair Skies","Clouds","Fog","Thunder","Thunderstorms"],
		"Outer La Noscea": ["Clear Skies","Fair Skies","Clouds","Fog","Rain" ],
		"Mist": ["Clouds","Clear Skies","Fair Skies","Fog","Rain" ],
		"Gridania": ["Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"Central Shroud": ["Thunder","Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"East Shroud": ["Thunder","Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"South Shroud": ["Fog","Thunderstorms","Thunder","Clouds","Fair Skies","Clear Skies"],
		"North Shroud": ["Fog","Showers","Rain","Clouds","Fair Skies","Clear Skies"],
		"The Lavender Beds": ["Clouds","Rain","Fog","Fair Skies","Clear Skies"],
		"Ul'dah": ["Clear Skies","Fair Skies","Clouds","Fog","Rain"],
		"Western Thanalan": ["Clear Skies","Fair Skies","Clouds","Fog","Rain"],
		"Central Thanalan": ["Dust Storms","Clear Skies","Fair Skies","Clouds","Fog","Rain"],
		"Eastern Thanalan": ["Clear Skies","Fair Skies","Clouds","Fog","Rain","Showers"],
		"Southern Thanalan": ["Heat Waves","Clear Skies","Fair Skies","Clouds","Fog"],
		"Northern Thanalan": ["Clear Skies","Fair Skies","Clouds","Fog"],
		"The Goblet": ["Clear Skies","Fair Skies","Clouds","Fog","Rain"],
		"Mor Dhona": ["Clouds", "Fog", "Gloom", "Clear Skies", "Fair Skies"],
		"Ishgard": ["Snow", "Fair Skies", "Clear Skies", "Clouds", "Fog"],
		"Coerthas Central Highlands": ["Blizzards", "Snow", "Fair Skies", "Clear Skies", "Clouds", "Fog"],
		"Coerthas Western Highlands": ["Blizzards", "Snow", "Fair Skies", "Clear Skies", "Clouds", "Fog"],
		"The Sea of Clouds": ["Clear Skies", "Fair Skies", "Clouds", "Fog", "Wind", "Umbral Wind"],
		"Azys Lla": ["Fair Skies", "Clouds", "Thunder"],
		"The Dravanian Forelands": ["Clouds", "Fog", "Thunder", "Dust Storms", "Clear Skies", "Fair Skies"],
		"The Dravanian Hinterlands": ["Clouds", "Fog", "Rain", "Showers", "Clear Skies", "Fair Skies"],
		"The Churning Mists": ["Clouds", "Gales", "Umbral Static", "Clear Skies", "Fair Skies"],
		"Idyllshire": ["Clouds", "Fog", "Rain", "Showers", "Clear Skies", "Fair Skies"],
		"Rhalgr's Reach": ["Clear Skies","Fair Skies","Clouds","Fog","Thunder"],
		"The Fringes": ["Clear Skies","Fair Skies","Clouds","Fog","Thunder"],
		"The Peaks": ["Clear Skies","Fair Skies","Clouds","Fog","Wind","Dust Storms"],
		"The Lochs": ["Clear Skies","Fair Skies","Clouds","Fog","Thunderstorms"],
		"Kugane": ["Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"The Ruby Sea": ["Thunder","Wind","Clouds","Fair Skies","Clear Skies"],
		"Yanxia": ["Showers","Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"The Azim Steppe": ["Gales","Wind","Rain","Fog","Clouds","Fair Skies","Clear Skies"],
		"Eureka": ["Fair Skies", "Gales", "Showers", "Snow"],
		"Diadem": ["Fair Skies", "Fog", "Wind", "Umbral Wind"],
		"Island Sanctuary": ["Clear Skies", "Fair Skies", "Clouds", "Rain", "Fog", "Showers"]
	}
};

var timeUtils = {
	calculateCurrentEorziaTime() {
		/**
		 * Code to calculate eorzian times is from Garland Tools
		 */
		var localEpoch = (new Date()).getTime();
		return timeUtils.calculateEorziaTime(localEpoch);
	},
	calculateEorziaTime(timestamp) {
		var epoch = timeUtils.realToEorzia(timestamp);
		var minutes = parseInt((epoch / (1000 * 60)) % 60);
		var hours = parseInt((epoch / (1000 * 60 * 60)) % 24);
		//var timeString = this.formatTimeString(hours, minutes);
		return [hours, minutes];
		/*{
		 hours: hours,
		 minutes: minutes
		 };*/
	},
	calculateCurrentWeatherTimeFloor() {
		var localEpoch = (new Date()).getTime();
		var epoch = localEpoch * 20.571428571428573;
		var hours = (epoch / (1000 * 60 * 60)) % 24;
		var hoursFloor;
		if (hours >= 16) {
			hoursFloor = 16;
		} else if (hours >= 8) {
			hoursFloor = 8;
		} else {
			hoursFloor = 0;
		}
		var hoursDifferenceToFloor = hours - hoursFloor;
		var epochDifferenceToFloor = hoursDifferenceToFloor * 1000 * 60 * 60;
		return timeUtils.eorziaToReal(epoch - epochDifferenceToFloor) + 1;
	},
	eorziaToReal(timestamp) {
		return Math.ceil(timestamp / 20.571428571428573);
	},
	realToEorzia(timestamp) {
		return timestamp * 20.571428571428573;
	},
	formatTimeString(hours, minutes) {
		var timeString = '';
		if (hours === 0) {
			timeString += '12';
		} else if (hours > 12) {
			timeString += (hours - 12);
		} else {
			timeString += hours;
		}
		timeString += ':';
		if (minutes < 10) {
			timeString += '0';
		}
		timeString += minutes;
		if (hours >= 12) {
			timeString += ' PM';
		} else {
			timeString += ' AM';
		}
		return timeString;
	},
	formatTimeDifference(seconds) {
		seconds = Math.floor(seconds / 1000);
		if (seconds === 1) {
			return seconds + ' second';
		}
		if (seconds < 120) {
			return seconds + ' seconds';
		}
		var minutes = Math.floor(seconds / 60);
		if (minutes === 1) {
			return minutes + ' minute';
		}
		if (minutes < 61) {
			return minutes + ' minutes';
		}
		var hours = Math.floor(minutes / 60);
		minutes = minutes - (hours*60);
		if (hours < 25) {
			return hours + ' hours and ' + minutes + ' minutes';
		}
		var days = Math.floor(hours / 24);
		hours = hours - (days*24);
		return days + ' days and ' + hours + ' hours';
	},
	toLocaleString(date) {
		return date.toLocaleString('en-US', {
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit'
		});
	}
};

let storageFilter;
let defaultFilter = {
	'Black Chocobo': true,
	'Ornery Karakul': true,
	'Paissa': true,
	'Grand Buffalo': true,
	'Goobbue': true,
	'Gold Back': true,
	'Beachcomb': true,
	'Alligator': true,
	'Yellow Coblyn': true,
	'Twinklefleece': true,
	'Griffin': true,
	'Tiger of Paradise': true,
	'Morbol Seedling': true,
	'Weird Spriggan': true,
	'Boar of Paradise': true,
	'Funguar': true,
	'Alkonost': true,
	'Morbol': true,
	'Adamantoise': true,
	'Pteranodon': true,
	'Grand Doblyn': true
};
try {
	storageFilter = JSON.parse(localStorage.getItem('lala-islands-filter'));
	Object.keys(storageFilter).forEach(key => {
		if (defaultFilter[key]) defaultFilter[key] = !!storageFilter[key];
	})
} catch(e) {
	// do nothing
}


function updateTime(filters) {
	//timeElement.innerHTML = timeUtils.formatTimeString(...timeUtils.calculateCurrentEorziaTime());

	var zones = Object.keys(weatherFinder.weatherLists);
	var weathers = {};
	zones.forEach(zone => {
		weathers[zone] = {};
	});
	//console.log(zones);
	//console.log(weathers);
	//var weatherStartTime = weatherFinder.getWeatherTimeFloor(new Date('aug 25 2022, 5:00 utc')).getTime();
	//var weatherStartHour = Math.floor(weatherFinder.getEorzeaHour(weatherStartTime)/8)*8;
	//var weatherStartHour = Math.floor(timeUtils.calculateCurrentEorziaTime()[0]/8)*8;
	var weatherStartTime = timeUtils.calculateCurrentWeatherTimeFloor();
	var weatherStartHour = timeUtils.calculateEorziaTime(weatherStartTime)[0];
	var bells = [], htmls = [];
	var nmCounts = {'Ornery Karakul': 0, 'Paissa': 0, 'Black Chocobo': 0, 'Grand Buffalo': 0, 'Goobbue': 0, 'Gold Back': 0, 'Beachcomb': 0, 'Yellow Coblyn': 0, 'Twinklefleece': 0, 'Alligator': 0, 'Griffin': 0, 'Tiger of Paradise': 0, 'Morbol Seedling': 0, 'Weird Spriggan': 0, 'Boar of Paradise': 0, 'Funguar': 0, 'Alkonost': 0, 'Pteranodon': 0, 'Grand Doblyn': 0, 'Morbol': 0, 'Adamantoise': 0};
	var nmCountDesired = Object.keys(nmCounts).length*5;
	var nmCount = 0;
	var i = 0;
	while (nmCount < nmCountDesired && i < 448) {
	//for (let i = 0; i < BELL_COUNT; i++) {
		var hour = (weatherStartHour + i*8) % 24;
		var hourString;
		if (hour >= 12) {
			hourString = (hour - 12) + 'p';
		} else if (hour >= 1) {
			hourString = hour + 'a';
		} else if (hour === 0) {
			hourString = '12a';
		} else {
			hourString = hour + '?';
		}
		var realDate = new Date(weatherStartTime + i*eightEorzianHours);
		/*var weathers1 = {};
		zones.forEach(zone => {
			var weather = weatherFinder.getWeather(realDate, zone);
			weathers[zone][i] = weather;
			weathers1[zone] = weather;
		});*/
		let bell = {
			i: i,
			hour: hour,
			hourString: hourString,
			realDate: realDate,
			realDateString: timeUtils.toLocaleString(realDate),
			//weathers: weathers1
		};
		bells.push(bell);
		i++;

		//let weather = bell.weathers['Island Sanctuary'];
		let weather = weatherFinder.getWeather(realDate, 'Island Sanctuary');

		var nm = [], icon;
		if (weather === 'Fair Skies') {
			icon = '/islandsfiles/ui/060202.webp';
			if (nmCounts['Ornery Karakul'] < 5) {
				if (filters['Ornery Karakul']) nm.push(`<img src="/islandsfiles/ui/070122.webp" height="40" width="40" /> [S] Ornery Karakul (20,&nbsp;23)`);
				nmCounts['Ornery Karakul']++;
				nmCount++;
			}
			if (bell.hourString === '12a' && nmCounts['Grand Doblyn'] < 5) {
				if (filters['Grand Doblyn']) nm.push(`<img src="/islandsfiles/ui/070161.webp" height="40" width="40" /> [L] Grand Doblyn (Cave&nbsp;23,&nbsp;21) (3am-6am)`);
				nmCounts['Grand Doblyn']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Paissa'] < 5) {
				if (filters['Paissa']) nm.push(`<img src="/islandsfiles/ui/070147.webp" height="40" width="40" /> [M] Paissa (24,&nbsp;28) (12pm-3pm) (flying req.)`);
				nmCounts['Paissa']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Tiger of Paradise'] < 5) {
				if (filters['Tiger of Paradise']) nm.push(`<img src="/islandsfiles/ui/070151.webp" height="40" width="40" /> [M] Tiger of Paradise (15,&nbsp;14) (6pm-9pm)`);
				nmCounts['Tiger of Paradise']++;
				nmCount++;
			}
		} else if (weather === 'Clear Skies') {
			icon = '/islandsfiles/ui/060201.webp';
			if (nmCounts['Black Chocobo'] < 5) {
				if (filters['Black Chocobo']) nm.push(`<img src="/islandsfiles/ui/070136.webp" height="40" width="40" /> [M] Black Chocobo (13,&nbsp;11)`);
				nmCounts['Black Chocobo']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Pteranodon'] < 5) {
				if (filters['Pteranodon']) nm.push(`<img src="/islandsfiles/ui/070162.webp" height="40" width="40" /> [L] Pteranodon (25,&nbsp;16) (9am-12pm) (flying req.)`);
				nmCounts['Pteranodon']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Griffin'] < 5) {
				if (filters['Griffin']) nm.push(`<img src="/islandsfiles/ui/070150.webp" height="40" width="40" /> [L] Griffin (14,&nbsp;22) (3pm-6pm) (flying req.)`);
				nmCounts['Griffin']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Griffin'] < 5) {
				if (filters['Griffin']) nm.push(`<img src="/islandsfiles/ui/070150.webp" height="40" width="40" /> [L] Griffin (14,&nbsp;22) (3pm-6pm) (flying req.)`);
				nmCounts['Griffin']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Alkonost'] < 5) {
				if (filters['Alkonost']) nm.push(`<img src="/islandsfiles/ui/070159.webp" height="40" width="40" /> [M] Alkonost (23,&nbsp;30) (9pm-12am)`);
				nmCounts['Alkonost']++;
				nmCount++;
			}
		} else if (weather === 'Clouds') {
			icon = '/islandsfiles/ui/060203.webp';
			if (nmCounts['Grand Buffalo'] < 5) {
				if (filters['Grand Buffalo']) nm.push(`<img src="/islandsfiles/ui/070140.webp" height="40" width="40" /> [L] Grand Buffalo (12,&nbsp;17)`);
				nmCounts['Grand Buffalo']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Goobbue'] < 5) {
				if (filters['Goobbue']) nm.push(`<img src="/islandsfiles/ui/070149.webp" height="40" width="40" /> [L] Goobbue (33,&nbsp;16) (9am-12pm)`);
				nmCounts['Goobbue']++;
				nmCount++;
			}
			if (bell.hourString === '12a' && nmCounts['Morbol Seedling'] < 5) {
				if (filters['Morbol Seedling']) nm.push(`<img src="/islandsfiles/ui/070152.webp" height="40" width="40" /> [S] Morbol Seedling (19,&nbsp;19) (3am-6am)`);
				nmCounts['Morbol Seedling']++;
				nmCount++;
			}
		} else if (weather === 'Rain') {
			icon = '/islandsfiles/ui/060207.webp';
			if (nmCounts['Gold Back'] < 5) {
				if (filters['Gold Back']) nm.push(`<img src="/islandsfiles/ui/070144.webp" height="40" width="40" /> [L] Gold Back (31,&nbsp;28)`);
				nmCounts['Gold Back']++;
				nmCount++;
			}
			if (bell.hourString === '12a' && nmCounts['Beachcomb'] < 5) {
				if (filters['Beachcomb']) nm.push(`<img src="/islandsfiles/ui/070146.webp" height="40" width="40" /> [S] Beachcomb (17.8,&nbsp;12.6) (12am-3am)`);
				nmCounts['Beachcomb']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Funguar'] < 5) {
				if (filters['Funguar']) nm.push(`<img src="/islandsfiles/ui/070158.webp" height="40" width="40" /> [S] Funguar (20,&nbsp;27) (3pm-6pm)`);
				nmCounts['Funguar']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Funguar'] < 5) {
				if (filters['Funguar']) nm.push(`<img src="/islandsfiles/ui/070158.webp" height="40" width="40" /> [S] Funguar (20,&nbsp;27) (3pm-6pm)`);
				nmCounts['Funguar']++;
				nmCount++;
			}
		} else if (weather === 'Fog') {
			icon = '/islandsfiles/ui/060204.webp';
			if (nmCounts['Yellow Coblyn'] < 5) {
				if (filters['Yellow Coblyn']) nm.push(`<img src="/islandsfiles/ui/070130.webp" height="40" width="40" /> [S] Yellow Coblyn (27,&nbsp;19)`);
				nmCounts['Yellow Coblyn']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Twinklefleece'] < 5) {
				if (filters['Twinklefleece']) nm.push(`<img src="/islandsfiles/ui/070145.webp" height="40" width="40" /> [S] Twinklefleece (22.1,&nbsp;20.8) (6pm-9pm)`);
				nmCounts['Twinklefleece']++;
				nmCount++;
			}
			if (bell.hourString === '12a' && nmCounts['Weird Spriggan'] < 5) {
				if (filters['Weird Spriggan']) nm.push(`<img src="/islandsfiles/ui/070157.webp" height="40" width="40" /> [L] Weird Spriggan (Cave&nbsp;24,&nbsp;19) (12am-3am)`);
				nmCounts['Weird Spriggan']++;
				nmCount++;
			}
			if (bell.hourString === '8a' && nmCounts['Adamantoise'] < 5) {
				if (filters['Adamantoise']) nm.push(`<img src="/islandsfiles/ui/070160.webp" height="40" width="40" /> [L] Adamantoise (32,&nbsp;12) (12pm-3pm)`);
				nmCounts['Adamantoise']++;
				nmCount++;
			}
		} else if (weather === 'Showers') {
			icon = '/islandsfiles/ui/060208.webp';
			if (bell.hourString === '12a' && nmCounts['Alligator'] < 5) {
				if (filters['Alligator']) nm.push(`<img src="/islandsfiles/ui/070148.webp" height="40" width="40" /> [L] Alligator (17&nbsp;24) (6am-9am)`);
				nmCounts['Alligator']++;
				nmCount++;
			} else if (bell.hourString === '8a' && nmCounts['Alligator'] < 5) {
				if (filters['Alligator']) nm.push(`<img src="/islandsfiles/ui/070148.webp" height="40" width="40" /> [L] Alligator (17,&nbsp;24) (6am-9am)`);
				nmCounts['Alligator']++;
				nmCount++;
			}
			if (bell.hourString === '4p' && nmCounts['Morbol'] < 5) {
				if (filters['Morbol']) nm.push(`<img src="/islandsfiles/ui/070163.webp" height="40" width="40" /> [L] Morbol (28,&nbsp;24) (9pm-12am)`);
				nmCounts['Morbol']++;
				nmCount++;
			}
			if (nmCounts['Boar of Paradise'] < 5) {
				if (filters['Boar of Paradise']) nm.push(`<img src="/islandsfiles/ui/070156.webp" height="40" width="40" /> [L] Boar of Paradise (17,&nbsp;16)`);
				nmCounts['Boar of Paradise']++;
				nmCount++;
			}
			/*

						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060208.webp" width="32" height="32" /> Showers</td>
							<td>9am-12pm</td>
							<td><img src="/islandsfiles/ui/070156.webp" height="40" width="40" /> [L] Boar of Paradise (17,&nbsp;16)</td>
							<td>Horn, Fang</td>
						</tr>
			 */
		}
		if (nm.length > 0) {
			htmls.push(`
				<tr class="MountRow">
				<td><img src="${icon}" height="32" width="32" /> ${weather}</td>
				<td>${bell.hourString}</td>
				<td>${bell.realDateString}</td>
				<td>${nm.join('<br />')}</td>
				</tr>
			`);
		}
	}
	return htmls.join('');

	/*var html = '';
//let deadDate = new Date(1572321600000);
	return bells.map(function(bell) {
		let weather = bell.weathers['Island Sanctuary'];

		var nm = [];
		if (weather === 'Fair Skies') {
			nm.push(`<img src="/islandsfiles/ui/070122.webp" /> [S] Ornery Karakul (20,&nbsp;23)`);
			if (bell.hourString === '8a') {
				nm.push(`<img src="/islandsfiles/ui/070147.webp"/> [M] Paissa (24,&nbsp;28) (12pm-3pm) (flying req.)<br /><i class="fontello-attention"></i> Leave before 8am, queue at 12:05pm Eorzea time`);
			}
		} else if (weather === 'Clear Skies') {
			nm.push(`<img src="/islandsfiles/ui/070136.webp" /> [M] Black Chocobo (13,&nbsp;11)`);
		} else if (weather === 'Clouds') {
			nm.push(`<img src="/islandsfiles/ui/070140.webp" /> [L] Grand Buffalo (12,&nbsp;17)`);
			if (bell.hourString === '8a') {
				nm.push(`<img src="/islandsfiles/ui/070149.webp"/> [L] Goobbue (33,&nbsp;16) (9am-12pm)<br /><i class="fontello-attention"></i> Leave before 8am, queue at 9:05am Eorzea time`);
			}
		} else if (weather === 'Rain') {
			nm.push(`<img src="/islandsfiles/ui/070144.webp" /> [L] Gold Back (31,&nbsp;28)`);
			if (bell.hourString === '12a') {
				nm.push(`<img src="/islandsfiles/ui/070146.webp" /> [S] Beachcomb (17.8,&nbsp;12.6) (12am-3am)<br /><i class="fontello-attention"></i> Leave before 11pm, queue at 12:05am Eorzea time`);
			} else if (bell.hourString === '8a') {
			} else if (bell.hourString === '4p') {
			}
		} else if (weather === 'Fog') {
			nm.push(`<img src="/islandsfiles/ui/070130.webp" /> [S] Yellow Coblyn (27,&nbsp;19)`);
			if (bell.hourString === '4p') {
				nm.push(`<img src="/islandsfiles/ui/070145.webp" /> [S] Twinklefleece (22.1,&nbsp;20.8) (6pm-9pm)<br /><i class="fontello-attention"></i> Leave before 4pm, queue at 6:05pm Eorzea time`);
			}
		} else if (weather === 'Showers') {
			if (bell.hourString === '12a') {
				nm.push(`<img src="/islandsfiles/ui/070148.webp" /> [L] Alligator (17&nbsp;24) (6am-9am)<br /><i class="fontello-attention"></i> Leave before 12am, queue at 6:05am Eorzea time`);
			} else if (bell.hourString === '8a') {
				nm.push(`<img src="/islandsfiles/ui/070148.webp" /> [L] Alligator (17,&nbsp;24) (6am-9am)<br /><i class="fontello-attention"></i> Leave before 7am, queue at 8:05am Eorzea time`);
			} else if (bell.hourString === '4p') {
			}
		}

		html = `
        <tr class="MountRow">
        <td>${weather}</td>
        <td>${bell.hourString}</td>
        <td>${bell.realDateString}</td>
        <td>${nm.join('<br />')}</td>
        </tr>
        `;
		return html;
	}).join('');*/

	/*var weatherStartTime = WeatherFinder.getWeatherTimeFloor(new Date()).getTime();
	var weatherStartHour = WeatherFinder.getEorzeaHour(weatherStartTime);
	var zone = $("#zoneSelect").val();
	var tries = 0;
	var weather = WeatherFinder.getWeather(weatherStartTime, zone);
	var prevWeather = WeatherFinder.getWeather(weatherStartTime-1, zone);
	for (var i = i; i < 15; i++) {

	}*/

	//setTimeout(updateTime, 1000);
}

class IslandsRoute extends Component {
	state = {
		filters: defaultFilter
	};
	constructor() {
		super();
		this.toggleFilter = this.toggleFilter.bind(this);
	}
	toggleFilter(filter) {
		let newFilters = Object.assign({}, this.state.filters);
		newFilters[filter] = !newFilters[filter];
		localStorage.setItem('lala-islands-filter', JSON.stringify(newFilters));
		this.setState({
			filters: newFilters
		});
	}
	render() {
		//let lang = this.props.lang;
		return (
			<div className="PageContainer-Fixed">
				<div className="SideMenu">
					<p>&nbsp;</p>
					<h3>Island Sanctuary</h3>
					<a href={`/islandworkshop/`} className={``} key="islandworkshop">Workshop</a>
					<a href={`/islands/`} className={`current`} key="islands">Animals</a>
				</div>
				<div className="PageContent PageContent-WithMenu">
					<p>The first leaving on the list is a guaranteed drop, the second has a chance to drop.</p>
					<p>[S, M, L] is the restraint required [Net, Restraint, Soporific].</p>

					{ /*<h2>Unknown</h2>
					<table className="MountTable">
						<thead>
						<tr>
							<th>Weather</th>
							<th>Time</th>
							<th>Name</th>
							<th>Leavings</th>
						</tr>
						</thead>
						<tbody>
						</tbody>
					</table>*/ }
					<div className="BigSummaryGrid">
						<div>
							<h2>Always available</h2>
							<table className="MountTable">
								<thead>
								<tr>
									<th>Name</th>
									<th>Leavings</th>
								</tr>
								</thead>
								<tbody>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070121.webp" height="40" width="40" /> [S] Lost Lamb (20,&nbsp;23)</td>
									<td>Fleece, Milk</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070123.webp" height="40" width="40" /> [S] Opo-Opo (20,&nbsp;26)</td>
									<td>Claw, Fur</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070125.webp" height="40" width="40" /> [S] Apkallu (19,&nbsp;11)</td>
									<td>Fleece, Egg</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070127.webp" height="40" width="40" /> [S] Ground Squirrel (15,&nbsp;19)</td>
									<td>Claw, Fur</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070129.webp" height="40" width="40" /> [S] Coblyn (20,&nbsp;13)</td>
									<td>Fang, Carapace</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070131.webp" height="40" width="40" /> [M] Wild Dodo (16,&nbsp;12)</td>
									<td>Egg, Feather</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070133.webp" height="40" width="40" /> [M] Island Doe (21,&nbsp;19)</td>
									<td>Fur, Milk</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070135.webp" height="40" width="40" /> [M] Chocobo (20,&nbsp;21)</td>
									<td>Fur, Feather</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070137.webp" height="40" width="40" /> [M] Glyptodon Pup (30,&nbsp;11)</td>
									<td>Carapace, Claw</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070139.webp" height="40" width="40" /> [L] Aurochs (12,&nbsp;17)</td>
									<td>Milk, Horn</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070141.webp" height="40" width="40" /> [L] Island Nanny (26,&nbsp;24)</td>
									<td>Milk, Horn</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070143.webp" height="40" width="40" /> [L] Blue Back (28,&nbsp;28)</td>
									<td>Egg, Feather</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070153.webp" height="40" width="40" /> [S] Quartz Spriggan (Cave)</td>
									<td>Fang, Fur</td>
								</tr>
								<tr className="MountRow">
									<td><img src="/islandsfiles/ui/070155.webp" height="40" width="40" /> [L] Wild Boar (18,&nbsp;16)</td>
									<td>Fang, Horn</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div>
							<h2>Time-based</h2>
							<table className="MountTable">
								<thead>
								<tr>
									<th>Eorzea Time</th>
									<th>Name</th>
									<th>Leavings</th>
								</tr>
								</thead>
								<tbody>
								<tr className="MountRow">
									<td>12am-3am</td>
									<td><img src="/islandsfiles/ui/070138.webp" height="40" width="40" /> [M] Glyptodon (31,&nbsp;11)</td>
									<td>Claw, Carapace</td>
								</tr>
								<tr className="MountRow">
									<td>3am-6am</td>
									<td><img src="/islandsfiles/ui/070142.webp" height="40" width="40" /> [L] Island Billy (26,&nbsp;22)</td>
									<td>Horn, Fleece</td>
								</tr>
								<tr className="MountRow">
									<td>6am-9am</td>
									<td><img src="/islandsfiles/ui/070124.webp" height="40" width="40" /> [S] Lemur (20,&nbsp;26)</td>
									<td>Fur, Claw</td>
								</tr>
								<tr className="MountRow">
									<td>9am-12pm</td>
									<td><img src="/islandsfiles/ui/070128.webp" height="40" width="40" /> [S] Star Marmot (15,&nbsp;19)</td>
									<td>Fur, Claw</td>
								</tr>
								<tr className="MountRow">
									<td>12pm-3pm</td>
									<td><img src="/islandsfiles/ui/070126.webp" height="40" width="40" /> [S] Apkallu of Paradise (19,&nbsp;11)</td>
									<td>Egg, Fleece</td>
								</tr>
								<tr className="MountRow">
									<td>3pm-6pm</td>
									<td><img src="/islandsfiles/ui/070132.webp" height="40" width="40" /> [M] Dodo of Paradise (16,&nbsp;12)</td>
									<td>Feather, Egg</td>
								</tr>
								<tr className="MountRow">
									<td>6pm-9pm</td>
									<td><img src="/islandsfiles/ui/070134.webp" height="40" width="40" /> [M] Island Stag (20,&nbsp;19)</td>
									<td>Fur, Horn</td>
								</tr>
								<tr className="MountRow">
									<td>9pm-12am</td>
									<td><img src="/islandsfiles/ui/070154.webp" height="40" width="40" /> [S] Amethyst Spriggan (Cave&nbsp;28,&nbsp;16&nbsp;or&nbsp;24,&nbsp;19)</td>
									<td>Fur, Fang</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<h2>Weather-Based</h2>
					<table className="MountTable">
						<thead>
						<tr>
							<th>Weather</th>
							<th>Time</th>
							<th>Name</th>
							<th>Leavings</th>
						</tr>
						</thead>
						<tbody>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060201.webp" width="32" height="32" /> Clear Skies</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070136.webp" width="40" height="40" /> [M] Black Chocobo (13,&nbsp;11)</td>
							<td>Feather, Fur</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060201.webp" width="32" height="32" /> Clear Skies</td>
							<td>9am-12pm</td>
							<td><img src="/islandsfiles/ui/070162.webp" height="40" width="40" /> [L] Pteranodon (25,&nbsp;16)</td>
							<td>Claw, Horn</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060201.webp" width="32" height="32" /> Clear Skies</td>
							<td>3pm-6pm</td>
							<td><img src="/islandsfiles/ui/070150.webp" width="40" height="40" /> [L] Griffin (15,&nbsp;22) (flying req.)</td>
							<td>Feather, Claw</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060201.webp" width="32" height="32" /> Clear Skies</td>
							<td>9pm-12am</td>
							<td><img src="/islandsfiles/ui/070159.webp" height="40" width="40" /> [M] Alkonost (23,&nbsp;30)</td>
							<td>Feather, Egg</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060202.webp" width="32" height="32" /> Fair Skies</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070122.webp" width="40" height="40" /> [S] Ornery Karakul (20,&nbsp;23)</td>
							<td>Milk, Fleece</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060202.webp" width="32" height="32" /> Fair Skies</td>
							<td>3am-6am</td>
							<td><img src="/islandsfiles/ui/070161.webp" width="40" height="40" /> [L] Grand Doblyn (Cave&nbsp;23,&nbsp;21)</td>
							<td>Fang, Carapace</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060202.webp" width="32" height="32" /> Fair Skies</td>
							<td>12pm-3pm</td>
							<td><img src="/islandsfiles/ui/070147.webp" width="40" height="40" /> [M] Paissa (24,&nbsp;28) (flying req.)</td>
							<td>Claw, Fleece</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060202.webp" width="32" height="32" /> Fair Skies</td>
							<td>6pm-9pm</td>
							<td><img src="/islandsfiles/ui/070151.webp" height="40" width="40" /> [M] Tiger of Paradise (15,&nbsp;14)</td>
							<td>Fang, Fur</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060203.webp" width="32" height="32" /> Clouds</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070140.webp" width="40" height="40" /> [L] Grand Buffalo (12,&nbsp;17)</td>
							<td>Horn, Milk</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060203.webp" width="32" height="32" /> Clouds</td>
							<td>3am-6am</td>
							<td><img src="/islandsfiles/ui/070152.webp" width="40" height="40" /> [S] Morbol Seedling (19,&nbsp;19)</td>
							<td>Carpace, Fang</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060203.webp" width="32" height="32" /> Clouds</td>
							<td>9am-12pm</td>
							<td><img src="/islandsfiles/ui/070149.webp" width="40" height="40" /> [L] Goobbue (33,&nbsp;16)</td>
							<td>Fang, Claw</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060207.webp" width="32" height="32" /> Rain</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070144.webp" width="40" height="40" /> [L] Gold Back (31,&nbsp;28)</td>
							<td>Feather, Egg</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060207.webp" width="32" height="32" /> Rain</td>
							<td>12am-3am</td>
							<td><img src="/islandsfiles/ui/070146.webp" width="40" height="40" /> [S] Beachcomb (17.8,&nbsp;12.6)</td>
							<td>Carapace, Claw</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060207.webp" width="32" height="32" /> Rain</td>
							<td>3pm-6pm</td>
							<td><img src="/islandsfiles/ui/070158.webp" height="40" width="40" /> [S] Funguar (20,&nbsp;27)</td>
							<td>Fleece, Fur</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060208.webp" width="32" height="32" /> Showers</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070156.webp" height="40" width="40" /> [L] Boar of Paradise (17,&nbsp;16)</td>
							<td>Horn, Fang</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060208.webp" width="32" height="32" /> Showers</td>
							<td>6am-9am</td>
							<td><img src="/islandsfiles/ui/070148.webp" width="40" height="40" /> [L] Alligator (17,&nbsp;24)</td>
							<td>Claw, Fang</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060208.webp" width="32" height="32" /> Showers</td>
							<td>9pm-12am</td>
							<td><img src="/islandsfiles/ui/070163.webp" height="40" width="40" /> [L] Morbol (28,&nbsp;24)</td>
							<td>Fang, Carapace</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060204.webp" width="32" height="32" /> Fog</td>
							<td>-</td>
							<td><img src="/islandsfiles/ui/070130.webp" width="40" height="40" /> [S] Yellow Coblyn (27,&nbsp;19)</td>
							<td>Carapace, Fang</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060204.webp" width="32" height="32" /> Fog</td>
							<td>12pm-3pm</td>
							<td><img src="/islandsfiles/ui/070160.webp" height="40" width="40" /> [L] Adamantoise (32,&nbsp;12)</td>
							<td>Carapace, Claw</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060204.webp" width="32" height="32" /> Fog</td>
							<td>12am-3am</td>
							<td><img src="/islandsfiles/ui/070157.webp" height="40" width="40" /> [L] Weird Spriggan (Cave&nbsp;24,&nbsp;19)</td>
							<td>Fang, Fur</td>
						</tr>
						<tr className="MountRow">
							<td><img src="/islandsfiles/ui/060204.webp" width="32" height="32" /> Fog</td>
							<td>6pm-9pm</td>
							<td><img src="/islandsfiles/ui/070145.webp" width="40" height="40" /> [S] Twinklefleece (22.1,&nbsp;20.8)</td>
							<td>Fleece, Fur</td>
						</tr>
						</tbody>
					</table>
					<h2>Forecast</h2>
					<div className="InfoBox"><div>
						<p>The next 5 spawns of each animal are shown, up to one week into the future.</p>
						<p>Local time displayed is when the weather changes. Weather changes at midnight, 8am, and 4pm Eorzean time. You will still need to wait for the animal's window for it to spawn. There are approximately 3 Earth minutes in a single Eorzean hour.</p>
					</div></div>
					<div className="FilterBox FilterBox-UiIconFilters">
						<h2>Filter</h2>
						<a href="javascript:" className={this.state.filters['Black Chocobo']?'current':''} onClick={()=>this.toggleFilter('Black Chocobo')}>
							<img className="icon" src="/islandsfiles/ui/070136.webp" width="40" height="40" title="Black Chocobo" />
							<i class="IconDecoration fontello-ok" />
						</a>
						<a href="javascript:" className={this.state.filters['Ornery Karakul']?'current':''} onClick={()=>this.toggleFilter('Ornery Karakul')}>
							<img className="icon" src="/islandsfiles/ui/070122.webp" width="40" height="40" title="Ornery Karakul" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Paissa']?'current':''} onClick={()=>this.toggleFilter('Paissa')}>
							<img className="icon" src="/islandsfiles/ui/070147.webp" width="40" height="40" title="Paissa" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Grand Buffalo']?'current':''} onClick={()=>this.toggleFilter('Grand Buffalo')}>
							<img className="icon" src="/islandsfiles/ui/070140.webp" width="40" height="40" title="Grand Buffalo" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Goobbue']?'current':''} onClick={()=>this.toggleFilter('Goobbue')}>
							<img className="icon" src="/islandsfiles/ui/070149.webp" width="40" height="40" title="Goobbue" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Gold Back']?'current':''} onClick={()=>this.toggleFilter('Gold Back')}>
							<img className="icon" src="/islandsfiles/ui/070144.webp" width="40" height="40" title="Gold Back" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Beachcomb']?'current':''} onClick={()=>this.toggleFilter('Beachcomb')}>
							<img className="icon" src="/islandsfiles/ui/070146.webp" width="40" height="40" title="Beachcomb" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Alligator']?'current':''} onClick={()=>this.toggleFilter('Alligator')}>
							<img className="icon" src="/islandsfiles/ui/070148.webp" width="40" height="40" title="Alligator" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Yellow Coblyn']?'current':''} onClick={()=>this.toggleFilter('Yellow Coblyn')}>
							<img className="icon" src="/islandsfiles/ui/070130.webp" width="40" height="40" title="Yellow Coblyn" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Twinklefleece']?'current':''} onClick={()=>this.toggleFilter('Twinklefleece')}>
							<img className="icon" src="/islandsfiles/ui/070145.webp" width="40" height="40" title="Twinklefleece" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Griffin']?'current':''} onClick={()=>this.toggleFilter('Griffin')}>
							<img className="icon" src="/islandsfiles/ui/070150.webp" width="40" height="40" title="Griffin" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Tiger of Paradise']?'current':''} onClick={()=>this.toggleFilter('Tiger of Paradise')}>
							<img className="icon" src="/islandsfiles/ui/070151.webp" width="40" height="40" title="Tiger of Paradise" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Morbol Seedling']?'current':''} onClick={()=>this.toggleFilter('Morbol Seedling')}>
							<img className="icon" src="/islandsfiles/ui/070152.webp" width="40" height="40" title="Morbol Seedling" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Boar of Paradise']?'current':''} onClick={()=>this.toggleFilter('Boar of Paradise')}>
							<img className="icon" src="/islandsfiles/ui/070156.webp" width="40" height="40" title="Boar of Paradise" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Weird Spriggan']?'current':''} onClick={()=>this.toggleFilter('Weird Spriggan')}>
							<img className="icon" src="/islandsfiles/ui/070157.webp" width="40" height="40" title="Weird Spriggan" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Funguar']?'current':''} onClick={()=>this.toggleFilter('Funguar')}>
							<img className="icon" src="/islandsfiles/ui/070158.webp" width="40" height="40" title="Funguar" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Alkonost']?'current':''} onClick={()=>this.toggleFilter('Alkonost')}>
							<img className="icon" src="/islandsfiles/ui/070159.webp" width="40" height="40" title="Alkonost" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Pteranodon']?'current':''} onClick={()=>this.toggleFilter('Pteranodon')}>
							<img className="icon" src="/islandsfiles/ui/070162.webp" width="40" height="40" title="Pteranodon" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Grand Doblyn']?'current':''} onClick={()=>this.toggleFilter('Grand Doblyn')}>
							<img className="icon" src="/islandsfiles/ui/070161.webp" width="40" height="40" title="Grand Doblyn" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Adamantoise']?'current':''} onClick={()=>this.toggleFilter('Adamantoise')}>
							<img className="icon" src="/islandsfiles/ui/070160.webp" width="40" height="40" title="Adamantoise" />
							<i className="IconDecoration fontello-ok"/>
						</a>
						<a href="javascript:" className={this.state.filters['Morbol']?'current':''} onClick={()=>this.toggleFilter('Morbol')}>
							<img className="icon" src="/islandsfiles/ui/070163.webp" width="40" height="40" title="Morbol" />
							<i className="IconDecoration fontello-ok"/>
						</a>
					</div>

					<table className="MountTable">
						<thead>
						<tr>
							<th>Weather</th>
							<th>Eorzea Time</th>
							<th>Local Time</th>
							<th>Name (eorzea time)</th>
						</tr>
						</thead>
						<tbody id="table" dangerouslySetInnerHTML={{__html:updateTime(this.state.filters)}}>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default IslandsRoute;