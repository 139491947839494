import EventEmitter from 'eventemitter3';
import createAuth0Client from '@auth0/auth0-spa-js';

let user, auth0;
let ee = new EventEmitter();

let config = {
	domain: 'lalachievements.auth0.com',
	client_id: 'zcMBsGxU9rVQbRBh5DjGqWlwghTeg4WI',
	redirect_uri: window.location.origin+'/login',
	audience: 'https://lalachievements.com/api',
	leeway: 500,
	cacheLocation: 'localstorage',
	useRefreshTokens: true
};

async function init() {
	if (!auth0) {
		auth0 = await createAuth0Client(config);
	}
	const isAuthenticated = await auth0.isAuthenticated();
	console.log('is auth ? ', isAuthenticated);
	if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
		//console.log()
		console.log('auth - handling new login');
		await auth0.handleRedirectCallback();
		user = await auth0.getUser();
	} else if (!isAuthenticated) {
		console.log('auth - not logged in');
		//await auth0.loginWithRedirect();
	} else {
		console.log('auth - already logged in');
		user = await auth0.getUser();
		let accessToken = await auth0.getTokenSilently({audience: 'https://lalachievements.com/api'});
		/*await fetch('/api/user/favoritechars', {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + accessToken
			}
		});*/
		//console.log(tokens);
	}
	console.log('user', user);
	ee.emit('change');
}

async function login() {
	await auth0.loginWithRedirect();
	ee.emit('change');
}
async function logout() {
	auth0.logout({
		returnTo: window.location.origin
	});
	ee.emit('change');
}
async function authorizedFetch(url, options) {
	let accessToken = await auth0.getTokenSilently({audience: 'https://lalachievements.com/api'});
	options.headers = {
		Authorization: 'Bearer ' + accessToken
	};
	return await fetch(url, options);
}
async function authorizedXHR(url) {
	let accessToken = await auth0.getTokenSilently({audience: 'https://lalachievements.com/api'});
	let xhr = new XMLHttpRequest();
	xhr.open('POST', url);
	xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
	return xhr;
}

export default {
	on(listener, context) {
		ee.on('change', listener, context);
	},
	off(listener, context) {
		ee.off('change', listener, context);
	},
	init: init,
	login: login,
	logout: logout,
	fetch: authorizedFetch,
	xhr: authorizedXHR,
	async sendPasswordReset() {
		await fetch(`https://${config.domain}/dbconnections/change_password`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				client_id: config.client_id,
				email: user.email,
				connection: 'Username-Password-Authentication'
			})
		});
	},
	getUser() {
		return user;
	}
}
