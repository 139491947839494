//'use strict';
//import React, {Component} from 'react';
//import {observable, autorun, action} from "mobx";

import EventEmitter from "eventemitter3";
let urlify = require('urlify').create();
let EE = new EventEmitter();
let now = (new Date()).getTime();

let initialLoad = true;
let gameData = {
	dataCenters: {
		data: require('../gamedata/json/dataCenters.json')
	},
	achievementCategories: {
		de: require('../gamedata/json/de/achievementCategories.json'),
		en: require('../gamedata/json/en/achievementCategories.json'),
		fr: require('../gamedata/json/fr/achievementCategories.json'),
		ja: require('../gamedata/json/ja/achievementCategories.json')
	},
	achievements: {data: [], rarity: []},
	bardings: {data: []},
	bozjaFates: {data: []},
	bozjaNotes: {data: []},
	clans: {
		de: require('../gamedata/json/de/clans.json'),
		en: require('../gamedata/json/en/clans.json'),
		fr: require('../gamedata/json/fr/clans.json'),
		ja: require('../gamedata/json/ja/clans.json')
	},
	emoteCategories: {
		de: require('../gamedata/json/de/emoteCategories.json'),
		en: require('../gamedata/json/en/emoteCategories.json'),
		fr: require('../gamedata/json/fr/emoteCategories.json'),
		ja: require('../gamedata/json/ja/emoteCategories.json')
	},
	emotes: {data: []},
	fashions: {data: []},
	fish: {data: []},
	gcs: {
		de: require('../gamedata/json/de/gcs.json'),
		en: require('../gamedata/json/en/gcs.json'),
		fr: require('../gamedata/json/fr/gcs.json'),
		ja: require('../gamedata/json/ja/gcs.json')
	},
	/*gear: {
        type: 'gear',
        preloaded: false,
        loading: false,
        updatedAt: now,
        data: [] //require('../gamedata/json/en/gear.json')
    },*/
	/*genders: {
		de: require('../gamedata/json/de/genders.json'),
		en: require('../gamedata/json/en/genders.json'),
		fr: require('../gamedata/json/fr/genders.json'),
		ja: require('../gamedata/json/ja/genders.json')
	},*/
	guardians: {
		de: require('../gamedata/json/de/guardians.json'),
		en: require('../gamedata/json/en/guardians.json'),
		fr: require('../gamedata/json/fr/guardians.json'),
		ja: require('../gamedata/json/ja/guardians.json'),
	},
	hairs: {data: []},
	items: {data: []},
	jobs: {
		de: require('../gamedata/json/de/jobs.json'),
		en: require('../gamedata/json/en/jobs.json'),
		fr: require('../gamedata/json/fr/jobs.json'),
		ja: require('../gamedata/json/ja/jobs.json')
	},
	leves: {data: []},
	leveAssignmentTypes: {
		de: require('../gamedata/json/de/leveAssignmentTypes.json'),
		en: require('../gamedata/json/en/leveAssignmentTypes.json'),
		fr: require('../gamedata/json/fr/leveAssignmentTypes.json'),
		ja: require('../gamedata/json/ja/leveAssignmentTypes.json')
	},
	npcs: {data: []},
	placeNames: {data: []},
	minions: {data: [], rarity: []},
	moogletomes: {
		data: require('../gamedata/json/en/moogletomes.json')
	},
	mounts: {data: [], rarity: []},
	orchestrionCategories: {
		de: require('../gamedata/json/de/orchestrionCategories.json'),
		en: require('../gamedata/json/en/orchestrionCategories.json'),
		fr: require('../gamedata/json/fr/orchestrionCategories.json'),
		ja: require('../gamedata/json/ja/orchestrionCategories.json')
	},
	orchestrions: {data: []},
	portraits: {data: []},
	portraitConditions: {data: []},
	raids: {data: []},
	sourceTypes: {data: []},
	titles: {data: []},
	ttcardTypes: {data: []},
	ttcards: {data: []},
	ttrules: {data: []},
	ttnpcs: {data: []},
	/*weather: {
		de: require('../gamedata/json/de/weather.json'),
		en: require('../gamedata/json/en/weather.json'),
		fr: require('../gamedata/json/fr/weather.json'),
		ja: require('../gamedata/json/ja/weather.json')
	},*/
};
Object.keys(gameData).forEach(type => {
	if ((gameData[type].data && gameData[type].data.length > 0) || (gameData[type].en && gameData[type].en.length > 0)) {
		gameData[type].preloaded = true;
		gameData[type].loading = false;
		gameData[type].updatedAt = now;
	} else {
		gameData[type].preloaded = false;
		gameData[type].loading = !gameData[type].defer;
		gameData[type].updatedAt = 0;
	}
});
['en', 'de', 'fr', 'ja'].forEach(lang => {
	for (let i = 0; i < gameData.leveAssignmentTypes[lang].length; i++) {
		gameData.leveAssignmentTypes[lang][i].slug = urlify(gameData.leveAssignmentTypes.en[i].name);
	}
});

function decorateAchievements() {
	['en', 'de', 'fr', 'ja'].forEach(lang => {
		if (!gameData.achievements[lang] || gameData.achievements[lang].length < 1) return;
		gameData.achievements[lang] = gameData.achievements[lang].map(achievement => {
			gameData.achievementCategories[lang].forEach(kind => {
				kind.Categories.forEach(category => {
					if (category.Id === achievement.achievementCategoryId) {
						achievement.kind = kind;
						achievement.category = category;
					}
				});
			});
			return achievement;
		});
	});
}
function makeLinks() {
	['en', 'de', 'fr', 'ja'].forEach(lang => {
		if (!gameData.portraitConditions[lang] || gameData.portraitConditions[lang].length < 1) return;
		for (let i = 0; i < gameData.portraitConditions[lang].length; i++) {
			if (gameData.portraitConditions[lang][i].unlockType === 5 && gameData.emotes[lang]) {
				let emote = gameData.emotes[lang].find(emote => 'Emote: ' + emote.name === gameData.portraitConditions[lang][i].name);
				if (emote) gameData.portraitConditions[lang][i].emote = emote;
			}
			if (gameData.portraitConditions[lang][i].unlockType === 6 && gameData.minions[lang]) {
				let minion = gameData.minions[lang].find(minion => 'Minion: ' + minion.name === gameData.portraitConditions[lang][i].name);
				if (minion) gameData.portraitConditions[lang][i].minion = minion;
			}
		}
	});
}

let gameDataStore = {
	on(listener, context) {
		EE.on('change', listener, context);
	},
	off(listener, context) {
		EE.off('change', listener, context);
	},
	/**
	 * each object in here has:
	 * type: equals the object key
	 * loaded: does data exist
	 * loading: is it loading right now
	 * error: string of an error, this can be displayed in the UI
	 * updatedAt: date it was last updated
	 * data: items, key is the id from the game and matches the id property
	 *
	 * all loading of data happens automatically behind the scenes
	 * the only api is getType which will either return an empty array or some data
	 * there is no loading state for individual components, just show empty data
	 * there is a separate loading widget in the corner, use tataruhum animation there
	 * check for new data every 5 minutes or on request
	 */
	//gameData = {};
	//@observable
	gameData: gameData,
	lang: '-',
	chars: {},

	/*constructor(initialGameData, initialChars) {
		this.ini
	}*/

	setLang(lang) {
		if (['en', 'de', 'fr', 'ja'].indexOf(lang) < 0) return;
		if (this.lang === lang) return;

		this.lang = lang;

		this.sync(lang);

		EE.emit('change');

	},
	async sync(lang) {
		if (['en', 'de', 'fr', 'ja'].indexOf(lang) < 0) lang = this.lang;
		let maxDate = 0;
		let tables = [];
		Object.keys(gameData).forEach(type => {
			let data = gameData[type];
			if (data.preloaded) return;
			if (data.defer) return;

			if (data[lang] && data[lang].length > 0) {
				data[lang].forEach(r => maxDate = Math.max(maxDate, r.updatedAt));
			} else {
				data.loading = true;
			}
			tables.push(type);
		});



		EE.emit('change');

		//let url = `/api/game/${lang}/${tables.join(',')}`;
		let url = `/api/game/${lang}/all`;
		if (maxDate > 0) url += `/${maxDate}`;

		let controller = new AbortController();
		let timer = setTimeout(() => {
			controller.abort();
			if (initialLoad) throw new Error('Unable to connect to server');
		}, 32000);
		fetch(url, {signal: controller.signal})
			.then(res => {
				clearTimeout(timer);
				initialLoad = false;
				if (!res.ok) throw new Error('notok');
				else return res.json();
			}).then(a => {
				if (a.deployTime > window.LALA_DEPLOY_TIME) {
					window.location.reload();
				}
				Object.keys(a.tables).forEach(type => {
					let data = gameData[type];
					if (!data) return;
					data.loading = false;
					if (data[lang] && data[lang].length > 0) {
						a.tables[type].forEach(row => {
							let index = data[lang].findIndex(d => d.id === row.id);
							if (index > -1) {
								data[lang][index] = row;
							} else {
								data[lang].push(row);
							}
						});
					} else {
						data[lang] = a.tables[type];
					}
					data[lang] = data[lang].filter(row => !row.deleted);
				});
				decorateAchievements();
				makeLinks();
				//Object.keys(gameData).forEach(type => {if (!gameData[type].preloaded) console.log(type, gameData[type].loading);});
				EE.emit('change');
				if(maxDate < 1) this.sync();
				//console.log('aaaaaaaaaaa', gameData);
		}).catch(error => {
			Object.keys(gameData).forEach(type => {
				gameData[type].loading = false;
			});
			if (initialLoad) throw new Error('Unable to connect to server');
			console.error(error);
		});
	},
	syncAll() {
		['en', 'de', 'fr', 'ja'].forEach(lang => {
			this.sync(lang);
		});
	},

	get(type, lang) {
		if (['en', 'de', 'fr', 'ja'].indexOf(lang) === -1) lang = this.lang;
		/*if (this.gameData[type].defer) {
			this.gameData[type].defer = false;
			this.sync(lang);
		}*/
		if (this.gameData[type][lang]) return this.gameData[type][lang];
		if (this.gameData[type].data) return this.gameData[type].data;
		if (this.gameData[type].en) return this.gameData[type].en;

	},
	getRarity(type) {
		if (!!this.gameData[type].rarity) return this.gameData[type].rarity;
		else return [];
	}

};


function loadRarities() {
	Object.keys(gameData).filter(type => !!gameData[type].rarity).forEach(type => {
		let url = `/api/rarity/${type}/global`;
		fetch(url).then(res => {
			if (!res.ok) throw new Error('notok');
			else return res.json();
		}).then(json => {
			gameData[type].rarity = json.rarity.map(r => {
				let percent = (r ? r.count : 0) * 100 / json.charCount;
				r.percentFull = percent;
				if (percent > 100) {
					percent = (100).toFixed(0);
				} else if (percent < 0) {
					percent = (0).toFixed(0);
				} else if (percent < 0.1) {
					percent = percent.toFixed(3);
				} else if (percent < 1) {
					percent = percent.toFixed(2);
				} else {
					percent = percent.toFixed(1);
				}
				r.percent = percent;
				return r;
			});
			gameData[type].rarityCharCount = json.charCount;
		}).catch(error => {
			throw new Error('Unable to connect to server');
			console.error(error);
		});
	});
}


function syncTimer() {
	gameDataStore.sync();
	setTimeout(syncTimer, 300000);
}
setTimeout(syncTimer, 300000); // 5 minutes

loadRarities();

export default gameDataStore;
