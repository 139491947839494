import React, {Component} from 'react';
export default class LoadingBox extends Component {
	state = {
		visible: true
	};
	componentDidUpdate(prevProps) {
		if (!prevProps.visible && this.props.visible) {
			setTimeout(() => {
				if (this.props.visible) this.setState({ visible: true });
			}, 100);
		}
		if (prevProps.visible && !this.props.visible) this.setState({ visible: false });
	}
	render() {
		let className = 'LoadingOverlay';
		if (this.state.visible && this.props.visible) className += ' LoadingOverlay-Visible';
		return <div className={className}>Loading...</div>
	}
}
