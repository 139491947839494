import React, {PureComponent} from 'react';
import {ResponsiveLine} from "@nivo/line";
import theme from "../../styles/nivotheme";
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let unobtainableAchievements = require('../../data/unobtainableAchievements');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let achievementCategories = require('../../gamedata/json/en/achievementCategories.json');
//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');
//let jobs = require('../../gamedata/json/en/jobs.json');
//let gear = require('../../gamedata/json/en/gear.json');

//let componentFetch = require('../../helpers/componentFetch');
let charStore = require('../../stores/char').default;
let store = require('../../stores/gameData').default;

/*export default function CharTimelineRoute({url, lang}) {
	let QUERY = gql`query ($characterId:Int!) {
  characters_by_pk(id:$characterId) {
    id
    mounts(limit:50, order_by:{createdAt:desc}, where:{verified:{_eq:true}}) {
      createdAt
      mount {
        id
        name:name_${lang}
      }
    }
    minions(limit:50, order_by:{createdAt:desc}, where:{verified:{_eq:true}}) {
      createdAt
      minion {
        id
        name:name_${lang}
      }
    }
    achievements(limit:50, order_by:{createdAt:desc}) {
      createdAt
      achievement {
        id
        name:name_${lang}
        icon
        points
      }
    }
    gears(limit:50, order_by:{createdAt:desc}, where:{verified:{_eq:true}}) {
      createdAt
      item {
        id
        name:name_${lang}
      }
    }
    levels(limit:50, order_by:{createdAt:desc}) {
      level
      createdAt
      job {
        id
        name:name_${lang}
        abbreviation:abbreviation_${lang}
      }
    }
  }
}`;
	//const client = useApolloClient();
	const {data, error, loading} = useQuery(QUERY, {
		variables: {characterId:url[1]}
	});
	if (loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (error) {
		return <div>Error.</div>
	}
	let events = [];
	data.characters_by_pk.mounts.forEach(function(event) {
		event.type = 'mount';
		events.push(event);
	});
	data.characters_by_pk.minions.forEach(function(event) {
		event.type = 'minion';
		events.push(event);
	});
	data.characters_by_pk.achievements.forEach(function(event) {
		event.type = 'achievement';
		events.push(event);
	});
	data.characters_by_pk.gears.forEach(function(event) {
		event.type = 'gear';
		events.push(event);
	});
	data.characters_by_pk.levels.forEach(function(event) {
		event.type = 'level';
		events.push(event);
	});
	let eventsByDate = {};
	events.forEach(function(event) {
		let date = new Date(event.createdAt);
		let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
		if (typeof eventsByDate[dateString] === 'undefined') {
			eventsByDate[dateString] = {
				date: date,
				level: [],
				gear: [],
				mount: [],
				minion: [],
				achievement: []
			};
		}
		eventsByDate[dateString][event.type].push(event);

	});

	let days = [];
	Object.keys(eventsByDate).sort().reverse().forEach(function(key) {
		let d = eventsByDate[key];
		let dateString = d.date.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
			year: 'numeric'
		});
		let achievementEle, mountEle, minionEle, levelEle, gearEle;
		if (d.achievement.length > 0) {
			let points = 0;
			let rows = d.achievement.map(function(event) {
				//let a = achievements.find(aa => aa.Id === event.achievement.id);
				points += event.achievement.points;
				return <tr className='TimelineRow' key={event.achievement.id}>
					<td className="TimelineRow-Icon"><span className={`icon icon-achievement icon-achievement-${event.achievement.icon}`} /></td>
					<td className="TimelineRow-Text">{event.achievement.name}</td>
					<td className="TimelineRow-Value">{event.achievement.points}</td>
				</tr>;
			});
			achievementEle = <div className="Timeline">
				<h3><span className={`icon fontello-achievement`} /> {points} achievement points</h3>
				<table><tbody>{rows}</tbody></table>
			</div>
		}
		if (d.mount.length > 0) {
			//let points = 0;
			let rows = d.mount.map(function(event) {
				//let a = mounts.find(aa => aa.Id === event.mount.id);
				return <tr className='TimelineRow' key={event.mount.id}>
					<td className="TimelineRow-Icon"><span className={`icon icon-mount icon-mount-${event.mount.id}`} /></td>
					<td className="TimelineRow-Text">{event.mount.name}</td>
				</tr>;
			});
			mountEle = <div className="Timeline">
				<h3><span className={`icon fontello-mounts`} /> {d.mount.length} mounts</h3>
				<table><tbody>{rows}</tbody></table>
			</div>
		}
		if (d.minion.length > 0) {
			let rows = d.minion.map(function(event) {
				//let a = minions.find(aa => aa.Id === event.id);
				return <tr className='TimelineRow' key={event.minion.id}>
					<td className="TimelineRow-Icon"><span className={`icon icon-minion icon-minion-${event.minion.id}`} /></td>
					<td className="TimelineRow-Text">{event.minion.name}</td>
				</tr>;
			});
			minionEle = <div className="Timeline">
				<h3><span className={`icon fontello-minions`} /> {d.minion.length} minions</h3>
				<table><tbody>{rows}</tbody></table>
			</div>
		}
		if (d.level.length > 0) {
			let rows = d.level.map(function(event) {
				//let a = jobs.find(aa => aa.Id === event.id);
				return <tr className='TimelineRow' key={event.job.id+''+event.level}>
					<td className="TimelineRow-Text">{event.job.abbreviation}</td>
					<td className="TimelineRow-Text">{event.job.name} reached level {event.level}</td>
				</tr>;
			});
			levelEle = <div className="Timeline">
				<h3>{d.level.length} jobs leveled</h3>
				<table><tbody>{rows}</tbody></table>
			</div>
		}
		if (d.gear.length > 0) {
			let rows = d.gear.map(function(event) {
				//let a = gear.find(aa => aa.Id === event.id);
				return <tr className='TimelineRow' key={event.item.id}>
					<td className="TimelineRow-Icon"><span className={`icon icon-gear icon-gear-${event.item.id}`} /></td>
					<td className="TimelineRow-Text">{event.item.name}</td>
				</tr>;
			});
			gearEle = <div className="Timeline">
				<h3>{d.gear.length} new pieces of gear</h3>
				<table><tbody>{rows}</tbody></table>
			</div>
		}
		days.push(<div key={dateString}>
			<h2 className="TimelineDate">{dateString}</h2>
			<div className="TimelineContainer">
				{achievementEle}
				{mountEle}
				{minionEle}
				{levelEle}
				{gearEle}
			</div>
		</div>)

	});

	return <div>
		{days}
	</div>;
}*/

function makeState() {
	return {
		char: charStore.getData(),
		achievements: store.get('achievements'),
		mounts: store.get('mounts'),
		minions: store.get('minions'),
		jobs: store.get('jobs')
	};
}
export default class CharTimelineRoute extends PureComponent {
	state = makeState();
	//state = useQuery(QUERY);
	constructor(props) {
		super(props);
		//this.fetch = componentFetch.bind(this);
		charStore.on(this.onChange, this);
		store.on(this.onChange, this);
		charStore.loadTimeline();
	}
	componentWillUnmount() {
		charStore.off(this.onChange, this);
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState(makeState());
	}
	componentDidMount() {
		//this.fetch('data', '/api/chartimeline/' + this.props.url[1] + '/');
	}
	componentDidUpdate(prevProps, prevState) {
		charStore.loadTimeline();
	}
	render() {
		//const { loading, error, data } = useQuery(QUERY);

		/*if (loading) {
			return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
		}
		if (error) {
			return <div>Error.</div>
		}
		console.log(data);

		return <div>hi</div>;*/

		if (!this.state.char.timeline) {
			return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
		}
		let eventsByDate = {};

		this.state.char.timeline.events.forEach(function(event) {
			let date = new Date(event.createdAt);
			let dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
			if (typeof eventsByDate[dateString] === 'undefined') {
				eventsByDate[dateString] = {
					date: date,
					level: [],
					gear: [],
					mount: [],
					minion: [],
					achievement: []
				};
			}
			eventsByDate[dateString][event.type].push(event);
		});
		let days = [];
		Object.keys(eventsByDate).forEach((key) => {
			let d = eventsByDate[key];
			let dateString = d.date.toLocaleDateString('en-US', {
				month: 'short',
				day: 'numeric',
				year: 'numeric'
			});
			let achievementEle, mountEle, minionEle, levelEle, gearEle;
			if (d.achievement.length > 0) {
				let points = 0;
				let rows = d.achievement.map((event) => {
					let a = this.state.achievements.find(aa => aa.id === event.id);
					points += a.points;
					return <tr className='TimelineRow' key={a.id}>
						<td className="TimelineRow-Icon"><span className={`icon icon-achievement icon-achievement-${a.icon}`} /></td>
						<td className="TimelineRow-Text">{a.name}</td>
						<td className="TimelineRow-Value">{a.points}</td>
					</tr>;
				});
				achievementEle = <div className="Timeline">
					<h3><span className={`icon fontello-achievement`} /> {points} achievement points</h3>
					<table><tbody>{rows}</tbody></table>
				</div>
			}
			if (d.mount.length > 0) {
				//let points = 0;
				let rows = d.mount.map((event) => {
					let a = this.state.mounts.find(aa => aa.id === event.id);
					return <tr className='TimelineRow' key={a.id}>
						<td className="TimelineRow-Icon"><span className={`icon icon-mount icon-mount-${a.id}`} /></td>
						<td className="TimelineRow-Text">{a.name}</td>
					</tr>;
				});
				mountEle = <div className="Timeline">
					<h3><span className={`icon fontello-mounts`} /> {d.mount.length} mounts</h3>
					<table><tbody>{rows}</tbody></table>
				</div>
			}
			if (d.minion.length > 0) {
				let rows = d.minion.map((event) => {
					let a = this.state.minions.find(aa => aa.id === event.id);
					return <tr className='TimelineRow' key={a.id}>
						<td className="TimelineRow-Icon"><span className={`icon icon-minion icon-minion-${a.id}`} /></td>
						<td className="TimelineRow-Text">{a.name}</td>
					</tr>;
				});
				minionEle = <div className="Timeline">
					<h3><span className={`icon fontello-minions`} /> {d.minion.length} minions</h3>
					<table><tbody>{rows}</tbody></table>
				</div>
			}
			if (d.level.length > 0) {
				let rows = d.level.map((event) => {
					let a = this.state.jobs.find(aa => aa.Id === event.id);
					return <tr className='TimelineRow' key={a.Id}>
						<td className="TimelineRow-Text">{a.Abbreviation}</td>
						<td className="TimelineRow-Text">{a.Name} reached level {event.details}</td>
					</tr>;
				});
				levelEle = <div className="Timeline">
					<h3>{d.level.length} jobs leveled</h3>
					<table><tbody>{rows}</tbody></table>
				</div>
			}
			if (d.gear.length > 0) {
				let rows = d.gear.map(function(event) {
					//let a = gear.find(aa => aa.Id === event.id);
					return <tr className='TimelineRow' key={event.id}>
						<td className="TimelineRow-Icon"><span className={`icon icon-gear icon-gear-${event.id}`} /></td>
						<td className="TimelineRow-Text">{event.details}</td>
					</tr>;
				});
				gearEle = <div className="Timeline">
					<h3>{d.gear.length} new pieces of gear</h3>
					<table><tbody>{rows}</tbody></table>
				</div>
			}
			days.push(<div key={dateString}>
				<h2 className="TimelineDate">{dateString}</h2>
				<div className="TimelineContainer">
					{achievementEle}
					{mountEle}
					{minionEle}
					{levelEle}
					{gearEle}
				</div>
			</div>)

		});


		let achievementsByDate = {};
		this.state.char.char.achievements.forEach((event) => {
			let d = this.state.achievements.find(d => d.id === event.id);
			if (!d.obtainable || !d.notLegacy) return;
			let date = new Date((event.date+2592000)*1000);
			let dateString = date.getUTCFullYear() + '-' + ('00'+(date.getUTCMonth()+1)).slice(-2);
			//let dateString = date.getFullYear() + '-' + ('00'+(date.getMonth()+1)).slice(-2) + '-' + ('00'+(date.getDate()+1)).slice(-2);
			if (typeof achievementsByDate[dateString] === 'undefined') {
				achievementsByDate[dateString] = {
					date: dateString,
					count: 0
				};
			}
			achievementsByDate[dateString].count += d.points;
			//let chartData = char[typePlural]
		});
		let achievementsChartData = [];
		let achievementsChartDataRunning = 0;
		Object.keys(achievementsByDate).sort().forEach(key => {
			achievementsChartDataRunning += achievementsByDate[key].count;
			achievementsChartData.push({
				x: achievementsByDate[key].date,
				y: achievementsChartDataRunning
			});
		});


		let mountsByDate = {};
		this.state.char.char.mounts.forEach((event) => {
			let d = this.state.mounts.find(d => d.id === event.id);
			if (!d.obtainable) return;
			let date = new Date((event.date+2592000)*1000);
			let dateString = date.getUTCFullYear() + '-' + ('00'+(date.getUTCMonth()+1)).slice(-2);
			//let dateString = date.getFullYear() + '-' + ('00'+(date.getMonth()+1)).slice(-2) + '-' + ('00'+(date.getDate()+1)).slice(-2);
			if (typeof mountsByDate[dateString] === 'undefined') {
				mountsByDate[dateString] = {
					date: dateString,
					count: 0
				};
			}
			mountsByDate[dateString].count++;
			//let chartData = char[typePlural]
		});
		let mountsChartData = [];
		let mountsChartDataRunning = 0;
		Object.keys(mountsByDate).sort().forEach(key => {
			mountsChartDataRunning += mountsByDate[key].count;
			mountsChartData.push({
				x: mountsByDate[key].date,
				y: mountsChartDataRunning
			});
		});

		let minionsByDate = {};
		this.state.char.char.minions.forEach((event) => {
			let d = this.state.minions.find(d => d.id === event.id);
			if (!d.obtainable) return;
			let date = new Date((event.date+2592000)*1000);
			let dateString = date.getUTCFullYear() + '-' + ('00'+(date.getUTCMonth()+1)).slice(-2);
			//let dateString = date.getFullYear() + '-' + ('00'+(date.getMonth()+1)).slice(-2) + '-' + ('00'+(date.getDate()+1)).slice(-2);
			if (typeof minionsByDate[dateString] === 'undefined') {
				minionsByDate[dateString] = {
					date: dateString,
					count: 0
				};
			}
			minionsByDate[dateString].count++;
			//let chartData = char[typePlural]
		});
		let minionsChartData = [];
		let minionsChartDataRunning = 0;
		Object.keys(minionsByDate).sort().forEach(key => {
			minionsChartDataRunning += minionsByDate[key].count;
			minionsChartData.push({
				x: minionsByDate[key].date,
				y: minionsChartDataRunning
			});
		});

		return <div>

			<div className="CharProfileBox">
				<h2>Achievement Points</h2>
				<div className="CharProfileBox-Content" style={{height:'320px'}}>
					<ResponsiveLine
						data={[{
							id: 'achievements',
							data: achievementsChartData
						}]}
						colors="#06a"
						theme={theme}
						margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
						xScale={{
							type: 'time',
							format: '%Y-%m',
							precision: 'month',
						}}
						xFormat="time:%Y-%m"
						yScale={{
							type: 'linear',
							stacked: false,
						}}
						axisLeft={{
							//legend: typePlural,
							legendOffset: 12,
						}}
						axisBottom={{
							format: '%Y %b',
							tickValues: 'every 1 year',
							//legend: 'time',
							legendOffset: -12,
						}}
						enablePoints={true}
						enableArea={true}
						areaOpacity={0.5}
						useMesh={true}
						isInteractive={true}
					/>
				</div>
			</div>
			<div className="CharProfileTwo">

			<div className="CharProfileBox">
				<h2>Mounts</h2>
				<div className="CharProfileBox-Content" style={{height:'320px'}}>
					<ResponsiveLine
						data={[{
							id: 'mounts',
							data: mountsChartData
						}]}
						colors="#06a"
						theme={theme}
						margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
						xScale={{
							type: 'time',
							format: '%Y-%m',
							precision: 'month',
						}}
						xFormat="time:%Y-%m"
						yScale={{
							type: 'linear',
							stacked: false,
						}}
						axisLeft={{
							//legend: typePlural,
							legendOffset: 12,
						}}
						axisBottom={{
							format: '%Y %b',
							tickValues: 'every 1 year',
							//legend: 'time',
							legendOffset: -12,
						}}
						enablePoints={true}
						enableArea={true}
						areaOpacity={0.5}
						useMesh={true}
						isInteractive={true}
					/>
				</div>
			</div>

			<div className="CharProfileBox">
				<h2>Minions</h2>
				<div className="CharProfileBox-Content" style={{height:'320px'}}>
					<ResponsiveLine
						data={[{
							id: 'minions',
							data: minionsChartData
						}]}
						colors="#06a"
						theme={theme}
						margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
						xScale={{
							type: 'time',
							format: '%Y-%m',
							precision: 'month',
						}}
						xFormat="time:%Y-%m"
						yScale={{
							type: 'linear',
							stacked: false,
						}}
						axisLeft={{
							//legend: typePlural,
							legendOffset: 12,
						}}
						axisBottom={{
							format: '%Y %b',
							tickValues: 'every 1 year',
							//legend: 'time',
							legendOffset: -12,
						}}
						enablePoints={true}
						enableArea={true}
						areaOpacity={0.5}
						useMesh={true}
						isInteractive={true}
					/>
				</div>
			</div>
			</div>

			{days}
		</div>;
	}
}
