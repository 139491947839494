import React, {Component} from 'react';
import '../../gamedata/spritesheet/moogletomes.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
import clans from '../../gamedata/json/en/clans.json';
//let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

let items = require('../../gamedata/json/en/moogletomes');
//let shops = require('../../gamedata/json/shops');
/*let realItems = {
    mount: require('../../gamedata/json/en/mounts.json'),
    minion: require('../../gamedata/json/en/minions.json'),
    emote: require('../../gamedata/json/en/emotes.json'),
    orchestrion: require('../../gamedata/json/en/orchestrions.json'),
    triadCard: require('../../gamedata/json/en/ttcards.json')
};*/

let filterTypes = ['All', 'Completed', 'Missing'];

let store = require('../../stores/gameData').default;

function makeState() {
    return {
        char: charStore.getData(),
        mount: store.get('mounts'),
        minion: store.get('minions'),
        emote: store.get('emotes'),
        orchestrion: store.get('orchestrions'),
        triadCard: store.get('ttcards'),
        fashion: store.get('fashions'),
        hair: store.get('hairs'),
        barding: store.get('bardings'),
        portraitCondition: store.get('portraitConditions'),
    }
}
export default class CharMoogleTomesRoute extends Component {
    state = Object.assign({
        filter: 'All'
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('emote').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('emote').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }

    toggleItem(type, id, toggleTo) {
        if (toggleTo) {
            charStore.markItem(type, id);
        } else {
            charStore.unmarkItem(type, id);
        }
    }

    render() {
        //let lang = this.props.lang;
        let char = this.props.char;
        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        //let currentId = this.props.url[3]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        //let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            emotes.filter(emote => {
                if (category.Id === 1) return true;
                return emote.emoteCategory === category;
            }).forEach(emote => {
                let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`/${lang}/char/${char.id}/emotes/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/


        let clan = clans.find(clan => clan.Id === char.clanId2);
        let iconClan = 'midm';
        if (clan) {
            if (clan.Tribe === 'Midlander') iconClan = 'mid';
            else if (clan.Tribe === 'Highlander') iconClan = 'high';
            else if (clan.Race === 'Elezen') iconClan = 'ele';
            else if (clan.Race === 'Lalafell') iconClan = 'lala';
            else if (clan.Race === 'Miqo\'te') iconClan = 'miqo';
            else if (clan.Race === 'Roegadyn') iconClan = 'roe';
            else if (clan.Race === 'Au Ra') iconClan = 'aura';
            else if (clan.Race === 'Hrothgar') iconClan = 'roe';
            else if (clan.Race === 'Viera') iconClan = 'mid';
            else iconClan = 'hyur';
            iconClan = iconClan + (clan.Gender === 1 ? 'f' : 'm');
        }

        let hasCount = 0, totalCount = 0;
        let rows = items.filter(item => {
            return item.id > 0;
            //if (currentCategory.Id === 1) return true;
            //return emote.emoteCategory === currentCategory;
        }).filter(item => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem(item.type, item.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (this.state.filter === 'Completed') {
                return has;
            } else if (this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).map(item => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem(item.type, item.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            className += ` MoogleRow-${item.type}`

            let realItem;
            if (this.state[item.type]) {
                realItem = this.state[item.type].find(i => i.id === item.id);
            }
            if (!realItem) {
                realItem = {
                    Id: 0,
                    Name: item.Name,
                    data: {
                        howTo: ''
                    }
                }
            }
            let howTo, realIconClass;
            if (item.type === 'orchestrion') {
                howTo = realItem.description
            } else if (realItem.howTo) {
                howTo = realItem.howTo;
            } else {
                howTo = '';
            }
            if (item.type === 'hair') {
                realIconClass = `icon icon-${item.type} icon-${item.type}-${realItem.id}_${iconClan}`
            } else if (item.type === 'triadCard') {
                realIconClass = `icon icon-ttcards icon-ttcards-${realItem.id}`
            } else if (item.type === 'barding') {
                realIconClass = `icon icon-barding icon-barding-${realItem.id}h`
            } else {
                realIconClass = `icon icon-${item.type} icon-${item.type}-${realItem.id}`;
            }
            let checkbox;
            if (isCharMine && (item.type !== 'mount' && item.type !== 'minion')) {
                checkbox = <button onClick={(e) => this.toggleItem(item.type, realItem.id, !has)}>
                    <i className={this.state.char.char[item.type+'load'] && this.state.char.char[item.type+'load'].includes(realItem.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <button disabled><i className={'fontello-ok'}/></button>;
            }
            return <tr className={className} key={item.type+item.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Icon"><span className={realIconClass} title={realItem.name}/></td>
                <td className="MountRow-Icon"><span className={`icon icon-moogletomes icon-moogletomes-${item.item.Id}`} title={item.item.Name}/></td>
                <td className="MountRow-Text">
                    <span className="MountRow-Name">{item.item.Name}</span><br />
                    <span className="MountMinionDetails-Tag">Unlocks: </span> {realItem.name}
                </td>
                <td dangerouslySetInnerHTML={{__html:howTo}} />
                <td className="MountRow-Value">{item.cost}</td>
            </tr>;
        });
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let rowsUnknown = items.filter(item => {
            return item.id <= 0;
        }).map(item => {
            return <tr className="MountRow" key={item.item.Id}>
                <td className="MountRow-Icon"><span className={`icon icon-moogletomes icon-moogletomes-${item.item.Id}`} title={item.item.Name}/></td>
                <td className="MountRow-Text">
                    <span className="MountRow-Name">{item.item.Name}</span>
                </td>
                <td className="MountRow-Value">{item.cost}</td>
            </tr>;
        });


        return <div className="">

            <div>

                <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div>
                {char.id > 0 ? <div className="CompletionSummary">
                    <h3>Moogle Treasure Trove</h3>{/*<h3>Fall Guys</h3>*/}
                    <p className="CompletionSummary-Percent">{Math.floor(percent)}%</p>
                    <p className="CompletionSummary-Numbers">{hasCount} / {totalCount}</p>
                    <p className="CompletionSummary-Remaining">({totalCount-hasCount} remaining)</p>
                    <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
                </div> : <div className="CompletionSummary">
                    <h3>Moogle Treasure Trove</h3>{/*<h3>Fall Guys</h3>*/ }
                </div>}
                <p>The Moogle Treasure Trove provides an alternative method of obtaining some items by farming dungeons or PvP.</p>
                <h2>Collection Items</h2>
                <table className="MountTable">
                    <tbody>
                    {rows}
                    </tbody>
                </table>
                <h2>Misc. Items</h2>
                <table className="MountTable">
                    <tbody>
                    {rowsUnknown}
                    </tbody>
                </table>
            </div>

        </div>;
    }
}