import React, {Component} from 'react';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import '../../gamedata/spritesheet/mount.css';
import '../../gamedata/spritesheet/minion.css';
import MountPage from '../itemPages/mount';
import MinionPage from '../itemPages/minion';
import {ResponsiveLine} from '@nivo/line';
import theme from '../../styles/nivotheme';
import {ResponsiveRadar} from "@nivo/radar";

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');

let store = require('../../stores/gameData').default;

/*export default function CharMountsRoute({url, lang, type}) {
	let types = type + 's';

	let QUERY = gql`
query characters($characterId:Int!) {
  characters_by_pk(id: $characterId) {
    id
    ${types} {
      id:${type}Id
    }
  }
}`;
	let DATAQUERY = gql`
query {
  data:${types} {
    id
    name:name_${lang}
    obtainable
    howTo:howTo_${lang}
    sourceType {
      id
      name:name_${lang}
    }
  }
}`;
	const {data, error, loading} = useQuery(QUERY, {
		variables: {characterId:url[1]}
	});
	const dataResult = useQuery(DATAQUERY, {
	});
	if (loading || dataResult.loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (error || dataResult.error) {
		return <div>Error.</div>
	}
	return <CharMountsRouteInner lang={lang} char={data.characters_by_pk} data={dataResult.data.data} type={type} url={url} />;
}*/
function makeState() {
	return {
		mounts: store.get('mounts'),
		minions: store.get('minions'),
		sourceTypes: store.get('sourceTypes'),
		mountsRarity: store.getRarity('mounts'),
		minionsRarity: store.getRarity('minions')
	};
}
export default class CharMountsRouteInner extends Component {
	state = Object.assign({
		sort: 'In-Game',
		view: 'Standard',
		sourceFilter: -1,
		filter2: []
	}, makeState());
	constructor(props) {
		super(props);
		this.setSort = this.setSort.bind(this);
		this.setView = this.setView.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.setFilter2 = this.setFilter2.bind(this);
		store.on(this.onChange, this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState(makeState());
	}
	setSort(e) {
		this.setState({
			sort: e.target.innerHTML
		});
	}
	setView(e) {
		this.setState({
			view: e.target.innerHTML
		});
	}
	setFilter(e) {
		let f = e.target.dataset.source * 1;
		if (this.state.sourceFilter === f) {
			this.setState({sourceFilter: -1});
		} else {
			this.setState({sourceFilter: f});
		}
	}
	setFilter2(e) {
		let f = e.target.innerHTML;
		let index = this.state.filter2.indexOf(f);
		let newFilters = [...this.state.filter2];
		if (index > -1) {
			newFilters.splice(index, 1);
		} else {
			newFilters.push(f);
		}
		this.setState({
			filter2: newFilters
		});
	}
	render() {
		let char = this.props.char, lang = this.props.lang, urlBase = this.props.urlBase;

		let type = this.props.type;
		let typePlural = this.props.type + 's';

		let data;// = this.props.data;
		let rarity;
		if (type === 'mount') {
			data = this.state.mounts;
			rarity = this.state.mountsRarity;
		} else {
			data = this.state.minions;
			rarity = this.state.minionsRarity;
		}
		let sortKey = this.state.sort;
		data.sort((a, b) => {
			if (sortKey === 'Name') {
				if (a.name < b.name) {return -1;}
				if (a.name > b.name) {return 1;}
				if (a.id < b.id) {return -1;}
				if (a.id > b.id) {return 1;}
				return 0;
			} else if (sortKey === 'Source') {
				if (a.sourceTypeId < b.sourceTypeId) {return -1;}
				if (a.sourceTypeId > b.sourceTypeId) {return 1;}
				if (a.id < b.id) {return -1;}
				if (a.id > b.id) {return 1;}
				return 0;
			} else if (this.state.sort === 'Rarest' || this.state.sort === 'Most Common') {
				let ra = rarity.find(_r => _r.id === a.id);
				let rb = rarity.find(_r => _r.id === b.id);
				let rap = 100, rbp = 100;
				if (ra) rap = ra.percentFull;
				if (rb) rbp = rb.percentFull;
				if (this.state.sort === 'Rarest') {
					if (rap < rbp) return -1;
					if (rap > rbp) return 1;
				} else {
					if (rap < rbp) return 1;
					if (rap > rbp) return -1;
				}
				if (a.sort < b.sort) {return -1;}
				if (a.sort > b.sort) {return 1;}
				if (a.id < b.id) {return -1;}
				if (a.id > b.id) {return 1;}
				return 0;
			} else {
				if (a.sort < b.sort) {return -1;}
				if (a.sort > b.sort) {return 1;}
				if (a.id < b.id) {return -1;}
				if (a.id > b.id) {return 1;}
				return 0;
			}
		});
		let filters = data.reduce(function(accumulator, d) {
			if (accumulator.indexOf(d.sourceTypeId) === -1) {
				accumulator.push(d.sourceTypeId);
			}
			return accumulator;
		}, []).sort();
		//let filters = char.id > 0 ? Object.keys(char[type+'Sources']) : [];
		let filtersElements = filters.map((filterId) => {
			let sourceType = this.state.sourceTypes.find(t => t.id === filterId*1);
			if (!sourceType) return;
			let filterName = sourceType.name;
			let percent = char.id > 0 && char[type+'Sources'][filterId].count * 100 / char[type+'Sources'][filterId].countPossible;
			let progressClassName = 'FilterProgressInner';
			if (percent === 100) {
				progressClassName += ' FilterProgressInner-Complete';
			}
			return <button data-source={filterId} key={filterId} className={this.state.sourceFilter === filterId*1?'current':''} onClick={this.setFilter}>
				{filterName}
				{char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
			</button>;
		});

		let filteredData = data.filter((d) => {
			if (this.state.view === 'Standard') return true;

			let has = char.id > 0 && this.props.char[typePlural].find(dd => dd.id === d.id);
			let obtainable = !!d.obtainable;

			if (!obtainable && this.state.filter2.indexOf('Not Obtainable') === -1) return false;

			if (char.id < 1) return true;
			if (this.state.filter2.indexOf('Obtained') === -1 && this.state.filter2.indexOf('Unobtained') === -1) return true;
			if (has && this.state.filter2.indexOf('Obtained') > -1) return true;
			if (!has && this.state.filter2.indexOf('Unobtained') > -1) return true;
			return false;
		}).filter((d) => {
			if (this.state.sourceFilter < 0) return true;
			return this.state.sourceFilter === d.sourceTypeId;
		});

		if (filteredData.length < 1) filteredData = data;

		let content;
		if (this.state.view === 'List') {
			let rows = [], obtainedCount = 0, totalCount = 0;
			rows = filteredData.map(d => {
				let r = rarity.find(_r => _r.id === d.id);
				let has = char.id > 0 && this.props.char[typePlural].find(dd => dd.id === d.id);
				let obtainable = typeof d.obtainable === 'undefined' || !!d.obtainable;
				if (has) obtainedCount++;
				totalCount++;

				let unobtainableElement;
				if (!obtainable) {
					unobtainableElement = <span className="MountMinionDetails-Tag UnobtainableTag">Unobtainable</span>;
				}
				let className = 'MountRow';
				if (has) {
					className += ' MountRow-Have';
				}
				let sourceType = this.state.sourceTypes.find(t => t.id === d.sourceTypeId) || {};
				return <tr className={className} key={d.id}>
					<td className="MountRow-Check"><i className="fontello-ok" /></td>
					<td className="MountRow-Icon"><a href={`${urlBase}${type}s/${d.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span
						className={`icon icon-${type} icon-${type}-${d.id}`} title={d.name}/></a></td>
					<td className="MountRow-Text">
						<a href={`${urlBase}${type}s/${d.id}/`}
						   className="MountRow-Name">{d.name}</a><br />
						From: <span className="MountMinionDetails-Tag">{sourceType.name}</span>
					</td>
					<td>
						<div dangerouslySetInnerHTML={{__html:d.howTo}} /><br />
						{unobtainableElement}
					</td>
					{ /*<td className="MountRow-Patch">{d.data.patch}</td>*/ }
					<td className="MountRow-Value">
						{r ? <span className="RankingRow-All" title={`Obtained by ${r.count} characters`}>{r.percent}%</span> : ''}
					</td>
				</tr>;
			});
			content = <div>
				<h2>{totalCount} {type}s</h2>
				{char.id > 0 ? <p>Of the filtered selection, you have {obtainedCount} {type}s.</p> : ''}

				<table className="MountTable">
					<tbody>
					{rows}
					</tbody>
				</table>

			</div>;
		} else {
			//if (this.state.view === 'Standard') {
			let obtained = [], mogstation = [], remaining = [], unobtainable = [], hasCount = 0, rankingsCount = 0;
			//console.log(this.props.char);
			for (let d of filteredData) {
				let has = char.id > 0 && this.props.char[typePlural].find(dd => dd.id === d.id);

				let obtainable = typeof d.obtainable === 'undefined' || !!d.obtainable;
				if (has) {
					obtained.push(d);
					hasCount++;
					if (obtainable) rankingsCount++;
				} else if (d.sourceTypeId === 7 || d.sourceTypeId === 28) {
					mogstation.push(d);
				} else if (obtainable) {
					remaining.push(d);
				} else {
					unobtainable.push(d);
				}
			}
			function mapMini(d) {
				let icon;
				if (d.sourceTypeId === 7 || d.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
				else if (!d.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
				let r = rarity.find(_r => _r.id === d.id);
				return <a href={`${urlBase}${type}s/${d.id}/`} key={d.id} className={`gacha gacha${r ? r.points : 0}`}><span
					className={`icon icon-${type} icon-${type}-${d.id}`} title={d.name}/>{icon}</a>;
			}
			obtained = obtained.map(mapMini);
			let hasPages = [];
			while(obtained.length) {
				hasPages.push(obtained.splice(0, 25));
			}
			hasPages = hasPages.map((page, i) => {
				return <div key={i} className="IconList TwentyFiveList"><h3>{i+1}</h3>{page}</div>;
			});
			unobtainable = unobtainable.map(mapMini);
			mogstation = mogstation.map(mapMini);
			/*obtained = obtained.map(d => {
				return <a href={`${urlBase}${type}s/${d.id}/`} key={d.id} className="GroupedIcon"><span
					className={`icon icon-${type} icon-${type}-${d.id}`} title={d.name}/></a>;
			});
			extra = extra.map(d => {
				return <a href={`${urlBase}${type}s/${d.Id}/`} key={d.Id} className="GroupedIcon"><span
					className={`icon icon-${type} icon-${type}-${d.Id}`} title={d.Name}/></a>;
			});
			unobtainable = unobtainable.map(d => {
				return <a href={`${urlBase}${type}s/${d.Id}/`} key={d.Id} className="GroupedIcon"><span
					className={`icon icon-${type} icon-${type}-${d.Id}`} title={d.Name}/></a>;
			});*/

			remaining = remaining.map(d => {
				let sourceType = this.state.sourceTypes.find(t => t.id === d.sourceTypeId) || {};
				let r = rarity.find(_r => _r.id === d.id);
				let icon;
				if (d.sourceTypeId === 7 || d.sourceTypeId === 28) icon = <i className="fontello-dollar IconDecoration IconDecoration-Unobtainable" />;
				else if (!d.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
				return <tr className="MountRow" key={d.id}>
					<td className="MountRow-Icon"><a href={`${urlBase}${type}s/${d.id}/`} className={`gacha gacha${r ? r.points : 0}`}><span
						className={`icon icon-${type} icon-${type}-${d.id}`} title={d.name}/>{icon}</a></td>
					<td className="MountRow-Text">
						<a href={`${urlBase}${type}s/${d.id}/`}
						   className="MountRow-Name">{d.name}</a>
						<span className="MountMinionDetails-Tag">{sourceType.name}</span> <br />
						<span dangerouslySetInnerHTML={{__html:d.howTo}} />
					</td>
					{ /*<td dangerouslySetInnerHTML={{__html:d.howTo}} />*/ }
					{ /*<td className="MountRow-Patch">{d.data.patch}</td>*/ }
					<td className="MountRow-Value">
						{r ? <span className="RankingRow-All" title={`Obtained by ${r.count} characters`}>{r.percent}%</span> : ''}
					</td>
				</tr>;
			});

			content = <div>
				{char.id > 0 ? <div>
					<h2>{hasCount} {type}s</h2>
					<p>{rankingsCount} of these count towards your rankings. Items marked with <i className="fontello-attention" /> are not possible for all players to obtain. Items marked with <i className="fontello-dollar" /> are purchased with real world currencies. Any items marked with either icon do not count towards your total score on the rankings.</p>
					<div className="IconList">{hasPages}</div>

					<h2>{remaining.length} {type}s left to collect</h2>
				</div> : <div>
					<h2>{remaining.length} {type}s</h2>
				</div>}

				<table className="MountTable">
					<tbody>
					{remaining}
					</tbody>
				</table>

				<h2>{mogstation.length} {type}s are purchased with real world currencies</h2>
				<p>These {type}s are either from the online store, promotional events, or codes included with physical merchandise, and do not count against your rankings.</p>
				<div className="IconList">{mogstation}</div>

				<h2>{unobtainable.length} {type}s are not currently obtainable</h2>
				<p>These {type}s are either from past events, limited-time promotions, or are not yet released, and do not count against your rankings.</p>
				<div className="IconList">{unobtainable}</div>
			</div>;
		}

		let details;
		if (this.props.url[0] > 0) {
			if (type === 'mount') {
				details = <div className="DetailsColumn DetailsColumn-MobileVisible">
					<a className="DetailsColumnClose" href={`/${this.props.lang}/char/${char.id}/mounts/`}><i className="fontello-left-open" /> Back to mount list</a>
					<MountPage id={this.props.url[0] * 1} char={char}/>
				</div>;
			} else if (type === 'minion') {
				details = <div className="DetailsColumn DetailsColumn-MobileVisible">
					<a className="DetailsColumnClose" href={`/${this.props.lang}/char/${char.id}/minions/`}><i className="fontello-left-open" /> Back to minion list</a>
					<MinionPage id={this.props.url[0] * 1} char={char}/>
				</div>;
			} else {
				details = <div className="DetailsColumn"><p>Select an item to see its details here.</p></div>;
			}
		} else {
			details = <div className="DetailsColumn"><p>Select an item to see its details here.</p></div>;
		}
		/*let chartData = filters.map(filter => {

			let filteredData = data.filter(d => {
				return d.data.from === filter;
			});
			return {
				source: filter,
				count: filteredData.length
			}
		});*/

		return <div className="PageContent-WithDetails">

			<div className="FilterBoxContainer">
				<div className="FilterBox FilterBox-Small">
					<h2>Sort</h2>
					<button className={this.state.sort==='In-Game'?'current':''} onClick={this.setSort}>
						In-Game
					</button>
					<button className={this.state.sort==='Name'?'current':''} onClick={this.setSort}>
						Name
					</button>
					<button className={this.state.sort==='Source'?'current':''} onClick={this.setSort}>
						Source
					</button>
					<button className={this.state.sort==='Rarest'?'current':''} onClick={this.setSort}>
						Rarest
					</button>
					<button className={this.state.sort==='Most Common'?'current':''} onClick={this.setSort}>
						Most Common
					</button>
				</div>

				<div className="FilterBox FilterBox-Small">
					<h2>View</h2>
					<button className={this.state.view==='Standard'?'current':''} onClick={this.setView}>
						Standard
					</button>
					<button className={this.state.view==='List'?'current':''} onClick={this.setView}>
						List
					</button>
				</div>

				{this.state.view === 'List' ? <div className="FilterBox FilterBox-Small">
					<h2>Filter</h2>
					{char.id > 0 ? <span>
					<button className={this.state.filter2.indexOf('Obtained')>-1?'current':''} onClick={this.setFilter2}>
						Obtained
					</button>
					<button className={this.state.filter2.indexOf('Unobtained')>-1?'current':''} onClick={this.setFilter2}>
						Unobtained
					</button>&nbsp;&nbsp;</span> : ''}
					<button className={this.state.filter2.indexOf('Not Obtainable')>-1?'current':''} onClick={this.setFilter2}>
						Not Obtainable
					</button>
				</div> : <div>&nbsp;</div>}
			</div>

			<div className="FilterBox">
				<h2>Source</h2>
				{filtersElements}
			</div>

			{content}

			{details}

		</div>;
	}
}
