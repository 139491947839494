import React, {Component} from "react";

class HomeRoute extends Component {
	render() {
		//let lang = this.props.lang;
		return (
			<div className="PageContainer-Center PageContent">
				<p>This page documents the various public API endpoints for this site. All endpoints (except for image cards) begin with the following URL:</p>
				<p>https://lalachievements.com/api/</p>
				<p>Some endpoints are rate limited. You are allowed 30 points every 15 seconds. This is enforced on each individual server instance, thus you may be capable of exceeding it sometimes, but this should not be relied on. There are no rate limit headers.</p>
				<p>If you use this API, you should <a href="http://discord.gg/3gV95J7">join the discord</a> for notifications of breaking changes, which will occur.</p>
				<p>Undocumented API endpoints may work but should not be relied on, as they can change at any time with no warning.</p>

				<h2>Game Data</h2>
				<p>Game data endpoints are tightly integrated to the frontend's requirements, and thus you may have a better time obtaining your data elsewhere (XIVAPI, SaintCoinach, etc). However, they are still available to use. Column names and IDs are usually similar to the names used in other APIs.</p>
				<p>&nbsp;</p>
				<p><strong>/api/game/schema</strong></p>
				<p>This lists the endpoints available, with the list of values you can expect within each.</p>
				<p>&nbsp;</p>
				<p><strong>/api/game/:lang/:table</strong></p>
				<p>Cacheable version of the below endpoint. In most scenarios, you will use this version.</p>
				<p>&nbsp;</p>
				<p><strong>/api/game/:lang/:table/:since</strong></p>
				<p>This retrieves one or more tables of data. Not cacheable.</p>
				<ul>
					<li><strong>lang</strong> - one of "en", "fr", "de", "ja". Affects the language of all values listed under "langKeys" in the schema.</li>
					<li><strong>table</strong> - comma separated list of table names, or "all".</li>
					<li><strong>since</strong> - unix timestamp, only data that has been modified after this date will be returned. Optional. Milliseconds.</li>
				</ul>

				<h2>Cards (experimental)</h2>
				<p>These are PNG image endpoints summarizing various data on the site. All of these follow the same format. Do not save or crawl these, you should hotlink to them and they will be generated on demand. Examples:</p>
				<ul>
					<li><a href="https://lalachievements.com/en/char/11901583.png" target="_blank">https://lalachievements.com/en/char/11901583.png</a></li>
				</ul>

				<h2>Characters (old)</h2>
				<p>Requests providing a valid API key consume 0 points.</p>
				<ul>
					<li>/api/charcache/:id - Cached data, consumes 0 points.</li>
					<li>/api/charrealtime/:id - Consumes 5 points and syncs the character with Lodestone before returning data.</li>
					<li>/api/charprofile/:id - Deprecated.</li>
					<li>/api/charsearch/:text - Consumes 3 points. Searches only the Lalachievements database, not Lodestone.</li>
				</ul>

				<h2>Characters (new)</h2>
				<p>Coming soon.</p>

				<h2>Rankings + Rarity</h2>
				<p>Coming soon.</p>
			</div>
		);
	}
}

export default HomeRoute;