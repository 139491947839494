import React, {Component} from 'react';

export default class PrivacyPolicyRoute extends Component {
	render() {
		return <div className="PageContainer-Center PageContent">
			<h2>Privacy Policy</h2>
			<p>Last updated November 7, 2021.</p>
			<p>This privacy policy applies to lalachievements.com, Lalachievements, and Neon Potato LLC (referred to as Lalachievements or We hereafter) and governs data collection and usage. By using this website or any services provided by Lalachievements, you agree to the data collection described in this statement.</p>
			<h3>Collection of your information</h3>
			<p>Lalachievements will collect any personally identifiable information you provide, such as your name and email address. If you purchase any services we will collect billing information, and our billing provider will collect payment information, such as your credit card number.</p>
			<p>We do not collect any personally identifiable information unless you voluntarily give it to us.</p>
			<p>Certain website features, such as comment sections, are publicly viewable, and any information you provide through them may be seen, collected, or used by others.</p>
			<h3>Automatically collected information</h3>
			<p>We automatically collect non-identifiable information about your computer hardware and software, such as browser type, screen size, pages visited, and errors encountered.</p>
			<p>We also collect any information publicly visible on your Final Fantasy XIV Lodestone profile. You may control access to this information using Lodestone's tools.</p>
			<h3>Cookies</h3>
			<p>The Lalachievements website may use cookies or local browser storage to personalize your experience. You may set your browser to decline these cookies but certain features of lalachievements.com may not work properly if you do so.</p>
			<h3>Third Parties</h3>
			<p>We do not sell, rent, or lease any customer or user information to third parties.</p>
			<p>Your data may be shared with third party services with whom we have contracted. These third parties will only use your information as required to provide the service we contract them to provide, such as server hosting or user authentication. These parties include but are not limited to:</p>
			<ul>
				<li>Digital Ocean - servers and data storage</li>
				<li>Cloudflare - security and caching</li>
				<li>Google - servers, data storage, and user analytics</li>
				<li>Mailchimp - email newsletters</li>
			</ul>
			<h3>Security</h3>
			<p>We attempt to protect your information from unauthorized use with methods such as encryption and firewalls. However, we cannot guarantee to be 100% secure. You acknowledge that there may be circumstances beyond our control, and that the security of your information cannot be guaranteed.</p>
			<h3>Children under thirteen</h3>
			<p>If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
			<h3>Contact information</h3>
			<p>If you have any concerns about this privacy policy, contact Lalachievements at:</p>
			<p>
				Neon Potato LLC<br />
				PO Box 1040<br />
				Laveen, Arizona 85339-0770
			</p>
			<p>info@lalachievements.com</p>
		</div>;
	}
}