import React, {Component} from 'react';
//import {useQuery} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import './profile.scss';
import {ResponsiveRadar} from '@nivo/radar';
import theme from '../../styles/nivotheme';
import userStore from '../../stores/user';

//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let charStore = require('../../stores/char').default;

export default class CharVerifyRoute extends Component {
	state = {
		verificationCode: '',
		loading: true,
		verifying: false,
		verified: false,
		error: false
	};
	constructor(props) {
		super(props);
		this.verify = this.verify.bind(this);
		this.getCode();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.char.id !== this.props.char.id) {
			this.getCode();
		}
	}
	getCode() {
		userStore.getCharVerificationCode(this.props.char.id).then(code => {
			this.setState({verificationCode: code.code, verified: code.verified, loading: false});
		});
	}
	verify(e) {
		e.preventDefault();
		this.setState({verifying: true});
		userStore.verifyCode(this.props.char.id).then(verified => {
			if (verified === true) {
				this.setState({verifying: false, verified: true, error: false});
				//userStore.init();
				//window.location.reload();
			} else {
				this.setState({verifying: false, verified: false, error: true});
			}
		});
		//fetch(`/api/charrealtime/${this.props.char.id}/`);
		//this.props.sync();
		//this.setState({syncRequested: true});
		//charStore.sync();
	}
	render() {
		let char = this.props.char;

		let verifyButton, error;
		if (this.state.loading) {
			verifyButton = <button disabled="disabled">Loading...</button>;
		} else if (this.state.verifying) {
			verifyButton = <button disabled="disabled">Verifying...</button>;
		} else if (!this.state.verified) {
			verifyButton = <button onClick={this.verify}>Verify</button>;
		}
		if (this.state.error) {
			error = <p className="InfoBox InfoBox-Error">Could not find the verification code on this character's Lodestone profile. Unable to verify ownership of this character.</p>;
		}
		if (this.state.verified) {
			error = <p className="InfoBox InfoBox-Success">Verified successfully!</p>;
		}

		return <div>
			<div className="ImageBox">
				<img src="/images/search.png" alt="" />
				<div>
					<h2>Verify Ownership of This Character</h2>
					<form action="#">
						<p>Verifying you own this character will allow you to adjust settings, mark items as complete, and more.</p>
						<p>Add the following code to the <a href="https://na.finalfantasyxiv.com/lodestone/my/setting/profile/" target="_blank">Lodestone profile</a> of this character. If you have multiple characters, you may need to switch your active character on Lodestone first. Then, click "verify".</p>
						<label>Verification code: <input type="text" value={this.state.verificationCode} /></label>
						{error}
						{verifyButton}
					</form>
				</div>
			</div>
		</div>;
	}
}
