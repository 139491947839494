import React, {Component} from 'react';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';

//let dataCenters = require('../../gamedata/json/dataCenters.json');
//let achievements = require('../../gamedata/json/en/achievements.json');
//let raids = require('../../data/raids');
let expansions = require('../../data/expansions');

let store = require('../../stores/gameData').default;

/*export default function CharAchievementsRoute({url, lang}) {
	let QUERY = gql`
query characters($characterId: Int!) {
  raids {
    id
    name
    expansion
    released
    achievement {
      icon
    }
  }
  characters_by_pk(id: $characterId) {
    id
    achievementsPrivate
    raids {
      id
      date:createdAt
    }
  }
}
`;
	const {data, error, loading} = useQuery(QUERY, {
		variables: {characterId:url[1]}
	});
	if (loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (error) {
		return <div>Error.</div>
	}
	let char = data.characters_by_pk;
	if (char.achievementsPrivate) {
		return <p><strong>This character has achievements set to private on Lodestone.</strong></p>;
	}
	let expansionRows = expansions.map(function(expansion) {
		let raidRows = data.raids.filter(function(raid) {
			return raid.expansion === expansion.id;
		}).map(function(raid) {
			//let achievement = achievements.find(a => a.Id === raid.achievementId);
			let released = new Date(raid.released);
			let releasedHuman = released.toLocaleDateString('en-US', {
				month: 'long',
				day: 'numeric',
				year: 'numeric'
			});
			let charAchievement = char.raids.find(a => a.id === raid.id);
			let isCleared, clearedDate, clearedDateHuman;
			if (charAchievement) {
				isCleared = true;
				clearedDate = new Date(charAchievement.date);
				clearedDateHuman = clearedDate.toLocaleDateString('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric'
				});
			}
			//let isCleared = char.achievements.indexOf(raid.achievementId) > -1;
			return <tr className="MountRow MountRow-Obtained" key={raid.id}>
				<td className="MountRow-Icon"><span className={`icon icon-achievement icon-achievement-${raid.achievement.icon}`} /></td>
				<td className="MountRow-Text">
					<div className="MountRow-Name">{raid.name}</div>
					Added: {releasedHuman}<br />
					Cleared: {isCleared ? clearedDateHuman : 'No'}
				</td>
			</tr>
		});
		return <div key={expansion.id}>
			<p>&nbsp;</p>
			<h2>{expansion.name}</h2>
			<table className="MountTable">
				<tbody>
				{raidRows}
				</tbody>
			</table>
		</div>;
	}).reverse();

	return <div>
		<h2>Raids completed</h2>
		{expansionRows}
	</div>;
}*/





function makeState() {
	return {
		achievements: store.get('achievements'),
		raids: store.get('raids')
	};
}
export default class CharRaidsRoute extends Component {
	state = makeState();
	constructor(props) {
		super(props);
		store.on(this.onChange, this);
	}
	componentWillUnmount() {
		store.off(this.onChange, this);
	}
	onChange() {
		this.setState(makeState());
	}
	render() {
		let char = this.props.char;
		if (char.achievementsPrivate) {
			return <p><strong>This character has achievements set to private on Lodestone.</strong></p>;
		}

		if (this.state.achievements.length < 1 || this.state.raids.length < 1) {
			return <p>Loading</p>;
		}

		let sortedRaids = this.state.raids.sort((a, b) => {
			if (a.released > b.released) return 1;
			if (a.released < b.released) return -1;
			if (a.id > b.id) return 1;
			if (a.id < b.id) return -1;
			return 0;
		});

		let expansionRows = expansions.map((expansion) => {
			let raidRows = sortedRaids.filter((raid) => {
				return raid.expansion === expansion.id;
			}).map((raid) => {
				let achievement = this.state.achievements.find(a => a.id === raid.id);
				let released = new Date(raid.released);
				let releasedHuman = released.toLocaleDateString('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric'
				});
				let charAchievement = char.achievements && char.achievements.find(a => a.id === raid.id);
				let isCleared, clearedDate, clearedDateHuman;
				if (charAchievement) {
					isCleared = true;
					clearedDate = new Date(charAchievement.date*1000);
					clearedDateHuman = clearedDate.toLocaleDateString('en-US', {
						month: 'long',
						day: 'numeric',
						year: 'numeric'
					});
				}
				//let isCleared = char.achievements.indexOf(raid.achievementId) > -1;
				return <tr className="MountRow" key={raid.id}>
					<td className="MountRow-Icon">{isCleared ? <i className={'fontello-ok'}/> : ''}</td>
					<td className="MountRow-Icon"><span className={`icon icon-achievement icon-achievement-${achievement.icon}`} /></td>
					<td className="MountRow-Text">
						<div className="MountRow-Name">{raid.name}</div>
						Added: {releasedHuman}<br />
						Cleared: {isCleared ? `${clearedDateHuman} (week ${Math.ceil((clearedDate - released - 28800000)/86400000/7)})` : 'No'}
					</td>
					<td>

					</td>
					</tr>
			});
			return <div key={expansion.id}>
				<p>&nbsp;</p>
				<h2>{expansion.name}</h2>
				<table className="MountTable">
					<tbody>
						{raidRows}
					</tbody>
				</table>
			</div>;
		}).reverse();

		return <div>
			<h2>Raids completed</h2>
			{expansionRows}
		</div>;
	}
}
