import React, {Component} from 'react';
import '../../gamedata/spritesheet/ttcards.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
import {ImageOverlay, MapContainer, Marker} from "react-leaflet";
import Leaflet from "leaflet";
import DetailMap from '../../components/DetailMap';
//let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;
//let TriadCard = require('../../components/TriadCard').default;
let CardSources = require('../../components/TriadCardSourcesSmall').default;


//let cards = require('../../gamedata/json/en/ttcards.json');
//let npcs = require('../../gamedata/json/en/ttnpcs.json');

let filterTypes = ['All', 'Completed', 'Missing'];

let store = require('../../stores/gameData').default;
function makeState() {
    return {
        char: charStore.getData(),
        npcs: store.get('npcs'),
        placeNames: store.get('placeNames'),
        sourceTypes: store.get('sourceTypes'),
        ttcardTypes: store.get('ttcardTypes'),
        ttcards: store.get('ttcards'),
        ttrules: store.get('ttrules'),
        ttnpcs: store.get('ttnpcs')
    };
}
export default class CharTTCardsRoute extends Component {
    state = Object.assign({
        filter: 'All'
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }

    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange,this);
    }

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('triadNpc', id);
        } else {
            charStore.unmarkItem('triadNpc', id);
        }
    }

    toggleCard(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('triadCard', id);
        } else {
            charStore.unmarkItem('triadCard', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        //let currentId = this.props.url[3]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            emotes.filter(emote => {
                if (category.Id === 1) return true;
                return emote.emoteCategory === category;
            }).forEach(emote => {
                let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}emotes/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/

        let cardHasCount = 0, cardTotalCount = 0;
        this.state.ttcards.forEach(card => {
            let has = char.id > 0 && charStore.hasItem('triadCard', card.id);
            if (has) {
                cardHasCount++;
            }
            cardTotalCount++;
        });

        //let npcHasCount = 0, npcTotalCount = 0;
        let rows = this.state.ttnpcs.filter(npc => {
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('triadNpc', npc.id);
            /*if (npc.obtainable !== false) {
                if (has) {
                    npcHasCount++;
                }
                npcTotalCount++;
            }*/
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).map(ttnpc => {
            let npc = this.state.npcs.find(npc => npc.id === ttnpc.npcId);
            if (!npc) return;
            let url = `${urlBase}ttnpcs/${ttnpc.id}/`;
            //let has = this.state.char && this.state.char.emote && this.state.char.emote.find(l => l.emoteId === emote.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('triadNpc', ttnpc.id);
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(ttnpc.id, !has)}>
                    <i className={this.state.char.char.triadNpcload && this.state.char.char.triadNpcload.includes(ttnpc.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            /*let npc, other;
            let sources = [];
            if (card.data && card.data.howTo && card.data.from !== 'Unknown') {
                sources.push(card.data.howTo);
            }
            card.npcs.forEach(npcId => {
                let npc = npcs.find(npc => npc.Id === npcId);
                let location = '';
                if (npc.Location) location = ` - ${npc.Location.PlaceName} (${npc.Location.X}, ${npc.Location.Y})`;
                let url1 = `${urlBase}ttnpcs/${npc.Id}/`;
                sources.push(`NPC: <a href="${url1}">${npc.Name}</a>${location}`);
            });
            let sources1 = sources.join('<br />');*/
            let rewardCards = ttnpc.rewardTtcardIds.map(cardId => {
                let card = this.state.ttcards.find(c => c.id === cardId);
                let url = `${urlBase}ttcards/${card.id}/`;
                let hasCard = charStore.hasItem('triadCard', card.id);
                let className = hasCard ? 'IconList-Has' : '';
                let check = hasCard ? <i className={this.state.char.char.triadCardload && this.state.char.char.triadCardload.includes(card.id) ? 'fontello-spin1 animate-spin IconDecoration' : 'fontello-ok IconDecoration'}/> : '';
                return <span key={card.id} className={className}><span className={`icon icon-ttcards icon-ttcards-${card.id}`} title={card.name}/>{check}</span>
            });
            let placeName = this.state.placeNames.find(pn => pn.id === npc.placeNameId);
            let unobtainable = ttnpc.obtainable === false ? <div>Does not count towards NPC achievements.</div> : '';

            return <tr className={className} key={ttnpc.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">{npc.name}</a><br />
                    {placeName ? placeName.name : '???'} ({npc.displayX}, {npc.displayY})
                    {unobtainable}
                </td>
                <td className="MountRow-Text IconList">{rewardCards}</td>
            </tr>;
        });
        //let npcPercent = npcHasCount * 100 / npcTotalCount;
        let npcProgressClassName = 'FilterProgressInner';
        if (char.triadNpcPercent === 100) {
            npcProgressClassName += ' FilterProgressInner-Complete';
        }
        //let cardPercent = cardHasCount * 100 / cardTotalCount;
        let cardProgressClassName = 'FilterProgressInner';
        if (char.triadCardPercent === 100) {
            cardProgressClassName += ' FilterProgressInner-Complete';
        }

        let details;
        if (this.props.url[0] > 0 && this.state.ttnpcs.length > 0 && this.state.npcs.length > 0) {
            let ttnpc = this.state.ttnpcs.find(c => c.id === this.props.url[0] * 1);
            let npc = this.state.npcs.find(npc => npc.id === ttnpc.npcId);

            let has = char.id > 0 && charStore.hasItem('triadNpc', ttnpc.id);
            let hasEle;
            if (has) {
                let hasDate = new Date(has*1000).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
                hasEle = <p><i className="fontello-ok MountHasCheck" /> Marked as defeated on {hasDate}.</p>;
            } else if (char.id > 0) {
                hasEle = <p>You have not defeated this NPC.</p>;
            }
            let regional;
            if (ttnpc.usesRegionalRules) {
                regional = <div><span className="fontello-ok" /> Yes</div>;
            } else {
                regional = <div>No</div>;
            }
            let deckMapper = (cardId, i) => {
                let card = this.state.ttcards.find(c => c.id === cardId);
                let url = `${urlBase}ttcards/${card.id}/`;
                let type = this.state.ttcardTypes.find(type => type.id === card.ttcardTypeId);
                return <tr className="MountRow" key={card.id}>
                    <td className="MountRow-Icon"><a href={url}><span className={`icon icon-ttcards icon-ttcards-${card.id}`} title={card.name}/></a></td>
                    <td className="MountRow-Text">
                        <div className="TriadNumbers">
                            <span className="TriadNumbersTop">{card.top===10?'A':card.top}</span>
                            <span className="TriadNumbersLeft">{card.left===10?'A':card.left}</span>
                            <span className="TriadNumbersRight">{card.right===10?'A':card.right}</span>
                            <span className="TriadNumbersBottom">{card.bottom===10?'A':card.bottom}</span>
                        </div>
                    </td>
                    <td>
                        <strong>Type<br />{type ? type.name : '-'}</strong>
                    </td>
                </tr>;
            };
            let deckFixed = ttnpc.fixedTtcardIds.map(deckMapper);
            let deckVariable = ttnpc.variableTtcardIds.map(deckMapper);
            let rewards = ttnpc.rewardTtcardIds.map((cardId, i) => {
                let card = this.state.ttcards.find(c => c.id === cardId);
                let has = char.id > 0 && charStore.hasItem('triadCard', card.id);
                let url = `${urlBase}ttcards/${card.id}/`;
                let className = 'MountRow';
                let checkbox;
                if (has) {
                    className += ' MountRow-Have';
                    checkbox = <i className={'fontello-ok'}/>;
                }
                if (isCharMine) {
                    checkbox = <button onClick={(e) => this.toggleCard(card.id, !has)}>
                        <i className={this.state.char.char.triadCardload && this.state.char.char.triadCardload.includes(card.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                    </button>;
                } else if (has) {
                    checkbox = <i className={'fontello-ok'}/>;
                }
                return <tr className={className} key={card.id}>
                    <td className="MountRow-Check">{checkbox}</td>
                    <td className="MountRow-Icon"><a href={url}><span className={`icon icon-ttcards icon-ttcards-${card.id}`} title={card.name}/></a></td>
                    <td className="MountRow-Text">
                        <a href={url} className="MountRow-Name">{card.name}</a><br />
                        <CardSources urlBase={urlBase} lang={lang} char={char} card={card} npcs={this.state.npcs} ttnpcs={this.state.ttnpcs} placeNames={this.state.placeNames} />
                    </td>
                </tr>;
                //return <TriadCard cardId={cardId} style="table" key={i} showHas={true} />;
            });

            let checkbox;
            if (isCharMine) {
                checkbox = <span className={'MountRow-Check'}><button onClick={(e) => this.toggleItem(ttnpc.id, !has)}>
                    <i className={this.state.char.char.triadNpcload && this.state.char.char.triadNpcload.includes(ttnpc.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button></span>;
            } else if (char.id > 0) {
                checkbox = <i className={'fontello-ok'}/>;
            }

            let rules;
            if (this.state.ttrules.length > 1) {
                rules = ttnpc.ttruleIds
                    .map(ruleId => this.state.ttrules.find(rule => rule.id === ruleId))
                    .map(rule => rule ? rule.name : '').join(', ');
            }
            let placeName = this.state.placeNames.find(pn => pn.id === npc.placeNameId);

            details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                <a className="DetailsColumnClose" href={`/${this.props.lang}/char/${char.id}/ttnpcs/`}><i className="fontello-left-open" /> Back to NPC list</a>

                <h2 className={has ? 'Details-Have' : ''}>{checkbox} {npc.name}</h2>
                <div className="DetailsRewardsBox">
                    <div className="Pill Pill-Reward">Rules<br />{rules}</div>
                    <div className="Pill Pill-Reward">Regional Rules<br />{regional}</div>
                    <div className="Pill Pill-Reward">Fee<br /><i className="icon icon-rewards icon-rewards-065025" /> {ttnpc.fee || '-'}</div>
                </div>
                {hasEle}

                <h3>Location</h3>
                <DetailMap npc={npc} placeName={placeName} />

                <h3>Card rewards</h3>
                <table className="MountTable">
                    <tbody>
                    {rewards}
                    </tbody>
                </table>

                <h3>Cards in deck</h3>
                <table className="MountTable">
                    <tbody>
                    <tr><td colSpan={3}>All of these:</td></tr>
                    {deckFixed}
                    <tr><td colSpan={3}>Plus {5 - deckFixed.length} of these:</td></tr>
                    {deckVariable}
                    </tbody>
                </table>
            </div>;
        }



        return <div className="PageContent-WithDetails">

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                {char.id > 0 ? <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}ttcards/`}>Cards</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.triadCardPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.triadCardCount} / {char.triadCardPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.triadCardPossible-char.triadCardCount} remaining)</p>
                        <span className={cardProgressClassName} style={{width:char.triadCardPercent+'%'}}>&nbsp;</span>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}ttnpcs/`} className="current">NPCs</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.triadNpcPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.triadNpcCount} / {char.triadNpcPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.triadNpcPossible-char.triadNpcCount} remaining)</p>
                        <span className={npcProgressClassName} style={{width:char.triadNpcPercent+'%'}}>&nbsp;</span>
                    </div>
                </div> : <div className="CompletionSummaryContainer">
                <div className="CompletionSummary">
                    <h3><a href={`${urlBase}ttcards/`}>Cards</a></h3>
                </div>
                <div className="CompletionSummary">
                    <h3><a href={`${urlBase}ttnpcs/`} className="current">NPCs</a></h3>
                </div>
            </div>}
                <table className="MountTable">
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
            {details}

        </div>;
    }
}