import React, {Component} from 'react';
import '../../gamedata/spritesheet/portrait.css';
//import {useQuery, useApolloClient} from '@apollo/react-hooks';
//import gql from 'graphql-tag';
import Audio from '../../components/Audio';
let urlify = require('urlify').create();
let charStore = require('../../stores/char').default;
let userStore = require('../../stores/user').default;

let store = require('../../stores/gameData').default;
//let bardings = require('../../gamedata/json/en/bardings.json');

let filterTypes = ['All', 'Completed', 'Missing'];

function makeState() {
    return {
        char: charStore.getData(),
        sourceTypes: store.get('sourceTypes'),
        portraits: store.get('portraits'),
        portraitConditions: store.get('portraitConditions')
    };
}
export default class CharPortraitsRoute extends Component {
    state = Object.assign({
        sourceFilter: -1,
        filter: 'All',
    }, makeState());

    constructor(props) {
        super(props);
        this.toggleItem = this.toggleItem.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        charStore.on(this.onChange, this);
        store.on(this.onChange, this);
    }
    onChange() {
        this.setState(makeState());
    }
    componentWillUnmount() {
        charStore.off(this.onChange, this);
        userStore.off(this.onChange, this);
        store.off(this.onChange, this);
    }

    /*componentDidMount() {
        this.setState({loading: true});
        charStore.loadItems('barding').then(() => this.setState({loading: false}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.char.id !== this.props.char.id) {
            this.setState({loading: true});
            charStore.loadItems('barding').then(() => this.setState({loading: false}));
        }
    }*/

    onFilterClick(e) {
        this.setState({filter: e.target.innerText});
    }
    setFilter(e) {
        let f = e.target.dataset.source * 1;
        if (this.state.sourceFilter === f) {
            this.setState({sourceFilter: -1});
        } else {
            this.setState({sourceFilter: f});
        }
    }

    toggleItem(id, toggleTo) {
        if (toggleTo) {
            charStore.markItem('portrait', id);
        } else {
            charStore.unmarkItem('portrait', id);
        }
    }

    render() {
        let lang = this.props.lang;
        let char = this.props.char;
        let urlBase = this.props.urlBase;
        //let currentCategory = categories.find(c => c.Url === this.props.url[3]);
        let portraitType = 'framers';
        if (this.props.url[0].trim() === 'framers') portraitType = 'framers';
        if (this.props.url[0].trim() === 'others') portraitType = 'others';
        let currentId = this.props.url[1]*1;

        //if (!currentCategory) currentCategory = categories[0];
        //console.log(currentCategory);

        let isCharMine = userStore.getUserChars().find(c => c.id === char.id);

        let viewFilters = filterTypes.map(type => {
            return <button onClick={this.onFilterClick} className={this.state.filter===type?'current':''} key={type}>
                {type}
            </button>;
        });

        /*let filters = categories.map(category => {
            let urlified = category.Url;
            let hasCount = 0, totalCount = 0;
            bardings.filter(barding => {
                if (category.Id === 1) return true;
                return barding.bardingCategory === category;
            }).forEach(barding => {
                let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
                if (has) hasCount++;
                totalCount++;
            });
            let percent = hasCount * 100 / totalCount;
            let progressClassName = 'FilterProgressInner';
            if (percent === 100) {
                progressClassName += ' FilterProgressInner-Complete';
            }
            return <a href={`${urlBase}bardings/${urlified}`} className={currentCategory.Url===urlified?'current':''} key={category.Id}>
                {category.Name}
                <span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span>
            </a>;
        });*/
        let filters, filtersElements;
        if (portraitType === 'framers') {
            filters = this.state.portraitConditions.reduce(function (accumulator, d) {
                if (accumulator.indexOf(d.sourceTypeId) === -1) {
                    accumulator.push(d.sourceTypeId);
                }
                return accumulator;
            }, []).sort();
            filtersElements = filters.map((filterId) => {
                let sourceType = this.state.sourceTypes.find(t => t.id === filterId*1);
                if (!sourceType) return;
                let filterName = sourceType.name;
                let filteredConditions = this.state.portraitConditions.filter(condition => condition.obtainable && condition.sourceTypeId === filterId);
                let hasConditions = filteredConditions.filter(condition => char.id > 0 && charStore.hasItem('portrait', condition.id));
                let percent = char.id > 0 && hasConditions.length * 100 / filteredConditions.length;
                let progressClassName = 'FilterProgressInner';
                if (percent === 100) {
                    progressClassName += ' FilterProgressInner-Complete';
                }
                return <button data-source={filterId} key={filterId} className={this.state.sourceFilter === filterId*1?'current':''} onClick={this.setFilter}>
                    {filterName}
                    {char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
                </button>;
            });
        } else if (portraitType === 'others') {
            filters = [5,6];
            filtersElements = filters.map((filterId) => {
                let filterName = filterId;
                if (filterId === 1) filterName = 'Quest';
                if (filterId === 2) filterName = 'Misc';
                if (filterId === 4) filterName = 'Instance';
                if (filterId === 5) filterName = 'Emote';
                if (filterId === 6) filterName = 'Minion';
                let filteredConditions = this.state.portraitConditions.filter(condition => condition.obtainable && condition.unlockType === filterId);
                let hasConditions = filteredConditions.filter(condition => char.id > 0 && charStore.hasItem('portrait', condition.id));
                let percent = char.id > 0 && hasConditions.length * 100 / filteredConditions.length;
                let progressClassName = 'FilterProgressInner';
                if (percent === 100) {
                    progressClassName += ' FilterProgressInner-Complete';
                }
                return <button data-source={filterId} key={filterId} className={this.state.sourceFilter === filterId*1?'current':''} onClick={this.setFilter}>
                    {filterName}
                    {char.id > 0 ? <span className="FilterProgressContainer"><span className={progressClassName} style={{width:percent+'%'}}>&nbsp;</span></span> : ''}
                </button>;
            });
        }

        let hasCount = 0, totalCount = 0;
        let rows = this.state.portraitConditions.filter(condition => {
            if (condition.id === 1) return false;
            if (this.state.portraits.filter(portrait => portrait.portraitConditionId === condition.id).length === 0) return false;
            if (portraitType === 'framers') {
                if ((condition.unlockType === 5 || condition.unlockType === 6)) return false;
            } else if (portraitType === 'others') {
                if (!(condition.unlockType === 5 || condition.unlockType === 6)) return false;
            }
            return true;
            //if (currentCategory.Id === 1) return true;
            //return barding.bardingCategory === currentCategory;
        }).filter(condition => {
            //let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('portrait', condition.id);
            if (has) {
                hasCount++;
            }
            totalCount++;
            if (this.state.sourceFilter > 0 && portraitType === 'framers' && this.state.sourceFilter !== condition.sourceTypeId) return false;
            if (this.state.sourceFilter > 0 && portraitType === 'others' && this.state.sourceFilter !== condition.unlockType) return false;
            if (char.id > 0 && this.state.filter === 'Completed') {
                return has;
            } else if (char.id > 0 && this.state.filter === 'Missing') {
                return !has;
            } else {
                return true;
            }
        }).sort((a, b) => {
            if (a.sort < b.sort) {return -1;}
            if (a.sort > b.sort) {return 1;}
            if (a.id < b.id) {return -1;}
            if (a.id > b.id) {return 1;}
            return 0;
        }).map(condition => {
            let url = `${urlBase}portraits/${portraitType}/${condition.id}/`;
            //let has = this.state.char && this.state.char.barding && this.state.char.barding.find(l => l.bardingId === barding.Id && l.deleted !== true);
            let has = char.id > 0 && charStore.hasItem('portrait', condition.id);
            let howTo = condition.howTo;
            if (condition.unlockType === 5) {
                has = char.id > 0 && charStore.hasItem('emote', condition.emote.id);
                howTo = condition.emote.howTo;
            } else if (condition.unlockType === 6) {
                has = char.id > 0 && charStore.hasItem('minion', condition.minion.id);
                howTo = condition.minion.howTo;
            }
            let className = 'MountRow';
            if (has) {
                className += ' MountRow-Have';
            }
            let checkbox;
            if (isCharMine) {
                checkbox = <button onClick={(e) => this.toggleItem(condition.id, !has)}>
                    <i className={this.state.char.char.portraitload && this.state.char.char.portraitload.includes(condition.id) ? 'fontello-spin1 animate-spin' : 'fontello-ok'}/>
                </button>;
            } else {
                checkbox = <i className={'fontello-ok'}/>;
            }
            let icon;
            if (!condition.obtainable) icon = <i className="fontello-attention IconDecoration IconDecoration-Unobtainable" />;
            let portraits = this.state.portraits.filter(portrait => portrait.portraitConditionId === condition.id).map(portrait => {
                return <span key={portrait.id} className={`icon icon-portrait icon-portrait-${portrait.id}`} title={`[${portrait.id}] ${portrait.name}`}/>;
            });

            return <tr className={className} key={condition.id}>
                <td className="MountRow-Check">
                    {checkbox}
                </td>
                { /*<td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${condition.id}h`} title={condition.name}/>{icon}</td>
                <td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${condition.id}b`} title={condition.name}/></td>
                <td className="MountRow-Icon"><span className={`icon icon-barding icon-barding-${condition.id}l`} title={condition.name}/></td>*/ }
                <td className="MountRow-Text">
                    <a href={url} className="MountRow-Name">[{condition.id}] [{condition.unlockType}] {condition.name}</a><br />
                    <span dangerouslySetInnerHTML={{__html:howTo}} />
                </td>

                <td>
                    {portraits}
                </td>
            </tr>;
        });
        let percent = hasCount * 100 / totalCount;
        let progressClassName = 'FilterProgressInner';
        if (percent === 100) {
            progressClassName += ' FilterProgressInner-Complete';
        }

        let details = <div className="DetailsColumn">
            <p>Select an item to view details here.</p>
        </div>;
        console.log('asdfasdfasdfasfd', currentId);
        if (currentId > 0) {
            let condition = this.state.portraitConditions.find(l => l.id === currentId);
            console.log('asdfasdfasdfasfd', condition);
            if (condition) {
                /*let order;
                if (barding.Order < 1000) {
                    order = <div># {barding.Order}</div>
                } else {
                    order = <div>Hidden until obtained</div>
                }*/
                let organizedPortraits = {
                    plate: {
                        border: [],
                        accent: [],
                        frame: [],
                        portraitFrame: [],
                        patternOverlay: [],
                        backing: [],
                        base: []
                    },
                    portrait: {
                        pose: [],
                        frame: [],
                        expression: [],
                        accent: [],
                        background: []
                    }
                }
                let portraits = this.state.portraits.filter(portrait => portrait.portraitConditionId === condition.id).map(portrait => {
                    let type = '';
                    if (portrait.id > 120000) {type = 'Plate Top/Bottom Border'; organizedPortraits.plate.border.push(portrait);}
                    else if (portrait.id > 110000) {type = 'Plate Accent'; organizedPortraits.plate.accent.push(portrait);}
                    else if (portrait.id > 100000) {type = 'Plate Frame'; organizedPortraits.plate.frame.push(portrait);}
                    else if (portrait.id > 90000) {type = 'Plate Portrait Frame'; organizedPortraits.plate.portraitFrame.push(portrait);}
                    else if (portrait.id > 80000) {type = 'Plate Pattern Overlay'; organizedPortraits.plate.patternOverlay.push(portrait);}
                    else if (portrait.id > 70000) {type = 'Plate Backing'; organizedPortraits.plate.backing.push(portrait);}
                    else if (portrait.id > 60000) {type = 'Plate Base'; organizedPortraits.plate.base.push(portrait);}
                    else if (portrait.id > 50000) {type = 'Portrait Pose'; organizedPortraits.portrait.pose.push(portrait);}
                    else if (portrait.id > 40000) {type = 'Portrait Frame'; organizedPortraits.portrait.frame.push(portrait);}
                    else if (portrait.id > 30000) {type = 'Portrait Expression'; organizedPortraits.portrait.expression.push(portrait);}
                    else if (portrait.id > 20000) {type = 'Portrait Accent'; organizedPortraits.portrait.accent.push(portrait);}
                    else if (portrait.id > 10000) {type = 'Portrait Background'; organizedPortraits.portrait.background.push(portrait);}
                    return <div>
                        <h3>{type}</h3>
                        <span key={portrait.id} className={`icon icon-portrait icon-portrait-${portrait.id}`} title={`[${portrait.id}] ${portrait.name}`}/>
                        <pre>{JSON.stringify(portrait, ' ', 3)}</pre>
                    </div>;
                });
                details = <div className="DetailsColumn DetailsColumn-MobileVisible">
                    <a className="DetailsColumnClose"
                       href={`/${this.props.lang}/char/${char.id}/portraits/`}><i
                        className="fontello-left-open"/> Back to portrait list</a>
                    <pre>{JSON.stringify(condition, ' ', 3)}</pre>
                    <h3>Included Items</h3>
                    <ul>
                        <ol>Plate Top/Bottom Border</ol>
                    </ul>
                    {portraits}
                    { /*<div className="DetailsRewardsBox">
                        <div>{barding.bardingCategory.Name}</div>
                        <div>{order}</div>
                    </div>
                    <h3>In-game Source</h3>
                    <p>{barding.Description}</p>
                    <h3>Detailed Source</h3>
                    <p>&nbsp;</p>
                    <h3>Sample</h3>
                    <Audio src={`/assets/barding/${barding.Id}.ogg`} />*/ }
                </div>;
            }
        }

        return <div className="PageContent-WithDetails">
            {/*this.state.loading &&
            <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
            */}

            <div>

                {char.id > 0 ? <div className="FilterBoxContainer">
                    <div>&nbsp;</div>
                    <div className="FilterBox">
                        <h2>Filter</h2>
                        {viewFilters}
                    </div>
                </div> : ''}
                {char.id > 0 ? <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}portraits/framers/`}>Framer's Kits</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.portraitPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.portraitCount} / {char.portraitPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.portraitPossible-char.portraitCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.portraitPercent+'%'}}>&nbsp;</span>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}portraits/others/`}>Emotes &amp; Minions</a></h3>
                        <p className="CompletionSummary-Percent">{Math.floor(char.portraitPercent)}%</p>
                        <p className="CompletionSummary-Numbers">{char.portraitCount} / {char.portraitPossible}</p>
                        <p className="CompletionSummary-Remaining">({char.portraitPossible-char.portraitCount} remaining)</p>
                        <span className={progressClassName} style={{width:char.portraitPercent+'%'}}>&nbsp;</span>
                    </div>
                </div> : <div className="CompletionSummaryContainer">
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}portraits/framers/`}>Framer's Kits</a></h3>
                    </div>
                    <div className="CompletionSummary">
                        <h3><a href={`${urlBase}portraits/others/`}>Emotes &amp; Minions</a></h3>
                    </div>
                </div>}

                <div className="FilterBox">
                    <h2>Source</h2>
                    {filtersElements}
                </div>

                <table className="MountTable">
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

            {details}
        </div>;
    }
}